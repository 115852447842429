import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AuthReduxActionsCreators from '../../reducers/auth.reducer';

import { Helmet } from 'Components/Helmet';
import { Link } from 'react-router-dom';
import { Routes } from 'App/routes/Routes';
import { InputText } from 'Components/InputText';
import { validateFormFields } from 'Utils/commonFunction';
import loginImg from 'Assets/images/forgot-img.svg';
import Logo from 'Assets/images/rentpotential-logo.png';
import CloseIcon from 'Assets/images/close.png';
import loading from 'Assets/images/loading.gif';

const INITIAL_STATE = {
    email: null,
	
	errors: {
		email: '',
    },
}

class ForgotPassword extends Component {
	constructor(props){
		super(props);
		this.state = {
			...INITIAL_STATE,
		};
	}

	handleOnErrors = (fieldName, fieldValue) => {
		this.setState(prevState => ({
		  errors: {
			...prevState.errors,
			[fieldName]: fieldValue
		  }
		}));
	}

	handleOnChangeInput = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	}
	
	forgotPasswordFormValidate = () => {
        const { email } = this.state;
        const emailIsValid = validateFormFields('email', email);
        const stateOfErrors = 'errors';
        if (emailIsValid) {
            this.handleOnErrors('email', emailIsValid.message, stateOfErrors);
		}

        return emailIsValid.status
	}
	
	handleOnForgotPasswordSubmit = () => {
		const isForgotPasswordFormFieldsValid = this.forgotPasswordFormValidate();
		if(!isForgotPasswordFormFieldsValid){
			console.log('You may enter wrong value, please try again')
		} else {
			const { actions: { forgotPassword } } = this.props;
			const { email } = this.state;
			forgotPassword({ email });
			// console.log('Done')
		}
	}

	render(){
		const { errors, email } = this.state;
		let { fetching } = this.props;
      	return (
			<>
				<Helmet title="Rent Potential" metaName="description" metaContent="Rent Potential" />
				<div className="main-content">
					<div className="login-register-main">
						<div className="background-gray login-register-bg-img-side">
							<div className="login-register-logo">
								<img src={Logo} />
							</div>
							<div className="login-register-img">
								<img src={loginImg} />
							</div>
						</div>
						<div className="login-register-form-section">
							<Link className="close-btn-right" to={Routes.HOME_VIEW}>
								<img src={CloseIcon} />
							</Link>
							<div className="login-register-logo show-after-responsive">
								<img src={Logo} />
							</div>
							<div className="login-register-form">
								<h2 className="login-register-title">Reset Password</h2>
								<div className="form-group">
									<div className="row">
										<div className="col-md-12">
											<InputText singleLableText="Email Id" singleInputType="email" singleInputClass={`${errors.email && 'error-text-border'}`} singleInputPlaceholder="Enter Email" singleInputName="email" singleInputValue={email || ''} singleInputonChangeFunc={this.handleOnChangeInput} errorMsg={errors.email} />
										</div>
									</div>
								</div>
								<div className="form-group">
									<div className="row">
										<div className="col-md-12 text-center">
											{(fetching) ?
												(
													<button className="btn btn-primary loading-btn" disabled>Loading <img className="btn-loading" src={loading} /></button>
												)
											: (
												<button className="btn btn-primary form-btn" onClick={this.handleOnForgotPasswordSubmit}>SEND LINK</button>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
    	);
  	}
}

const mapStateToProps = (state) => ({
	fetching: state.authUsers.fetching,
	fetchingErr: state.authUsers.fetchingErr,
	fetchingErrMsg: state.authUsers.fetchingErrMsg,
})
  
const mapDispatchToProps = (dispatch) => {
	const reduxActions = {
	  ...AuthReduxActionsCreators
	};
	
  
	return { actions: bindActionCreators(reduxActions, dispatch)};
}

const connectedForgotPasswordPage = connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
export { connectedForgotPasswordPage as ForgotPassword };
