// Update Profile Loading/Fetching status
const setUpdateProfileLoading = (state, { status }) =>
  state.merge({ updateProfileLoading: status });

// Change Password Loading/Fetching status
const setChangePasswordLoading = (state, { status }) =>
  state.merge({ changePasswordLoading: status });

// Full Page Loading/Fetching status
const setFullPageLoading = (state, { status }) =>
  state.merge({ fullPageLoading: status });

// Error status set to TRUE/FALSE while ENDPOINT fetching issue.
const setFullPageLoadingErr = (state, { error }) =>
  state.merge({ fullPageLoadingErr: error });

// Loading/Fetching status
const setFetching = (state, { status }) => state.merge({ fetching: status });

// Error status set to TRUE/FALSE while ENDPOINT fetching issue.
const setFetchingErr = (state, { error }) =>
  state.merge({ fetchingErr: error });

// Error message set while ENDPOINT fetching issue.
const setFetchingErrMsg = (state, { errorMsg }) =>
  state.merge({ fetchingErrMsg: errorMsg });

//Defined Success action in case of user Profile successfully.
const getProfileSuccess = (state, { payload }) =>
  state.merge({ error: null, get_profile_success: payload });

//Defined Failure action in case of user Profile .
const getProfileFailure = (state, { payload }) =>
  state.merge({ fetching: false, error: null, get_profile_failure: payload });

//Defined Success action in case of user update Profile successfully.
const updateProfileSuccess = (state, { payload }) =>
  state.merge({ error: null, update_profile_success: payload });

//Defined Success action in case of user change password successfully.
const changePasswordSuccess = (state, { payload }) =>
  state.merge({ error: null, change_password_success: payload });

//Defined Success action in case of user delete User successfully.
const deleteUserSuccess = (state, { payload }) =>
  state.merge({ error: null, delete_user_success: payload });

//Defined Failure action in case of delete User.
const deleteUserFailure = (state, { payload }) =>
  state.merge({ error: null, delete_user_failure: payload });

//Defined Success action in case of open verify modal successfully.
const setVerifySuccess = (state, { status }) =>
  state.merge({ open_verify_modal: status });

//Defined Success action in case of close verify modal successfully.
const resendVerifyEmailSuccess = (state, { payload }) => {
  return state.merge({ error: null, close_verify_modal: payload });
};
//Defined Success action in case of Logout successfully.
const logoutSuccessHandler = (state) => {
  return state;
};

const setContactUsPostStatus = (state, { status }) =>
  state.merge({ statusForContactUs: status });

const authReducerActions = {
  setChangePasswordLoading,
  setUpdateProfileLoading,
  setFetching,
  setFullPageLoading,
  setFullPageLoadingErr,
  setFetchingErr,
  setFetchingErrMsg,
  getProfileSuccess,
  getProfileFailure,
  updateProfileSuccess,
  changePasswordSuccess,
  setVerifySuccess,
  deleteUserFailure,
  deleteUserSuccess,
  resendVerifyEmailSuccess,
  logoutSuccessHandler,
  setContactUsPostStatus,
};

export { authReducerActions as reduxAuthActions };
