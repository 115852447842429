import React, { Component } from "react";

import { Helmet } from "Components/Helmet";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AuthReduxActionsCreators from "../../reducers/auth.reducer";
import PaymentReduxActionsCreators from "Reducers/payment.reducer";
import { Header } from "Components/Header";
import { Footer } from "Components/Footer";
import { AlertAndDeleteModal } from "Components/AlertAndDeleteModal";
import { Modal } from "Components/Modal";
import { InputText } from "Components/InputText";
import { PaymentSuccessUnsuccessModal } from "Components/PaymentSuccessUnsuccessModal";
import { PaymentCheckoutModal } from "Components/PaymentCheckoutModal";

import { validateFormFields } from "Utils/commonFunction";

import profileHeader from "Assets/images/profile_header.svg";
import arrowRight from "Assets/images/arrow-right.png";
import alertProfileImg from "Assets/images/alert_profile.svg";
import deleteProfileImg from "Assets/images/delete_profile.svg";
import creditCardImg from "Assets/images/credit-card.svg";
import paypalImg from "Assets/images/paypal.svg";
import paymentSuccessImg from "Assets/images/payment_success.svg";
import paymentUnsuccessImg from "Assets/images/payment_unsuccess.svg";
import loading from "Assets/images/loading.gif";
import { Link } from "react-router-dom";

const INITIAL_STATE = {
  name: null,
  email: null,
  oldPassword: null,
  newPassword: null,
  confirmPassword: null,

  errors: {
    name: "",
    email: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  },
};

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVerifyModalOpen: false,
      isAlertModalOpen: false,
      isDeleteModalOpen: false,
      isPaymentMethodModalOpen: false,
      isPaymentDetailsModalOpen: false,
      isPaymentSuccessModalOpen: false,
      isPaymentUnsuccessModalOpen: false,
      accountEmail: null,
      accountStatus: null,
      startDate: null,
      endDate: null,
      nextBillingDate: null,
      planTypeAmount: null,
      planName: "",
      planStatus: null,
      oneTimePlan: 0,
      ...INITIAL_STATE,
      selectedPlanName: null,
      selectedPlanPrice: null,
      planMembershipPrice: null,
      planOnetimePrice: null,
      planStandardPrice: null,
      paymentMethod: null,
    };
  }

  async componentDidMount() {
    const {
      actions: { getUserProfile, planStatus },
      location,
    } = this.props;

    const { planName } = this.state;

    if ("location" in this.props && location.search) {
      const requestParams = queryString.parse(location.search);
      if (requestParams.PayerID && requestParams.token) {
        const {
          actions: { doExpressCheckoutSuccess },
        } = this.props;
        doExpressCheckoutSuccess({
          query: location.search,
          planType: planName.toString(),
        });
      }
    }

    await getUserProfile();
    await planStatus();
  }

  componentDidUpdate(prevProps, preState) {
    if (
      prevProps.planStatusSuccess !== this.props.planStatusSuccess &&
      this.props.planStatusSuccess
    ) {
      const { planStatusSuccess } = this.props;
      this.setState({
        planMembershipPrice:
          planStatusSuccess.data &&
          planStatusSuccess.data.plan_membership.plan_price,
        planOnetimePrice:
          planStatusSuccess.data &&
          planStatusSuccess.data.plan_onetime.plan_price,
        planStandardPrice:
          planStatusSuccess.data &&
          planStatusSuccess.data.plan_standard.plan_price,
      });
    }
    if (
      prevProps.get_profile_success !== this.props.get_profile_success &&
      this.props.get_profile_success
    ) {
      const { get_profile_success } = this.props;
      let statusAccount;
      let nextBilling;
      let oneTimePlanCount;
      if (
        get_profile_success.data &&
        get_profile_success.data.current_plan.status == "recurring"
      ) {
        statusAccount = "Active";
      } else if (
        get_profile_success.data.current_plan.status == "onGracePeriod"
      ) {
        statusAccount = `End At ${get_profile_success.data.current_plan.cancel_at}`;
      }
      if (
        get_profile_success.data &&
        get_profile_success.data.current_plan.status == "recurring"
      ) {
        nextBilling = get_profile_success.data.current_plan.current_period_end;
      } else if (
        get_profile_success.data.current_plan.status == "onGracePeriod"
      ) {
        nextBilling = get_profile_success.data.current_plan.cancel_at;
      }
      if (
        (!get_profile_success.data && get_profile_success.data.one_time_plan) ||
        (get_profile_success.data &&
          get_profile_success.data.one_time_plan == null) ||
        (get_profile_success.data &&
          get_profile_success.data.one_time_plan == "")
      ) {
        oneTimePlanCount = 0;
      } else {
        oneTimePlanCount = 1;
      }

      this.setState({
        name: get_profile_success.data.user.name,
        email: get_profile_success.data.user.email,
        accountEmail: get_profile_success.data.user.email,
        accountStatus: statusAccount,
        startDate: get_profile_success.data.current_plan.current_period_start,
        endDate: get_profile_success.data.current_plan.current_period_end,
        nextBillingDate: nextBilling,
        planTypeAmount: get_profile_success.data.current_plan.plan,
        planName: get_profile_success.data.current_plan.plan,
        planStatus: get_profile_success.data.current_plan.status,
        oneTimePlan: oneTimePlanCount,
        // oneTimePlan: get_profile_success.data.one_time_plan,
        paymentMethod: get_profile_success.data.payment_method
          ? get_profile_success.data.payment_method
          : "STRIPE",
      });
    }
    if (
      prevProps.delete_user_success !== this.props.delete_user_success &&
      this.props.delete_user_success
    ) {
      this.setState({ isDeleteModalOpen: false });
    }
    if (
      prevProps.get_profile_failure !== this.props.get_profile_failure &&
      this.props.get_profile_failure
    ) {
      const emailVerifiedAt = localStorage.getItem("email_verified_at");
      if (emailVerifiedAt == "null") {
        this.setState({ isVerifyModalOpen: true });
      }
    }
    if (
      prevProps.delete_user_failure !== this.props.delete_user_failure &&
      this.props.delete_user_failure
    ) {
      const emailVerifiedAt = localStorage.getItem("email_verified_at");
      if (emailVerifiedAt == "null") {
        this.setState({ isVerifyModalOpen: true, isDeleteModalOpen: false });
      }
    }
    if (
      prevProps.update_profile_success !== this.props.update_profile_success &&
      this.props.update_profile_success
    ) {
      const { update_profile_success } = this.props;
      this.setState({
        name: update_profile_success.data.name,
        email: update_profile_success.data.email,
        accountEmail: update_profile_success.data.email,
      });
    }
    if (
      prevProps.change_password_success !==
        this.props.change_password_success &&
      this.props.change_password_success
    ) {
      this.setState({
        oldPassword: null,
        newPassword: null,
        confirmPassword: null,
      });
    }
    if (
      prevProps.close_verify_modal !== this.props.close_verify_modal &&
      this.props.close_verify_modal
    ) {
      this.setState({ isVerifyModalOpen: false });
    }
    if (
      prevProps.stripeClosePlanSuccess !== this.props.stripeClosePlanSuccess &&
      this.props.stripeClosePlanSuccess
    ) {
      const { stripeClosePlanSuccess } = this.props;
      // console.log("stripeClosePlanSuccess------",stripeClosePlanSuccess)
      this.setState({ isAlertModalOpen: false });
      const {
        actions: { getUserProfile },
      } = this.props;
      getUserProfile();
    }
    if (
      prevProps.stripePaymentSuccessModal !==
        this.props.stripePaymentSuccessModal &&
      this.props.stripePaymentSuccessModal
    ) {
      const { stripePaymentSuccessModal } = this.props;
      this.setState({
        isPaymentDetailsModalOpen: false,
        isPaymentSuccessModalOpen: stripePaymentSuccessModal,
      });
    }
    if (
      prevProps.stripePaymentFailureModal !==
        this.props.stripePaymentFailureModal &&
      this.props.stripePaymentFailureModal
    ) {
      const { stripePaymentFailureModal } = this.props;
      this.setState({
        isPaymentDetailsModalOpen: false,
        isPaymentUnsuccessModalOpen: stripePaymentFailureModal,
      });
    }

    if (
      prevProps.statusOfExpressCheckoutPayPal !==
        this.props.statusOfExpressCheckoutPayPal &&
      this.props.statusOfExpressCheckoutPayPal
    ) {
      const { statusOfExpressCheckoutPayPal } = this.props;
      this.setState({
        isPaymentSuccessModalOpen: statusOfExpressCheckoutPayPal,
      });
    }

    if (
      prevProps.statusOfExpressCheckoutPayPal !==
        this.props.statusOfExpressCheckoutPayPal &&
      !this.props.statusOfExpressCheckoutPayPal
    ) {
      const { statusOfExpressCheckoutPayPal } = this.props;
      this.setState({
        isPaymentUnsuccessModalOpen: statusOfExpressCheckoutPayPal,
      });
    }
  }

  handleOpenCloseVerifyModal = () => {
    this.setState((prevState) => {
      return { isVerifyModalOpen: !prevState.isVerifyModalOpen };
    });
  };

  handleOpenCloseAlertModal = () => {
    this.setState((prevState) => {
      return { isAlertModalOpen: !prevState.isAlertModalOpen };
    });
  };

  handleOpenCloseDeleteModal = () => {
    this.setState((prevState) => {
      return { isDeleteModalOpen: !prevState.isDeleteModalOpen };
    });
  };

  handleOpenClosePaymentMethodModal = () => {
    this.setState((prevState) => {
      return { isPaymentMethodModalOpen: !prevState.isPaymentMethodModalOpen };
    });
  };

  handleOpenClosePaymentDetailsModal = () => {
    this.setState((prevState) => {
      return {
        isPaymentMethodModalOpen: false,
        isPaymentDetailsModalOpen: !prevState.isPaymentDetailsModalOpen,
      };
    });
  };

  handleOpenClosePaymentBackModal = () => {
    this.setState({
      isPaymentMethodModalOpen: true,
      isPaymentDetailsModalOpen: false,
    });
  };

  handleOpenClosePaymentSuccessModal = () => {
    this.setState((prevState) => {
      return {
        isPaymentDetailsModalOpen: false,
        isPaymentSuccessModalOpen: !prevState.isPaymentSuccessModalOpen,
      };
    });
    const {
      actions: { getUserProfile },
    } = this.props;
    getUserProfile();
  };

  handleOpenClosePaymentUnsuccessModal = () => {
    this.setState((prevState) => {
      return {
        isPaymentMethodModalOpen: false,
        isPaymentUnsuccessModalOpen: !prevState.isPaymentUnsuccessModalOpen,
      };
    });
  };

  handleOnErrors = (fieldName, fieldValue) => {
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [fieldName]: fieldValue,
      },
    }));
  };

  handleOnChangeInput = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  changePasswordFormValidate = () => {
    const { oldPassword, newPassword, confirmPassword } = this.state;
    const oldPasswordIsValid = validateFormFields("password", oldPassword);
    const newPasswordIsValid = validateFormFields("password", newPassword);
    const confirmPasswordIsValid = validateFormFields(
      "confirmPassword",
      confirmPassword
    );
    const stateOfErrors = "errors";
    if (oldPasswordIsValid) {
      this.handleOnErrors(
        "oldPassword",
        oldPasswordIsValid.message,
        stateOfErrors
      );
    }

    if (newPasswordIsValid) {
      this.handleOnErrors(
        "newPassword",
        newPasswordIsValid.message,
        stateOfErrors
      );
    }

    if (confirmPasswordIsValid) {
      this.handleOnErrors(
        "confirmPassword",
        confirmPasswordIsValid.message,
        stateOfErrors
      );
    }

    return (
      oldPasswordIsValid.status &&
      newPasswordIsValid.status &&
      confirmPasswordIsValid.status
    );
  };

  handleOnChangePasswordSubmit = () => {
    const isChangePasswordFormFieldsValid = this.changePasswordFormValidate();
    if (!isChangePasswordFormFieldsValid) {
      console.log("You may enter wrong value, please try again");
    } else {
      const { oldPassword, newPassword, confirmPassword } = this.state;
      if (newPassword != confirmPassword) {
        this.setState({
          errors: {
            confirmPassword: "Confirm password must be same as password",
          },
        });
      } else {
        const {
          actions: { changePassword },
        } = this.props;
        changePassword({
          old_password: oldPassword,
          new_password: newPassword,
          new_password_confirmation: confirmPassword,
        });
        // console.log('Done')
      }
    }
  };

  updateProfileFormValidate = () => {
    const { name, email } = this.state;
    const nameIsValid = validateFormFields("name", name);
    const emailIsValid = validateFormFields("email", email);
    const stateOfErrors = "errors";
    if (nameIsValid) {
      this.handleOnErrors("name", nameIsValid.message, stateOfErrors);
    }
    if (emailIsValid) {
      this.handleOnErrors("email", emailIsValid.message, stateOfErrors);
    }

    return nameIsValid.status && emailIsValid.status;
  };

  handleOnUpdateProfileSubmit = () => {
    const isUpdateProfileFormFieldsValid = this.updateProfileFormValidate();
    if (!isUpdateProfileFormFieldsValid) {
      console.log("You may enter wrong value, please try again");
    } else {
      const { name, email } = this.state;
      const {
        actions: { updateUserProfile },
      } = this.props;
      updateUserProfile({ name, email });
    }
  };

  handleOnClickResendEmail = () => {
    const userId = localStorage.getItem("logged_user_ID");
    const {
      actions: { resendUserVerifyEmail },
    } = this.props;
    resendUserVerifyEmail({ userId });
  };

  handleOnDelete = () => {
    const id = localStorage.getItem("logged_user_ID");
    console.log("Delete-----------", id);
    const {
      actions: { deleteUser },
    } = this.props;
    deleteUser({ id });
  };

  handleOnClosePlan = () => {
    const { planName, paymentMethod } = this.state;
    if (planName == "PREMIUM") {
      var plan = "premium";
    } else {
      var plan = "standard";
    }
    // console.log('planName-----------',plan)
    const {
      actions: { stripeClosePlan },
    } = this.props;
    stripeClosePlan({ plan, paymentMethod });
  };

  handleOnPayByPayPalSubmit = async () => {
    const { planName } = this.state;
    const {
      actions: { doExpressCheckoutPaypalPayment },
    } = this.props;
    await doExpressCheckoutPaypalPayment({
      planType: planName.toString(),
      recurring: true,
    });
  };

  render() {
    const {
      isVerifyModalOpen,
      isAlertModalOpen,
      isDeleteModalOpen,
      isPaymentMethodModalOpen,
      isPaymentDetailsModalOpen,
      isPaymentSuccessModalOpen,
      isPaymentUnsuccessModalOpen,
      errors,
      name,
      email,
      oldPassword,
      newPassword,
      confirmPassword,
      accountEmail,
      accountStatus,
      startDate,
      endDate,
      nextBillingDate,
      planTypeAmount,
      planStatus,
      oneTimePlan,
      planMembershipPrice,
      planOnetimePrice,
      planStandardPrice,
    } = this.state;
    let {
      changePasswordLoading,
      updateProfileLoading,
      stripeClosePlanSuccess,
    } = this.props;
    return (
      <>
        <Helmet
          title="Rent Potential :: Profile"
          metaName="description"
          metaContent="Rent Potential"
        />
        <div className="main-content">
          <Header
            pageTitle="Edit"
            pageSubTitle="PROFILE"
            pageHeaderImg={profileHeader}
          />
          <div className="container padding-top-bot pt-0">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-10 offset-lg-1">
                <div className="row">
                  <div className="col-md-6">
                    <div className="membership-plan-main">
                      <h2 className="profile-title">edit Profile</h2>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-2">
                            <label className="form-lable">Name</label>
                          </div>
                          <div className="col-md-10">
                            <input
                              type="text"
                              className={`form-input form-control ${
                                errors.name && "error-text-border"
                              }`}
                              placeholder="Enter Name"
                              name="name"
                              value={name || ""}
                              onChange={this.handleOnChangeInput}
                            />
                            <span className="error-msg">
                              {errors.name && errors.name}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-2">
                            <label className="form-lable">Email</label>
                          </div>
                          <div className="col-md-10">
                            <input
                              type="email"
                              className={`form-input form-control ${
                                errors.email && "error-text-border"
                              }`}
                              placeholder="Enter Email"
                              name="email"
                              value={email || ""}
                              onChange={this.handleOnChangeInput}
                            />
                            <span className="error-msg">
                              {errors.email && errors.email}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        {updateProfileLoading ? (
                          <button
                            className="btn btn-primary profile-loading-btn"
                            disabled
                          >
                            Loading{" "}
                            <img className="btn-loading" src={loading} />
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary profile-btn"
                            onClick={this.handleOnUpdateProfileSubmit}
                          >
                            update profile
                          </button>
                        )}
                      </div>
                      <div className="form-group">
                        <InputText
                          singleLableText="Enter Old Password"
                          singleInputType="password"
                          singleInputClass={`${
                            errors.oldPassword && "error-text-border"
                          }`}
                          singleInputPlaceholder="Enter Old Password"
                          singleInputName="oldPassword"
                          singleInputValue={oldPassword || ""}
                          singleInputonChangeFunc={this.handleOnChangeInput}
                          errorMsg={errors.oldPassword}
                        />
                      </div>
                      <div className="form-group">
                        <InputText
                          singleLableText="Enter New Password"
                          singleInputType="password"
                          singleInputClass={`${
                            errors.newPassword && "error-text-border"
                          }`}
                          singleInputPlaceholder="Enter New Password"
                          singleInputName="newPassword"
                          singleInputValue={newPassword || ""}
                          singleInputonChangeFunc={this.handleOnChangeInput}
                          errorMsg={errors.newPassword}
                        />
                      </div>
                      <div className="form-group">
                        <InputText
                          singleLableText="Enter Confirm Password"
                          singleInputType="password"
                          singleInputClass={`${
                            errors.confirmPassword && "error-text-border"
                          }`}
                          singleInputPlaceholder="Enter Confirm Password"
                          singleInputName="confirmPassword"
                          singleInputValue={confirmPassword || ""}
                          singleInputonChangeFunc={this.handleOnChangeInput}
                          errorMsg={errors.confirmPassword}
                        />
                      </div>
                      <div className="form-group text-center m-t">
                        <div className="row">
                          <div className="col-md-6 text-left">
                            {changePasswordLoading ? (
                              <button
                                className="btn btn-primary profile-loading-btn"
                                disabled
                              >
                                Loading{" "}
                                <img className="btn-loading" src={loading} />
                              </button>
                            ) : (
                              <button
                                className="btn btn-primary profile-btn"
                                onClick={this.handleOnChangePasswordSubmit}
                              >
                                update password
                              </button>
                            )}
                          </div>
                          <div className="col-md-6 text-right mt-2 m-view-account">
                            <a
                              className="prof-delete link-text-color"
                              onClick={this.handleOpenCloseDeleteModal}
                            >
                              Delete account
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="membership-plan-main">
                      <h2 className="profile-title">Plan type</h2>
                      {oneTimePlan > 0 ? (
                        <div className="text-center">
                          <div>
                            You have used your three free uses, but have
                            purchased an additional use for $2.99.
                          </div>
                          <div className="m-t-20">
                            Use Calculator or{" "}
                            <Link to="/premium-plan">Sign Up</Link> for monthly
                            (unlimited) access.
                          </div>
                          {/* <Link className="btn btn-primary get-started-btn" to="/login">get started</Link> */}
                          <Link
                            className="btn btn-primary plan-btn plan-active-btn"
                            to="/premium-plan"
                          >
                            get started
                          </Link>
                          {/* <button class="btn btn-primary plan-btn plan-active-btn">get started</button> */}
                        </div>
                      ) : planStatus == "_ended" || !planStatus ? (
                        <div className="text-center">
                          <div>
                            Currently, your subscription is not active.
                            <br /> You can resume your subscription by click the
                            Resume Plan button below
                          </div>
                          {/* <Link className="btn btn-primary get-started-btn" to="/login">get started</Link> */}
                          <Link
                            className="btn btn-primary plan-btn plan-active-btn"
                            to="/plans"
                          >
                            get started
                          </Link>
                          {/* <button class="btn btn-primary plan-btn plan-active-btn">get started</button> */}
                        </div>
                      ) : (
                        <>
                          <div className="text-center">
                            {planTypeAmount == "PREMIUM" ? (
                              <>
                                <h2 className="profile-plan-title">
                                  ${planMembershipPrice}
                                </h2>
                              </>
                            ) : null}
                            {planTypeAmount == "STANDARD" ? (
                              <>
                                <h2 className="profile-plan-title">
                                  ${planStandardPrice}
                                </h2>
                              </>
                            ) : null}
                            {/* <h2 className="profile-plan-title">
                              {planTypeAmount == "Premium" ? "$14.99" : ""}
                              {planTypeAmount == "Standard" ? "$9.99" : ""}
                            </h2> */}
                            <p className="profile-plan-subtitle">per month</p>
                          </div>
                          <div className="form-group">
                            <label className="form-lable">Email</label>
                            <input
                              type="email"
                              className="form-input form-control"
                              placeholder="Email"
                              name="accountEmail"
                              value={accountEmail || ""}
                              disabled
                            />
                          </div>
                          <div className="form-group">
                            <label className="form-lable">Account Status</label>
                            <input
                              type="text"
                              className="form-input form-control"
                              placeholder="Active"
                              name="accountStatus"
                              value={accountStatus || ""}
                              disabled
                            />
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="form-lable">Start Date</label>
                                <input
                                  type="text"
                                  className="form-input form-control"
                                  placeholder="11 June 2020"
                                  name="startDate"
                                  value={startDate || ""}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="form-lable">End Date</label>
                                <input
                                  type="text"
                                  className="form-input form-control"
                                  placeholder="11 June 2020"
                                  name="endDate"
                                  value={endDate || ""}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="form-lable">
                              Next Billing Date
                            </label>
                            <input
                              type="text"
                              className="form-input form-control"
                              placeholder="11 June 2020"
                              name="nextBillingDate"
                              value={nextBillingDate || ""}
                              disabled
                            />
                          </div>
                          <div className="form-group text-center m-t">
                            <div className="row">
                              <div className="col-md-5 text-left">
                                <button
                                  className="btn btn-primary profile-btn"
                                  onClick={this.handleOpenCloseAlertModal}
                                >
                                  cancel plan
                                </button>
                              </div>
                              <div className="col-md-7 text-right">
                                <button
                                  className="btn btn-primary profile-btn"
                                  onClick={
                                    this.handleOpenClosePaymentMethodModal
                                  }
                                >
                                  update payment method
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>

        <Modal
          isModalOpen={isVerifyModalOpen}
          handleOnRequestClose={this.handleOpenCloseVerifyModal}
          customStyle="modal-dialog modal-dialog-centered modal-lg verify-mail-modal"
        >
          <div className="modal-content multi-step">
            <div className="modal-body multi-step">
              <div className="text-center modal-header">
                <h2 className="modal-title">VERIFY YOUR EMAIL ADDRESS</h2>
                <span
                  className="modal-close"
                  aria-hidden="true"
                  onClick={this.handleOpenCloseVerifyModal}
                >
                  &times;
                </span>
              </div>
              <div className="text-center modal-body">
                <p>
                  Before proceeding, please check your email for a verification
                  link. If no email is received, please check your spam inbox
                  before requesting a new verification email,{" "}
                  <span
                    className="resend-email-link"
                    onClick={this.handleOnClickResendEmail}
                  >
                    click here to request another.
                  </span>
                </p>
                {/* <button className="btn btn-primary multi-step-close" onClick={this.handleOpenCloseVerifyModal}>Close</button> */}
              </div>
            </div>
          </div>
        </Modal>

        <AlertAndDeleteModal
          isModalOpen={isAlertModalOpen}
          handleOnRequestClose={this.handleOpenCloseAlertModal}
          customStyle="modal-dialog modal-dialog-centered modal-md"
          popupTitle="Alert"
          popupTitleClass="payment-alert-popup-title"
          popupImage={alertProfileImg}
          popupBtnClass="popup-img"
          nosOfButtonToShow={2}
          popupBtnTextLeft="Yes"
          popupBtnActionLeft={this.handleOnClosePlan}
          popupBtnTextRight="No"
          popupBtnActionRight={this.handleOpenCloseAlertModal}
        >
          <p className="popup-alert-text">
            Do you really want to cancel this plan?
            <br />
            Your amount will not be refunded.
          </p>
        </AlertAndDeleteModal>

        <AlertAndDeleteModal
          isModalOpen={isDeleteModalOpen}
          handleOnRequestClose={this.handleOpenCloseDeleteModal}
          customStyle="modal-dialog modal-dialog-centered modal-md"
          popupTitle="delete account ?"
          popupTitleClass="payment-alert-popup-title"
          popupImage={deleteProfileImg}
          popupBtnClass="popup-img"
          nosOfButtonToShow={2}
          popupBtnTextLeft="Yes"
          popupBtnActionLeft={this.handleOnDelete}
          popupBtnTextRight="No"
          popupBtnActionRight={this.handleOpenCloseDeleteModal}
        >
          <p className="popup-alert-text">
            Are you sure you want to delete
            <br />
            your account?
          </p>
        </AlertAndDeleteModal>

        <Modal
          isModalOpen={isPaymentMethodModalOpen}
          handleOnRequestClose={this.handleOpenClosePaymentMethodModal}
          customStyle="modal-dialog modal-dialog-centered modal-md"
        >
          <div className="modal-content multi-step">
            <div className="modal-body multi-step">
              <div className="text-center">
                <h2 className="popup-title payment-method-title">
                  CHOOSE PAYMENT METHOD
                </h2>
                <div
                  className="payment-method-main"
                  onClick={this.handleOpenClosePaymentDetailsModal}
                >
                  <img className="card-payment-icon" src={creditCardImg} />
                  <p className="payment-method-text">pay by credit card</p>
                </div>
                <div
                  className="payment-method-main"
                  onClick={this.handleOnPayByPayPalSubmit}
                >
                  <img className="paypal-payment-icon" src={paypalImg} />
                  <p className="payment-method-text">Paypal Payment</p>
                </div>
                <button
                  className="btn btn-primary multi-step-close"
                  onClick={this.handleOpenClosePaymentMethodModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <PaymentCheckoutModal
          isModalOpen={isPaymentDetailsModalOpen}
          handleOnRequestClose={this.handleOpenClosePaymentDetailsModal}
          customStyle="modal-dialog modal-dialog-centered modal-lg"
          popupTitle="payment details"
          popupTitleClass="payment-method-title"
          planName={this.state.planName == "PREMIUM" ? "premium" : "standard"}
          popupBtnCloseAction={this.handleOpenClosePaymentBackModal}
          popupBtnText={`pay now ($${
            this.state.planName == "PREMIUM"
              ? planMembershipPrice
              : planStandardPrice
          })`}
        />

        <PaymentSuccessUnsuccessModal
          isModalOpen={isPaymentSuccessModalOpen}
          handleOnRequestClose={this.handleOpenClosePaymentSuccessModal}
          customStyle="modal-dialog modal-dialog-centered modal-md"
          popupTitle="payment successful"
          popupTitleClass="payment-success-title"
          popupImage={paymentSuccessImg}
          popupBtnClass="popup-img"
          popupBtnText="Complete"
          popupBtnAction={this.handleOpenClosePaymentSuccessModal}
          popupBtnClass="payment-success"
          buttonIcon={arrowRight}
          buttonIconClass="payment-success-icon"
        >
          <p className="popup-text">
            The payment of{" "}
            <strong className="color-dark-gray">
              $
              {this.state.planName == "PREMIUM"
                ? planMembershipPrice
                : planStandardPrice}
            </strong>{" "}
            has been
            <br />
            received successfully.
          </p>
        </PaymentSuccessUnsuccessModal>

        <PaymentSuccessUnsuccessModal
          isModalOpen={isPaymentUnsuccessModalOpen}
          handleOnRequestClose={this.handleOpenClosePaymentUnsuccessModal}
          customStyle="modal-dialog modal-dialog-centered modal-md"
          popupTitle="payment unSuccessful"
          popupTitleClass="payment-unsuccess-title"
          popupImage={paymentUnsuccessImg}
          popupBtnClass="popup-img"
          popupBtnText="try again"
          popupBtnAction={this.handleOpenClosePaymentUnsuccessModal}
          popupBtnClass="payment-unsuccess"
          buttonIcon={arrowRight}
          buttonIconClass="payment-unsuccess-icon"
        >
          <p className="popup-text">
            We are not able to process you payment.
            <br />
            Please try again.
          </p>
        </PaymentSuccessUnsuccessModal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  planStatusSuccess: state.payment.planStatusSuccess,
  get_profile_success: state.authUsers.get_profile_success,
  get_profile_failure: state.authUsers.get_profile_failure,
  update_profile_success: state.authUsers.update_profile_success,
  change_password_success: state.authUsers.change_password_success,
  delete_user_success: state.authUsers.delete_user_success,
  delete_user_failure: state.authUsers.delete_user_failure,
  close_verify_modal: state.authUsers.close_verify_modal,
  changePasswordLoading: state.authUsers.changePasswordLoading,
  updateProfileLoading: state.authUsers.updateProfileLoading,
  stripeClosePlanSuccess: state.payment.stripeClosePlanSuccess,
  stripePaymentSuccessModal: state.payment.stripePaymentSuccessModal,
  stripePaymentFailureModal: state.payment.stripePaymentFailureModal,
  statusOfExpressCheckoutPayPal: state.payment.statusOfExpressCheckoutPayPal,
});

const mapDispatchToProps = (dispatch) => {
  const reduxActions = {
    ...AuthReduxActionsCreators,
    ...PaymentReduxActionsCreators,
  };

  return { actions: bindActionCreators(reduxActions, dispatch) };
};

const connectedProfilePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
export { connectedProfilePage as Profile };
