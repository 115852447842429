//File for Authentication controller in the backend.
import axios from '../config/axios.config';
import API_ENDPOINT from '../constants/api.constants';
import { getItemInLocalStorage } from 'App/utils/commonFunction';

//Method for getting addresses by postal code.
function* getAddressesByPostcodeQuery(payload){
    try {
        const response = yield axios.get(`${API_ENDPOINT.getAddress}?pincode=${payload.postalCode}`, true);
        return yield Promise.resolve(response);
     } catch (error) {
         return yield Promise.reject(error);
     }
}

//Method for generate Report.
function* generateReportQuery(payload){
    try {
        const headers = {
            'Authorization': `Bearer ${getItemInLocalStorage('logged_user_auth_token')}`
        }
        const response = yield axios.post(`${API_ENDPOINT.calculator}`, payload, { headers: headers }, true);
        // console.log("Api try response ------", response)
        return yield Promise.resolve(response);
     } catch (error) {
        // console.log("Api error response ------", error)
        return yield Promise.reject(error);
     }
}


//Exporting service methods.
export {
    getAddressesByPostcodeQuery,
    generateReportQuery
};