import React from 'react';
import {CardElement, CardNumberElement, CardExpiryElement, CardCvcElement} from 'react-stripe-elements';

class CardSection extends React.Component {
  render() {
    return (
      <>
      <label className="label">
        <div className="form-group">
          <div className="row">
            <div className="col-md-12">
              <label className="form-lable">Card Number</label>
              <CardNumberElement style={{base: {fontSize: '16px', color: '#b5b5b5'}}} />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-md-6">
              <label className="form-lable">CVV</label>
              <CardCvcElement style={{base: {fontSize: '16px', color: '#b5b5b5'}}} />
            </div>
            <div className="col-md-6">
              <label className="form-lable">Expiry Month/Year</label>
              <CardExpiryElement style={{base: {fontSize: '16px', color: '#b5b5b5'}}} />
            </div>
          </div>
        </div>
      </label>
      </>
    );
  }
}

export default CardSection;