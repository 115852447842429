import { Component } from 'react'

class Routes extends Component {

  static HOME_VIEW = '/';
  static ABOUT_VIEW = '/about-us';
  static COUNTACT_US_VIEW = '/contact-us';
  static PREMIUM_PLAN_VIEW = '/premium-plan';
  static ONE_AND_DONE_PLAN_VIEW = '/one-and-done-plan';
  static STANDARD_PLAN_VIEW = '/standard-plan';
  static LOGIN_VIEW = '/login';
  static REGISTER_VIEW = '/register';
  static PROFILE_VIEW = '/profile';
  static FORGOT_PASSWORD_VIEW = '/forgot-password';
  // static POTENTIAL_REPORT_VIEW = '/potential-report/:id';
  static SAVE_REPORTS_VIEW = '/saved-report';
  // static PASS_REPORT_VIEW = '/pass-report/:id';
  static PLANS_VIEW = '/plans';
  static RESET_PASSWORD_VIEW = '/reset-password';
  static TERMS_AND_CONDITIONS_VIEW = '/terms-and-conditions';
  static REPORT_RESULT_VIEW = '/reportresult/:id';
  static REPORT_VIEW = '/report/:id';
  static EDIT_REPORT_VIEW = '/editreport/:id';
  static EDIT_REPORT_RESULT_VIEW = '/editreportresult/:id';
}

const connectedRoutes = Routes
export {connectedRoutes as Routes };