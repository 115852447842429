import React from 'react';
import { Route, Redirect } from 'react-router';
import { Routes } from 'Routes';

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
      localStorage.getItem('logged_user_auth_token')
          ? <Redirect to={{ pathname: Routes.HOME_VIEW, state: { from: props.location } }} />
          : <Component {...props} />
  )} />
);

export const UnprivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
      localStorage.getItem('logged_user_auth_token')
          ? <Component {...props} />
          : <Redirect to={{ pathname: Routes.LOGIN_VIEW, state: { from: props.location } }} />
  )} />
);