import { delay, takeEvery, takeLatest, put, call } from "redux-saga/effects";
import AuthReduxActionsCreators, { AuthTypes } from "../reducers/auth.reducer";
import {
  loginUser,
  forgotPasswordUser,
  registerUser,
  changePasswordUser,
  logout,
  resetPasswordUser,
  getProfileUser,
  verifyEmailUser,
  resendVerifyEmailUser,
  updateProfileUser,
  deleteUser,
  contactUs,
} from "../services/auth.services";
import { push } from "react-router-redux";
import { Routes } from "Routes";
import * as CommonFunc from "Utils/commonFunction";

export default [
  loginUserWatcher,
  forgotPasswordWatcher,
  registerUserWatcher,
  changePasswordWatcher,
  logoutUserWatcher,
  resetPasswordWatcher,
  getUserProfileWatcher,
  userVerifyEmailWatcher,
  resendUserVerifyEmailWatcher,
  updateUserProfileWatcher,
  deleteUserWatcher,
  contactUsPostWatcher,
];

const errorMsg = "a network error has occurred";

//function definition for login request.
function* loginUserWatcher() {
  yield takeLatest(AuthTypes.LOGIN_USER, loginUserRequestHandler);
}

//function definition for forgot Password request.
function* forgotPasswordWatcher() {
  yield takeLatest(AuthTypes.FORGOT_PASSWORD, forgotPasswordRequestHandler);
}

//function definition for Register request.
function* registerUserWatcher() {
  yield takeLatest(AuthTypes.REGISTER_USER, registerUserRequestHandler);
}

//function definition for change Password request.
function* changePasswordWatcher() {
  yield takeLatest(AuthTypes.CHANGE_PASSWORD, changePasswordRequestHandler);
}

//function definition for logout request.
function* logoutUserWatcher() {
  yield takeLatest(AuthTypes.LOGOUT_USER, logoutUserRequestHandler);
}

//function definition for reset Password request.
function* resetPasswordWatcher() {
  yield takeLatest(AuthTypes.RESET_PASSWORD, resetPasswordRequestHandler);
}

//function definition for get Profile request.
function* getUserProfileWatcher() {
  yield takeLatest(AuthTypes.GET_USER_PROFILE, getUserProfileRequestHandler);
}

//function definition for verify Email request.
function* userVerifyEmailWatcher() {
  yield takeLatest(AuthTypes.USER_VERIFY_EMAIL, userVerifyEmailRequestHandler);
}

//function definition for resend verify Email request.
function* resendUserVerifyEmailWatcher() {
  yield takeLatest(
    AuthTypes.RESEND_USER_VERIFY_EMAIL,
    resendUserVerifyEmailRequestHandler
  );
}

//function definition for update Profile request.
function* updateUserProfileWatcher() {
  yield takeLatest(
    AuthTypes.UPDATE_USER_PROFILE,
    updateUserProfileRequestHandler
  );
}

//function definition for delete User request.
function* deleteUserWatcher() {
  yield takeLatest(AuthTypes.DELETE_USER, deleteUserRequestHandler);
}

//function definition for delete User request.
function* contactUsPostWatcher() {
  yield takeLatest(AuthTypes.CONTACT_US_POST, contactUsPostRequestHandler);
}

// furnction implementation for login
function* loginUserRequestHandler({ payload }) {
  yield put(AuthReduxActionsCreators.setFetching(true));
  yield put(AuthReduxActionsCreators.setFetchingErr(false));
  yield put(AuthReduxActionsCreators.setFetchingErrMsg(""));

  try {
    const { email, password } = payload;
    if (!email && !password) {
      return "Enter valid username & password for login.";
    }
    const response = yield call(loginUser, payload);
    if (response && response.data) {
      if (!response.data.success) {
        CommonFunc.showUnhandledErrors(response.data.message);
        yield put(AuthReduxActionsCreators.setFetchingErr(true));
      } else {
        CommonFunc.handleOnSuccessToastify(response.data.message);
        // set logged_user_auth_token in localstorage
        CommonFunc.setItemInLocalStorage(
          "logged_user_auth_token",
          response.data.data.api_token,
          false
        );
        CommonFunc.setItemInLocalStorage(
          "logged_user_ID",
          response.data.data.user.id,
          false
        );
        CommonFunc.setItemInLocalStorage(
          "email_verified_at",
          response.data.data.user.email_verified_at,
          false
        );
        yield put(push(Routes.HOME_VIEW));
      }
    }
    if (response && response.error) {
      return response.error;
    }
    yield put(AuthReduxActionsCreators.setFetching(false));
  } catch (errors) {
    yield put(AuthReduxActionsCreators.setFetchingErr(true));
    yield put(AuthReduxActionsCreators.setFetching(false));

    if (errors.response && errors.response.data) {
      CommonFunc.showUnhandledErrors(errors.response.data.message);
      yield put(
        AuthReduxActionsCreators.setFetchingErrMsg(errors.response.data.message)
      );
    } else {
      CommonFunc.showUnhandledErrors(errorMsg);
      yield put(AuthReduxActionsCreators.setFetchingErrMsg(errorMsg));
    }
  }
}

// furnction implementation for forgotPassword
function* forgotPasswordRequestHandler({ email }) {
  yield put(AuthReduxActionsCreators.setFetching(true));
  yield put(AuthReduxActionsCreators.setFetchingErr(false));
  yield put(AuthReduxActionsCreators.setFetchingErrMsg(""));

  try {
    const response = yield call(forgotPasswordUser, email);
    if (response && response.data) {
      if (!response.data.success) {
        CommonFunc.showUnhandledErrors(response.data.message);
        yield put(AuthReduxActionsCreators.setFetchingErr(true));
      } else {
        CommonFunc.handleOnSuccessToastify(response.data.message);
        yield put(push(Routes.LOGIN_VIEW));
      }
    }
    if (response && response.error) {
      return response.error;
    }
    yield put(AuthReduxActionsCreators.setFetching(false));
  } catch (errors) {
    yield put(AuthReduxActionsCreators.setFetchingErr(true));
    yield put(AuthReduxActionsCreators.setFetching(false));

    if (errors.response && errors.response.data) {
      CommonFunc.showUnhandledErrors(errors.response.data.message);
      yield put(
        AuthReduxActionsCreators.setFetchingErrMsg(errors.response.data.message)
      );
    } else {
      CommonFunc.showUnhandledErrors(errorMsg);
      yield put(AuthReduxActionsCreators.setFetchingErrMsg(errorMsg));
    }
  }
}

// furnction implementation for register user
function* registerUserRequestHandler({ payload }) {
  yield put(AuthReduxActionsCreators.setFetching(true));
  yield put(AuthReduxActionsCreators.setFetchingErr(false));
  yield put(AuthReduxActionsCreators.setFetchingErrMsg(""));

  yield put(AuthReduxActionsCreators.setVerifySuccess(false));
  try {
    const response = yield call(registerUser, payload);
    if (response && response.data) {
      if (!response.data.success) {
        CommonFunc.showUnhandledErrors(response.data.message);
        yield put(AuthReduxActionsCreators.setFetchingErr(true));
      } else {
        CommonFunc.handleOnSuccessToastify(response.data.message);
        yield put(AuthReduxActionsCreators.setVerifySuccess(true));
        CommonFunc.setItemInLocalStorage(
          "logged_user_auth_token",
          response.data.data.api_token,
          false
        );
        CommonFunc.setItemInLocalStorage(
          "logged_user_ID",
          response.data.data.user.id,
          false
        );
        CommonFunc.setItemInLocalStorage(
          "email_verified_at",
          response.data.data.user.email_verified_at,
          false
        );
        yield put(push(Routes.HOME_VIEW));
      }
    }
    if (response && response.error) {
      return response.error;
    }
    yield put(AuthReduxActionsCreators.setFetching(false));
  } catch (errors) {
    yield put(AuthReduxActionsCreators.setFetchingErr(true));
    yield put(AuthReduxActionsCreators.setFetching(false));

    if (errors.response && errors.response.data) {
      CommonFunc.showUnhandledErrors(errors.response.data.message);
      yield put(
        AuthReduxActionsCreators.setFetchingErrMsg(errors.response.data.message)
      );
    } else {
      CommonFunc.showUnhandledErrors(errorMsg);
      yield put(AuthReduxActionsCreators.setFetchingErrMsg(errorMsg));
    }
  }
}

// furnction implementation for changePassword
function* changePasswordRequestHandler({ payload }) {
  yield put(AuthReduxActionsCreators.setChangePasswordLoading(true));
  yield put(AuthReduxActionsCreators.setFetching(true));
  yield put(AuthReduxActionsCreators.setFetchingErr(false));
  yield put(AuthReduxActionsCreators.setFetchingErrMsg(""));

  try {
    const response = yield call(changePasswordUser, payload);
    if (response && response.data) {
      // Success
      if (!response.data.success) {
        CommonFunc.showUnhandledErrors(response.data.message);
        yield put(AuthReduxActionsCreators.setFetchingErr(true));
      } else {
        CommonFunc.handleOnSuccessToastify(response.data.message);
        yield put(
          AuthReduxActionsCreators.changePasswordSuccess(response.data)
        );
      }
    }
    if (response && response.error) {
      return response.error;
    }
    yield put(AuthReduxActionsCreators.setChangePasswordLoading(false));
    yield put(AuthReduxActionsCreators.setFetching(false));
  } catch (errors) {
    yield put(AuthReduxActionsCreators.setFetchingErr(true));
    yield put(AuthReduxActionsCreators.setFetching(false));

    if (errors.response && errors.response.data) {
      CommonFunc.showUnhandledErrors(errors.response.data.message);
      yield put(
        AuthReduxActionsCreators.setFetchingErrMsg(errors.response.data.message)
      );
    } else {
      CommonFunc.showUnhandledErrors(errorMsg);
      yield put(AuthReduxActionsCreators.setFetchingErrMsg(errorMsg));
    }
    yield put(AuthReduxActionsCreators.setChangePasswordLoading(false));
  }
}

// furnction implementation for  logout from system
function* logoutUserRequestHandler() {
  yield put(AuthReduxActionsCreators.setFetching(true));
  yield put(AuthReduxActionsCreators.setFetchingErr(false));
  yield put(AuthReduxActionsCreators.setFetchingErrMsg(""));
  try {
    const response = yield call(logout, null);
    if (response && response.data) {
      if (response.data.success) {
        CommonFunc.handleOnSuccessToastify(response.data.message);
        yield put(AuthReduxActionsCreators.logoutSuccess());
      }
    }

    if (response && response.error) {
      return response.error;
    }

    CommonFunc.cleanLocalStorage();
    yield put(push(Routes.HOME_VIEW));
    yield put(AuthReduxActionsCreators.setFetching(false));
  } catch (errors) {
    yield put(AuthReduxActionsCreators.setFetchingErr(true));
    yield put(AuthReduxActionsCreators.setFetching(false));

    CommonFunc.cleanLocalStorage();
    yield put(push(Routes.HOME_VIEW));

    if (errors.response && errors.response.data) {
      // CommonFunc.showUnhandledErrors(errors.response.data.message);
      yield put(
        AuthReduxActionsCreators.setFetchingErrMsg(errors.response.data.message)
      );
    } else {
      CommonFunc.showUnhandledErrors(errorMsg);
      yield put(AuthReduxActionsCreators.setFetchingErrMsg(errorMsg));
    }
  }
}

// furnction implementation for reset Password
function* resetPasswordRequestHandler({ payload }) {
  yield put(AuthReduxActionsCreators.setFetching(true));
  yield put(AuthReduxActionsCreators.setFetchingErr(false));
  yield put(AuthReduxActionsCreators.setFetchingErrMsg(""));
  try {
    const response = yield call(resetPasswordUser, payload);
    if (response && response.data) {
      if (!response.data.success) {
        CommonFunc.showUnhandledErrors(response.data.message);
        yield put(AuthReduxActionsCreators.setFetchingErr(true));
      } else {
        CommonFunc.handleOnSuccessToastify(response.data.message);
        yield put(push(Routes.LOGIN_VIEW));
      }
    }
    if (response && response.error) {
      return response.error;
    }
    yield put(AuthReduxActionsCreators.setFetching(false));
  } catch (errors) {
    yield put(AuthReduxActionsCreators.setFetchingErr(true));
    yield put(AuthReduxActionsCreators.setFetching(false));

    if (errors.response && errors.response.data) {
      CommonFunc.showUnhandledErrors(errors.response.data.message);
      yield put(
        AuthReduxActionsCreators.setFetchingErrMsg(errors.response.data.message)
      );
    } else {
      CommonFunc.showUnhandledErrors(errorMsg);
      yield put(AuthReduxActionsCreators.setFetchingErrMsg(errorMsg));
    }
  }
}

// furnction implementation for get Profile
function* getUserProfileRequestHandler() {
  yield put(AuthReduxActionsCreators.setFetching(true));
  yield put(AuthReduxActionsCreators.setFetchingErr(false));
  yield put(AuthReduxActionsCreators.setFetchingErrMsg(""));

  try {
    const response = yield call(getProfileUser, null);
    if (response && response.data) {
      if (!response.data.success) {
        CommonFunc.showUnhandledErrors(response.data.message);
        yield put(AuthReduxActionsCreators.setFetchingErr(true));
        yield put(AuthReduxActionsCreators.getProfileFailure(response.data));
      } else {
        // CommonFunc.handleOnSuccessToastify(response.data.message)
        yield put(AuthReduxActionsCreators.getProfileSuccess(response.data));
      }
    }
    if (response && response.error) {
      return response.error;
    }
    yield put(AuthReduxActionsCreators.setFetching(false));
  } catch (errors) {
    yield put(AuthReduxActionsCreators.setFetchingErr(true));
    yield put(AuthReduxActionsCreators.setFetching(false));

    if (errors.response && errors.response.data) {
      CommonFunc.showUnhandledErrors(errors.response.data.message);
      yield put(
        AuthReduxActionsCreators.setFetchingErrMsg(errors.response.data.message)
      );
    } else {
      CommonFunc.showUnhandledErrors(errorMsg);
      yield put(AuthReduxActionsCreators.setFetchingErrMsg(errorMsg));
    }
  }
}

// furnction implementation for Verify Email
function* userVerifyEmailRequestHandler({ payload }) {
  yield put(AuthReduxActionsCreators.setFetching(true));
  yield put(AuthReduxActionsCreators.setFetchingErr(false));
  yield put(AuthReduxActionsCreators.setFetchingErrMsg(""));
  try {
    const response = yield call(verifyEmailUser, payload);
    if (response && response.data) {
      if (!response.data.success) {
        CommonFunc.showUnhandledErrors(response.data.message);
        yield put(AuthReduxActionsCreators.setFetchingErr(true));
        yield put(push(Routes.HOME_VIEW));
      } else {
        CommonFunc.setItemInLocalStorage(
          "logged_user_auth_token",
          response.data.data.api_token,
          false
        );
        CommonFunc.setItemInLocalStorage(
          "logged_user_ID",
          response.data.data.user.id,
          false
        );
        CommonFunc.setItemInLocalStorage(
          "email_verified_at",
          response.data.data.user.email_verified_at,
          false
        );
        CommonFunc.handleOnSuccessToastify(response.data.message);
        yield put(push(Routes.HOME_VIEW));
      }
    }
    if (response && response.error) {
      return response.error;
    }
    yield put(AuthReduxActionsCreators.setFetching(false));
  } catch (errors) {
    yield put(AuthReduxActionsCreators.setFetchingErr(true));
    yield put(AuthReduxActionsCreators.setFetching(false));

    if (errors.response && errors.response.data) {
      CommonFunc.showUnhandledErrors(errors.response.data.message);
      yield put(
        AuthReduxActionsCreators.setFetchingErrMsg(errors.response.data.message)
      );
    } else {
      CommonFunc.showUnhandledErrors(errorMsg);
      yield put(AuthReduxActionsCreators.setFetchingErrMsg(errorMsg));
    }
  }
}

// furnction implementation for resend Verify Email
function* resendUserVerifyEmailRequestHandler({ userId }) {
  yield put(AuthReduxActionsCreators.setFetching(true));
  yield put(AuthReduxActionsCreators.setFetchingErr(false));
  yield put(AuthReduxActionsCreators.setFetchingErrMsg(""));
  try {
    const response = yield call(resendVerifyEmailUser, userId);
    if (response && response.data) {
      if (!response.data.success) {
        CommonFunc.showUnhandledErrors(response.data.message);
        yield put(AuthReduxActionsCreators.setFetchingErr(true));
      } else {
        CommonFunc.handleOnSuccessToastify(response.data.message);
        yield put(
          AuthReduxActionsCreators.resendVerifyEmailSuccess(response.data)
        );
      }
    }
    if (response && response.error) {
      return response.error;
    }
    yield put(AuthReduxActionsCreators.setFetching(false));
  } catch (errors) {
    yield put(AuthReduxActionsCreators.setFetchingErr(true));
    yield put(AuthReduxActionsCreators.setFetching(false));

    if (errors.response && errors.response.data) {
      CommonFunc.showUnhandledErrors(errors.response.data.message);
      yield put(
        AuthReduxActionsCreators.setFetchingErrMsg(errors.response.data.message)
      );
    } else {
      CommonFunc.showUnhandledErrors(errorMsg);
      yield put(AuthReduxActionsCreators.setFetchingErrMsg(errorMsg));
    }
  }
}

// furnction implementation for update profile user
function* updateUserProfileRequestHandler({ payload }) {
  yield put(AuthReduxActionsCreators.setUpdateProfileLoading(true));
  yield put(AuthReduxActionsCreators.setFetching(true));
  yield put(AuthReduxActionsCreators.setFetchingErr(false));
  yield put(AuthReduxActionsCreators.setFetchingErrMsg(""));
  try {
    const response = yield call(updateProfileUser, payload);
    if (response && response.data) {
      if (!response.data.success) {
        CommonFunc.showUnhandledErrors(response.data.message);
        yield put(AuthReduxActionsCreators.setFetchingErr(true));
      } else {
        CommonFunc.handleOnSuccessToastify(response.data.message);
        yield put(AuthReduxActionsCreators.updateProfileSuccess(response.data));
      }
    }
    if (response && response.error) {
      return response.error;
    }
    yield put(AuthReduxActionsCreators.setUpdateProfileLoading(false));
    yield put(AuthReduxActionsCreators.setFetching(false));
  } catch (errors) {
    yield put(AuthReduxActionsCreators.setFetchingErr(true));
    yield put(AuthReduxActionsCreators.setFetching(false));

    if (errors.response && errors.response.data) {
      CommonFunc.showUnhandledErrors(errors.response.data.message);
      yield put(
        AuthReduxActionsCreators.setFetchingErrMsg(errors.response.data.message)
      );
    } else {
      CommonFunc.showUnhandledErrors(errorMsg);
      yield put(AuthReduxActionsCreators.setFetchingErrMsg(errorMsg));
    }
    yield put(AuthReduxActionsCreators.setUpdateProfileLoading(false));
  }
}

// furnction implementation for deleteUser
function* deleteUserRequestHandler({ id }) {
  yield put(AuthReduxActionsCreators.setFetching(true));
  yield put(AuthReduxActionsCreators.setFetchingErr(false));
  yield put(AuthReduxActionsCreators.setFetchingErrMsg(""));
  try {
    const response = yield call(deleteUser, id);
    if (response && response.data) {
      // Success
      if (!response.data.success) {
        CommonFunc.showUnhandledErrors(response.data.message);
        yield put(AuthReduxActionsCreators.setFetchingErr(true));
        yield put(AuthReduxActionsCreators.deleteUserFailure(response.data));
      } else {
        CommonFunc.handleOnSuccessToastify(response.data.message);
        CommonFunc.cleanLocalStorage();
        yield put(push(Routes.HOME_VIEW));
      }
    }
    if (response && response.error) {
      return response.error;
    }
    yield put(AuthReduxActionsCreators.setFetching(false));
  } catch (errors) {
    yield put(AuthReduxActionsCreators.setFetchingErr(true));
    yield put(AuthReduxActionsCreators.setFetching(false));

    if (errors.response && errors.response.data) {
      CommonFunc.showUnhandledErrors(errors.response.data.message);
      yield put(
        AuthReduxActionsCreators.setFetchingErrMsg(errors.response.data.message)
      );
    } else {
      CommonFunc.showUnhandledErrors(errorMsg);
      yield put(AuthReduxActionsCreators.setFetchingErrMsg(errorMsg));
    }
  }
}

// furnction implementation for forgotPassword
function* contactUsPostRequestHandler({ payload }) {
  yield put(AuthReduxActionsCreators.setFetching(true));
  yield put(AuthReduxActionsCreators.setFetchingErr(false));
  yield put(AuthReduxActionsCreators.setFetchingErrMsg(""));

  yield put(AuthReduxActionsCreators.setContactUsPostStatus(false));
  try {
    const response = yield call(contactUs, payload);
    if (response && response.data) {
      if (!response.data.success) {
        CommonFunc.showUnhandledErrors(response.data.message);
        yield put(AuthReduxActionsCreators.setFetchingErr(true));
        yield put(AuthReduxActionsCreators.setContactUsPostStatus(false));
      } else {
        CommonFunc.handleOnSuccessToastify(response.data.message);
        yield put(AuthReduxActionsCreators.setContactUsPostStatus(true));
      }
    }
    if (response && response.error) {
      return response.error;
    }
    yield put(AuthReduxActionsCreators.setFetching(false));
  } catch (errors) {
    yield put(AuthReduxActionsCreators.setFetchingErr(true));
    yield put(AuthReduxActionsCreators.setFetching(false));

    if (errors.response && errors.response.data) {
      CommonFunc.showUnhandledErrors(errors.response.data.message);
      yield put(
        AuthReduxActionsCreators.setFetchingErrMsg(errors.response.data.message)
      );
    } else {
      CommonFunc.showUnhandledErrors(errorMsg);
      yield put(AuthReduxActionsCreators.setFetchingErrMsg(errorMsg));
    }
    yield put(AuthReduxActionsCreators.setContactUsPostStatus(false));
  }
}
