import { delay, takeEvery, takeLatest, put, call } from "redux-saga/effects";
import PaymentReduxActionsCreators, {
  PaymentTypes,
} from "../reducers/payment.reducer";
import AuthReduxActionsCreators from "../reducers/auth.reducer";
import {
  stripePaymentQuery,
  stripePaymentSingleUseQuery,
  planStatusQuery,
  stripeClosePlanQuery,
  userPlanStatusQuery,
  doExpressCheckoutPayPalQuery,
  doExpressCheckoutSuccessQuery,
  doExpressCheckoutSuccessOneAndDoneQuery,
} from "../services/payment.services";
import { push } from "react-router-redux";
import { Routes } from "Routes";
import { Route, Redirect } from "react-router";
import * as CommonFunc from "Utils/commonFunction";

export default [
  stripePaymentWatcher,
  planStatusWatcher,
  stripeClosePlanWatcher,
  userPlanStatusWatcher,
  doExpressCheckoutPaypalPaymentWatcher,
  doExpressCheckoutSuccessWatcher,
];

const errorMsg = "a network error has occurred";

//function definition for stripe Payment request.
function* stripePaymentWatcher() {
  yield takeLatest(PaymentTypes.STRIPE_PAYMENT, stripePaymentRequestHandler);
}

//function definition for plan Status request.
function* planStatusWatcher() {
  yield takeLatest(PaymentTypes.PLAN_STATUS, planStatusRequestHandler);
}

//function definition for stripe Payment request.
function* stripeClosePlanWatcher() {
  yield takeLatest(
    PaymentTypes.STRIPE_CLOSE_PLAN,
    stripeClosePlanRequestHandler
  );
}

//function definition for User Plan Status request.
function* userPlanStatusWatcher() {
  yield takeLatest(PaymentTypes.USER_PLAN_STATUS, userPlanStatusRequestHandler);
}

//function for paypal express checkout call
function* doExpressCheckoutPaypalPaymentWatcher() {
  yield takeLatest(
    PaymentTypes.DO_EXPRESS_CHECKOUT_PAYPAL_PAYMENT,
    doExpressCheckoutPaypalPaymentHandler
  );
}

//function for express checkout success call
function* doExpressCheckoutSuccessWatcher() {
  yield takeLatest(
    PaymentTypes.DO_EXPRESS_CHECKOUT_SUCCESS,
    doExpressCheckoutSuccessHandler
  );
}

// Handler for stripe Payment
function* stripePaymentRequestHandler({ payload }) {
  // console.log("stripePayment-----", payload)
  yield put(PaymentReduxActionsCreators.setPaymentFetching(true));
  yield put(PaymentReduxActionsCreators.setStripePaymentSuccess(false));
  yield put(PaymentReduxActionsCreators.setStripePaymentFailure(false));
  try {
    if (payload.plan == "single-checkout") {
      var response = yield call(stripePaymentSingleUseQuery, payload);
    } else {
      var response = yield call(stripePaymentQuery, payload);
    }
    if (response && response.data) {
      // Success
      if (!response.data.success) {
        // console.log("try_if-----", response.data.message)
        CommonFunc.showUnhandledErrors(response.data.message);
        yield put(PaymentReduxActionsCreators.setStripePaymentFailure(true));
        // yield put(PaymentReduxActionsCreators.deleteUserFailure(response.data));
      } else {
        // console.log("try_else-----", response.data.message)
        CommonFunc.handleOnSuccessToastify(response.data.message);
        yield put(PaymentReduxActionsCreators.setStripePaymentSuccess(true));
      }
    }
    if (response && response.error) {
      return response.error;
    }
    yield put(PaymentReduxActionsCreators.setPaymentFetching(false));
  } catch (errors) {
    if (errors.response && errors.response.data) {
      // console.log("catch_if-----", errors.response.data.message)
      CommonFunc.showUnhandledErrors(errors.response.data.message);
      yield put(PaymentReduxActionsCreators.setStripePaymentFailure(true));
    } else {
      // console.log("catch_else-----", errors.message)
      CommonFunc.showUnhandledErrors(errors.message);
      yield put(PaymentReduxActionsCreators.setStripePaymentFailure(true));
    }
    yield put(PaymentReduxActionsCreators.setPaymentFetching(false));
  }
}

// Handler for stripe Payment
function* planStatusRequestHandler() {
  // console.log("planStatus-----", payload)
  yield put(PaymentReduxActionsCreators.setPlanStatusFetching(true));
  yield put(PaymentReduxActionsCreators.setPremiumPlanStatus(false));
  yield put(PaymentReduxActionsCreators.setStandardPlanStatus(false));
  yield put(PaymentReduxActionsCreators.setCountOnetimePlan(0));
  try {
    var response = yield call(planStatusQuery, null);
    if (response && response.data) {
      // Success
      if (!response.data.success) {
        // console.log("try_if-----", response.data)
        // CommonFunc.showUnhandledErrors(response.data.message)
        yield put(PaymentReduxActionsCreators.setPremiumPlanStatus(false));
        yield put(PaymentReduxActionsCreators.setStandardPlanStatus(false));
        yield put(PaymentReduxActionsCreators.setCountOnetimePlan(0));
      } else {
        // console.log("try_else-----", response.data)
        // CommonFunc.handleOnSuccessToastify(response.data.message)
        yield put(PaymentReduxActionsCreators.setPlanStatus(response.data));
        yield put(
          PaymentReduxActionsCreators.setPremiumPlanStatus(
            response.data.data.premium_plan_status
          )
        );
        yield put(
          PaymentReduxActionsCreators.setStandardPlanStatus(
            response.data.data.standard_plan_status
          )
        );
        yield put(
          PaymentReduxActionsCreators.setCountOnetimePlan(
            response.data.data.count_onetime_plan
          )
        );
      }
    }
    if (response && response.error) {
      return response.error;
    }
    yield put(PaymentReduxActionsCreators.setPlanStatusFetching(false));
  } catch (errors) {
    if (errors.response && errors.response.data) {
      // console.log("catch_if-----", errors.response.data.message)
      // CommonFunc.showUnhandledErrors(errors.response.data.message);
    } else {
      // console.log("catch_else-----", errors.message)
      // CommonFunc.showUnhandledErrors(errors.message);
    }
    yield put(PaymentReduxActionsCreators.setPlanStatusFetching(false));
  }
}

// Handler for stripe close plan
function* stripeClosePlanRequestHandler({ payload }) {
  yield put(AuthReduxActionsCreators.setFetching(true));
  yield put(AuthReduxActionsCreators.setFetchingErr(false));
  yield put(AuthReduxActionsCreators.setFetchingErrMsg(""));

  try {
    var response = yield call(stripeClosePlanQuery, payload);
    if (response && response.data) {
      // Success
      if (!response.data.success) {
        yield put(AuthReduxActionsCreators.setFetchingErr(true));
        CommonFunc.showUnhandledErrors(response.data.message);
        yield put(
          PaymentReduxActionsCreators.setStripeClosePlan(response.data)
        );
      } else {
        console.log("try_else-----", response.data);
        CommonFunc.handleOnSuccessToastify(response.data.message);
        yield put(
          PaymentReduxActionsCreators.setStripeClosePlan(response.data)
        );
      }
    }
    if (response && response.error) {
      return response.error;
    }
    yield put(AuthReduxActionsCreators.setFetching(false));
  } catch (errors) {
    yield put(AuthReduxActionsCreators.setFetching(false));
    yield put(AuthReduxActionsCreators.setFetchingErr(true));
    if (errors.response && errors.response.data) {
      console.log("catch_if-----", errors.response.data.message);
      yield put(
        AuthReduxActionsCreators.setFetchingErrMsg(errors.response.data.message)
      );
      CommonFunc.showUnhandledErrors(errors.response.data.message);
    } else {
      console.log("catch_else-----", errors.message);
      yield put(AuthReduxActionsCreators.setFetchingErrMsg(errorMsg));
      CommonFunc.showUnhandledErrors(errors.message);
    }
  }
}

// Handler for User Plan Status
function* userPlanStatusRequestHandler() {
  // console.log("planStatus-----", payload)
  try {
    var response = yield call(userPlanStatusQuery, null);
    if (response && response.data) {
      // Success
      if (!response.data.success) {
        console.log("try_if-----", response.data);
        // CommonFunc.showUnhandledErrors(response.data.message)
      } else {
        console.log("try_else-----", response.data);
        yield put(PaymentReduxActionsCreators.setUserPlanStatus(response.data));
        if (!response.data.data.plan_status) {
          console.log("plan_status_if-----", response.data.data.plan_status);
          yield put(push(Routes.PLANS_VIEW));
        } else {
          if (!response.data.data.api_plan_status) {
            console.log(
              "api_plan_status_if-----",
              response.data.data.api_plan_status
            );
            yield put(push(Routes.HOME_VIEW));
          } else {
            console.log(
              "api_plan_status_else-----",
              response.data.data.api_plan_status
            );
            // yield put(push(Routes.HOME_VIEW));
          }
        }
        // CommonFunc.handleOnSuccessToastify(response.data.message)
      }
    }
    if (response && response.error) {
      return response.error;
    }
  } catch (errors) {
    if (errors.response && errors.response.data) {
      console.log("catch_if-----", errors.response.data.message);
      // CommonFunc.showUnhandledErrors(errors.response.data.message);
    } else {
      console.log("catch_else-----", errors.message);
      // CommonFunc.showUnhandledErrors(errors.message);
    }
  }
}

// Handler for PayPal Payment
function* doExpressCheckoutPaypalPaymentHandler({ payload }) {
  yield put(AuthReduxActionsCreators.setFetching(true));
  yield put(AuthReduxActionsCreators.setFetchingErr(false));
  yield put(AuthReduxActionsCreators.setFetchingErrMsg(""));

  try {
    var response = yield call(doExpressCheckoutPayPalQuery, payload);
    console.log("response", response);
    if (response && response.status === 200) {
      if (
        response.data.data &&
        "paypal_link" in response.data.data &&
        response.data.data.paypal_link
      ) {
        const PayPalFallbackURL = response.data.data.paypal_link;
        window.open(PayPalFallbackURL, "_self");
        yield put(
          PaymentReduxActionsCreators.setExpressCheckoutPaypalCallback(
            response.data
          )
        );
        yield put(AuthReduxActionsCreators.setFetching(false));
      }

      if (!response.data.success) {
        CommonFunc.showUnhandledErrors(response.data.message);
        yield put(AuthReduxActionsCreators.setFetching(false));
      }
    } else if (response && !response.data) {
      yield put(AuthReduxActionsCreators.setFetchingErr(true));
      CommonFunc.showUnhandledErrors(response.data.message);
      yield put(AuthReduxActionsCreators.setFetching(false));
    }

    if (response && response.error) {
      yield put(AuthReduxActionsCreators.setFetching(false));
      return response.error;
    }
  } catch (errors) {
    yield put(AuthReduxActionsCreators.setFetching(false));
    yield put(AuthReduxActionsCreators.setFetchingErr(true));

    if (errors.response && errors.response.data) {
      CommonFunc.showUnhandledErrors(
        "We can't process your payment right now, so please try again later. We're sorry for the inconvenience"
      );
      yield put(
        AuthReduxActionsCreators.setFetchingErrMsg(errors.response.data)
      );
    } else {
      CommonFunc.showUnhandledErrors(errorMsg);
      yield put(AuthReduxActionsCreators.setFetchingErrMsg(errorMsg));
    }
  }
}

// Handler for Express checkout
function* doExpressCheckoutSuccessHandler({ payload }) {
  yield put(AuthReduxActionsCreators.setFetching(true));
  yield put(AuthReduxActionsCreators.setFetchingErr(false));
  yield put(AuthReduxActionsCreators.setFetchingErrMsg(""));

  try {
    var response = yield call(
      payload.planType === "single-use-success"
        ? doExpressCheckoutSuccessOneAndDoneQuery
        : doExpressCheckoutSuccessQuery,
      payload
    );
    console.log("response", response);
    if (response && response.status === 200) {
      if (response.data.data && response.data.success === true) {
        yield put(
          PaymentReduxActionsCreators.setExpressCheckoutSuccess({
            status: true,
          })
        );
        CommonFunc.handleOnSuccessToastify(response.data.message);
      }

      if (!response.data.success) {
        CommonFunc.showUnhandledErrors(response.data.message);
        yield put(
          PaymentReduxActionsCreators.setExpressCheckoutSuccess({
            status: false,
          })
        );
      }
    } else if (response && response.success === false && !response.data) {
      yield put(AuthReduxActionsCreators.setFetchingErr(true));
      CommonFunc.showUnhandledErrors(response.data.message);
      yield put(
        PaymentReduxActionsCreators.setExpressCheckoutSuccess({ status: false })
      );
    }

    if (response && response.error) {
      return response.error;
    }

    yield put(AuthReduxActionsCreators.setFetching(false));
  } catch (errors) {
    yield put(AuthReduxActionsCreators.setFetching(false));
    yield put(AuthReduxActionsCreators.setFetchingErr(true));

    if (errors.response && errors.response.data) {
      CommonFunc.showUnhandledErrors(
        "We can't process your payment right now, so please try again later. We're sorry for the inconvenience"
      );
      if (payload.planType === "premium") {
        yield put(push(Routes.PREMIUM_PLAN_VIEW));
      }
      yield put(
        AuthReduxActionsCreators.setFetchingErrMsg(errors.response.data)
      );
    } else {
      CommonFunc.showUnhandledErrors(errorMsg);
      yield put(AuthReduxActionsCreators.setFetchingErrMsg(errorMsg));
    }
  }
}
