//File for Authentication controller in the backend.
import axios from '../config/axios.config';
import API_ENDPOINT from '../constants/api.constants';
import { getItemInLocalStorage } from 'App/utils/commonFunction';

//Method for getting users reports.
function* getUsersReportsQuery(){
    try {
        const headers = {
            'Authorization': `Bearer ${getItemInLocalStorage('logged_user_auth_token')}`
        }
        const response = yield axios.get(`${API_ENDPOINT.reports}`, {headers}, true);
        return yield Promise.resolve(response);
     } catch (error) {
         return yield Promise.reject(error);
     }
}

//Method for getting user report by Id.
function* getUserReportByIdQuery(reportId){
    try {
        const headers = {
            'Authorization': `Bearer ${getItemInLocalStorage('logged_user_auth_token')}`
        }
        const response = yield axios.get(`${API_ENDPOINT.reports}/${reportId}`,{headers},  true);
        return yield Promise.resolve(response);
     } catch (error) {
         return yield Promise.reject(error);
     }
}

//Method for Update Report.
function* updateReportQuery(payload){
    try {
        const headers = {
            'Authorization': `Bearer ${getItemInLocalStorage('logged_user_auth_token')}`
        }
        const response = yield axios.post(`${API_ENDPOINT.updateReportData}`, payload, { headers: headers }, true);
        return yield Promise.resolve(response);
     } catch (error) {
        return yield Promise.reject(error);
     }
}

//Exporting service methods.
export {
    getUsersReportsQuery,
    getUserReportByIdQuery,
    updateReportQuery,
};