import React, { Component } from 'react';
import {injectStripe} from 'react-stripe-elements';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PatmentReduxActionsCreators from 'Reducers/payment.reducer';
import CardSection from '../CardSection/CardSection';
import arrowRight from 'Assets/images/arrow-right.png';
import loading from 'Assets/images/loading.gif';

class CheckoutForm extends Component {
  constructor(props){
		super(props);
		this.state = {
			errorMessage: null,
      cardHolderName: null,
      loadingBtn: false,
		};
  }
  
  handleOnChangeInput = (event) => {
    // console.log("name", event.target.name)
    // console.log("value", event.target.value)
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit = async (event) => {
    const { cardHolderName } = this.state;
    this.setState({ loadingBtn: true });
    if(!cardHolderName) {
      // console.log('cardHolderName', cardHolderName);
      this.setState({ loadingBtn: false, errorMessage: 'Your card holder name is incomplete.' });
    } else {
        event.preventDefault();
        const {stripe, elements} = this.props;

        if (!stripe || !elements) {
          return;
        }

        const createToken = await stripe.createToken();
        if (createToken.error) {
          // console.log('[error]', createToken.error.message);
          this.setState({ loadingBtn: false, errorMessage: createToken.error.message });
        } else {
          this.setState({ loadingBtn: false, errorMessage: null });
          const { actions: { stripePayment } } = this.props;
          stripePayment({
            plan: this.props.planNameText,
            stripeToken: createToken.token.id,
          });
          // console.log('[createToken]', createToken.token.id);
          // console.log('planNameText', this.props.planNameText);
        }
    }
  };

  render() {
    const { errorMessage, loadingBtn } = this.state;
    const { paymentFetching, buttonCloseAction, planNameText, planBtnText } = this.props;
    // console.log("planNameText--", planNameText)
    return (
      <div className="checkoutMain">
        <div className="form-group">
          <div className="row">
            <div className="col-md-12">
              <label className="form-lable">Name On Card</label>
              <input type="text" name="cardHolderName" className="form-input form-control " placeholder="Card Holder Name" onChange={this.handleOnChangeInput}></input>
            </div>
          </div>
        </div>
        <div className="card-main-calss">
          <div className="row">
            <div className="col-md-12">
              <CardSection />
            </div>
          </div>
        </div>
        {errorMessage ? (<div className="form-group checkout-error">
          <div className="alert-danger alert">{errorMessage}</div>
        </div>) : (null)}
        <div className="form-group">
          <div className="payment-details-btn-main">
            <div className="payment-details-btn-sub">
              <button className="btn btn-primary payment-details-btn-left" onClick={buttonCloseAction}><i className="fa fa-long-arrow-left" aria-hidden="true"></i></button>
            </div>
            <div className="payment-details-btn-sub">
              {/* <button className="btn btn-primary loading-btn" disabled>Loading <img className="btn-loading" src={loading} /></button> */}
              {(paymentFetching || loadingBtn ) ?
                (
                  <button className="btn btn-primary loading-btn" disabled>Loading <img className="btn-loading" src={loading} /></button>
                )
              : (<button className="btn btn-primary payment-details-btn-right" onClick={this.handleSubmit}>{planBtnText} <img src={arrowRight} /></button>)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  paymentFetching: state.payment.paymentFetching,
})

const mapDispatchToProps = (dispatch) => {
const reduxActions = {
  ...PatmentReduxActionsCreators,
};


return { actions: bindActionCreators(reduxActions, dispatch)};
}

export default connect(mapStateToProps, mapDispatchToProps)(injectStripe(CheckoutForm));
// export default injectStripe(CheckoutForm);