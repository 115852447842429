import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router';
const createHistory = require("history").createHashHistory;

// creates the store
export default (rootReducer, rootSaga) => {
  /* ------------- Redux Configuration ------------- */

  const middleware = [];
  const enhancers = [];
  let composeEnhancers = compose;

  // // If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
  // if (process.env.NODE_ENV === 'development') {
  //   const devToolsExtension = typeof window === 'object' &&
  //   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
  //     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
  //       // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
  //     }) : compose;
    
  //   console.log('devToolsExtensiondevToolsExtension', devToolsExtension)
  //   composeEnhancers = devToolsExtension;
  // }

  /* ------------- React Redux Router ------------- */
  if (process.env.NODE_ENV === `development`) {
    const { logger } = require(`redux-logger`);
  
    middleware.push(logger);
  }

  const history = createHistory({
    initialEntries: ['/'], // The initial URLs in the history stack
    basename: '/'
  });
  
  const routerMiddleware = createRouterMiddleware(history);
  middleware.push(routerMiddleware);

  /* ------------- Saga Middleware ------------- */

  // const sagaMonitor = null;
  const sagaMiddleware = createSagaMiddleware({});
  middleware.push(sagaMiddleware);
  
  const store = createStore(rootReducer(history), applyMiddleware(...middleware));

  // kick off root saga
  let sagasManager = sagaMiddleware.run(rootSaga);

  return {
    history,
    store,
    sagasManager,
    sagaMiddleware
  };
};