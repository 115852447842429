import { delay, takeEvery, takeLatest, put, call } from "redux-saga/effects";
import LandingHomeReduxActionsCreators, {
  LandingHomeTypes,
} from "Reducers/landingHome.reducer";
import {
  getAddressesByPostcodeQuery,
  generateReportQuery,
} from "../services/landingHome.services";
import { push } from "react-router-redux";
import { Routes } from "Routes";
import * as CommonFunc from "Utils/commonFunction";

export default [getAddressesWatcher, generateReportWatcher];

const errorMsg = "a network error has occurred";

//function definition for get Addresses request.
function* getAddressesWatcher() {
  // console.log('getAddressesWatcher');
  yield takeEvery(
    LandingHomeTypes.GET_ADDRESSES_BY_POSTAL_CODE,
    getAddressesByPostalCodeRequest
  );
}

//function definition for generate Report request.
function* generateReportWatcher() {
  // console.log('getAddressesWatcher');
  yield takeEvery(LandingHomeTypes.GENERATE_REPORT, generateReportRequest);
}

// get addresses by postcode
function* getAddressesByPostalCodeRequest({ postalCode }) {
  // console.log('getAddressesByPostalCodeRequest', postalCode, token);
  yield put(LandingHomeReduxActionsCreators.setFetching(true));
  yield put(LandingHomeReduxActionsCreators.setFetchingErr(false));
  yield put(LandingHomeReduxActionsCreators.setFetchingErrMsg(""));

  try {
    if (!postalCode) {
      throw new Error("Not found postal code.");
    }
    const payload = {
      postalCode,
    };
    const response = yield call(getAddressesByPostcodeQuery, payload);
    if (response && response.data) {
      if (response.data.success == false) {
        CommonFunc.showUnhandledErrors(response.data.message);
        yield put(
          LandingHomeReduxActionsCreators.setFetchingErrMsg(
            response.data.message
          )
        );
        yield put(LandingHomeReduxActionsCreators.setFetchingErr(true));
      } else {
        CommonFunc.handleOnSuccessToastify(response.data.message);
        yield put(
          LandingHomeReduxActionsCreators.setAddressesByPostalCode(
            response.data
          )
        );
      }
    }

    if (response && response.error) {
      throw new Error(response.error);
    }

    yield put(LandingHomeReduxActionsCreators.setFetching(false));
  } catch (errors) {
    yield put(LandingHomeReduxActionsCreators.setFetchingErr(true));
    yield put(LandingHomeReduxActionsCreators.setFetching(false));

    if (errors.response) {
      if (errors.response.data) {
        yield put(
          LandingHomeReduxActionsCreators.setFetchingErrMsg(
            errors.response.data.message
          )
        );
        CommonFunc.showUnhandledErrors(errors.response.data.message);
      }
    } else if (errors && errors.message) {
      CommonFunc.showUnhandledErrors(errors.message);
      yield put(
        LandingHomeReduxActionsCreators.setFetchingErrMsg(errors.message)
      );
    } else {
      CommonFunc.showUnhandledErrors(errorMsg);
      yield put(LandingHomeReduxActionsCreators.setFetchingErrMsg(errorMsg));
    }
  }
}

// get addresses by postcode
function* generateReportRequest({ payload }) {
  yield put(LandingHomeReduxActionsCreators.setFetching(true));
  yield put(LandingHomeReduxActionsCreators.setFetchingErr(false));
  yield put(LandingHomeReduxActionsCreators.setFetchingErrMsg(""));

  try {
    const response = yield call(generateReportQuery, payload);
    if (response && response.data) {
      if (response.data.success == false) {
        CommonFunc.showUnhandledErrors(response.data.message);
        yield put(
          LandingHomeReduxActionsCreators.setFetchingErrMsg(
            response.data.message
          )
        );
        yield put(LandingHomeReduxActionsCreators.setFetchingErr(true));
      } else {
        yield put(
          LandingHomeReduxActionsCreators.setGenerateReport(response.data)
        );
        if (response.data.data.report_id == "") {
          yield put(push(Routes.SAVE_REPORTS_VIEW));
        } else {
          yield put(push(`/reportresult/${response.data.data.report_id}`));
        }
      }
      yield put(LandingHomeReduxActionsCreators.setFetching(false));
    }

    if (response && response.error) {
      throw new Error(response.error);
    }
  } catch (errors) {
    yield put(LandingHomeReduxActionsCreators.setFetchingErr(true));
    yield put(LandingHomeReduxActionsCreators.setFetching(false));

    if (errors.response) {
      if (errors.response.data) {
        yield put(
          LandingHomeReduxActionsCreators.setFetchingErrMsg(
            errors.response.data.message
          )
        );
        CommonFunc.showUnhandledErrors(errors.response.data.message);
      }
    } else if (errors && errors.message) {
      CommonFunc.showUnhandledErrors(errors.message);
      yield put(
        LandingHomeReduxActionsCreators.setFetchingErrMsg(errors.message)
      );
    } else {
      CommonFunc.showUnhandledErrors(errorMsg);
      yield put(LandingHomeReduxActionsCreators.setFetchingErrMsg(errorMsg));
    }
  }
}
