import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import { reduxReportsActions } from 'App/actions';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    getUsersReports: null,
    setUsersReports: ['reports'],
    
    getUserReportById: ['id'],
    setUserReportById: ['report'],

    updateReport: ['payload'],
    updateReportResult: ['payload'],

    setFetching: ['status'],
    setFetchingErr: ['error'],
    setFetchingErrMsg: ['errorMsg'],

    setReportFullPageLoading: ['status'],
});
  
export const ReportsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    usersReports: [],
    userReportById: {},

    fetching: false,
    fetchingErr: false,
    fetchingErrMsg: '',
    reportFullPageLoading: false,
});

/* ------------- Reducers Actions ------------- */

const { 
    setFetching,
    setFetchingErr,
    setFetchingErrMsg,
    setUsersReports,
    setUserReportById,
    setReportFullPageLoading,
} = reduxReportsActions;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_USERS_REPORTS]: setUsersReports,
    [Types.SET_USER_REPORT_BY_ID]: setUserReportById,

    [Types.SET_FETCHING]: setFetching,
    [Types.SET_FETCHING_ERR]: setFetchingErr,
    [Types.SET_FETCHING_ERR_MSG]: setFetchingErrMsg,

    [Types.SET_REPORT_FULL_PAGE_LOADING]: setReportFullPageLoading,
});