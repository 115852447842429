import axios from 'axios';
import APP_CONFIG from 'App/config';

//console.log('APP_CONFIG', APP_CONFIG)
const { networkInterface } = APP_CONFIG;
//console.log("networkInterfacenetworkInterface", networkInterface);
const globalInstance = axios.create({
    baseURL: networkInterface,
    timeout: 120000,
    headers: { 'Content-Type' : 'application/json', 'Access-Control-Allow-Origin': '*' }
});
// globalInstance.defaults.baseURl = networkInterface;
// globalInstance.defaults.timeout = 120000,
// globalInstance.defaults.headers = { 'Content-Type' : 'application/json' };

// console.log('globalInstance', globalInstance.defaults);

export { globalInstance as default };