import React, {Component} from 'react';

class StepFormNextPrevButton extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  getCustomActions = (action) => {
    const { 
      prevButtonOnClickHandler,
      nextButtonOnClickHandler,
      prevButtonImage,
      nextButtonImage,
      prevChildren,
      nextChildren,
      prevButtonText,
      nextButtonText,
      prevClassName,
      nextClassName } = this.props;
    switch (action) {
      case 'prev':
        return (
          <div className="step-prev">
            <button className={`btn-back ${prevClassName}`} onClick={prevButtonOnClickHandler}>{prevChildren}Back</button>
          </div>
        );
      case 'next':
        return (
          <div className="step-next">
            <button className={`btn btn-primary ${nextClassName}`} onClick={nextButtonOnClickHandler}>{nextButtonText} <img src={nextButtonImage} /></button>
          </div>
        );
      default:
        return null;
    }
  }
   
  render() {
    const { buttonActions } = this.props;
    let actions = [];
    return (
      <div className="step-next-prev-btn">
        {
          buttonActions.map((action, actionKeyIdx) => {
            actions.push(this.getCustomActions(action));
          })
        }
        {actions}
      </div>
    )
  }
}

const connectedStepFormNextPrevButton = StepFormNextPrevButton;
export {connectedStepFormNextPrevButton as StepFormNextPrevButton};
