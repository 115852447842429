import React, {Component} from 'react';
import ReactModal from 'react-modal';

class Modal extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

   
  componentWillMount() {
    ReactModal.setAppElement('body');
  }

  render() {
    const { 
      isModalOpen,
      children,
      handleOnRequestClose,
      customStyle,
      contentInfo,
      handleAfterOnModalOpened } = this.props;
    return (
      <ReactModal 
        shouldCloseOnOverlayClick={false} 
        isOpen={isModalOpen}
        onRequestClose={handleOnRequestClose}
        className={customStyle}
        onAfterOpen={handleAfterOnModalOpened}
        contentLabel={contentInfo}
      >
        {children}
      </ReactModal>
    )
  }
}

const connectedModal = Modal;
export {connectedModal as Modal};
