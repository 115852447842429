import React, { Component } from "react";

import { Helmet } from "Components/Helmet";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PaymentReduxActionsCreators from "Reducers/payment.reducer";
import AuthReduxActionsCreators from "Reducers/auth.reducer";
import { Header } from "Components/Header";
import { Footer } from "Components/Footer";
import { getItemInLocalStorage } from "App/utils/commonFunction";
import { PaymentSuccessUnsuccessModal } from "Components/PaymentSuccessUnsuccessModal";
import { PaymentCheckoutModal } from "Components/PaymentCheckoutModal";
import { Modal } from "Components/Modal";

import planHeader from "Assets/images/plan_header.svg";
import arrowRight from "Assets/images/arrow-right.png";
import calculatorPlanIcon from "Assets/images/calculator_plan_icon.svg";
import calculatorPlanIconActive from "Assets/images/calculator_plan_icon_active.svg";
import creditCardImg from "Assets/images/credit-card.svg";
import paypalImg from "Assets/images/paypal.svg";
import paymentSuccessImg from "Assets/images/payment_success.svg";
import paymentUnsuccessImg from "Assets/images/payment_unsuccess.svg";
import loading from "Assets/images/loading.gif";

class Plans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planMembershipPrice: null,
      planOnetimePrice: null,
      planOnetimeDescription: null,
      planStandardPrice: null,
      isPaymentMethodModalOpen: false,
      isUserPlanStatusDataModal: true,
      isPaymentDetailsModalOpen: false,
      isPaymentSuccessModalOpen: false,
      isPaymentUnsuccessModalOpen: false,
      isVerifyModalOpen: false,
      selectedPlanName: null,
      selectedPlanPrice: null,
    };
  }

  componentDidMount() {
    const {
      actions: { planStatus },
    } = this.props;
    planStatus();
  }

  componentDidUpdate(prevProps, preState) {
    if (
      prevProps.planStatusSuccess !== this.props.planStatusSuccess &&
      this.props.planStatusSuccess
    ) {
      const { planStatusSuccess } = this.props;
      console.log("planStatusSuccess---", planStatusSuccess);
      this.setState({
        planMembershipPrice:
          planStatusSuccess.data &&
          planStatusSuccess.data.plan_membership.plan_price,
        planOnetimePrice:
          planStatusSuccess.data &&
          planStatusSuccess.data.plan_onetime.plan_price,
        planOnetimeDescription:
          planStatusSuccess.data &&
          planStatusSuccess.data.plan_onetime.plan_description,
        planStandardPrice:
          planStatusSuccess.data &&
          planStatusSuccess.data.plan_standard.plan_price,
      });
    }
    if (
      prevProps.stripePaymentSuccessModal !==
        this.props.stripePaymentSuccessModal &&
      this.props.stripePaymentSuccessModal
    ) {
      const { stripePaymentSuccessModal } = this.props;
      this.setState({
        isPaymentDetailsModalOpen: false,
        isPaymentSuccessModalOpen: stripePaymentSuccessModal,
      });
    }
    if (
      prevProps.stripePaymentFailureModal !==
        this.props.stripePaymentFailureModal &&
      this.props.stripePaymentFailureModal
    ) {
      const { stripePaymentFailureModal } = this.props;
      this.setState({
        isPaymentDetailsModalOpen: false,
        isPaymentUnsuccessModalOpen: stripePaymentFailureModal,
      });
    }
    if (
      prevProps.close_verify_modal !== this.props.close_verify_modal &&
      this.props.close_verify_modal
    ) {
      this.setState({ isVerifyModalOpen: false });
    }
  }

  handleCloseUserPlanStatusDataModal = () => {
    this.setState({ isUserPlanStatusDataModal: false });
  };

  handleOpenCloseVerifyModal = () => {
    this.setState({ isVerifyModalOpen: false });
  };

  handleOpenClosePaymentMethodModal = (planName, planPrice) => {
    const emailVerified = getItemInLocalStorage("email_verified_at");
    if (emailVerified == "null") {
      this.setState({ isVerifyModalOpen: true });
    } else {
      this.setState((prevState) => {
        return {
          isPaymentMethodModalOpen: !prevState.isPaymentMethodModalOpen,
          selectedPlanName: planName,
          selectedPlanPrice: planPrice,
        };
      });
    }
  };

  handleOpenClosePaymentDetailsModal = () => {
    this.setState((prevState) => {
      return {
        isPaymentMethodModalOpen: false,
        isPaymentDetailsModalOpen: !prevState.isPaymentDetailsModalOpen,
      };
    });
  };

  handleOpenClosePaymentUnsuccessModal = () => {
    this.setState((prevState) => {
      return {
        isPaymentMethodModalOpen: false,
        isPaymentUnsuccessModalOpen: !prevState.isPaymentUnsuccessModalOpen,
      };
    });
  };

  handleOpenClosePaymentBackModal = () => {
    this.setState({
      isPaymentMethodModalOpen: true,
      isPaymentDetailsModalOpen: false,
      selectedPlanName: null,
      selectedPlanPrice: null,
    });
  };

  handleOpenClosePaymentSuccessModal = () => {
    this.setState((prevState) => {
      return {
        isPaymentDetailsModalOpen: false,
        isPaymentSuccessModalOpen: !prevState.isPaymentSuccessModalOpen,
        selectedPlanName: null,
        selectedPlanPrice: null,
      };
    });
    const {
      actions: { planStatus },
    } = this.props;
    planStatus();
  };

  handleOnClickResendEmail = () => {
    // console.log("deded")
    const userId = localStorage.getItem("logged_user_ID");
    const {
      actions: { resendUserVerifyEmail },
    } = this.props;
    resendUserVerifyEmail({ userId });
  };

  handleOnPayByPayPalSubmit = async () => {
    const { selectedPlanName, selectedPlanPrice } = this.state;
    console.log("selectedPlanName--", this.state.selectedPlanName);
    console.log("selectedPlanPrice--", this.state.selectedPlanPrice);
    const {
      actions: { doExpressCheckoutPaypalPayment },
    } = this.props;
    await doExpressCheckoutPaypalPayment({
      planType: selectedPlanName,
      recurring: true,
    });
  };

  render() {
    const {
      isVerifyModalOpen,
      planMembershipPrice,
      planOnetimePrice,
      planOnetimeDescription,
      planStandardPrice,
      isPaymentMethodModalOpen,
      isPaymentDetailsModalOpen,
      isPaymentSuccessModalOpen,
      isPaymentUnsuccessModalOpen,
      isUserPlanStatusDataModal,
    } = this.state;
    let {
      premiumPlanStatus,
      standardPlanStatus,
      countOnetimePlan,
      planStatusFetching,
      setUserPlanStatusData,
    } = this.props;
    let planName = "";
    return (
      <>
        <Helmet
          title="Rent Potential"
          metaName="description"
          metaContent="Rent Potential"
        />
        <div className="main-content">
          <Header
            pageTitle="Real Estate Investment"
            pageSubTitle="select plan"
            pageHeaderImg={planHeader}
          />
          <div className="container padding-top-bot">
            <h2 className="plan-title">select plan</h2>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 m-t-20">
                <div className="select-plan-main text-center">
                  <img
                    className="calculator-plan-icon"
                    src={calculatorPlanIcon}
                  />
                  <p className="plan-list-title">PREMIUM PLAN</p>
                  <p className="plan-list-text">
                    Unlimited calculator access per month
                  </p>
                  <hr className="plan-list-line" />
                  <p className="plan-list-text">
                    Unlimited PDF analytical reports
                  </p>
                  <hr className="plan-list-line" />
                  <p className="plan-list-text">
                    5 comp & estimated market value reports per month
                  </p>
                  {planStatusFetching ? (
                    <div className="plans-loading-img">
                      <img src={loading} />
                    </div>
                  ) : (
                    <h2 className="plan-list-text">${planMembershipPrice}</h2>
                  )}
                  <p className="plan-list-text font-color-gray">Per Month</p>
                  {planStatusFetching ? (
                    <div className="plans-loading-img m-t-20">
                      <img src={loading} />
                    </div>
                  ) : premiumPlanStatus || standardPlanStatus ? (
                    premiumPlanStatus ? (
                      <button
                        class="btn btn-primary plan-btn plan-activated"
                        disabled
                      >
                        Activated
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary plan-btn plan-activated"
                        disabled
                      >
                        Already subscribed to monthly membership
                      </button>
                    )
                  ) : (
                    <button
                      class="btn btn-primary plan-btn"
                      onClick={() =>
                        this.handleOpenClosePaymentMethodModal(
                          (planName = "premium"),
                          planMembershipPrice
                        )
                      }
                    >
                      get started
                    </button>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 m-t-20">
                <div className="select-plan-main plan-active text-center">
                  <img
                    className="calculator-plan-icon"
                    src={calculatorPlanIconActive}
                  />
                  <p className="plan-list-title">ONE AND DONE!</p>
                  <p className="plan-list-text">One time calculator access</p>
                  <hr className="plan-list-line" />
                  <p className="plan-list-text">
                    Generate 1 PDF analytical report
                  </p>
                  <hr className="plan-list-line" />
                  <p className="plan-list-text">
                    Upgrade for comp & estimated market value report
                  </p>
                  {planStatusFetching ? (
                    <div className="plans-loading-img">
                      <img src={loading} />
                    </div>
                  ) : (
                    <h2 className="plan-list-text">${planOnetimePrice}</h2>
                  )}
                  <p className="plan-list-text font-color-gray">Per Month</p>
                  {planStatusFetching ? (
                    <div className="plans-loading-img m-t-20">
                      <img src={loading} />
                    </div>
                  ) : premiumPlanStatus ||
                    standardPlanStatus ||
                    countOnetimePlan > 0 ? (
                    countOnetimePlan > 0 ? (
                      <button
                        class="btn btn-primary plan-btn plan-activated"
                        disabled
                      >
                        Activated
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary plan-btn plan-activated"
                        disabled
                      >
                        Already subscribed to monthly membership
                      </button>
                    )
                  ) : (
                    <button
                      class="btn btn-primary plan-btn plan-active-btn"
                      onClick={() =>
                        this.handleOpenClosePaymentMethodModal(
                          (planName = "single-checkout"),
                          planOnetimePrice
                        )
                      }
                    >
                      get started
                    </button>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 m-t-20">
                <div className="select-plan-main text-center">
                  <img
                    className="calculator-plan-icon"
                    src={calculatorPlanIcon}
                  />
                  <p className="plan-list-title">standard PLAN</p>
                  <p className="plan-list-text">
                    10 time calculator access per month
                  </p>
                  <hr className="plan-list-line" />
                  <p className="plan-list-text">
                    Generate 10 PDF analytical reports
                  </p>
                  <hr className="plan-list-line" />
                  <p className="plan-list-text">
                    1 comp & estimated market value report per month
                  </p>
                  {planStatusFetching ? (
                    <div className="plans-loading-img">
                      <img src={loading} />
                    </div>
                  ) : (
                    <h2 className="plan-list-text">${planStandardPrice}</h2>
                  )}
                  <p className="plan-list-text font-color-gray">Per Month</p>
                  {planStatusFetching ? (
                    <div className="plans-loading-img m-t-20">
                      <img src={loading} />
                    </div>
                  ) : premiumPlanStatus || standardPlanStatus ? (
                    standardPlanStatus ? (
                      <button
                        class="btn btn-primary plan-btn plan-activated"
                        disabled
                      >
                        Activated
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary plan-btn plan-activated"
                        disabled
                      >
                        Already subscribed to monthly membership
                      </button>
                    )
                  ) : (
                    <button
                      class="btn btn-primary plan-btn"
                      onClick={() =>
                        this.handleOpenClosePaymentMethodModal(
                          (planName = "standard"),
                          planStandardPrice
                        )
                      }
                    >
                      get started
                    </button>
                  )}
                  {/* <button class="btn btn-primary plan-btn">get started</button> */}
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
        {setUserPlanStatusData.data &&
        !setUserPlanStatusData.data.plan_status &&
        !setUserPlanStatusData.data.api_plan_status ? (
          <Modal
            isModalOpen={isUserPlanStatusDataModal}
            handleOnRequestClose={this.handleCloseUserPlanStatusDataModal}
            customStyle="modal-dialog modal-dialog-centered modal-lg verify-mail-modal"
          >
            <div className="modal-content multi-step">
              <div className="modal-body multi-step">
                <div className="text-center modal-header">
                  <span
                    className="modal-close"
                    aria-hidden="true"
                    onClick={this.handleCloseUserPlanStatusDataModal}
                  >
                    &times;
                  </span>
                </div>
                <div className="text-center modal-body nopadding">
                  <div className="container m-b-15">
                    <p class="alert alert-info mb-0">
                      {setUserPlanStatusData.message}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}

        <Modal
          isModalOpen={isPaymentMethodModalOpen}
          handleOnRequestClose={this.handleOpenClosePaymentMethodModal}
          customStyle="modal-dialog modal-dialog-centered modal-md"
        >
          <div className="modal-content multi-step">
            <div className="modal-body multi-step">
              <div className="text-center">
                <h2 className="popup-title payment-method-title">
                  CHOOSE PAYMENT METHOD
                </h2>
                <div
                  className="payment-method-main"
                  onClick={this.handleOpenClosePaymentDetailsModal}
                >
                  <img className="card-payment-icon" src={creditCardImg} />
                  <p className="payment-method-text">pay by credit card</p>
                </div>
                <div
                  className="payment-method-main"
                  onClick={() => this.handleOnPayByPayPalSubmit()}
                >
                  <img className="paypal-payment-icon" src={paypalImg} />
                  <p className="payment-method-text">Paypal Payment</p>
                </div>
                <button
                  className="btn btn-primary multi-step-close"
                  onClick={this.handleOpenClosePaymentMethodModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <PaymentCheckoutModal
          isModalOpen={isPaymentDetailsModalOpen}
          handleOnRequestClose={this.handleOpenClosePaymentDetailsModal}
          customStyle="modal-dialog modal-dialog-centered modal-lg"
          popupTitle="payment details"
          popupTitleClass="payment-method-title"
          planName={this.state.selectedPlanName}
          popupBtnCloseAction={this.handleOpenClosePaymentBackModal}
          popupBtnText={`pay now ($${this.state.selectedPlanPrice})`}
        />

        <PaymentSuccessUnsuccessModal
          isModalOpen={isPaymentSuccessModalOpen}
          handleOnRequestClose={this.handleOpenClosePaymentSuccessModal}
          customStyle="modal-dialog modal-dialog-centered modal-md"
          popupTitle="payment successful"
          popupTitleClass="payment-success-title"
          popupImage={paymentSuccessImg}
          popupBtnClass="popup-img"
          popupBtnText="Complete"
          popupBtnAction={this.handleOpenClosePaymentSuccessModal}
          popupBtnClass="payment-success"
          buttonIcon={arrowRight}
          buttonIconClass="payment-success-icon"
        >
          <p className="popup-text">
            The payment of{" "}
            <strong className="color-dark-gray">
              ${this.state.selectedPlanPrice}
            </strong>{" "}
            has been
            <br />
            received successfully.
          </p>
        </PaymentSuccessUnsuccessModal>

        <PaymentSuccessUnsuccessModal
          isModalOpen={isPaymentUnsuccessModalOpen}
          handleOnRequestClose={this.handleOpenClosePaymentUnsuccessModal}
          customStyle="modal-dialog modal-dialog-centered modal-md"
          popupTitle="payment unSuccessful"
          popupTitleClass="payment-unsuccess-title"
          popupImage={paymentUnsuccessImg}
          popupBtnClass="popup-img"
          popupBtnText="try again"
          popupBtnAction={this.handleOpenClosePaymentUnsuccessModal}
          popupBtnClass="payment-unsuccess"
          buttonIcon={arrowRight}
          buttonIconClass="payment-unsuccess-icon"
        >
          <p className="popup-text">
            We are not able to process you payment.
            <br />
            Please try again.
          </p>
        </PaymentSuccessUnsuccessModal>

        <Modal
          isModalOpen={isVerifyModalOpen}
          handleOnRequestClose={this.handleOpenCloseVerifyModal}
          customStyle="modal-dialog modal-dialog-centered modal-lg verify-mail-modal"
        >
          <div className="modal-content multi-step">
            <div className="modal-body multi-step">
              <div className="text-center modal-header">
                <h2 className="modal-title">VERIFY YOUR EMAIL ADDRESS</h2>
                <span
                  className="modal-close"
                  aria-hidden="true"
                  onClick={this.handleOpenCloseVerifyModal}
                >
                  &times;
                </span>
              </div>
              <div className="text-center modal-body">
                <p>
                  Before proceeding, please check your email for a verification
                  link. If no email is received, please check your spam inbox
                  before requesting a new verification email,{" "}
                  <span
                    className="resend-email-link"
                    onClick={this.handleOnClickResendEmail}
                  >
                    click here to request another.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  planStatusFetching: state.payment.planStatusFetching,
  planStatusSuccess: state.payment.planStatusSuccess,
  premiumPlanStatus: state.payment.premiumPlanStatus,
  standardPlanStatus: state.payment.standardPlanStatus,
  countOnetimePlan: state.payment.countOnetimePlan,
  setUserPlanStatusData: state.payment.setUserPlanStatusData,
  close_verify_modal: state.authUsers.close_verify_modal,
  stripePaymentSuccessModal: state.payment.stripePaymentSuccessModal,
  stripePaymentFailureModal: state.payment.stripePaymentFailureModal,
});

const mapDispatchToProps = (dispatch) => {
  const reduxActions = {
    ...PaymentReduxActionsCreators,
    ...AuthReduxActionsCreators,
  };

  return { actions: bindActionCreators(reduxActions, dispatch) };
};

const connectedPlansPage = connect(mapStateToProps, mapDispatchToProps)(Plans);
export { connectedPlansPage as Plans };
