import React, { Component } from 'react';
import ReactModal from 'react-modal';

import { StripeProvider, Elements } from 'react-stripe-elements';
// import MyStoreCheckout from './MyStoreCheckout/MyStoreCheckout';
import InjectedCheckoutForm from './CheckoutForm/CheckoutForm';

class PaymentCheckoutModal extends Component {
	constructor(props){
		super(props);
		this.state = {
			
		};
	}

	componentDidMount(){
	}

	componentWillMount() {
		ReactModal.setAppElement('body');
	}

	render(){
		const { 
			isModalOpen,
			handleOnRequestClose,
			customStyle,
			popupTitle, popupTitleClass, popupBtnCloseAction, planName, popupBtnText } = this.props;
		return (
			<>
			<ReactModal 
				shouldCloseOnOverlayClick={false} 
				isOpen={isModalOpen}
				onRequestClose={handleOnRequestClose}
				className={customStyle}
			>
				<div className="modal-content multi-step">
					<div className="modal-body multi-step">
						<h2 className={`popup-title ${popupTitleClass}`}>{popupTitle}</h2>
						<div className="payment-details-main">
							<StripeProvider apiKey="pk_live_BGlBTmF130uSZr92NGH6CjBf00vaNcr0Mj">
							{/* <StripeProvider apiKey="pk_test_lRC6gTizhJUbpMoIQlyC7pq900wRJBnwOL"> */}
								<Elements>
									<InjectedCheckoutForm buttonCloseAction={popupBtnCloseAction} planNameText={planName} planBtnText={popupBtnText} />
								</Elements>
							</StripeProvider>
						</div>
					</div>
				</div>
			</ReactModal>
			</>
    	);
  	}
}

const connectedCheckoutPage = PaymentCheckoutModal;
export { connectedCheckoutPage as PaymentCheckoutModal };
