import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import { reduxPlansActions } from 'App/actions';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    getPremiumPlanRequest: ['payload'],
    getPremiumPlanSuccess: ['payload'],

    getStandardPlan: null,
    setStandardPlan: ['standardPlanSummary'],

    getOneAndDonePlan: null,
    setOneAndDonePlan: ['oneAndDoneSummary'],

    setFetching: ['status'],
    setFetchingErr: ['error'],
    setFetchingErrMsg: ['errorMsg']
});
  
export const PlansTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    premiumPlanSuccess: [],
    fetching: false,
    fetchingErr: false,
    fetchingErrMsg: '',

    standardPlan: {},
    oneTimePlan: {}
});

/* ------------- Reducers Actions ------------- */

const { 
    setFetching,
    setFetchingErr,
    setFetchingErrMsg,
    getPremiumPlanSuccess,
    setStandardPlan,
    setOneAndDonePlan
} = reduxPlansActions;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.GET_PREMIUM_PLAN_SUCCESS]: getPremiumPlanSuccess,

    [Types.SET_STANDARD_PLAN]: setStandardPlan,
    [Types.SET_ONE_AND_DONE_PLAN]: setOneAndDonePlan,

    [Types.SET_FETCHING]: setFetching,
    [Types.SET_FETCHING_ERR]: setFetchingErr,
    [Types.SET_FETCHING_ERR_MSG]: setFetchingErrMsg,
});