//File for Authentication controller in the backend.
import axios from "../config/axios.config";
import API_ENDPOINT from "../constants/api.constants";
import { getItemInLocalStorage } from "App/utils/commonFunction";

//Method of stripe Payment Query.
function* stripePaymentQuery(payload) {
  try {
    const headers = {
      Authorization: `Bearer ${getItemInLocalStorage(
        "logged_user_auth_token"
      )}`,
    };
    const response = yield axios.post(
      `${API_ENDPOINT.stripeSubscribePlan}`,
      payload,
      { headers: headers },
      true
    );
    return yield Promise.resolve(response);
  } catch (error) {
    return yield Promise.reject(error);
  }
}

//Method of stripe Payment Single Use Query.
function* stripePaymentSingleUseQuery(payload) {
  try {
    const headers = {
      Authorization: `Bearer ${getItemInLocalStorage(
        "logged_user_auth_token"
      )}`,
    };
    const response = yield axios.post(
      `${API_ENDPOINT.stripeSingleUseSubscribePlan}`,
      payload,
      { headers: headers },
      true
    );
    return yield Promise.resolve(response);
  } catch (error) {
    return yield Promise.reject(error);
  }
}

//Method for getting Standard Plan.
function* planStatusQuery() {
  try {
    const headers = {
      Authorization: `Bearer ${getItemInLocalStorage(
        "logged_user_auth_token"
      )}`,
    };
    const response = yield axios.get(
      `${API_ENDPOINT.planStatusData}`,
      { headers: headers },
      true
    );
    return yield Promise.resolve(response);
  } catch (error) {
    return yield Promise.reject(error);
  }
}

//Method of stripe Payment Single Use Query.
function* stripeClosePlanQuery(payload) {
  try {
    const headers = {
      Authorization: `Bearer ${getItemInLocalStorage(
        "logged_user_auth_token"
      )}`,
    };
    const response = yield axios.post(
      `${
        payload.paymentMethod === "PAYPAL"
          ? API_ENDPOINT.cancelSubscriptionPaypal
          : API_ENDPOINT.cancelSubscriptionStripe
      }`,
      payload,
      { headers: headers },
      true
    );
    return yield Promise.resolve(response);
  } catch (error) {
    return yield Promise.reject(error);
  }
}

//Method for user Plan Status Query.
function* userPlanStatusQuery() {
  try {
    const headers = {
      Authorization: `Bearer ${getItemInLocalStorage(
        "logged_user_auth_token"
      )}`,
    };
    const response = yield axios.get(
      `${API_ENDPOINT.userPlanStatusData}`,
      { headers: headers },
      true
    );
    return yield Promise.resolve(response);
  } catch (error) {
    return yield Promise.reject(error);
  }
}

//Method for Doing express checkout payment through PayPal Query.
function* doExpressCheckoutPayPalQuery(payload) {
  try {
    const headers = {
      Authorization: `Bearer ${getItemInLocalStorage(
        "logged_user_auth_token"
      )}`,
    };
    let response;
    if (payload.planType !== "single-checkout") {
      response = yield axios.post(
        `${API_ENDPOINT.paypalEndpoint}/${payload.planType}`,
        payload,
        { headers: headers },
        true
      );
    } else {
      response = yield axios.get(
        `${API_ENDPOINT.paypal}/${payload.planType}`,
        { headers: headers },
        true
      );
    }
    return yield Promise.resolve(response);
  } catch (error) {
    return yield Promise.reject(error);
  }
}

//Method for Doing express checkout success after PayPal Response getting.
function* doExpressCheckoutSuccessQuery(payload) {
  try {
    const headers = {
      Authorization: `Bearer ${getItemInLocalStorage(
        "logged_user_auth_token"
      )}`,
    };
    console.log(
      "expressCheckout",
      `${API_ENDPOINT.expressCheckout}/${payload.planType}${payload.query}`
    );
    const response = yield axios.get(
      `${API_ENDPOINT.expressCheckout}/${payload.planType}${payload.query}`,
      { headers: headers },
      true
    );
    return yield Promise.resolve(response);
  } catch (error) {
    return yield Promise.reject(error);
  }
}

//Method for Doing express checkout success after PayPal Response getting.
function* doExpressCheckoutSuccessOneAndDoneQuery(payload) {
  try {
    const headers = {
      Authorization: `Bearer ${getItemInLocalStorage(
        "logged_user_auth_token"
      )}`,
    };
    console.log(
      "expressOneAndDoneCheckout",
      `${API_ENDPOINT.expressOneAndDoneCheckout}-${payload.planType}${payload.query}`
    );
    const response = yield axios.get(
      `${API_ENDPOINT.expressOneAndDoneCheckout}-${payload.planType}${payload.query}`,
      { headers: headers },
      true
    );
    return yield Promise.resolve(response);
  } catch (error) {
    return yield Promise.reject(error);
  }
}

//Exporting service methods.
export {
  stripePaymentQuery,
  stripePaymentSingleUseQuery,
  planStatusQuery,
  stripeClosePlanQuery,
  userPlanStatusQuery,
  doExpressCheckoutPayPalQuery,
  doExpressCheckoutSuccessQuery,
  doExpressCheckoutSuccessOneAndDoneQuery,
};
