import React from "react";
import CurrencyInput from "../../utils/CurrencyInput";

const InputText = ({
  singleLableText,
  singleInputName,
  singleInputClass,
  singleInputId,
  singleInputPlaceholder,
  singleInputValue,
  singleInputonChangeFunc,
  singleInputOnBlurFunc,
  singleInputType,
  disabled,
  errorMsg,
  autoComplete,
  currencyInput,
}) => {
  return (
    <>
      {currencyInput ? (
        <>
          <label className="form-lable">{singleLableText}</label>
          <CurrencyInput
            type={singleInputType}
            id={singleInputId}
            name={singleInputName}
            className={`form-input form-control ${singleInputClass}`}
            placeholder={singleInputPlaceholder}
            value={parseFloat(singleInputValue).toFixed(2) || ""}
            onChange={singleInputonChangeFunc}
            onBlur={singleInputOnBlurFunc}
            disabled={disabled}
            autoComplete={autoComplete}
          />
          <span className="error-msg">{errorMsg && errorMsg}</span>
        </>
      ) : (
        <>
          <label className="form-lable">{singleLableText}</label>
          <input
            type={singleInputType}
            id={singleInputId}
            name={singleInputName}
            className={`form-input form-control ${singleInputClass}`}
            placeholder={singleInputPlaceholder}
            value={singleInputValue || ""}
            onChange={singleInputonChangeFunc}
            onBlur={singleInputOnBlurFunc}
            disabled={disabled}
            autoComplete={autoComplete}
          />
          <span className="error-msg">{errorMsg && errorMsg}</span>
        </>
      )}
    </>
  );
};

const connectedInputText = InputText;
export { connectedInputText as InputText };
