import React from 'react';
import CurrencyInput from '../../utils/CurrencyInput';

const InputTextWithIcon = ({inputName,
  inputClass,
  inputPlaceholder,
  inputType,
  inputValue,
  inputChangeFunc,
  inputTextIcon,
  disabled,
  onFocusText,
  currencyInput,
  errorMsg}) => {
  
  return (
      <>
        {currencyInput ? (<><div className="investor-form">
          <CurrencyInput type={inputType} name={inputName} className={`form-input form-control ${inputClass}`} placeholder={inputPlaceholder} value={parseFloat(inputValue).toFixed(2) || ''} onChange={inputChangeFunc} disabled = {disabled} onBlur = {onFocusText} />
          <p className="rental-income-line"></p>
          <p className="rental-income-icon">{inputTextIcon}</p>
        </div>
        <span className="error-msg">{errorMsg && errorMsg}</span></>) : (<><div className="investor-form">
          <input type={inputType} name={inputName} className={`form-input form-control ${inputClass}`} placeholder={inputPlaceholder} value={inputValue || ''} onChange={inputChangeFunc} disabled = {disabled} onBlur = {onFocusText} />
          <p className="rental-income-line"></p>
          <p className="rental-income-icon">{inputTextIcon}</p>
        </div>
        <span className="error-msg">{errorMsg && errorMsg}</span></>)}
      </>
    )
  }

const connectedInputTextWithIcon = InputTextWithIcon;
export {connectedInputTextWithIcon as InputTextWithIcon};
