const API_ENDPOINT = {
  loginUser: "login",
  signout: "logout",
  forgotPasswordUser: "forgotpassword",
  registerUser: "register",
  resetPasswordUser: "resetpassword",
  getUserProfile: "profile",
  verifyUserEmail: "email/verify",
  resendVerifyEmail: "email/resend",
  updateProfile: "updateprofile",
  changePassword: "updatepassword",
  deleteUser: "user/delete",

  getAddress: "get-address",

  getPremiumPlan: "premium-plan",
  getStandardPlan: "standard-plan",
  getOneAndDonePlan: "one-and-done-plan",

  reports: "reports",
  updateReportData: "reports/edit",
  calculator: "calculator",

  stripeSubscribePlan: "stripe",
  stripeSingleUseSubscribePlan: "single-use",

  planStatusData: "plans",
  cancelSubscriptionStripe: "cancel-subscription",
  cancelSubscriptionPaypal: "paypal/cancel-paypal-subscription",

  userPlanStatusData: "userplanstatus",
  paypal: "paypal",
  paypalEndpoint: "paypal/express-checkout",
  expressCheckout: "paypal/express-checkout-success",
  expressOneAndDoneCheckout: "paypal/express-checkout",
  contactUs: "contact-us",
};

export default API_ENDPOINT;
