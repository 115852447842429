import React, { Component } from "react";
import GoogleMapReact from "google-map-react";

class GoogleMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: 3,
    };
  }
  render() {
    const { zoom } = this.state;
    console.log("this.props.lat--", this.props.lat);
    console.log("this.props.lng--", this.props.lng);
    return (
      <div style={{ height: "450px", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyBhhdW5ZdA1ZRwtyuWUETxCC_NKMV0tC74" }}
          // bootstrapURLKeys={{ key: 'AIzaSyCilLeT6TgLs2kC1YRwzTmW8uqGE2kCz98' }}
          defaultCenter={{ lat: 46.437857, lng: 2.570801 }}
          defaultZoom={zoom}
        >
          <div
            className="marker"
            style={{
              backgroundImage:
                "url('https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2_hdpi.png')",
              cursor: "pointer",
            }}
            lat={this.props.lat}
            lng={this.props.lng}
          />
        </GoogleMapReact>
      </div>
    );
  }
}

const connectedGoogleMap = GoogleMap;
export { connectedGoogleMap as GoogleMap };
