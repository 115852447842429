import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AuthReduxActionsCreators from '../../reducers/auth.reducer';

import { Helmet } from 'Components/Helmet';
import { Header } from 'Components/Header';
import { Footer } from 'Components/Footer';
import { InputText } from 'Components/InputText';

import { validateFormFields } from 'Utils/commonFunction';

import contactHeader from 'Assets/images/contact_header.png';
import emailimg from 'Assets/images/email.png';
import pin from 'Assets/images/pin.png';
import call from 'Assets/images/call.png';
import arrowRight from 'Assets/images/arrow-right.png';
import loading from 'Assets/images/loading.gif';


const INITIAL_STATE = {
	firstName: null,
	lastName: null,
	mobileNumber: null,
	email: null,
	message: null,

errors: {
	firstName: '',
	lastName: '',
	mobileNumber: '',
	email: '',
	message: '',
    },
}

class ContactUs extends Component {
	constructor(props){
		super(props);
		this.state = {
			...INITIAL_STATE,
		};
	}

	componentDidUpdate(prevProps, preState) {
		if ((prevProps.statusForContactUs !== this.props.statusForContactUs) && (this.props.statusForContactUs)) {
			this.setState({ ...INITIAL_STATE });
		}
	}

	handleOnErrors = (fieldName, fieldValue) => {
		this.setState(prevState => ({
		  errors: {
			...prevState.errors,
			[fieldName]: fieldValue
		  }
		}));
	}

	handleOnChangeInput = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	}
	
	contactFormValidate = () => {
		const { firstName, lastName, mobileNumber, email, message } = this.state;
		const firstNameIsValid = validateFormFields('firstName', firstName);
		const lastNameIsValid = validateFormFields('lastName', lastName);
		const mobileNumberIsValid = validateFormFields('mobileNumber', mobileNumber);
		const emailIsValid = validateFormFields('email', email);
		const messageIsValid = validateFormFields('message', message);
		const stateOfErrors = 'errors';
		if (firstNameIsValid) {
      this.handleOnErrors('firstName', firstNameIsValid.message, stateOfErrors);
		}
		if (lastNameIsValid) {
      this.handleOnErrors('lastName', lastNameIsValid.message, stateOfErrors);
		}
		if (mobileNumberIsValid) {
      this.handleOnErrors('mobileNumber', mobileNumberIsValid.message, stateOfErrors);
		}
		if (emailIsValid) {
      this.handleOnErrors('email', emailIsValid.message, stateOfErrors);
		}
		if (messageIsValid) {
      this.handleOnErrors('message', messageIsValid.message, stateOfErrors);
		}
    return firstNameIsValid.status && lastNameIsValid.status && mobileNumberIsValid.status && emailIsValid.status && messageIsValid.status
	}
	
	handleOnContactSubmit = () => {
		const isContactFormFieldsValid = this.contactFormValidate();
		if(!isContactFormFieldsValid){
			console.log('You may enter wrong value, please try again')
		} else {
			console.log('Done')
			const { actions: { contactUsPost } } = this.props;

			const { firstName, lastName, email, mobileNumber, message } = this.state;

			contactUsPost({ 
				first_name: firstName,
				last_name: lastName,
				mobile_number: mobileNumber,
				email,
				message
			})
		}
	}

	render(){
		const { errors, firstName, lastName, mobileNumber, email, message } = this.state;
    return (
			<>
				<Helmet title="Rent Potential" metaName="description" metaContent="Rent Potential" />
				<div className="main-content">
					<Header
					pageTitle = "Real Estate Investment"
					pageSubTitle = "CONTACT US"
					buttonText = "read More"
					arrowRightImg = {arrowRight}
					pageHeaderImg = {contactHeader} />
					<div className="container padding-top-bot pt-0">
						<div className="row">
							<div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 m-t-10 offset-lg-2">
								<div className="section-img-contact-main">
									<img src={pin} />
								</div>
								<div className="section-contact-text">
									<p className="contact-text1">Address</p>
									<p className="contact-text2">510 5th Avenue Floor 3<br/>New York, NY 10036</p>
								</div>
							</div>
							{/* <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 m-t-10">
								<div className="section-img-contact-main">
									<img src={call} />
								</div>
								<div className="section-contact-text">
									<p className="contact-text1">Phone number</p>
									<p className="contact-text2">+(62) 123 456 7890<br/>+(62) 123 456 7893</p>
								</div>
							</div> */}
							<div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 m-t-10">
								<div className="section-img-contact-main">
									<img src={emailimg} />
								</div>
								<div className="section-contact-text">
									<p className="contact-text1">Email</p>
									<p className="contact-text2">support@rentpotential.com</p>
								</div>
							</div>
						</div>
					</div>

					<div className="padding-top-bot background-gray">
						<div className="container">
							<div className="row">
								<div className="col-sm-12 col-md-12 col-lg-1 col-xl-1"></div>
								<div className="col-sm-12 col-md-12 col-lg-10 col-xl-10 contact-form-main">
										<div className="row">
											<div className="col-md-6">
											<div className="form-group">
												<InputText singleLableText="First Name" singleInputType="text" singleInputClass={`${errors.firstName && 'error-text-border'}`} singleInputPlaceholder="Enter First Name" singleInputName="firstName" singleInputValue={firstName || ''} singleInputonChangeFunc={this.handleOnChangeInput} errorMsg={errors.firstName} />
											</div>
											</div>										
											<div className="col-md-6">
											<div className="form-group">
												<InputText singleLableText="Last Name" singleInputType="text" singleInputClass={`${errors.lastName && 'error-text-border'}`} singleInputPlaceholder="Enter Last Name" singleInputName="lastName" singleInputValue={lastName || ''} singleInputonChangeFunc={this.handleOnChangeInput} errorMsg={errors.lastName} />
											</div>
											</div>									
									</div>
									
										<div className="row">
											<div className="col-md-6">
											<div className="form-group">
												<InputText singleLableText="Mobile Number" singleInputType="text" singleInputClass={`${errors.mobileNumber && 'error-text-border'}`} singleInputPlaceholder="Enter Mobile Number" singleInputName="mobileNumber" singleInputValue={mobileNumber || ''} singleInputonChangeFunc={this.handleOnChangeInput} errorMsg={errors.mobileNumber} />
											</div>
											</div>
											<div className="col-md-6">
											<div className="form-group">
												<InputText singleLableText="Email" singleInputType="email" singleInputClass={`${errors.email && 'error-text-border'}`} singleInputPlaceholder="Enter Email" singleInputName="email" singleInputValue={email || ''} singleInputonChangeFunc={this.handleOnChangeInput} errorMsg={errors.email} />
											</div>
											</div>
										</div>
									
									
										<div className="row">
											<div className="col-md-12">
											<div className="form-group">
												<label className="form-lable">Message</label>
												<textarea className={`form-input form-control ${errors.message && 'error-text-border'}`} name="message" placeholder="Write Message" value={message || ''} onChange={this.handleOnChangeInput}></textarea>
												<span className="error-msg">{errors.message && errors.message}</span>
											</div>
										</div>
									</div>
									<div className="form-group contact-form-btn-main">
										<div className="row">
											<div className="col-md-12">
											{(this.props.fetching) ?
												(
													<button className="btn btn-primary contact-form-btn" disabled>Loading <img className="btn-loading" src={loading} /></button>
												)
											: (
												<button className="btn btn-primary contact-form-btn" onClick={this.handleOnContactSubmit}>Contact Now</button>
											)}
											</div>
										</div>
									</div>
								</div>
								<div className="col-sm-12 col-md-12 col-lg-1 col-xl-1"></div>
							</div>
						</div>
					</div>

					<div className="contact-map">
						<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.358434669061!2d-73.98303608463368!3d40.7541405793274!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259003b796151%3A0x5b68c7ef5883073c!2s510%205th%20Ave%20Floor%203%2C%20New%20York%2C%20NY%2010036%2C%20USA!5e0!3m2!1sen!2sin!4v1591705368802!5m2!1sen!2sin" height="450"></iframe>
					</div>
					<Footer></Footer>
				</div>
			</>
    	);
  	}
}


const mapStateToProps = (state) => ({
	fetching: state.authUsers.fetching,
	fetchingErr: state.authUsers.fetchingErr,
	fetchingErrMsg: state.authUsers.fetchingErrMsg,
	statusForContactUs: state.authUsers.statusForContactUs
})
  
const mapDispatchToProps = (dispatch) => {
	const reduxActions = {
	  ...AuthReduxActionsCreators
	};
	
	return { actions: bindActionCreators(reduxActions, dispatch)};
}

const connectedContactUsPage = connect(mapStateToProps, mapDispatchToProps)(ContactUs);
export { connectedContactUsPage as ContactUs };
