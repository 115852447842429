//Cooking of axios for URL based on configuration.
const serverPort = process.env.SERVER_PORT || '3000';

const LIVE_API_URL = process.env.NODE_ENV === 'production' ? process.env.PRODUCTION_SERVER_API_URL : process.env.DEVELOPMENT_SERVER_API_URL;
const LOCAL_API_URL = `http://${process.env.LOCAL_API_URL}:${serverPort}`;
// console.log('LIVE_API_URL', LIVE_API_URL);
// console.log('LOCAL_API_URL', LOCAL_API_URL);
// console.log('process.env.IS_RUNNING_ON_LOCAL', process.env.IS_RUNNING_ON_LOCAL);
// console.log('process.env.IS_RUNNING_ON_LOCAL', typeof process.env.IS_RUNNING_ON_LOCAL);

const SERVER_API_URL = process.env.IS_RUNNING_ON_LOCAL === "true" ? LOCAL_API_URL : LIVE_API_URL ; 
// console.log('SERVER_API_URL', SERVER_API_URL);
// console.log('IS_RUNNING_ON_LOCAL', process.env.IS_RUNNING_ON_LOCAL);
const APP_CONFIG = {
    networkInterface: SERVER_API_URL,
    version: '1.0.0',
    target: 'WEB APP'
};

export default APP_CONFIG;