// Loading/Fetching status
const setFetching = (state, { status }) => state.merge({ fetching: status });

// Error status set to TRUE/FALSE while ENDPOINT fetching issue.
const setFetchingErr = (state, { error }) => state.merge({ fetchingErr: error });

// Error message set while ENDPOINT fetching issue.
const setFetchingErrMsg = (state, { errorMsg }) => state.merge({ fetchingErrMsg: errorMsg });

// Set Addresses By Premium Plan Entered.
const getPremiumPlanSuccess = (state, { payload }) => state.merge({ premiumPlanSuccess: payload || [] });

const setStandardPlan = (state, { standardPlanSummary }) => state.merge({ standardPlan: standardPlanSummary || {} });

const setOneAndDonePlan = (state, { oneAndDoneSummary }) => state.merge({ oneTimePlan: oneAndDoneSummary || {} });

const plansReducerActions = {
    setFetching,
    setFetchingErr,
    setFetchingErrMsg,
    getPremiumPlanSuccess,
    setStandardPlan,
    setOneAndDonePlan
}

export { plansReducerActions as reduxPlansActions };
