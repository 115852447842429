import React, { Component } from 'react';

import { Helmet } from 'Components/Helmet';
import { Header } from 'Components/Header';
import { Footer } from 'Components/Footer';

import aboutHeader from 'Assets/images/about.svg';
import aboutSection from 'Assets/images/about_section.png';
import arrowRight from 'Assets/images/arrow-right.png';

const About = () => {
    
    return (
        <>
            <Helmet title="Rent Potential" metaName="description" metaContent="Rent Potential" />
            <div className="main-content">
                <Header
                    pageTitle="Real Estate Investment"
                    pageSubTitle="ABOUT US"
                    buttonText="read More"
                    arrowRightImg={arrowRight}
                    pageHeaderImg={aboutHeader} />
                <div className="about-row">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 section-img-main">
                                <img className="section-img" src={aboutSection} />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 m-t-10">
                                <h2 className="section-title">About Us</h2>
                                <p className="section-text">Our mission is simple. To help real estate investors see if a property is worth taking a closer look. We are the product of endless online searches with no reliable results for a real estate investment analytical tool.</p>
                                <p className="section-text">We encourage you to use our solution and let us know how we can continue to improve it!</p>
                                <div className="section-text">
                                    <p className="section-text-title">Please reach out to us at <a href="mailto:support@rentpotential.com" className="section-text-link">support@rentpotential.com</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        </>
    );
}

const connectedAboutPage = About;
export { connectedAboutPage as About };
