import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';

const Helmet = ({title, metaName, metaContent}) => {

  return (
      <ReactHelmet>
          <title>{title}</title>
          <meta
              name={metaName}
              content={metaContent}
          />
      </ReactHelmet>
  );
}


const connectedHelmet = Helmet;
export { connectedHelmet as Helmet };
