// Loading/Fetching status
const setPaymentFetching = (state, { status }) => state.merge({ paymentFetching: status });

const setPlanStatusFetching = (state, { status }) => state.merge({ planStatusFetching: status });

// Set Stripe Payment Success.
const setStripePaymentSuccess = (state, { status }) => state.merge({ stripePaymentSuccessModal: status });

// Set Stripe Payment Failure.
const setStripePaymentFailure = (state, { status }) => state.merge({ stripePaymentFailureModal: status });

const setPremiumPlanStatus = (state, { status }) => state.merge({ premiumPlanStatus: status });

const setStandardPlanStatus = (state, { status }) => state.merge({ standardPlanStatus: status });

const setCountOnetimePlan = (state, { status }) => state.merge({ countOnetimePlan: status });

const setStripeFullPageLoading = (state, { status }) => state.merge({ stripeFullPageLoading: status });

// const setStripeClosePlan = (state, { status }) => state.merge({ stripeClosePlanSuccess: status });

const setStripeClosePlan = (state, { payload }) => {
  return state.merge({ error: null, stripeClosePlanSuccess: payload });
}

const setPlanStatus = (state, { payload }) => state.merge({ error: null, planStatusSuccess: payload });

const setUserPlanStatus = (state, { payload }) => state.merge({ error: null, setUserPlanStatusData: payload });

const setPayPalAPIResponseCallback = (state, { payload }) => state.merge({ error: null, paypalPaymentDetails: payload.paypalRes });

const setExpressCheckoutSuccessAPICallback = (state, { payload }) =>  state.merge({ error: null, statusOfExpressCheckoutPayPal: payload.status });

  const paymentReducerActions = {
    setPaymentFetching,
    setPlanStatusFetching,
    setStripePaymentSuccess,
    setStripePaymentFailure,
    setPremiumPlanStatus,
    setStandardPlanStatus,
    setCountOnetimePlan,
    setStripeClosePlan,
    setPlanStatus,
    setStripeFullPageLoading,
    setUserPlanStatus,
    setPayPalAPIResponseCallback,
    setExpressCheckoutSuccessAPICallback
}

export { paymentReducerActions as reduxPaymentActions };
