import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import { reduxLandingHomeActions } from 'App/actions';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    getAddressesByPostalCode: ['postalCode'],
    setAddressesByPostalCode: ['payload'],

    generateReport: ['payload'],
    setGenerateReport: ['payload'],

    setFetching: ['status'],
    setFetchingErr: ['error'],
    setFetchingErrMsg: ['errorMsg']
});
  
export const LandingHomeTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    postalAddresses: [],
    generateReport: [],
    fetching: false,
    fetchingErr: false,
    fetchingErrMsg: ''
});

/* ------------- Reducers Actions ------------- */

const { 
    setFetching,
    setFetchingErr,
    setFetchingErrMsg,
    setAddressesByPostalCode,
    setGenerateReport,
} = reduxLandingHomeActions;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_GENERATE_REPORT]: setGenerateReport,
    [Types.SET_ADDRESSES_BY_POSTAL_CODE]: setAddressesByPostalCode,
    [Types.SET_FETCHING]: setFetching,
    [Types.SET_FETCHING_ERR]: setFetchingErr,
    [Types.SET_FETCHING_ERR_MSG]: setFetchingErrMsg,
});