import { takeLatest, put, call } from "redux-saga/effects";
import ReportsReduxActionsCreators, {
  ReportsTypes,
} from "Reducers/reports.reducer";
import AuthReduxActionsCreators from "../reducers/auth.reducer";
import {
  getUsersReportsQuery,
  getUserReportByIdQuery,
  updateReportQuery,
  updateReportResultQuery,
} from "Services/reports.services";
import { push } from "react-router-redux";
import { Routes } from "Routes";
import * as CommonFunc from "Utils/commonFunction";

export default [
  getUsersReportsWatcher,
  getUserReportByIdWatcher,
  updateReportWatcher,
  updateReportResultWatcher,
];

const errorMsg = "a network error has occurred";

//function definition for get Premium Plan request.
function* getUsersReportsWatcher() {
  yield takeLatest(
    ReportsTypes.GET_USERS_REPORTS,
    getUsersReportsRequestHandler
  );
}

//function definition for get Standard Plan request.
function* getUserReportByIdWatcher() {
  yield takeLatest(
    ReportsTypes.GET_USER_REPORT_BY_ID,
    getUserReportByIdRequestHandler
  );
}

//function definition for get Standard Plan request.
function* updateReportWatcher() {
  yield takeLatest(ReportsTypes.UPDATE_REPORT, updateReportRequestHandler);
}

function* updateReportResultWatcher() {
  yield takeLatest(ReportsTypes.UPDATE_REPORT_RESULT, updateReportResultRequestHandler);
}

// Get Users Reports Request Handler
function* getUsersReportsRequestHandler() {
  yield put(ReportsReduxActionsCreators.setFetching(true));
  yield put(ReportsReduxActionsCreators.setFetchingErr(false));
  yield put(ReportsReduxActionsCreators.setFetchingErrMsg(""));

  try {
    const response = yield call(getUsersReportsQuery, null);

    if (response && response.data) {
      if (!response.data.success) {
        CommonFunc.showUnhandledErrors(response.data.message);
        yield put(ReportsReduxActionsCreators.setFetchingErr(true));
        yield put(
          ReportsReduxActionsCreators.setFetchingErrMsg(response.data.message)
        );
      }
      yield put(
        ReportsReduxActionsCreators.setUsersReports(response.data.data)
      );
    }

    if (response && response.error) {
      return response.error;
    }
    yield put(ReportsReduxActionsCreators.setFetching(false));
  } catch (errors) {
    console.log("errorserrorserrors", errors);
    yield put(ReportsReduxActionsCreators.setFetchingErr(true));
    yield put(ReportsReduxActionsCreators.setFetching(false));
    yield put(ReportsReduxActionsCreators.setUsersReports([]));

    if (errors.response && errors.response.data) {
      yield put(
        ReportsReduxActionsCreators.setFetchingErrMsg(
          errors.response.data.message
        )
      );
      CommonFunc.showUnhandledErrors(errors.response.data.message);
    } else {
      CommonFunc.showUnhandledErrors(errorMsg);
      yield put(ReportsReduxActionsCreators.setFetchingErrMsg(errorMsg));
      yield put(ReportsReduxActionsCreators.setFetchingErr(true));
    }
  }
}

// Get User Report By Id Request Handler
function* getUserReportByIdRequestHandler({ id }) {
  yield put(AuthReduxActionsCreators.setFetching(true));
  yield put(AuthReduxActionsCreators.setFetchingErr(false));
  yield put(AuthReduxActionsCreators.setFetchingErrMsg(""));

  try {
    const response = yield call(getUserReportByIdQuery, id);

    if (response && response.data) {
      if (!response.data.success) {
        CommonFunc.showUnhandledErrors(response.data.message);
        yield put(AuthReduxActionsCreators.setFetchingErr(true));
      }
      yield put(
        ReportsReduxActionsCreators.setUserReportById(response.data.data)
      );
    }

    if (response && response.error) {
      return response.error;
    }

    yield put(AuthReduxActionsCreators.setFetching(false));
  } catch (errors) {
    yield put(AuthReduxActionsCreators.setFetchingErr(true));
    yield put(AuthReduxActionsCreators.setFetching(false));
    yield put(ReportsReduxActionsCreators.setUserReportById({}));

    if (errors.response && errors.response.data) {
      yield put(
        AuthReduxActionsCreators.setFetchingErrMsg(errors.response.data.message)
      );
      CommonFunc.showUnhandledErrors(errors.response.data.message);
    } else {
      yield put(AuthReduxActionsCreators.setFetchingErrMsg(errorMsg));
      CommonFunc.showUnhandledErrors(errorMsg);
    }
  }
}

// Update Report By Id Request Handler
function* updateReportRequestHandler({ payload }) {
  yield put(AuthReduxActionsCreators.setFetching(true));
  yield put(AuthReduxActionsCreators.setFetchingErr(false));
  yield put(AuthReduxActionsCreators.setFetchingErrMsg(""));

  try {
    const response = yield call(updateReportQuery, payload);

    if (response && response.data) {
      if (response.data.success == false) {
        CommonFunc.showUnhandledErrors(response.data.message);
        yield put(AuthReduxActionsCreators.setFetchingErr(true));
      } else {
        if (response.data.data == "") {
          yield put(push(Routes.SAVE_REPORTS_VIEW));
        } else {
          yield put(push(`/report/${response.data.data}`));
        }
        CommonFunc.handleOnSuccessToastify(response.data.message);
      }
    }

    if (response && response.error) {
      return response.error;
    }

    yield put(AuthReduxActionsCreators.setFetching(false));
  } catch (errors) {
    yield put(AuthReduxActionsCreators.setFetchingErr(true));
    yield put(AuthReduxActionsCreators.setFetching(false));

    if (errors.response && errors.response.data) {
      CommonFunc.showUnhandledErrors(errors.response.data.message);
      yield put(
        AuthReduxActionsCreators.setFetchingErrMsg(errors.response.data.message)
      );
    } else {
      CommonFunc.showUnhandledErrors(errorMsg);
      yield put(AuthReduxActionsCreators.setFetchingErrMsg(errorMsg));
    }
  }
}

function* updateReportResultRequestHandler({ payload }) {
  yield put(AuthReduxActionsCreators.setFetching(true));
  yield put(AuthReduxActionsCreators.setFetchingErr(false));
  yield put(AuthReduxActionsCreators.setFetchingErrMsg(""));

  try {
    const response = yield call(updateReportQuery, payload);

    if (response && response.data) {
      if (response.data.success == false) {
        CommonFunc.showUnhandledErrors(response.data.message);
        yield put(AuthReduxActionsCreators.setFetchingErr(true));
      } else {
        if (response.data.data == "") {
          yield put(push(Routes.SAVE_REPORTS_VIEW));
        } else {
          yield put(push(`/reportresult/${response.data.data}`));
        }
        CommonFunc.handleOnSuccessToastify(response.data.message);
      }
    }

    if (response && response.error) {
      return response.error;
    }

    yield put(AuthReduxActionsCreators.setFetching(false));
  } catch (errors) {
    yield put(AuthReduxActionsCreators.setFetchingErr(true));
    yield put(AuthReduxActionsCreators.setFetching(false));

    if (errors.response && errors.response.data) {
      CommonFunc.showUnhandledErrors(errors.response.data.message);
      yield put(
        AuthReduxActionsCreators.setFetchingErrMsg(errors.response.data.message)
      );
    } else {
      CommonFunc.showUnhandledErrors(errorMsg);
      yield put(AuthReduxActionsCreators.setFetchingErrMsg(errorMsg));
    }
  }
}
