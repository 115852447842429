import React, { Component } from 'react';

import { Helmet } from 'Components/Helmet';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PlansReduxActionsCreators from '../../reducers/plans.reducer';
import PaymentReduxActionsCreators from 'Reducers/payment.reducer';
import AuthReduxActionsCreators from 'Reducers/auth.reducer';

import { Header } from 'Components/Header';
import { Footer } from 'Components/Footer';
import { getItemInLocalStorage } from 'App/utils/commonFunction';
import { PaymentSuccessUnsuccessModal } from 'Components/PaymentSuccessUnsuccessModal';
import { PaymentCheckoutModal } from 'Components/PaymentCheckoutModal';
import { Modal } from 'Components/Modal';
import { Link } from 'react-router-dom';
import queryString from 'querystring';

import premiumPlanHeader from 'Assets/images/premium_plan_header.png';
import arrowRight from 'Assets/images/arrow-right.png';
import planImg1 from 'Assets/images/box-img2.png';
import planImg2 from 'Assets/images/pdf.png';
import planImg3 from 'Assets/images/analysis.png';
import creditCardImg from 'Assets/images/credit-card.svg';
import paypalImg from 'Assets/images/paypal.svg';
import paymentSuccessImg from 'Assets/images/payment_success.svg';
import paymentUnsuccessImg from 'Assets/images/payment_unsuccess.svg';
import loading from 'Assets/images/loading.gif';

class PremiumPlan extends Component {
	constructor(props){
		super(props);
		this.state = {
			isPaymentMethodModalOpen: false,
			isPaymentDetailsModalOpen: false,
			isPaymentSuccessModalOpen: false,
			isPaymentUnsuccessModalOpen: false,
			isVerifyModalOpen: false,
			isPremiumPlanStatus: false,
			isStandardPlanStatus: false,
			isCountOnetimePlan: 0,
			premiumPlanPrice: null,
		};
	}

	async componentDidMount() {
		const { actions: { getPremiumPlanRequest, planStatus }, location } = this.props;
		if("location" in this.props && location.search){
			const requestParams = queryString.parse(location.search);
			if(requestParams.PayerID && requestParams.token){
				const { actions: { doExpressCheckoutSuccess }} = this.props;
				doExpressCheckoutSuccess({ query: location.search, planType: 'premium'})
			}
		}
		await getPremiumPlanRequest();
		await planStatus();
	}

	componentDidUpdate(prevProps, preState) {
		if ((prevProps.premiumPlanSuccess !== this.props.premiumPlanSuccess) && (this.props.premiumPlanSuccess)) {
			const { premiumPlanSuccess } = this.props;
			this.setState({ premiumPlanPrice: premiumPlanSuccess.data.plan_price });
		}
		if ((prevProps.stripePaymentSuccessModal !== this.props.stripePaymentSuccessModal) && (this.props.stripePaymentSuccessModal)) {
			const { stripePaymentSuccessModal } = this.props;
			this.setState({ isPaymentDetailsModalOpen: false, isPaymentSuccessModalOpen: stripePaymentSuccessModal, });
		}
		if ((prevProps.stripePaymentFailureModal !== this.props.stripePaymentFailureModal) && (this.props.stripePaymentFailureModal)) {
			const { stripePaymentFailureModal } = this.props;
			this.setState({ isPaymentDetailsModalOpen: false, isPaymentUnsuccessModalOpen: stripePaymentFailureModal, });
		}
		if ((prevProps.close_verify_modal !== this.props.close_verify_modal) && (this.props.close_verify_modal)) {
			this.setState({ isVerifyModalOpen: false });
		}

		if ((prevProps.statusOfExpressCheckoutPayPal !== this.props.statusOfExpressCheckoutPayPal) && (this.props.statusOfExpressCheckoutPayPal)) {
			const { statusOfExpressCheckoutPayPal } = this.props;
			this.setState({ isPaymentSuccessModalOpen: statusOfExpressCheckoutPayPal });
		}

		if ((prevProps.statusOfExpressCheckoutPayPal !== this.props.statusOfExpressCheckoutPayPal) && (!this.props.statusOfExpressCheckoutPayPal)) {
			const { statusOfExpressCheckoutPayPal } = this.props;
			this.setState({ isPaymentUnsuccessModalOpen: statusOfExpressCheckoutPayPal });
		}
	}

	handleOpenCloseVerifyModal = () => {
        this.setState({ isVerifyModalOpen: false });
    }

	handleOpenClosePaymentMethodModal = () => {
		const emailVerified = getItemInLocalStorage('email_verified_at');
		if(emailVerified == 'null') {
			this.setState({ isVerifyModalOpen: true });
		} else {
			this.setState((prevState) => {
				return { isPaymentMethodModalOpen: !prevState.isPaymentMethodModalOpen }
			});
		}
	}

	handleOpenClosePaymentDetailsModal = () => {
        this.setState((prevState) => {
            return { isPaymentMethodModalOpen: false, isPaymentDetailsModalOpen: !prevState.isPaymentDetailsModalOpen }
        });
	}

	handleOpenClosePaymentBackModal = () => {
        this.setState({ isPaymentMethodModalOpen: true, isPaymentDetailsModalOpen: false, });
	}

	handleOpenClosePaymentSuccessModal = () => {
        this.setState((prevState) => {
            return { isPaymentDetailsModalOpen: false, isPaymentSuccessModalOpen: !prevState.isPaymentSuccessModalOpen }
		});
		const { actions: { planStatus }, location } = this.props;
		planStatus();

		if("location" in this.props && location.search){
			const requestParams = queryString.parse(location.search);
			if(requestParams.PayerID && requestParams.token){
				this.props.history.push('/premium-plan');
			}
		}
	}

	handleOpenClosePaymentUnsuccessModal = () => {
        this.setState((prevState) => {
            return { isPaymentMethodModalOpen: false, isPaymentUnsuccessModalOpen: !prevState.isPaymentUnsuccessModalOpen }
        });
	}

	handleOnClickResendEmail = () => {
        // console.log("deded")
        const userId = localStorage.getItem('logged_user_ID');
        const { actions: { resendUserVerifyEmail } } = this.props;
        resendUserVerifyEmail({ userId });
	}

	handleOnPayByPayPalSubmit = async () => {
		const { actions: { doExpressCheckoutPaypalPayment }} = this.props;
		await doExpressCheckoutPaypalPayment({ planType: 'premium', recurring: true });
	}

	render(){
		const { isVerifyModalOpen, isPaymentMethodModalOpen, isPaymentDetailsModalOpen, isPaymentSuccessModalOpen, isPaymentUnsuccessModalOpen, premiumPlanPrice } = this.state;
		let { fetching, premiumPlanStatus, standardPlanStatus, countOnetimePlan, planStatusFetching } = this.props;

		const userLogged = localStorage.getItem('logged_user_auth_token');
      	return (
			<>
				<Helmet title="Rent Potential" metaName="description" metaContent="Rent Potential" />
				<div className="main-content">
					<Header
					pageTitle = "Membership"
					pageSubTitle = "PREMIUM PLAN"
					pageHeaderImg = {premiumPlanHeader} />
					<div className="container padding-top-bot  pt-0">
						<div className="row">
							<div className="col-sm-12 col-md-12 col-lg-1 col-xl-1"></div>
							<div className="col-sm-12 col-md-12 col-lg-10 col-xl-10">
								<div className="row membership-plan-main">
									<div className="col-md-12">
										{(fetching) ?
											(
												<div className="plans-loading-img">
													<img src={loading} />
												</div>
											)
										: (
											<h2 className="membership-plan-title">${premiumPlanPrice !== null ? premiumPlanPrice : '0.00'}</h2>
										)}
										<p className="membership-plan-subtitle">per month</p>
									</div>
									<div className="col-md-12 text-center plan-main-section">
										<div className="plan-sub-section">
											<img className="plan-img" src={planImg1} />
											<p className="plan-img-text">Unlimited tool access per month</p>
										</div>
										<div className="plan-sub-section">
											<img className="plan-img" src={planImg2} />
											<p className="plan-img-text">Generate unlimited analytics reports (PDF)</p>
										</div>
										<div className="plan-sub-section">
											<img className="plan-img" src={planImg3} />
											<p className="plan-img-text">10 comp & estimated market value reports per month</p>
										</div>
									</div>
									<div className="col-md-12 text-center payment-btn">
										{(planStatusFetching) ?
										(
											<div className="plans-loading-img m-t-20">
												<img src={loading} />
											</div>
										)
										: (
											(premiumPlanStatus || standardPlanStatus) ? (
												premiumPlanStatus ? (<button className="btn btn-primary already-subscribed-btn" disabled>already subscribed</button>) : (<button className="btn btn-primary already-subscribed-btn" disabled>Already subscribed to monthly membership</button>)
											) : (
												userLogged ? (<button className="btn btn-primary get-started-btn" onClick={this.handleOpenClosePaymentMethodModal}>get started <img src={arrowRight} /></button>) : (<Link className="btn btn-primary get-started-btn" to="/login">get started <img src={arrowRight} /></Link>)
											)
										)}
										{/* {(premiumPlanStatus || standardPlanStatus) ? (
											premiumPlanStatus ? (<button className="btn btn-primary already-subscribed-btn" disabled>already subscribed</button>) : (<button className="btn btn-primary already-subscribed-btn" disabled>Already subscribed to monthly membership</button>)
										) : (
											userLogged ? (<button className="btn btn-primary get-started-btn" onClick={this.handleOpenClosePaymentMethodModal}>get started <img src={arrowRight} /></button>) : (<Link className="btn btn-primary get-started-btn" to="/login">get started <img src={arrowRight} /></Link>)
										)} */}
									</div>
								</div>
							</div>
							<div className="col-sm-12 col-md-12 col-lg-1 col-xl-1"></div>
						</div>
					</div>
					<Footer></Footer>
				</div>

				<Modal
                    isModalOpen={isPaymentMethodModalOpen}
                    handleOnRequestClose={this.handleOpenClosePaymentMethodModal}
                    customStyle="modal-dialog modal-dialog-centered modal-md"
                >
                    <div className="modal-content multi-step">
                        <div className="modal-body multi-step">
                            <div className="text-center">
								<h2 className="popup-title payment-method-title">CHOOSE PAYMENT METHOD</h2>
                                <div className="payment-method-main" onClick={this.handleOpenClosePaymentDetailsModal}>
									<img className="card-payment-icon" src={creditCardImg} />
									<p className="payment-method-text">pay by credit card</p>
								</div>
								<div className="payment-method-main" onClick={() => this.handleOnPayByPayPalSubmit()}>
									<img className="paypal-payment-icon" src={paypalImg} />
									<p className="payment-method-text">Paypal Payment</p>
								</div>
                                <button className="btn btn-primary multi-step-close" onClick={this.handleOpenClosePaymentMethodModal}>Close</button>
                            </div>
                        </div>
                    </div>
                </Modal>

				<PaymentCheckoutModal
                    isModalOpen={isPaymentDetailsModalOpen}
                    handleOnRequestClose={this.handleOpenClosePaymentDetailsModal}
                    customStyle="modal-dialog modal-dialog-centered modal-lg"
					popupTitle="payment details"
					popupTitleClass="payment-method-title"
                    planName="premium"
                    popupBtnCloseAction={this.handleOpenClosePaymentBackModal}
                    popupBtnText={`pay now ($${premiumPlanPrice})`}
                />

				<PaymentSuccessUnsuccessModal
                    isModalOpen={isPaymentSuccessModalOpen}
                    handleOnRequestClose={this.handleOpenClosePaymentSuccessModal}
                    customStyle="modal-dialog modal-dialog-centered modal-md"
					popupTitle="payment successful"
					popupTitleClass="payment-success-title"
                    popupImage={paymentSuccessImg}
                    popupBtnClass="popup-img"
                    popupBtnText="Complete"
                    popupBtnAction={this.handleOpenClosePaymentSuccessModal}
                    popupBtnClass="payment-success"
                    buttonIcon={arrowRight}
                    buttonIconClass="payment-success-icon"
                >
					<p className="popup-text">The payment of <strong className="color-dark-gray">${premiumPlanPrice}</strong> has been<br/>received successfully.</p>
				</PaymentSuccessUnsuccessModal>

				<PaymentSuccessUnsuccessModal
                    isModalOpen={isPaymentUnsuccessModalOpen}
                    handleOnRequestClose={this.handleOpenClosePaymentUnsuccessModal}
                    customStyle="modal-dialog modal-dialog-centered modal-md"
                    popupTitle="payment unSuccessful"
                    popupTitleClass="payment-unsuccess-title"
                    popupImage={paymentUnsuccessImg}
                    popupBtnClass="popup-img"
                    popupBtnText="try again"
                    popupBtnAction={this.handleOpenClosePaymentUnsuccessModal}
                    popupBtnClass="payment-unsuccess"
                    buttonIcon={arrowRight}
                    buttonIconClass="payment-unsuccess-icon"
                >
					<p className="popup-text">We are not able to process you payment.<br/>Please try again.</p>
				</PaymentSuccessUnsuccessModal>

				<Modal
                    isModalOpen={isVerifyModalOpen}
                    handleOnRequestClose={this.handleOpenCloseVerifyModal}
                    customStyle="modal-dialog modal-dialog-centered modal-lg verify-mail-modal"
                >
                    <div className="modal-content multi-step">
                        <div className="modal-body multi-step">
                            <div className="text-center modal-header">
                                <h2 className="modal-title">VERIFY YOUR EMAIL ADDRESS</h2>
                                <span className="modal-close" aria-hidden="true" onClick={this.handleOpenCloseVerifyModal}>&times;</span>
                            </div>
                            <div className="text-center modal-body">
                                <p>Before proceeding, please check your email for a verification link. If no email is received, please check your spam inbox before requesting a new verification email, <span className="resend-email-link" onClick={this.handleOnClickResendEmail}>click here to request another.</span></p>
                            </div>
                        </div>
                    </div>
                </Modal>
			</>
    	);
  	}
}

const mapStateToProps = (state) => ({
	premiumPlanSuccess: state.plans.premiumPlanSuccess,
	fetching: state.plans.fetching,
	planStatusFetching: state.payment.planStatusFetching,
	stripePaymentSuccessModal: state.payment.stripePaymentSuccessModal,
	stripePaymentFailureModal: state.payment.stripePaymentFailureModal,
	premiumPlanStatus: state.payment.premiumPlanStatus,
	standardPlanStatus: state.payment.standardPlanStatus,
	countOnetimePlan: state.payment.countOnetimePlan,
	close_verify_modal: state.authUsers.close_verify_modal,
	statusOfExpressCheckoutPayPal: state.payment.statusOfExpressCheckoutPayPal
})

const mapDispatchToProps = (dispatch) => {
	const reduxActions = {
	  ...PlansReduxActionsCreators,
	  ...PaymentReduxActionsCreators,
	  ...AuthReduxActionsCreators,
	};

  	return { actions: bindActionCreators(reduxActions, dispatch)};
}

const connectedPremiumPlanPage = connect(mapStateToProps, mapDispatchToProps)(PremiumPlan);
export { connectedPremiumPlanPage as PremiumPlan };
