import React from 'react'
import { LoadingOrError } from 'Components/LoadingOrError';

function Table(props) {
  const {
    customStyles,
    data,
    errorMore,
    loadData,
    loadingMore,
    maxSize,
    table
  } = props

  const [maxHeight, setMaxHeight] = React.useState(0);

  const ref = React.useRef(null)

  React.useEffect(() => {
    if (data && data.length > 0) {
      setMaxHeight(ref.current.clientHeight)
    }
  }, [data])

  var tableTdCellMaxStyles = Object.assign({}, 
    { button: true }, {
      'backgroundColor' : `${props.style && props.style.bgColor
          ? props.style.bgColor
          : props.style && props.style.gray
          ? '#f5f5f5'
          : props.style && props.style.gray2
          ? '#ececec'
          : '#ffffff'}`,
      'border': `${props.style && props.borderless
          ? ''
          : props.style && props.borderColor
          ? props.borderColor
          : props.style && props.style.gray2
          ? '#d4d4d4'
          : '#ececec'}`, 
      'borderTop': `${props.style && (props.borderless || props.gray2)
          ? ''
          : props.style && props.borderColor
          ? props.borderColor
          : '#ececec'}`,
      'padding': `${props.style && props.style.padding
          ? props.style.padding
          : props.style && props.style.button
          ? '0'
          : '0 16px'}`,
      'textAlign': `${props.style && props.style.center ? 'center' : 'left'}`,
    }
  );
  
  return (
    <>
    <div ref={ref}>
      {table && (
        <table className="table saved-report-table" >
          {table.tableHead
            ? table.tableHead.map((v, i) => {
                return (
                  <thead key={i}>
                    <tr>
                      {v.map((w, j) => {
                        return (
                          <th className={w.className}
                            colSpan={w.colSpan}
                            key={j}>
                              {w.style && w.style.button && (
                                <button
                                  className="btn btn-report-view"
                                  onClick={w.onClick}>
                                    {w.value}
                                </button>
                              )}

                              {(!w.style || !w.style.button) && (
                                w.value
                              )}
                          </th>
                        )
                      })}
                    </tr>
                  </thead>
                )
              })
            : null}

          {table.tableRows ? (
            <tbody>
              {table.tableRows.map((v, i) => {
                return (
                  <tr key={i}>
                    {v.map((w, j) => {
                      return (
                        <td
                          className=""
                          colSpan={w.colSpan}
                          key={j}>
                          {w.style && w.style.button && (
                            <button
                              className="btn btn-report-view"
                              onClick={w.onClick}>
                              {w.value}
                            </button>
                          )}

                          {(!w.style || !w.style.button) && (
                            w.value
                          )}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          ) : null}

          {data && maxSize && data.length < maxSize ? (
            <tbody>
              <tr>
                <td 
                  className="table-cell" 
                  colSpan="100" 
                  style={tableTdCellMaxStyles}>
                  {/* <button className="load-more-button" onClick={() => !loadingMore ? loadData : undefined}>
                    {loadingMore ? (
                      <>
                        <LoadingOrError
                          err={errorMore?.length > 0}
                          errMsg={errorMore}
                          loading={loadingMore}
                          loadingMsg="Loading..."
                          style={{ fontSize: '13px', fontWeight: '400' }}
                        />
                      </>
                    ) : (
                      <>
                        Load More
                      </>
                    )}
                  </button> */}
                </td>
              </tr>
            </tbody>
          ) : null}
        </table>
      )}
    </div>
    </>
  )
}

const connectedTable = Table;
export { connectedTable as Table};
