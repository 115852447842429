import React, {Component} from 'react';
import ReactModal from 'react-modal';

class AlertAndDeleteModal extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  renderButtonsOnProps = (nos) => {
    const {
      popupBtnTextLeft, popupBtnActionLeft, popupBtnClassLeft, popupBtnTextRight, popupBtnActionRight, popupBtnClassRight
    } = this.props;
    switch (nos) {
      case 1:
        return(
          <button className={`btn btn-primary alert-yes ${popupBtnClassLeft}`} onClick={popupBtnActionLeft}>{popupBtnTextLeft}</button>
        );
      case 2: 
        return(
          <>
            <button className={`btn btn-primary alert-yes ${popupBtnClassLeft}`} onClick={popupBtnActionLeft}>{popupBtnTextLeft}</button>
            <button className={`btn btn-primary alert-no m-l-20 ${popupBtnClassRight}`} onClick={popupBtnActionRight}>{popupBtnTextRight}</button>
          </>
        );
      default:
        return(
          <button className={`btn btn-primary alert-yes ${popupBtnClassLeft}`} onClick={popupBtnActionLeft}>{popupBtnTextLeft}</button>
        );
    }
  }

  componentWillMount() {
    ReactModal.setAppElement('body');
  }

  render() {
    const { 
      isModalOpen,
      children,
      handleOnRequestClose,
      customStyle,
      contentInfo,
      handleAfterOnModalOpened, popupTitle, popupImage, popupImageClass, popupTitleClass, nosOfButtonToShow} = this.props;
    return (
      <ReactModal 
        shouldCloseOnOverlayClick={false} 
        isOpen={isModalOpen}
        onRequestClose={handleOnRequestClose}
        className={customStyle}
        onAfterOpen={handleAfterOnModalOpened}
        contentLabel={contentInfo}
      >
        <div className="modal-content multi-step">
            <div className="modal-body multi-step">
                <div className="text-center">
                  <h2 className={`popup-title ${popupTitleClass}`}>{popupTitle}</h2>
                  <img className={`m-b-15 ${popupImageClass}`} src={popupImage} />
                  {children}
                  {nosOfButtonToShow && this.renderButtonsOnProps(nosOfButtonToShow)}
                </div>
            </div>
        </div>
      </ReactModal>
    )
  }
}

const connectedAlertAndDeleteModal = AlertAndDeleteModal;
export {connectedAlertAndDeleteModal as AlertAndDeleteModal};
