//File for Authentication controller in the backend.
import axios from '../config/axios.config';
import API_ENDPOINT from '../constants/api.constants';

//Method for getting Premium Plan.
function* getPremiumPlanQuery(){
    try {
        const response = yield axios.get(`${API_ENDPOINT.getPremiumPlan}`, true);
        return yield Promise.resolve(response);
     } catch (error) {
         return yield Promise.reject(error);
     }
}

//Method for getting Standard Plan.
function* getStandardPlanQuery(){
    try {
        const response = yield axios.get(`${API_ENDPOINT.getStandardPlan}`, true);
        return yield Promise.resolve(response);
     } catch (error) {
         return yield Promise.reject(error);
     }
}

//Method for getting One & Done Plan.
function* getOneAndDonePlanQuery(){
    try {
        const response = yield axios.get(`${API_ENDPOINT.getOneAndDonePlan}`, true);
        return yield Promise.resolve(response);
     } catch (error) {
         return yield Promise.reject(error);
     }
}
//Exporting service methods.
export {
    getPremiumPlanQuery,
    getStandardPlanQuery,
    getOneAndDonePlanQuery,
};