// Loading/Fetching status
const setFetching = (state, { status }) => state.merge({ fetching: status });

// Error status set to TRUE/FALSE while ENDPOINT fetching issue.
const setFetchingErr = (state, { error }) => state.merge({ fetchingErr: error });

// Error message set while ENDPOINT fetching issue.
const setFetchingErrMsg = (state, { errorMsg }) => state.merge({ fetchingErrMsg: errorMsg });

// Set Addresses By Postal Code Entered.
const setAddressesByPostalCode = (state, { payload }) => state.merge({ postalAddresses: payload || [] });

// Set Generate Report Data.
const setGenerateReport = (state, { payload }) => state.merge({ generateReport: payload || [] });

const landingHomeReducerActions = {
    setFetching,
    setFetchingErr,
    setFetchingErrMsg,
    setAddressesByPostalCode,
    setGenerateReport,
}

export { landingHomeReducerActions as reduxLandingHomeActions };
