import reducers from 'App/reducers';
import configureStore from './createStore';

import rootSaga from 'App/sagas';

export default () => {
  let finalReducers = reducers;

  let { history, store, sagasManager, sagaMiddleware } = configureStore(
    finalReducers,
    rootSaga
  );

  if (module.hot) {
    module.hot.accept(() => {
      const nextRootReducer = require('App/reducers').default;
      store.replaceReducer(nextRootReducer);

      const newYieldedSagas = require('App/sagas').default;
      sagasManager.cancel();
      sagasManager.done.then(() => {
        sagasManager = sagaMiddleware.run(newYieldedSagas);
      });
    });
  }

  return {
    history,
    store
  };
};