import React, { Component } from 'react';

import { Helmet } from 'Components/Helmet';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AuthReduxActionsCreators from 'Reducers/auth.reducer';
import PatmentReduxActionsCreators from 'Reducers/payment.reducer';

import { Modal } from 'Components/Modal';
import { Header } from 'Components/Header';
import { Footer } from 'Components/Footer';
import { StepFromSection } from './StepFromSection';
import { StepFromSectionWithoutApi } from './StepFromSectionWithoutApi';
import { InputText } from 'Components/InputText';
import { Routes } from 'Routes';

import Line from 'Assets/images/line.png';
import boxImg1 from 'Assets/images/box-img1.png';
import boxImg2 from 'Assets/images/box-img2.png';
import boxImg3 from 'Assets/images/box-img3.png';
import calculator from 'Assets/images/calculator.png';
import checkmarkIcon from 'Assets/images/checkmark.png';

const queryString = require('query-string');

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isVerifyModalOpen: false,
            isRentalIncomeModalOpen: false,
            isFinancingModalOpen: false,
            isFinancingTwoModalOpen: false,
            isClosingCostsModalOpen: false,
            isOperatingExpensesModalOpen: false,
            isAnnualProfitModalOpen: false,
            isMetricsModalOpen: false,
            isBreakevenAnalysisTwoModalOpen: false,
            maximumReport: true,
        };
    }

    componentDidMount() {
        const userId = localStorage.getItem('logged_user_ID');
        const verifyEmail = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true }).email_verify;
        const verifyToken = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true }).token;
        
        if(verifyEmail) {
            const { actions: { userVerifyEmail } } = this.props;
            userVerifyEmail({ verifyEmail, verifyToken });
        }
        const { actions: { userPlanStatus } } = this.props;
        userPlanStatus();
        
        if(this.props.history.location && (this.props.history.location.hash && this.props.history.location.hash === "#how-it")){
            document.getElementById("how-it").scrollIntoView({ block: 'end',  behavior: 'smooth' });
        }
        // if (this.props.open_verify_modal) {
        //     const { open_verify_modal } = this.props;
        //     this.setState({ isVerifyModalOpen: open_verify_modal });
        // }
    }

    componentDidUpdate(prevProps, preState) {
        if ((prevProps.close_verify_modal !== this.props.close_verify_modal) && (this.props.close_verify_modal)) {
			this.setState({ isVerifyModalOpen: false });
        }
        if ((prevProps.open_verify_modal !== this.props.open_verify_modal) && (this.props.open_verify_modal)) {
            const { open_verify_modal } = this.props;
            this.setState({ isVerifyModalOpen: open_verify_modal });
        }
    }

    handleOpenCloseVerifyModal = () => {
        this.setState({ isVerifyModalOpen: false });
    }

    handleOpenCloseRentalIncomeModal = () => {
        this.setState((prevState) => {
            return { isRentalIncomeModalOpen: !prevState.isRentalIncomeModalOpen }
        });
    }

    handleOpenCloseFinancingModal = () => {
        this.setState((prevState) => {
            return { isFinancingModalOpen: !prevState.isFinancingModalOpen }
        });
    }

    handleOpenCloseFinancingTwoModal = () => {
        this.setState((prevState) => {
            return { isFinancingTwoModalOpen: !prevState.isFinancingTwoModalOpen }
        });
    }

    handleOpenCloseClosingCostsModal = () => {
        this.setState((prevState) => {
            return { isClosingCostsModalOpen: !prevState.isClosingCostsModalOpen }
        });
    }

    handleOpenCloseOperatingExpensesModal = () => {
        this.setState((prevState) => {
            return { isOperatingExpensesModalOpen: !prevState.isOperatingExpensesModalOpen }
        });
    }

    handleOpenCloseAnnualProfitModal = () => {
        this.setState((prevState) => {
            return { isAnnualProfitModalOpen: !prevState.isAnnualProfitModalOpen }
        });
    }

    handleOpenCloseMetricsModal = () => {
        this.setState((prevState) => {
            return { isMetricsModalOpen: !prevState.isMetricsModalOpen }
        });
    }

    handleOpenCloseBreakevenAnalysisModal = () => {
        this.setState((prevState) => {
            return { isBreakevenAnalysisModalOpen: !prevState.isBreakevenAnalysisModalOpen }
        });
    }

    handleOpenCloseBreakevenAnalysisTwoModal = () => {
        this.setState((prevState) => {
            return { isBreakevenAnalysisTwoModalOpen: !prevState.isBreakevenAnalysisTwoModalOpen }
        });
    }

    handleOnClickResendEmail = () => {
        // console.log("deded")
        const userId = localStorage.getItem('logged_user_ID');
        const { actions: { resendUserVerifyEmail } } = this.props;
        resendUserVerifyEmail({ userId });
    }

    render() {
        // console.log('thjis.porps', this.props);
        const { isVerifyModalOpen, isRentalIncomeModalOpen, isFinancingModalOpen, isFinancingTwoModalOpen, isClosingCostsModalOpen, isOperatingExpensesModalOpen, isAnnualProfitModalOpen, isMetricsModalOpen, isBreakevenAnalysisModalOpen, isBreakevenAnalysisTwoModalOpen } = this.state;
        const { setUserPlanStatusData } = this.props;
        return (
            <>
                <Helmet title="Rent Potential" metaName="description" metaContent="Rent Potential" />
                <div className="main-content">
                    <Header
                        pageTitle="Real Estate Investment"
                        pageSubTitle="CALCULATOR"
                        pageHeaderImg={calculator}>
                            <ul className="header-main-content-ul">
                                <li><img src={checkmarkIcon} /> Determine if your rental property has potential</li>
                                <li><img src={checkmarkIcon} /> Run your analysis and share with investors/lenders</li>
                                <li><img src={checkmarkIcon} /> Generate PDF reports with market data derived from county clerk offices across the U.S.</li>
                            </ul>
                    </Header> 
                    {setUserPlanStatusData && 'message' in setUserPlanStatusData && setUserPlanStatusData.message != "" && (
                        <div className="container m-b-15">
                        <p class="alert alert-info mb-0">
                          {setUserPlanStatusData.message}
                        </p>
                      </div>
                    )}
                    {(setUserPlanStatusData.data && !setUserPlanStatusData.data.api_plan_status) ? (
                        <StepFromSectionWithoutApi
                            rentalIncomeModalOpen={this.handleOpenCloseRentalIncomeModal}
                            financingModalOpen={this.handleOpenCloseFinancingModal}
                            financingTwoModalOpen={this.handleOpenCloseFinancingTwoModal}
                            closingCostsModalOpen={this.handleOpenCloseClosingCostsModal}
                            operatingExpensesModalOpen={this.handleOpenCloseOperatingExpensesModal}
                            annualProfitModalOpen={this.handleOpenCloseAnnualProfitModal}
                            metricsModalOpen={this.handleOpenCloseMetricsModal}
                            breakevenAnalysisModalOpen={this.handleOpenCloseBreakevenAnalysisModal}
                            breakevenAnalysisTwoModalOpen={this.handleOpenCloseBreakevenAnalysisTwoModal}
                            actions={this.props.actions}
                            maximumReportAttempts={(setUserPlanStatusData.data && !setUserPlanStatusData.data.api_plan_status) ? (false):(true)}
                        />
                    ):(
                        <StepFromSection
                            rentalIncomeModalOpen={this.handleOpenCloseRentalIncomeModal}
                            financingModalOpen={this.handleOpenCloseFinancingModal}
                            financingTwoModalOpen={this.handleOpenCloseFinancingTwoModal}
                            closingCostsModalOpen={this.handleOpenCloseClosingCostsModal}
                            operatingExpensesModalOpen={this.handleOpenCloseOperatingExpensesModal}
                            annualProfitModalOpen={this.handleOpenCloseAnnualProfitModal}
                            metricsModalOpen={this.handleOpenCloseMetricsModal}
                            breakevenAnalysisModalOpen={this.handleOpenCloseBreakevenAnalysisModal}
                            breakevenAnalysisTwoModalOpen={this.handleOpenCloseBreakevenAnalysisTwoModal}
                            actions={this.props.actions}
                            maximumReportAttempts={(setUserPlanStatusData.data && !setUserPlanStatusData.data.api_plan_status) ? (false):(true)}
                        />
                    )}

                    <div className="background-gray padding-top-bot how-it" id="how-it">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-1"></div>
                                <div className="col-md-10">
                                    <div className="main-content-header-title">
                                        <h3>HOW <strong>IT WORKS</strong></h3>
                                        {/* <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et is the quasi architecto</p> */}
                                    </div>
                                </div>
                                <div className="col-md-1"></div>
                            </div>
                            <div className="row padding-top-50">
                                <div className="col-md-1"></div>
                                <div className="col-md-10">
                                    <div className="work-bg-main-content">
                                        <img src={Line} alt="Line" />
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 text-center">
                                            <div className="work-rounded">
                                                <img src={boxImg1} alt="boxImg1" />
                                            </div>
                                            <h4 className="work-box-title">Register</h4>
                                            <p className="work-box-text">Registration is free!</p>
                                        </div>
                                        <div className="col-md-4 text-center">
                                            <div className="work-rounded">
                                                <img src={boxImg2} alt="boxImg1" />
                                            </div>
                                            <h4 className="work-box-title">Calculator</h4>
                                            <p className="work-box-text">Our tool calculates essential real estate metrics for investors (i.e. DSCR, Cap Rate, NOI, NPV)</p>
                                        </div>
                                        <div className="col-md-4 text-center">
                                            <div className="work-rounded">
                                                <img src={boxImg3} alt="boxImg1" />
                                            </div>
                                            <h4 className="work-box-title">Analysis</h4>
                                            <p className="work-box-text">Research recent comparable property sales, determine total initial investment required, possible return on investment, and # of years to break-even.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-1"></div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>

                <Modal
                    isModalOpen={isVerifyModalOpen}
                    handleOnRequestClose={this.handleOpenCloseVerifyModal}
                    customStyle="modal-dialog modal-dialog-centered modal-lg verify-mail-modal"
                >
                    <div className="modal-content multi-step">
                        <div className="modal-body multi-step">
                            <div className="text-center modal-header">
                                <h2 className="modal-title">VERIFY YOUR EMAIL ADDRESS</h2>
                                <span className="modal-close" aria-hidden="true" onClick={this.handleOpenCloseVerifyModal}>&times;</span>
                            </div>
                            <div className="text-center modal-body">
                                <p>Before proceeding, please check your email for a verification link. If no email is received, please check your spam inbox before requesting a new verification email, <span className="resend-email-link" onClick={this.handleOnClickResendEmail}>click here to request another.</span></p>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    isModalOpen={isRentalIncomeModalOpen}
                    handleOnRequestClose={this.handleOpenCloseRentalIncomeModal}
                    customStyle="modal-dialog modal-dialog-centered modal-md"
                >
                    <div className="modal-content multi-step">
                        <div className="modal-body multi-step">
                            <div className="text-center">
                                <p>If you're unsure of what rent to use, be sure to check <a href="https://www.rentometer.com/" target="_blank">rentometer.com</a> for some market rents based on your property's location</p>
                                <button className="btn btn-primary multi-step-close" onClick={this.handleOpenCloseRentalIncomeModal}>Close</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    isModalOpen={isFinancingModalOpen}
                    handleOnRequestClose={this.handleOpenCloseFinancingModal}
                    customStyle="modal-dialog modal-dialog-centered modal-md"
                >
                    <div className="modal-content multi-step">
                        <div className="modal-body multi-step">
                            <div className="text-center">
                                <p>Amortization is needed to calculate the monthly mortgage payment. This number is typically between twenty (240 months) and thirty years (360 months)</p>
                                <button className="btn btn-primary multi-step-close" onClick={this.handleOpenCloseFinancingModal}>Close</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    isModalOpen={isFinancingTwoModalOpen}
                    handleOnRequestClose={this.handleOpenCloseFinancingTwoModal}
                    customStyle="modal-dialog modal-dialog-centered modal-md"
                >
                    <div className="modal-content multi-step">
                        <div className="modal-body multi-step">
                            <div className="text-center">
                                <p>Some lenders may want to see that investors have a certain number of mortgage payments held in savings (typically up to three month's worth)</p>
                                <button className="btn btn-primary multi-step-close" onClick={this.handleOpenCloseFinancingTwoModal}>Close</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    isModalOpen={isClosingCostsModalOpen}
                    handleOnRequestClose={this.handleOpenCloseClosingCostsModal}
                    customStyle="modal-dialog modal-dialog-centered modal-md"
                >
                    <div className="modal-content multi-step">
                        <div className="modal-body multi-step">
                            <div className="text-center">
                                <p>These are standard estimates that can be edited as needed (e.g earnest money deposit, real estate transfer tax, and origination fee are listed as a percentage of the purchase price; property management fee is listed as a percentage of gross rental income)</p>
                                <button className="btn btn-primary multi-step-close" onClick={this.handleOpenCloseClosingCostsModal}>Close</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    isModalOpen={isOperatingExpensesModalOpen}
                    handleOnRequestClose={this.handleOpenCloseOperatingExpensesModal}
                    customStyle="modal-dialog modal-dialog-centered modal-md"
                >
                    <div className="modal-content multi-step">
                        <div className="modal-body multi-step">
                            <div className="text-center">
                                <p className="text-left">Unsure what these amounts should be? Check out these links below for some tips!</p>
                                <div className="row">
                                    <div className="col-md-5 text-left">
                                        <ul className="operating-expenses-modal-ul">
                                            <li><a href="https://wallethub.com/edu/t/states-with-the-highest-and-lowest-property-taxes/11585/" target="_blank">Property taxes </a></li>
                                            <li><a href="https://www.valuepenguin.com/average-cost-of-homeowners-insurance" target="_blank">Property insurance</a></li>
                                            <li><a href="https://www.homeadvisor.com/cost/environmental-safety/hire-an-insect-control-service/" target="_blank">Pest control</a></li>
                                        </ul>
                                    </div>
                                    <div className="col-md-7 text-left">
                                        <ul className="operating-expenses-modal-ul">
                                            <li><a href="https://www.fixr.com/costs/lawn-maintenance" target="_blank">Landscaping</a></li>
                                            <li><a href="https://www.homeadvisor.com/cost/cleaning-services/remove-waste/" target="_blank">Trash removal</a></li>
                                            <li><a href="https://howmuch.net/articles/cost-of-utilities-in-every-state" target="_blank">Utilities/Water (can be paid by tenants)</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <button className="btn btn-primary multi-step-close" onClick={this.handleOpenCloseOperatingExpensesModal}>Close</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    isModalOpen={isAnnualProfitModalOpen}
                    handleOnRequestClose={this.handleOpenCloseAnnualProfitModal}
                    customStyle="modal-dialog modal-dialog-centered modal-md"
                >
                    <div className="modal-content multi-step">
                        <div className="modal-body multi-step">
                            <div className="text-center">
                                <p>Cap rate is equal to net operating income (NOI) divided by the purchase price of the property</p>
                                <button className="btn btn-primary multi-step-close" onClick={this.handleOpenCloseAnnualProfitModal}>Close</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    isModalOpen={isMetricsModalOpen}
                    handleOnRequestClose={this.handleOpenCloseMetricsModal}
                    customStyle="modal-dialog modal-dialog-centered modal-md"
                >
                    <div className="modal-content multi-step">
                        <div className="modal-body multi-step">
                            <div className="text-center">
                                <p>1% Rule is met when your gross rental income is equal to at least 1% of your purchase price</p>
                                <p>Debt Service Coverage Ratio (DSCR) = Net Operating Income (NOI) divided by your debt service (which is your mortgage payment including insurance and tax expenses)</p>
                                <button className="btn btn-primary multi-step-close" onClick={this.handleOpenCloseMetricsModal}>Close</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    isModalOpen={isBreakevenAnalysisModalOpen}
                    handleOnRequestClose={this.handleOpenCloseBreakevenAnalysisModal}
                    customStyle="modal-dialog modal-dialog-centered modal-md"
                >
                    <div className="modal-content multi-step">
                        <div className="modal-body multi-step">
                            <div className="text-center">
                                <p>The discount rate is required to calculate the net present value (NPV) of your investment. It is the rate of return an investor requires for the risk being taken.</p>
                                <button className="btn btn-primary multi-step-close" onClick={this.handleOpenCloseBreakevenAnalysisModal}>Close</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    isModalOpen={isBreakevenAnalysisTwoModalOpen}
                    handleOnRequestClose={this.handleOpenCloseBreakevenAnalysisTwoModal}
                    customStyle="modal-dialog modal-dialog-centered modal-md"
                >
                    <div className="modal-content multi-step">
                        <div className="modal-body multi-step">
                            <div className="text-center">
                                <p>The net present value (NPV) indicates to an investor whether a deal is worthwhile or not. It is the present value of future cash flows. It takes into account the initial investment, annual cash flow, and required rate of return.</p>
                                <button className="btn btn-primary multi-step-close" onClick={this.handleOpenCloseBreakevenAnalysisTwoModal}>Close</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    open_verify_modal: state.authUsers.open_verify_modal,
    close_verify_modal: state.authUsers.close_verify_modal,
    setUserPlanStatusData: state.payment.setUserPlanStatusData,
})
  
const mapDispatchToProps = (dispatch) => {
	const reduxActions = {
      ...AuthReduxActionsCreators,
      ...PatmentReduxActionsCreators
	};
	
  
	return { actions: bindActionCreators(reduxActions, dispatch)};
}

const connectedHomePage = connect(mapStateToProps, mapDispatchToProps)(Home);
export { connectedHomePage as Home };
