import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Routes } from 'App/routes/Routes';
import { bindActionCreators } from 'redux';
import AuthReduxActionsCreators from '../../reducers/auth.reducer';
import PatmentReduxActionsCreators from 'Reducers/payment.reducer';

import HeaderLogo from 'Assets/images/rentpotential-logo.png';
import HeaderLogoWhite from 'Assets/images/rentpotential-logo-white.png';
import loading from 'Assets/images/loading.gif';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleOnSignOut = () => {
        const { actions: { logoutUser } } = this.props;
        logoutUser();
    }

    render() {
        const localStorage12 = localStorage.getItem('logged_user_auth_token');
        const { reportFullPageLoading, stripeFullPageLoading, fullPageLoading, router, children, pageTitle, pageSubTitle, buttonText, arrowRightImg, pageHeaderImg, pageDescription } = this.props;
        const checkRouteIsActive = (currntRoute) => {
            if ((router && router.location && 'pathname' in router.location)
                && (router.location.pathname === currntRoute)) {
                return ' active'
            }
            return '';
        }

        return (
            <>
                <div className="header-set-bg-img">
                    <nav className="navbar navbar-expand-lg navbar-dark">
                        <div className="main-wrapper">
                            <div className="navbar-row">
                                <Link className="navbar-brand logo-blue" to={Routes.HOME_VIEW}><img width="126" src={HeaderLogo} alt="Rent Potential" /></Link>
                                <Link className="navbar-brand logo-white" to={Routes.HOME_VIEW}><img width="126" src={HeaderLogoWhite} alt="Rent Potential" /></Link>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="collapsibleNavbar">
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <Link className={`nav-link ${checkRouteIsActive(Routes.HOME_VIEW)}`} to={Routes.HOME_VIEW}>HOME</Link>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link go-to-sec" href="#how-it">How It Works</a>
                                        </li>
                                        <li className="nav-item">
                                            <Link className={`nav-link ${checkRouteIsActive(Routes.ABOUT_VIEW)}`} to={Routes.ABOUT_VIEW}>about us</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className={`nav-link ${checkRouteIsActive(Routes.COUNTACT_US_VIEW)}`} to={Routes.COUNTACT_US_VIEW}>contact us</Link>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                                                Membership Plans
                                            </a>
                                            <div className="dropdown-menu">
                                                <Link className={`dropdown-item ${checkRouteIsActive(Routes.PREMIUM_PLAN_VIEW)}`} to={Routes.PREMIUM_PLAN_VIEW}>PREMIUM PLAN</Link>
                                                <Link className={`dropdown-item ${checkRouteIsActive(Routes.STANDARD_PLAN_VIEW)}`} to={Routes.STANDARD_PLAN_VIEW}>standard PLAN</Link>
                                                <Link className={`dropdown-item ${checkRouteIsActive(Routes.ONE_AND_DONE_PLAN_VIEW)}`} to={Routes.ONE_AND_DONE_PLAN_VIEW}>ONE AND DONE plan</Link>
                                            </div>
                                        </li>
                                        {localStorage12 ? (
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                                                my account
                                            </a>
                                            <div className="dropdown-menu">
                                                <Link className={`dropdown-item ${checkRouteIsActive(Routes.PROFILE_VIEW)}`} to={Routes.PROFILE_VIEW}>PROFILE</Link>
                                                <Link className={`dropdown-item ${checkRouteIsActive(Routes.SAVE_REPORTS_VIEW)}`} to={Routes.SAVE_REPORTS_VIEW}>view Saved Reports</Link>
                                                <Link className={`dropdown-item ${checkRouteIsActive(Routes.PLANS_VIEW)}`} to={Routes.PLANS_VIEW}>Plans</Link>
                                            </div>
                                        </li>
                                        ) : (null)}
                                        {localStorage12 ? (
                                            <li>
                                                <div className="navbar-right-main">
                                                    <Link className="navbar-right-logout" onClick={this.handleOnSignOut}>Logout</Link>
                                                </div>
                                            </li>
                                        ) : (
                                            <li>
                                                <div className="navbar-right-main">
                                                    <Link className="navbar-right" to={Routes.LOGIN_VIEW}>Login</Link>|
                                                    <Link className="navbar-right" to={Routes.REGISTER_VIEW}>Register</Link>
                                                </div>
                                            </li>
                                        )}
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>

                    <div className="header-main">
                        <div className="main-wrapper">
                            <div className="banner">
                                <div className="header-main-content">
                                    <h3>{pageTitle}</h3>
                                    <h2>{pageSubTitle}</h2>
                                    <p>{pageDescription}</p>
                                    <p>{children}</p>
                                    <button className="btn btn-primary read-more-btn">{buttonText} <img src={arrowRightImg} /></button>
                                </div>
                                <div className="header-main-img">
                                    <img src={pageHeaderImg} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        router: state.router,
        fullPageLoading: state.authUsers.fullPageLoading,
        stripeFullPageLoading: state.payment.stripeFullPageLoading,
        reportFullPageLoading: state.reports.reportFullPageLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    const reduxActions = {
        ...AuthReduxActionsCreators,
        ...PatmentReduxActionsCreators
    };


    return { actions: bindActionCreators(reduxActions, dispatch) };
}

const connectedHeaderComponent = connect(mapStateToProps, mapDispatchToProps)(Header);
export { connectedHeaderComponent as Header };
