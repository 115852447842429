import { combineReducers } from 'redux';
import { resettableReducer } from 'reduxsauce'
import { connectRouter } from 'connected-react-router'

import { reducer as AuthReduxReducer } from './auth.reducer';
import { reducer as PaymentReduxReducer } from './payment.reducer';
import { reducer as LandingHomeReduxReducer } from './landingHome.reducer';
import { reducer as PlansReduxReducer } from './plans.reducer';
import { reducer as ReportsReduxReducer } from './reports.reducer';

const resettable = resettableReducer('LOGOUT_SUCCESS');

const reducers = (history) => combineReducers({
  payment: resettable(PaymentReduxReducer),
  authUsers: AuthReduxReducer,
  landingHome: LandingHomeReduxReducer,
  plans: PlansReduxReducer,
  reports: ReportsReduxReducer,

  // payment: resettable(PaymentReduxReducer),
  // authUsers: resettable(AuthReduxReducer),
  // landingHome: resettable(LandingHomeReduxReducer),
  // plans: resettable(PlansReduxReducer),
  // reports: resettable(ReportsReduxReducer),

  router: connectRouter(history),
});

export default reducers;
