import { delay, takeEvery, takeLatest, put, call } from 'redux-saga/effects';
import PlansReduxActionsCreators, { PlansTypes } from 'Reducers/plans.reducer';
import { getPremiumPlanQuery, getStandardPlanQuery, getOneAndDonePlanQuery } from '../services/plans.services';
import * as CommonFunc from 'Utils/commonFunction';

export default [
    getPremiumPlanRequest,
    getStandardPlanWatcher,
    getOneAndDonePlanWatcher,
]

const errorMsg = "a network error has occurred"

//function definition for get Premium Plan request.
function* getPremiumPlanRequest() {
    yield takeLatest(PlansTypes.GET_PREMIUM_PLAN_REQUEST, getPremiumPlan);
};

//function definition for get Standard Plan request.
function* getStandardPlanWatcher() {
    yield takeLatest(PlansTypes.GET_STANDARD_PLAN, getStandardPlanRequest);
};

//function definition for get Standard Plan request.
function* getOneAndDonePlanWatcher() {
    yield takeLatest(PlansTypes.GET_ONE_AND_DONE_PLAN, getOneAndDonePlanRequest);
};

// get Premium Plan
function* getPremiumPlan({ payload }) {
    yield put(PlansReduxActionsCreators.setFetching(true));
    yield put(PlansReduxActionsCreators.setFetchingErr(false));
    yield put(PlansReduxActionsCreators.setFetchingErrMsg(''));

    try {
        const response = yield call(getPremiumPlanQuery, payload);
        if(response && response.data){
            if(!response.data.success) {
                CommonFunc.showUnhandledErrors(response.data.message);
                yield put(PlansReduxActionsCreators.setFetchingErr(true));
            } 
            yield put(PlansReduxActionsCreators.getPremiumPlanSuccess(response.data));
            
        }
        if(response && response.error){
            return response.error;
        }
        yield put(PlansReduxActionsCreators.setFetching(false));
    } catch (errors) {
        yield put(PlansReduxActionsCreators.setFetching(false));
        yield put(PlansReduxActionsCreators.setFetchingErr(true));

        if(errors.response && errors.response.data){
            yield put(PlansReduxActionsCreators.setFetchingErrMsg(errors.response.data.message))
            yield put(PlansReduxActionsCreators.setFetchingErr(true));
            CommonFunc.showUnhandledErrors(errors.response.data.message);
        } else {
            CommonFunc.showUnhandledErrors(errorMsg);
            yield put(PlansReduxActionsCreators.setFetchingErrMsg(errorMsg))
        }
    } 
}


// Get Standard Plan Request Function
function* getStandardPlanRequest() {
    yield put(PlansReduxActionsCreators.setFetching(true));
    yield put(PlansReduxActionsCreators.setFetchingErr(false));
    yield put(PlansReduxActionsCreators.setFetchingErrMsg(''));

    try {
        const response = yield call(getStandardPlanQuery, null);

        if(response && response.data){
            if(!response.data.success){
                CommonFunc.showUnhandledErrors(response.data.message);
                yield put(PlansReduxActionsCreators.setFetchingErr(true));
            }
            yield put(PlansReduxActionsCreators.setStandardPlan(response.data.data));
        }

        if(response && response.error){
            return response.error;
        }
        yield put(PlansReduxActionsCreators.setFetching(false));
    } catch (errors) {
        console.log('errorserrorserrors', errors);
        yield put(PlansReduxActionsCreators.setFetchingErr(true));
        yield put(PlansReduxActionsCreators.setFetching(false));

        if(errors.response && errors.response.data){
            yield put(PlansReduxActionsCreators.setFetchingErrMsg(errors.response.data.message))
            CommonFunc.showUnhandledErrors(errors.response.data.message);
        } else {
            CommonFunc.showUnhandledErrors(errorMsg);
            yield put(PlansReduxActionsCreators.setFetchingErrMsg(errorMsg))
            yield put(PlansReduxActionsCreators.setFetchingErr(true));
        }
    }
}

// Get One And Done Plan Request Function
function* getOneAndDonePlanRequest() {
    yield put(PlansReduxActionsCreators.setFetching(true));
    yield put(PlansReduxActionsCreators.setFetchingErr(false));
    yield put(PlansReduxActionsCreators.setFetchingErrMsg(''));

    try {
        const response = yield call(getOneAndDonePlanQuery, null);

        if(response && response.data){
            if(!response.data.success){
                CommonFunc.showUnhandledErrors(response.data.message);
                yield put(PlansReduxActionsCreators.setFetchingErr(true));
            }
            yield put(PlansReduxActionsCreators.setOneAndDonePlan(response.data.data));
        }

        if(response && response.error){
            return response.error;
        }
        
        yield put(PlansReduxActionsCreators.setFetching(false));
    } catch (errors) {
        yield put(PlansReduxActionsCreators.setFetchingErr(true));
        yield put(PlansReduxActionsCreators.setFetching(false));

        if(errors.response && errors.response.data){
            yield put(PlansReduxActionsCreators.setFetchingErrMsg(errors.response.data.message))
            CommonFunc.showUnhandledErrors(errors.response.data.message);
        } else {
            CommonFunc.showUnhandledErrors(errorMsg);
            yield put(PlansReduxActionsCreators.setFetchingErrMsg(errorMsg))
            yield put(PlansReduxActionsCreators.setFetchingErr(true));
        }
    }
}

