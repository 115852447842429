// Needed for redux-saga es6 generator support
import '@babel/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './app';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const MOUNT_NODE = document.getElementById('root');
ReactDOM.render(<App />, MOUNT_NODE);

if (module.hot) {
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept([require('App')], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render();
  });
}

Sentry.init({
  dsn: "https://f0e3f28d9e2c48e09292ff98c9dd774c@o481321.ingest.sentry.io/5529725",
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(<App />, document.getElementById("root"));

// Can also use with React Concurrent Mode
// ReactDOM.createRoot(document.getElementById('root')).render(<App />);
