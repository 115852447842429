import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Route, Switch, Redirect } from "react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Home } from "Scenes/Home";
import { About } from "Scenes/About";
import { TermsAndConditions } from "Scenes/TermsAndConditions";
import { ContactUs } from "Scenes/ContactUs";
import { PremiumPlan } from "Scenes/PremiumPlan";
import { OneAndDonePlan } from "Scenes/OneAndDonePlan";
import { StandardPlan } from "Scenes/StandardPlan";
import { Login } from "Scenes/Login";
import { Register } from "Scenes/Register";
import { Profile } from "Scenes/Profile";
import { ForgotPassword } from "Scenes/ForgotPassword";
import { ResetPassword } from "Scenes/ResetPassword";
// import { PotentialReport } from 'Scenes/PotentialReport';
import { Plans } from 'Scenes/Plans';
import { Report } from 'Scenes/Report';
import { ReportResult } from 'Scenes/Report/ReportResult';
import { EditReport } from 'Scenes/Report/EditReport';
import { EditReportResult } from 'Scenes/Report/EditReportResult';

import { SavedReports } from "Scenes/SavedReports";
// import { PassReport } from 'Scenes/PassReport';

import { Routes } from "./Routes";
import { PrivateRoute, UnprivateRoute } from "Components/PrivateRoute";

import AuthReduxActionsCreators from "../reducers/auth.reducer";

import LoaderIMG from "Assets/images/loading.gif";

class Root extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // console.log(this.props);
    const { router } = this.props;
    const headersNotForRoutes = [];
    const isLocalStorage = localStorage.getItem("logged_user_auth_token");
    return (
      <div className="">
        <main>
          {this.props.fetching ? (
            <div className="page-loading-main">
              <div className="page-loading">
                <img className="page-loading" src={LoaderIMG} />
              </div>
            </div>
          ) : undefined}
          <ToastContainer />
          {/* {router && router.location && 'pathname' in router.location && headersNotForRoutes.includes(router.location.pathname) ? null : (<Header></Header>)} */}
          <Switch>
            <Redirect path="/how-it" to={{ pathname: `${Routes.HOME_VIEW}`,hash:'#how-it'}} />
            <Route exact path={Routes.HOME_VIEW} component={Home} />
            <Route exact path={Routes.ABOUT_VIEW} component={About} />
            <Route
              exact
              path={Routes.TERMS_AND_CONDITIONS_VIEW}
              component={TermsAndConditions}
            />
            <Route exact path={Routes.COUNTACT_US_VIEW} component={ContactUs} />
            <Route
              exact
              path={Routes.PREMIUM_PLAN_VIEW}
              component={PremiumPlan}
            />
            <Route
              exact
              path={Routes.ONE_AND_DONE_PLAN_VIEW}
              component={OneAndDonePlan}
            />
            <Route
              exact
              path={Routes.STANDARD_PLAN_VIEW}
              component={StandardPlan}
            />
            <PrivateRoute exact path={Routes.LOGIN_VIEW} component={Login} />
            <PrivateRoute
              exact
              path={Routes.RESET_PASSWORD_VIEW}
              component={ResetPassword}
            />
            <Route exact path={Routes.REGISTER_VIEW} component={Register} />
            <UnprivateRoute exact path={Routes.REPORT_RESULT_VIEW} component={ReportResult} />
            <UnprivateRoute
              exact
              path={Routes.PROFILE_VIEW}
              component={Profile}
            />
            <PrivateRoute
              exact
              path={Routes.FORGOT_PASSWORD_VIEW}
              component={ForgotPassword}
            />
            <UnprivateRoute
              exact
              path={Routes.REPORT_VIEW}
              component={Report}
            />
            <UnprivateRoute
              exact
              path={Routes.EDIT_REPORT_VIEW}
              component={EditReport}
            />
            <UnprivateRoute
              exact
              path={Routes.EDIT_REPORT_RESULT_VIEW}
              component={EditReportResult}
            />
            {/* <UnprivateRoute exact path={Routes.POTENTIAL_REPORT_VIEW} component={PotentialReport} /> */}
            <UnprivateRoute
              exact
              path={Routes.SAVE_REPORTS_VIEW}
              component={SavedReports}
            />
            {/* <UnprivateRoute exact path={Routes.PASS_REPORT_VIEW} component={PassReport} /> */}
            <UnprivateRoute exact path={Routes.PLANS_VIEW} component={Plans} />
          </Switch>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    router: state.router,
    fetching: state.authUsers.fetching,
    fetchingErr: state.authUsers.fetchingErr,
    fetchingErrMsg: state.authUsers.fetchingErrMsg,
  };
};

const mapDispatchToProps = (dispatch) => {
  const reduxActions = {
    ...AuthReduxActionsCreators,
  };

  return { actions: bindActionCreators(reduxActions, dispatch) };
};

const connectedRoot = connect(mapStateToProps, mapDispatchToProps)(Root);
export { connectedRoot as Root };
