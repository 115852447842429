// Loading/Fetching status
const setFetching = (state, { status }) => state.merge({ fetching: status });

// Error status set to TRUE/FALSE while ENDPOINT fetching issue.
const setFetchingErr = (state, { error }) =>
  state.merge({ fetchingErr: error });

// Error message set while ENDPOINT fetching issue.
const setFetchingErrMsg = (state, { errorMsg }) =>
  state.merge({ fetchingErrMsg: errorMsg });

// Set Users Reports.
const setUsersReports = (state, { reports }) =>
  state.merge({ usersReports: reports || [] });

// Set User Report By ID
const setUserReportById = (state, { report }) =>
  state.merge({ userReportById: report || {} });

const setReportFullPageLoading = (state, { status }) =>
  state.merge({ reportFullPageLoading: status });

const reportsReducerActions = {
  setFetching,
  setFetchingErr,
  setFetchingErrMsg,
  setUsersReports,
  setUserReportById,
  setReportFullPageLoading,
};

export { reportsReducerActions as reduxReportsActions };
