import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AuthReduxActionsCreators from '../../reducers/auth.reducer';
import * as CommonFunc from 'Utils/commonFunction';

import { Helmet } from 'Components/Helmet';
import { Link } from 'react-router-dom';
import { Routes } from 'App/routes/Routes';
import { InputText } from 'Components/InputText';

import { validateFormFields } from 'Utils/commonFunction';

import registerImg from 'Assets/images/register-img.png';
import Logo from 'Assets/images/rentpotential-logo.png';
import CloseIcon from 'Assets/images/close.png';
import loading from 'Assets/images/loading.gif';

const INITIAL_STATE = {
	name: null,
	email: null,
	password: null,
	confirmPassword: null,

	errors: {
		name: '',
		email: '',
		password: '',
		confirmPassword: '',
	},
}

class Register extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...INITIAL_STATE,
		};
	}

	handleOnErrors = (fieldName, fieldValue) => {
		this.setState(prevState => ({
			errors: {
				...prevState.errors,
				[fieldName]: fieldValue
			}
		}));
	}

	handleOnChangeInput = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	}

	handleOnChangeModules = (event) => {
		let isChecked = event.target.checked;
		console.log("isChecked ---", isChecked);
		// const { name, value } = event.target;
		// // let tracksValue = value || '';
		// console.log("event ---", value);
		// console.log("eventname ---", name);
		this.setState({ termsCond: isChecked })
	}

	registerFormValidate = () => {
		const { name, email, password, confirmPassword } = this.state;
		const nameIsValid = validateFormFields('name', name);
		const emailIsValid = validateFormFields('email', email);
		const passwordIsValid = validateFormFields('password', password);
		const confirmPasswordIsValid = validateFormFields('confirmPassword', confirmPassword);
		const stateOfErrors = 'errors';
		if (nameIsValid) {
			this.handleOnErrors('name', nameIsValid.message, stateOfErrors);
		}

		if (emailIsValid) {
			this.handleOnErrors('email', emailIsValid.message, stateOfErrors);
		}

		if (passwordIsValid) {
			this.handleOnErrors('password', passwordIsValid.message, stateOfErrors);
		}

		if (confirmPasswordIsValid) {
			this.handleOnErrors('confirmPassword', confirmPasswordIsValid.message, stateOfErrors);
		}

		return nameIsValid.status && emailIsValid.status && passwordIsValid.status && confirmPasswordIsValid.status
	}

	handleOnRegisterSubmit = () => {
		const isRegisterFormFieldsValid = this.registerFormValidate();
		if (!isRegisterFormFieldsValid) {
			// console.log('You may enter wrong value, please try again')
		} else {
			const { name, email, password, confirmPassword, termsCond } = this.state;
			// console.log(termsCond)
			if(password != confirmPassword) {
				this.setState({ 
				  errors: {
					confirmPassword: 'Confirm password must be same as password',
				  } 
				});
			} else {
				if (termsCond == true){
					const { actions: { registerUser } } = this.props;
					registerUser({ name, email, password, password_confirmation: confirmPassword });
				} else {
					CommonFunc.showUnhandledErrors('please check on terms and conditions')
				}
			}
		}
	}

	render() {
		const { errors, name, email, password, confirmPassword } = this.state;
		let { fetching } = this.props;
		// console.log("error---",errors)
		return (
			<>
				<Helmet title="Rent Potential" metaName="description" metaContent="Rent Potential" />
				<div className="main-content">
					<div className="login-register-main">
						<div className="login-register-form-section">
							<Link className="close-btn-left" to={Routes.HOME_VIEW}>
								<img src={CloseIcon} />
							</Link>
							<div className="login-register-logo show-after-responsive">
								<img src={Logo} />
							</div>
							<div className="login-register-form">
								<h2 className="login-register-title">Register</h2>
								<div className="form-group">
									<div className="row">
										<div className="col-md-12">
											<InputText singleLableText="Name" singleInputType="text" singleInputClass={`${errors.name && 'error-text-border'}`} singleInputPlaceholder="Enter Name" singleInputName="name" singleInputValue={name || ''} singleInputonChangeFunc={this.handleOnChangeInput} errorMsg={errors.name} />
										</div>
									</div>
								</div>
								<div className="form-group">
									<div className="row">
										<div className="col-md-12">
											<InputText singleLableText="Email Id" singleInputType="email" singleInputClass={`${errors.email && 'error-text-border'}`} singleInputPlaceholder="Enter Email" singleInputName="email" singleInputValue={email || ''} singleInputonChangeFunc={this.handleOnChangeInput} errorMsg={errors.email} />
										</div>
									</div>
								</div>
								<div className="form-group">
									<div className="row">
										<div className="col-md-12">
											<InputText singleLableText="Password" singleInputType="password" singleInputClass={`${errors.password && 'error-text-border'}`} singleInputPlaceholder="Password" singleInputName="password" singleInputValue={password || ''} singleInputonChangeFunc={this.handleOnChangeInput} errorMsg={errors.password} />
										</div>
									</div>
								</div>
								<div className="form-group">
									<div className="row">
										<div className="col-md-12">
											<InputText singleLableText="Confirm Password" singleInputType="password" singleInputClass={`${errors.confirmPassword && 'error-text-border'}`} singleInputPlaceholder="Confirm Password" singleInputName="confirmPassword" singleInputValue={confirmPassword || ''} singleInputonChangeFunc={this.handleOnChangeInput} errorMsg={errors.confirmPassword} />
										</div>
									</div>
								</div>

								<div className="form-group">
									<div className="row">
										<div className="col-md-12">
											<div className="custom-control custom-checkbox">
												<input type="checkbox" className="custom-control-input" id="RememberMe" name="termsCond" onChange={this.handleOnChangeModules} />
												<label className="custom-control-label font-color-gray" htmlFor="RememberMe">I agree to the <Link className="link-text-color text-underline" to={Routes.TERMS_AND_CONDITIONS_VIEW}>terms and conditions</Link></label>
											</div>
										</div>
									</div>
								</div>
								<div className="form-group">
									<div className="row">
										<div className="col-md-12 text-center">
											{(fetching) ?
												(
													<button className="btn btn-primary loading-btn" disabled>Loading <img className="btn-loading" src={loading} /></button>
												)
											: (
												<button className="btn btn-primary form-btn" onClick={this.handleOnRegisterSubmit}>REGISTER</button>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="background-gray login-register-bg-img-side">
							<div className="login-register-logo">
								<img src={Logo} />
							</div>
							<div className="login-register-img">
								<img src={registerImg} />
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	fetching: state.authUsers.fetching,
	fetchingErr: state.authUsers.fetchingErr,
	fetchingErrMsg: state.authUsers.fetchingErrMsg,
})
  
const mapDispatchToProps = (dispatch) => {
	const reduxActions = {
	  ...AuthReduxActionsCreators
	};
	
  
	return { actions: bindActionCreators(reduxActions, dispatch)};
}

const connectedRegisterPage = connect(mapStateToProps, mapDispatchToProps)(Register);
export { connectedRegisterPage as Register };
