//File for Authentication controller in the backend.
import axios from '../config/axios.config';
import API_ENDPOINT from '../constants/api.constants';
import { getItemInLocalStorage } from 'App/utils/commonFunction';

//Method of Authenticating the user.
function* loginUser (payload){
    try {
        const response = yield axios.post(`${API_ENDPOINT.loginUser}`, payload, true);
        return yield Promise.resolve(response);
    } catch (error) {
        return yield Promise.reject(error);
    }
}

//Method for logout
function* logout (){
    try {
        const headers = {
            'Authorization': `Bearer ${getItemInLocalStorage('logged_user_auth_token')}`
        }
        const response = yield axios.get(`${API_ENDPOINT.signout}`, { headers: headers }, true);
        return yield Promise.resolve(response);
     } catch (error) {
         return yield Promise.reject(error);
     }
}

//Method of forgot Password the user.
function* forgotPasswordUser (email){
    try {
        const response = yield axios.post(`${API_ENDPOINT.forgotPasswordUser}`, email, true);
        return yield Promise.resolve(response);
    } catch (error) {
        return yield Promise.reject(error);
    }
}

//Method of register the user.
function* registerUser (payload){
    try {
        const response = yield axios.post(`${API_ENDPOINT.registerUser}`, payload, true);
        return yield Promise.resolve(response);
    } catch (error) {
        return yield Promise.reject(error);
    }
}

//Method of change Password the user.
function* changePasswordUser (payload){
    try {
        const headers = {
            'Authorization': `Bearer ${getItemInLocalStorage('logged_user_auth_token')}`
        }
        const response = yield axios.post(`${API_ENDPOINT.changePassword}`, payload, { headers: headers }, true);
        return yield Promise.resolve(response);
    } catch (error) {
        return yield Promise.reject(error);
    }
}

//Method of reset Password the user.
function* resetPasswordUser (payload){
    try {
        const response = yield axios.post(`${API_ENDPOINT.resetPasswordUser}`, payload, true);
        return yield Promise.resolve(response);
    } catch (error) {
        return yield Promise.reject(error);
    }
}

//Method for get Profile
function* getProfileUser (payload){
    try {
        const headers = {
            'Authorization': `Bearer ${getItemInLocalStorage('logged_user_auth_token')}`
        }
        const response = yield axios.get(`${API_ENDPOINT.getUserProfile}`, { headers: headers }, true);
        return yield Promise.resolve(response);
     } catch (error) {
         return yield Promise.reject(error);
     }
}

//Method for verify Email
function* verifyEmailUser (payload){
    try {
        const headers = {
            'Authorization': `Bearer ${getItemInLocalStorage('logged_user_auth_token')}`
        }
        const response = yield axios.get(`${API_ENDPOINT.verifyUserEmail}?email_verify=${payload.verifyEmail}&token=${payload.verifyToken}`, { headers: headers }, true);
        return yield Promise.resolve(response);
     } catch (error) {
         return yield Promise.reject(error);
     }
}

//Method for resend Verify Email
function* resendVerifyEmailUser (userId){
    try {
        const headers = {
            'Authorization': `Bearer ${getItemInLocalStorage('logged_user_auth_token')}`
        }
        const response = yield axios.get(`${API_ENDPOINT.resendVerifyEmail}/${userId.userId}`, { headers: headers }, true);
        return yield Promise.resolve(response);
     } catch (error) {
         return yield Promise.reject(error);
     }
}

//Method for update Profile User
function* updateProfileUser (payload){
    try {
        const headers = {
            'Authorization': `Bearer ${getItemInLocalStorage('logged_user_auth_token')}`
        }
        const response = yield axios.post(`${API_ENDPOINT.updateProfile}`, payload, { headers: headers }, true);
        return yield Promise.resolve(response);
    } catch (error) {
        return yield Promise.reject(error);
    }
}

//Method of delete User Data.
function* deleteUser (id){
    try {
        const headers = {
            'Authorization': `Bearer ${getItemInLocalStorage('logged_user_auth_token')}`
        }
        const response = yield axios.post(`${API_ENDPOINT.deleteUser}`, id, { headers: headers }, true);
        return yield Promise.resolve(response);
    } catch (error) {
        return yield Promise.reject(error);
    }
}

//Method for Contact Us
function* contactUs(payload){
    try {
        const response = yield axios.post(`${API_ENDPOINT.contactUs}`, payload, {}, true);
        return yield Promise.resolve(response);
    } catch (error) {
        return yield Promise.reject(error);
    }
}

//Exporting service methods.
export {
    loginUser,
    forgotPasswordUser,
    registerUser,
    changePasswordUser,
    logout,
    resetPasswordUser,
    getProfileUser,
    verifyEmailUser,
    resendVerifyEmailUser,
    updateProfileUser,
    deleteUser,
    contactUs
};
