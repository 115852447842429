export const validatePostalCode = (pincode) => {
  let number = /[0-9]/;
  if (!pincode) {
    return {
      message: 'Please enter your postal code',
      status: false
    };
  }
  if(!number.test(pincode)){
    return {
      message: 'Please make sure only digit',
      status: false
    };
  }
  return {
    message: '',
    status: true
  };
}

export const validateAddress = (address) => {
  if (!address) {
    return {
      message: 'Please enter your address',
      status: false
    };
  }
  return {
    message: '',
    status: true
  };
}

export const validateReportName = (report_name) => {
  if (!report_name) {
    return {
      message: 'Please enter your report name',
      status: false
    };
  }
  return {
    message: '',
    status: true
  };
}

export const validateInvestorsInvestmentPercentage = (investorsInvestmentPercentage) => {
  let number = /[0-9]/;
  if (!investorsInvestmentPercentage) {
    return {
      message: 'Please enter your investors',
      status: false
    };
  }
  if(!number.test(investorsInvestmentPercentage)){
    return {
      message: 'Please make sure only digit',
      status: false
    };
  }
  return {
    message: '',
    status: true
  };
}

export const validateRentalIncomeMonthly = (rentalIncomeMonthly) => {
  let number = /[0-9]/;
  if (!rentalIncomeMonthly) {
    return {
      message: 'Please enter your monthly rental',
      status: false
    };
  }
  if(!number.test(rentalIncomeMonthly)){
    return {
      message: 'Please make sure only digit',
      status: false
    };
  }
  return {
    message: '',
    status: true
  };
}

export const validateOtherRentalIncomeMonthly = (other_rental_income_monthly) => {
  let number = /[0-9]/;
  if (!other_rental_income_monthly) {
    return {
      message: 'Please enter your other monthly rental',
      status: false
    };
  }
  if(!number.test(other_rental_income_monthly)){
    return {
      message: 'Please make sure only digit',
      status: false
    };
  }
  return {
    message: '',
    status: true
  };
}

export const validatePurchasePrice = (purchange_price) => {
  let number = /[0-9]/;
  if (!purchange_price) {
    return {
      message: 'Please enter your purchase price',
      status: false
    };
  }
  if(!number.test(purchange_price)){
    return {
      message: 'Please make sure only digit',
      status: false
    };
  }
  return {
    message: '',
    status: true
  };
}

export const validateFinancingAmortizationTerm = (financing_amortization_term) => {
  let number = /[0-9]/;
  if (!financing_amortization_term) {
    return {
      message: 'Please enter your number of term',
      status: false
    };
  }
  if(!number.test(financing_amortization_term)){
    return {
      message: 'Please make sure only digit',
      status: false
    };
  }
  return {
    message: '',
    status: true
  };
}

export const validateFinancingInterestRate = (financing_interest_rate) => {
  let number = /[0-9]/;
  if (!financing_interest_rate) {
    return {
      message: 'Please enter your interest rate',
      status: false
    };
  }
  if(!number.test(financing_interest_rate)){
    return {
      message: 'Please make sure only digit',
      status: false
    };
  }
  if(financing_interest_rate > 20){
    return {
      message: 'Please enter a value less than or equal to 20',
      status: false
    };
  }
  return {
    message: '',
    status: true
  };
}

export const validateFinancingDownPayment = (financing_down_payment) => {
  let number = /[0-9]/;
  if (!financing_down_payment) {
    return {
      message: 'Please enter your down payment',
      status: false
    };
  }
  if(!number.test(financing_down_payment)){
    return {
      message: 'Please make sure only digit',
      status: false
    };
  }
  if(financing_down_payment > 99){
    return {
      message: 'Enter valid percentage of the down payment',
      status: false
    };
  }
  return {
    message: '',
    status: true
  };
}

export const validateRenovationCost = (renovation_cost) => {
  let number = /[0-9]/;
  if (!renovation_cost) {
    return {
      message: 'Please enter your renovation cost',
      status: false
    };
  }
  if(!number.test(renovation_cost)){
    return {
      message: 'Please make sure only digit',
      status: false
    };
  }
  return {
    message: '',
    status: true
  };
}

export const validateFinancingReserve = (financing_reserve) => {
  let number = /[0-9]/;
  if (!financing_reserve) {
    return {
      message: 'Please enter your bank reserve',
      status: false
    };
  }
  if(!number.test(financing_reserve)){
    return {
      message: 'Please make sure only digit',
      status: false
    };
  }
  return {
    message: '',
    status: true
  };
}

export const validatePropertyInspection = (property_inspection) => {
  let number = /[0-9]/;
  if (!property_inspection) {
    return {
      message: 'Please enter your property inspection fee',
      status: false
    };
  }
  if(!number.test(property_inspection)){
    return {
      message: 'Please make sure only digit',
      status: false
    };
  }
  return {
    message: '',
    status: true
  };
}

export const validateRealEstateTransferTax = (real_estate_transfer_tax) => {
  let number = /[0-9]/;
  if (!real_estate_transfer_tax) {
    return {
      message: 'Please enter your Real estate transfer tax',
      status: false
    };
  }
  if(!number.test(real_estate_transfer_tax)){
    return {
      message: 'Please make sure only digit',
      status: false
    };
  }
  return {
    message: '',
    status: true
  };
}

export const validateTitleInsuranceFee = (title_insurance_fee) => {
  let number = /[0-9]/;
  if (!title_insurance_fee) {
    return {
      message: 'Please enter your insurance fee title',
      status: false
    };
  }
  if(!number.test(title_insurance_fee)){
    return {
      message: 'Please make sure only digit',
      status: false
    };
  }
  return {
    message: '',
    status: true
  };
}

export const validateOriginationFee = (origination_fee) => {
  let number = /[0-9]/;
  if (!origination_fee) {
    return {
      message: 'Please enter your origination fee',
      status: false
    };
  }
  if(!number.test(origination_fee)){
    return {
      message: 'Please make sure only digit',
      status: false
    };
  }
  return {
    message: '',
    status: true
  };
}

export const validateAppraisalFees = (appraisal_fees) => {
  let number = /[0-9]/;
  if (!appraisal_fees) {
    return {
      message: 'Please enter your appraisal fee',
      status: false
    };
  }
  if(!number.test(appraisal_fees)){
    return {
      message: 'Please make sure only digit',
      status: false
    };
  }
  return {
    message: '',
    status: true
  };
}

export const validateWaterBillAnnually = (water_bill_annually) => {
	let number = /[0-9]/;
	if (!water_bill_annually) {
		return {
			message: 'Please enter your water bill annually',
			status: false
		};
	}
	if(!number.test(water_bill_annually)){
		return {
			message: 'Please make sure only digit',
			status: false
		};
	}
	return {
		message: '',
		status: true
	};
}

export const validateTaxesAnnually = (taxes_annually) => {
	let number = /[0-9]/;
	if (!taxes_annually) {
		return {
			message: 'Please enter your taxes annually',
			status: false
		};
	}
	if(!number.test(taxes_annually)){
		return {
			message: 'Please make sure only digit',
			status: false
		};
	}
	return {
		message: '',
		status: true
	};
}

export const validateUtilitiesAnnually = (utilities_annually) => {
	let number = /[0-9]/;
	if (!utilities_annually) {
		return {
			message: 'Please enter your utilities annually',
			status: false
		};
	}
	if(!number.test(utilities_annually)){
		return {
			message: 'Please make sure only digit',
			status: false
		};
	}
	return {
		message: '',
		status: true
	};
}

export const validateTrashPestLandscapingAnnually = (trash_pest_landscaping_annually) => {
	let number = /[0-9]/;
	if (!trash_pest_landscaping_annually) {
		return {
			message: 'This field is required',
			status: false
		};
	}
	if(!number.test(trash_pest_landscaping_annually)){
		return {
			message: 'Please make sure only digit',
			status: false
		};
	}
	return {
		message: '',
		status: true
	};
}

export const validateInsuranceAnnually = (insurance_annually) => {
	let number = /[0-9]/;
	if (!insurance_annually) {
		return {
			message: 'Please enter your insurance annually',
			status: false
		};
	}
	if(!number.test(insurance_annually)){
		return {
			message: 'Please make sure only digit',
			status: false
		};
	}
	return {
		message: '',
		status: true
	};
}

export const validateBreakevenDiscountRate = (breakeven_discount_rate) => {
	let number = /[0-9]/;
	if (!breakeven_discount_rate) {
		return {
			message: 'Please enter your discount rate',
			status: false
		};
	}
	if(!number.test(breakeven_discount_rate)){
		return {
			message: 'Please make sure only digit',
			status: false
		};
  }
  if(breakeven_discount_rate > 99){
    return {
      message: 'Please enter no more than 2 characters',
      status: false
    };
  }
	return {
		message: '',
		status: true
	};
}

export function validateFormFields(inputFieldName, inputFieldValue) {
  switch (inputFieldName) {
    case 'pincode':
      return validatePostalCode(inputFieldValue);

    case 'address':
      return validateAddress(inputFieldValue);

    case 'report_name':
      return validateReportName(inputFieldValue);

    case 'investorsInvestmentPercentage':
      return validateInvestorsInvestmentPercentage(inputFieldValue);

    case 'rentalIncomeMonthly':
      return validateRentalIncomeMonthly(inputFieldValue);

    case 'other_rental_income_monthly':
      return validateOtherRentalIncomeMonthly(inputFieldValue);

    case 'purchange_price':
      return validatePurchasePrice(inputFieldValue);

    case 'financing_amortization_term':
      return validateFinancingAmortizationTerm(inputFieldValue);

    case 'financing_interest_rate':
      return validateFinancingInterestRate(inputFieldValue);

    case 'financing_down_payment':
      return validateFinancingDownPayment(inputFieldValue);

    case 'renovation_cost':
      return validateRenovationCost(inputFieldValue);

    case 'financing_reserve':
      return validateFinancingReserve(inputFieldValue);

    case 'property_inspection':
      return validatePropertyInspection(inputFieldValue);

    case 'real_estate_transfer_tax':
      return validateRealEstateTransferTax(inputFieldValue);

    case 'title_insurance_fee':
      return validateTitleInsuranceFee(inputFieldValue);

    case 'origination_fee':
      return validateOriginationFee(inputFieldValue);

    case 'appraisal_fees':
      return validateAppraisalFees(inputFieldValue);

    case 'water_bill_annually':
      return validateWaterBillAnnually(inputFieldValue);

    case 'taxes_annually':
      return validateTaxesAnnually(inputFieldValue);

    case 'utilities_annually':
      return validateUtilitiesAnnually(inputFieldValue);

    case 'trash_pest_landscaping_annually':
      return validateTrashPestLandscapingAnnually(inputFieldValue);

    case 'insurance_annually':
      return validateInsuranceAnnually(inputFieldValue);

    case 'breakeven_discount_rate':
      return validateBreakevenDiscountRate(inputFieldValue);

    default:
      return {
        message: '',
        status: false
      };
  }
}