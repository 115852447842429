import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Helmet } from "Components/Helmet";
import { Header } from "Components/Header";
import { Footer } from "Components/Footer";
import { GoogleMap } from "../GoogleMap/GoogleMap";
import ReactEcharts from "echarts-for-react";
import echarts from "echarts";
import { bind } from "size-sensor";

import { Tabs, Tab } from "react-bootstrap";

import calculator from "Assets/images/calculator.png";
import arrowRight from "Assets/images/arrow-right.png";
import penIcon from "Assets/images/pen-icon.png";
import printIcon from "Assets/images/print-icon.png";
import homeIcon from "Assets/images/info-home.svg";

import ReportsReduxActionsCreators from "Reducers/reports.reducer";
import PatmentReduxActionsCreators from "Reducers/payment.reducer";

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }
class ReportResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userReportData: [{}],
      userApiData: [{}],
      downPayment: 0,
      chartRenovationCost: 0,
      totalDownPayment: 0,
      tinvestment: 0,
      resPurchangePrice: 0,
      chartRentalIncome: 0,
      chartOperatingExpenses: 0,
      chartPropertyManagementReserve: 0,
      chartNoi: 0,
      chartWaterbill: 0,
      chartTaxes: 0,
      chartUtilities: 0,
      chartPestcontrol: 0,
      chartInsurance: 0,
      chartManagefee: 0,
      chartRentalIncomeMonthly: [],
      chartRentalIncomeMonthlyName: [],
      chartEstimatedName: [],
      chartEstimatedNameValue: [],
      chartEstimatedValue: [],
    };

    this.chartInstance = null;
    this.dom = null;
  }

  getLineChartOption = () => {
    return {
      grid: {
        left: "20%",
      },

      tooltip: {
        trigger: "axis",
        formatter: function (params) {
          params = params[0];
          return (
            '<div class="line-chart-main"><span class="chart-label">' +
            params.name +
            '</span><br/><span class="chart-value">$' +
            params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
            '</span><div class="bottom-arrow"></div></div>'
          );
        },
        axisPointer: {
          type: "none",
        },
        backgroundColor: "transparent",
      },
      xAxis: {
        type: "category",
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        // data: ['Jan 2016', 'Jan 2017', 'Jan 2018', 'Jan 2019', 'Jan 2020']
        data: this.state.chartEstimatedName,
      },
      yAxis: {
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        type: "value",
      },
      series: [
        {
          data: this.state.chartEstimatedValue,
          type: "line",
          symbolSize: 15,
          color: "#3498DB",
          lineStyle: {
            width: 2,
            color: "#3498DB",
            shadowColor: "#000",
            shadowBlur: 10,
            shadowOffsetY: 15,
          },
        },
      ],
    };
  };

  getEchartsInstance() {
    const echartInstance =
      echarts.getInstanceByDom(this.dom) || echarts.init(this.dom);
    return echartInstance;
  }

  rerenderCharts() {
    this.chartInstance = this.getEchartsInstance();
    this.chartInstance.setOption(this.getLineChartOption());
    if (this.dom) {
      bind(this.dom, () => {
        this.chartInstance.resize();
      });
    }
  }

  componentDidMount() {
    if (
      "match" in this.props &&
      this.props.match &&
      "params" in this.props.match &&
      this.props.match.params
    ) {
      const {
        params: { id },
      } = this.props.match;
      const {
        actions: { getUserReportById, userPlanStatus },
      } = this.props;
      getUserReportById(id);
    }

    this.rerenderCharts();
  }

  componentDidUpdate(prevProps, preState) {
    if (
      prevProps.userReportById !== this.props.userReportById &&
      this.props.userReportById
    ) {
      const { userReportById } = this.props;
      if (userReportById.data && "down_payment_number" in userReportById.data) {
        if (!userReportById.data.down_payment_number) {
          var downPaymentValue1 = userReportById.data.down_payment_number;
        } else {
          var downPaymentValue1 = userReportById.data.down_payment_number
            .toString()
            .replace(/,/g, "");
        }
      }
      let downPaymentValue = Math.abs(
        parseFloat(downPaymentValue1) -
          (userReportById.data &&
            "earnest_money_deposit" in userReportById.data &&
            userReportById.data.earnest_money_deposit
              .toString()
              .replace(/,/g, ""))
      );

      let totalDownPaymentValue =
        parseFloat(
          userReportById.data &&
            "financing_total" in userReportById.data &&
            userReportById.data.financing_total
        ) -
        (userReportById.data &&
          "earnest_money_deposit" in userReportById.data &&
          userReportById.data.earnest_money_deposit
            .toString()
            .replace(/,/g, ""));

      let chartRenovationCostValue =
        userReportById.data &&
        "renovation_cost" in userReportById.data &&
        userReportById.data.renovation_cost
          ? userReportById.data.renovation_cost.toString().replace(/,/g, "")
          : 0;

      let earnestMoneyDeposit =
        userReportById.data &&
        "earnest_money_deposit" in userReportById.data &&
        userReportById.data.earnest_money_deposit
          ? userReportById.data.earnest_money_deposit
              .toString()
              .replace(/,/g, "")
          : 0;

      let breakevenInitialInvestment =
        userReportById.data &&
        "breakeven_initial_investment" in userReportById.data &&
        userReportById.data.breakeven_initial_investment
          ? userReportById.data.breakeven_initial_investment
              .toString()
              .replace(/,/g, "")
          : 0;

      let tinvestmentValue =
        parseFloat(earnestMoneyDeposit) +
        parseFloat(breakevenInitialInvestment);

      let purchangePrice =
        userReportById.data &&
        "purchange_price" in userReportById.data &&
        userReportById.data.purchange_price
          ? userReportById.data.purchange_price.toString().replace(/,/g, "")
          : 0;

      let resPurchangePriceValue = parseFloat(purchangePrice) / 27.5;

      let chartRentalIncomeValue =
        userReportById.data &&
        "profit_gross_rental_income" in userReportById.data &&
        userReportById.data.profit_gross_rental_income
          ? userReportById.data.profit_gross_rental_income
              .toString()
              .replace(/,/g, "")
          : 0;

      let chartOperatingExpensesValue =
        userReportById.data &&
        "profit_operating_expenses" in userReportById.data &&
        userReportById.data.profit_operating_expenses
          ? userReportById.data.profit_operating_expenses
              .toString()
              .replace(/,/g, "")
          : 0;

      let chartPropertyManagementReserveValue =
        userReportById.data &&
        "profit_pm_reserve" in userReportById.data &&
        userReportById.data.profit_pm_reserve
          ? userReportById.data.profit_pm_reserve.toString().replace(/,/g, "")
          : 0;

      let chartNoiValue =
        userReportById.data &&
        "profit_noi" in userReportById.data &&
        userReportById.data.profit_noi
          ? userReportById.data.profit_noi.toString().replace(/,/g, "")
          : 0;

      let chartWaterbillValue =
        ((userReportById.data &&
        "water_bill_monthly" in userReportById.data &&
        userReportById.data.water_bill_monthly
          ? parseFloat(userReportById.data.water_bill_monthly)
          : 0) /
          (userReportById.data &&
          "expences_monthly_total" in userReportById.data &&
          userReportById.data.expences_monthly_total
            ? parseFloat(userReportById.data.expences_monthly_total)
            : 0)) *
        100;

      let chartTaxesValue =
        ((userReportById.data &&
        "taxes_monthly" in userReportById.data &&
        userReportById.data.taxes_monthly
          ? parseFloat(userReportById.data.taxes_monthly)
          : 0) /
          (userReportById.data &&
          "expences_monthly_total" in userReportById.data &&
          userReportById.data.expences_monthly_total
            ? parseFloat(userReportById.data.expences_monthly_total)
            : 0)) *
        100;

      let chartUtilitiesValue =
        ((userReportById.data &&
        "utilities_monthly" in userReportById.data &&
        userReportById.data.utilities_monthly
          ? parseFloat(userReportById.data.utilities_monthly)
          : 0) /
          (userReportById.data &&
          "expences_monthly_total" in userReportById.data &&
          userReportById.data.expences_monthly_total
            ? parseFloat(userReportById.data.expences_monthly_total)
            : 0)) *
        100;

      let chartPestcontrolValue =
        ((userReportById.data &&
        "trash_pest_landscaping_monthly" in userReportById.data &&
        userReportById.data.trash_pest_landscaping_monthly
          ? parseFloat(userReportById.data.trash_pest_landscaping_monthly)
          : 0) /
          (userReportById.data &&
          "expences_monthly_total" in userReportById.data &&
          userReportById.data.expences_monthly_total
            ? parseFloat(userReportById.data.expences_monthly_total)
            : 0)) *
        100;

      let chartInsuranceValue =
        ((userReportById.data &&
        "insurance_monthly" in userReportById.data &&
        userReportById.data.insurance_monthly
          ? parseFloat(userReportById.data.insurance_monthly)
          : 0) /
          (userReportById.data &&
          "expences_monthly_total" in userReportById.data &&
          userReportById.data.expences_monthly_total
            ? parseFloat(userReportById.data.expences_monthly_total)
            : 0)) *
        100;

      let chartManagefeeValue =
        ((userReportById.data &&
        "prop_manage_fee_monthly" in userReportById.data &&
        userReportById.data.prop_manage_fee_monthly
          ? parseFloat(userReportById.data.prop_manage_fee_monthly)
          : 0) /
          (userReportById.data &&
          "expences_monthly_total" in userReportById.data &&
          userReportById.data.expences_monthly_total
            ? parseFloat(userReportById.data.expences_monthly_total)
            : 0)) *
        100;

      let totalRentalIncome =
        ((userReportById.data &&
        "other_rental_income_monthly" in userReportById.data &&
        userReportById.data.other_rental_income_monthly
          ? parseFloat(
              userReportById.data.other_rental_income_monthly
                .toString()
                .replace(/,/g, "")
            )
          : 0) /
          (userReportById.data &&
          "monthly_rental_total" in userReportById.data &&
          userReportById.data.monthly_rental_total
            ? parseFloat(
                userReportById.data.monthly_rental_total
                  .toString()
                  .replace(/,/g, "")
              )
            : 0)) *
        100;

      var breakevenNpValue1 =
        userReportById.data &&
        "breakeven_np_value" in userReportById.data &&
        userReportById.data.breakeven_np_value
          ? userReportById.data.breakeven_np_value.toString().replace(/,/g, "")
          : 0;

      let unit1income = [];
      let nkey = [];

      if (
        userReportById.data &&
        "rental_income_monthly" in userReportById.data &&
        userReportById.data.rental_income_monthly.length > 0
      ) {
        userReportById.data.rental_income_monthly.map(
          (rentalIncomeMonthlyMap, keyIndx) => {
            let rtNameValue = rentalIncomeMonthlyMap
              .toString()
              .replace(/,/g, "");
            let rtName = parseFloat(rtNameValue)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            let rtName1Value =
              userReportById.data &&
              "other_rental_income_monthly" in userReportById.data &&
              userReportById.data.other_rental_income_monthly
                ? userReportById.data.other_rental_income_monthly
                    .toString()
                    .replace(/,/g, "")
                : 0;
            let rtName1 = parseFloat(rtName1Value)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            nkey[keyIndx] = `Unit ${keyIndx + 1}\n$${rtName}`;
            nkey.push(`Garage Rent\n$${rtName1}`);
            let rt =
              (rentalIncomeMonthlyMap.toString().replace(/,/g, "") /
                (userReportById.data &&
                "monthly_rental_total" in userReportById.data &&
                userReportById.data.monthly_rental_total
                  ? parseFloat(userReportById.data.monthly_rental_total)
                  : 0)) *
              100;
            unit1income[keyIndx] = parseFloat(rt).toFixed(2);
            unit1income.push(parseFloat(totalRentalIncome).toFixed(2));
          }
        );
      }

      if (userReportById.api_data) {
        var setLatitude = userReportById.api_data.latitude;
        var setLongitude = userReportById.api_data.longitude;
        var estimatedName = [];
        var estimatedNameValue = [];
        var estimatedValue = [];

        if (
          "last_years_zest_his" in userReportById.api_data &&
          userReportById.api_data.last_years_zest_his.length > 0
        ) {
          userReportById.api_data.last_years_zest_his.map(
            (lastYearsZestHisMap, keyIndx) => {
              estimatedName[keyIndx] = lastYearsZestHisMap.label;
              estimatedNameValue[keyIndx] = lastYearsZestHisMap.y
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              estimatedValue[keyIndx] = lastYearsZestHisMap.y;
            }
          );
        }
      } else {
        var estimatedName = [];
        var estimatedNameValue = [];
        var estimatedValue = [];
        var setLatitude = 0;
        var setLongitude = 0;
      }

      this.setState({
        userReportData: userReportById.data,
        userApiData: userReportById.api_data,
        breakevenNpValue: Math.round(parseFloat(breakevenNpValue1))
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        latitude: setLatitude,
        longitude: setLongitude,
        downPayment:
          parseFloat(downPaymentValue)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0.0,
        totalDownPayment:
          parseFloat(totalDownPaymentValue)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0.0,
        tinvestment:
          parseFloat(tinvestmentValue)
            .toFixed(0)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0.0,
        resPurchangePrice:
          parseFloat(resPurchangePriceValue)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0.0,
        chartRenovationCost: chartRenovationCostValue,
        chartRentalIncome: chartRentalIncomeValue,
        chartOperatingExpenses: chartOperatingExpensesValue,
        chartPropertyManagementReserve: chartPropertyManagementReserveValue,
        chartNoi: chartNoiValue,

        chartWaterbill: parseFloat(chartWaterbillValue).toFixed(2),
        chartTaxes: parseFloat(chartTaxesValue).toFixed(2),
        chartUtilities: parseFloat(chartUtilitiesValue).toFixed(2),
        chartPestcontrol: parseFloat(chartPestcontrolValue).toFixed(2),
        chartInsurance: parseFloat(chartInsuranceValue).toFixed(2),
        chartManagefee: parseFloat(chartManagefeeValue).toFixed(2),

        chartRentalIncomeMonthly: unit1income,
        chartRentalIncomeMonthlyName: nkey,

        chartEstimatedName: estimatedName,
        chartEstimatedNameValue: estimatedNameValue,
        chartEstimatedValue: estimatedValue,
      });
    }

    if (
      (preState.chartEstimatedName !== this.state.chartEstimatedName &&
        this.state.chartEstimatedName.length > 0) ||
      (preState.chartEstimatedValue !== this.state.chartEstimatedValue &&
        this.state.chartEstimatedValue.length > 0)
    ) {
      this.rerenderCharts();
    }
  }

  componentWillUnmount() {
    if (this.dom) {
      try {
        clear(this.dom);
      } catch (e) {
        console.warn(e);
      }
    }
    this.chartInstance && this.chartInstance.dispose();
  }

  render() {
    const {
      userReportData,
      userApiData,
      downPayment,
      chartRenovationCost,
      totalDownPayment,
      tinvestment,
      resPurchangePrice,
      chartRentalIncome,
      chartOperatingExpenses,
      chartPropertyManagementReserve,
      chartNoi,
      chartWaterbill,
      chartTaxes,
      chartUtilities,
      chartPestcontrol,
      chartInsurance,
      chartManagefee,
      chartRentalIncomeMonthly,
      chartRentalIncomeMonthlyName,
      chartEstimatedName,
      chartEstimatedValue,
      latitude,
      longitude,
      breakevenNpValue,
    } = this.state;

    return (
      <>
        <Helmet
          title="Rent Potential"
          metaName="description"
          metaContent="Rent Potential"
        />
        <div className="main-content">
          <Header
            pageTitle="Real Estate Investment"
            pageSubTitle="CALCULATOR"
            buttonText="read More"
            arrowRightImg={arrowRight}
            pageHeaderImg={calculator}
          />

          <div className="container-fluid container-padding">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 report-tab-main">
                <Tabs defaultActiveKey="investment" id="controlled-tab-example">
                  <Tab eventKey="investment" title="Investment">
                    <div className="tab-bg-gray">
                      <div className="row">
                        <div className="col-md-8 offset-md-2">
                          {userReportData.pass_or_invest == "Potential" ? (
                            <h2 className="report-tab-title">
                              This property has potential
                            </h2>
                          ) : (
                            <h2 className="report-tab-title text-danger">
                              We would pass on this property! -1
                            </h2>
                          )}
                          <p className="report-tab-text">
                            {userReportData.metrics_one_persentage_rule ==
                            "Yes" ? (
                              <>
                                The <strong>1%</strong> rule is met for this
                                property!
                              </>
                            ) : (
                              <>
                                The <strong>1%</strong> rule is not met for this
                                property!
                              </>
                            )}{" "}
                            Your total initial investment will be{" "}
                            <strong>${tinvestment}</strong>. This deal has an
                            ROI of{" "}
                            <strong>
                              {userReportData.breakeven_return_on_investment}%
                            </strong>{" "}
                            {userReportData.financing_type == "loan" ? (
                              <>
                                , DSCR of{" "}
                                <strong>{userReportData.metrics_dscr}x</strong>
                              </>
                            ) : null}{" "}
                            , Operating Expense ratio of{" "}
                            <strong>
                              {userReportData.metrics_op_ex_ratio}%
                            </strong>{" "}
                            and will take{" "}
                            <strong>
                              {userReportData.breakeven_yearsto_breakeven}
                            </strong>{" "}
                            years to break even.{" "}
                            {userReportData.financing_type == "loan" ? (
                              <>
                                The property has a net present value of{" "}
                                <strong>${breakevenNpValue}.</strong>
                              </>
                            ) : null}{" "}
                            This property would provide tax savings via annual
                            depreciation of{" "}
                            <strong>${resPurchangePrice}</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="report-tab-chart-section">
                      <div className="gridRow m-t-20">
                        <div className=" m-t-10 gridClass">
                          <div className="report-tab-chart-sub">
                            <h3 className="report-tab-chart-title">
                              Rental Income
                            </h3>
                            <div className="table-responsive">
                              <table className="table report-tab-table">
                                <tr>
                                  <td className="td-width-44">
                                    Total Investors
                                  </td>
                                  <td className=""></td>
                                  <td className="font-color-gray">
                                    {userReportData.number_of_investors}
                                  </td>
                                </tr>
                                {userReportData.investors_investment_percentage &&
                                  userReportData.investors_investment_percentage
                                    .length > 0 &&
                                  userReportData.investors_investment_percentage.map(
                                    (investorsPercentage, keyIndx) => {
                                      return (
                                        <tr key={keyIndx}>
                                          <td className="td-width-44">
                                            % of investors money
                                          </td>
                                          <td className="font-color-gray">
                                            Investor {keyIndx + 1}
                                          </td>
                                          <td className="font-color-gray">
                                            {investorsPercentage}%
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                {userReportData.rental_income_monthly &&
                                  userReportData.rental_income_monthly.length >
                                    0 &&
                                  userReportData.rental_income_monthly.map(
                                    (rentalIncomeMonthly, keyIndx) => {
                                      return (
                                        <tr key={keyIndx}>
                                          <td className="td-width-44">
                                            Unit {keyIndx + 1}
                                          </td>
                                          <td className="font-color-gray">
                                            $
                                            {parseFloat(rentalIncomeMonthly)
                                              .toFixed(2)
                                              .toString()
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )}{" "}
                                            (Monthly)
                                          </td>
                                          <td className="font-color-gray">
                                            $
                                            {
                                              userReportData
                                                .rental_income_annually[keyIndx]
                                            }{" "}
                                            (Annually)
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                <tr>
                                  <td className="td-width-44">
                                    Rent for garage/parking
                                  </td>
                                  <td className="font-color-gray">
                                    $
                                    {parseFloat(
                                      userReportData.other_rental_income_monthly
                                    )
                                      .toFixed(2)
                                      .toString()
                                      .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}{" "}
                                    (Monthly)
                                  </td>
                                  <td className="font-color-gray">
                                    $
                                    {
                                      userReportData.other_rental_income_annually
                                    }{" "}
                                    (Annually)
                                  </td>
                                </tr>
                                <tr className="bg-white-tr">
                                  <td className="td-width-44">Total Income</td>
                                  <td className="">
                                    $
                                    {parseFloat(
                                      userReportData.monthly_rental_total
                                    )
                                      .toFixed(2)
                                      .toString()
                                      .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}{" "}
                                    (Monthly)
                                  </td>
                                  <td className="">
                                    $
                                    {parseFloat(
                                      userReportData.annually_rental_total
                                    )
                                      .toFixed(2)
                                      .toString()
                                      .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}{" "}
                                    (Annually)
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className=" m-t-10 gridClass">
                          <div className="report-tab-chart-sub">
                            <h3 className="report-tab-chart-title">
                              Rental Income
                            </h3>
                            <div className="operating-chart">
                              <ReactEcharts
                                className="echarts-margin"
                                option={{
                                  color: "#3AA6B4",
                                  xAxis: {
                                    type: "category",
                                    axisLine: {
                                      show: false,
                                    },
                                    axisTick: {
                                      show: false,
                                    },
                                    axisLabel: {
                                      formatter: "{value}",
                                    },
                                    // data: ['Unit 1\n$5,000.00', 'Unit 2\n$3,000.00', 'Garage Rent\n$3,000.00']
                                    data: chartRentalIncomeMonthlyName,
                                  },
                                  yAxis: {
                                    type: "value",
                                    axisLine: {
                                      show: false,
                                    },
                                    axisTick: {
                                      show: false,
                                    },
                                    axisLabel: {
                                      formatter: "{value}%",
                                    },
                                  },
                                  series: [
                                    {
                                      type: "bar",
                                      smooth: true,
                                      barWidth: "20%",
                                      // data: ['55', '20', '30']
                                      data: chartRentalIncomeMonthly,
                                    },
                                  ],
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="gridRow m-t-20">
                        <div className=" m-t-10 gridClass">
                          <div className="report-tab-chart-sub">
                            <h3 className="report-tab-chart-title">
                              Operating Expenses
                            </h3>
                            <div className="table-responsive">
                              <table className="table report-tab-table">
                                <tr>
                                  <td className="td-width-44">Water bill</td>
                                  <td className="font-color-gray">
                                    $
                                    {parseFloat(
                                      userReportData.water_bill_monthly
                                    )
                                      .toFixed(2)
                                      .toString()
                                      .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}{" "}
                                    (Monthly)
                                  </td>
                                  <td className="font-color-gray">
                                    $
                                    {parseFloat(
                                      userReportData.water_bill_annually
                                    )
                                      .toFixed(2)
                                      .toString()
                                      .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}{" "}
                                    (Annually)
                                  </td>
                                </tr>
                                <tr>
                                  <td className="td-width-44">Taxes</td>
                                  <td className="font-color-gray">
                                    $
                                    {parseFloat(userReportData.taxes_monthly)
                                      .toFixed(2)
                                      .toString()
                                      .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}{" "}
                                    (Monthly)
                                  </td>
                                  <td className="font-color-gray">
                                    $
                                    {parseFloat(userReportData.taxes_annually)
                                      .toFixed(2)
                                      .toString()
                                      .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}{" "}
                                    (Annually)
                                  </td>
                                </tr>
                                <tr>
                                  <td className="td-width-44">Utilities</td>
                                  <td className="font-color-gray">
                                    $
                                    {parseFloat(
                                      userReportData.utilities_monthly
                                    )
                                      .toFixed(2)
                                      .toString()
                                      .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}{" "}
                                    (Monthly)
                                  </td>
                                  <td className="font-color-gray">
                                    $
                                    {parseFloat(
                                      userReportData.utilities_annually
                                    )
                                      .toFixed(2)
                                      .toString()
                                      .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}{" "}
                                    (Annually)
                                  </td>
                                </tr>
                                <tr>
                                  <td className="td-width-44">
                                    Trash Removal/Pest Control/Landscaping
                                  </td>
                                  <td className="font-color-gray">
                                    $
                                    {parseFloat(
                                      userReportData.trash_pest_landscaping_monthly
                                    )
                                      .toFixed(2)
                                      .toString()
                                      .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}{" "}
                                    (Monthly)
                                  </td>
                                  <td className="font-color-gray">
                                    $
                                    {parseFloat(
                                      userReportData.trash_pest_landscaping_annually
                                    )
                                      .toFixed(2)
                                      .toString()
                                      .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}{" "}
                                    (Annually)
                                  </td>
                                </tr>
                                <tr>
                                  <td className="td-width-44">Insurance</td>
                                  <td className="font-color-gray">
                                    $
                                    {parseFloat(
                                      userReportData.insurance_monthly
                                    )
                                      .toFixed(2)
                                      .toString()
                                      .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}{" "}
                                    (Monthly)
                                  </td>
                                  <td className="font-color-gray">
                                    $
                                    {parseFloat(
                                      userReportData.insurance_annually
                                    )
                                      .toFixed(2)
                                      .toString()
                                      .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}{" "}
                                    (Annually)
                                  </td>
                                </tr>
                                <tr>
                                  <td className="td-width-44">
                                    Property management fee
                                  </td>
                                  <td className="font-color-gray">
                                    $
                                    {parseFloat(
                                      userReportData.prop_manage_fee_monthly
                                    )
                                      .toFixed(2)
                                      .toString()
                                      .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}{" "}
                                    (Monthly)
                                  </td>
                                  <td className="font-color-gray">
                                    $
                                    {parseFloat(
                                      userReportData.prop_manage_fee_annually
                                    )
                                      .toFixed(2)
                                      .toString()
                                      .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}{" "}
                                    (Annually)
                                  </td>
                                </tr>
                                <tr className="bg-white-tr">
                                  <td className="td-width-44">
                                    Total Expenses including Vacancy (5%) and
                                    CapEx (3%)
                                  </td>
                                  <td className="">
                                    $
                                    {parseFloat(
                                      userReportData.expences_monthly_total
                                    )
                                      .toFixed(2)
                                      .toString()
                                      .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}{" "}
                                    (Monthly)
                                  </td>
                                  <td className="">
                                    $
                                    {parseFloat(
                                      userReportData.expences_annually_total
                                    )
                                      .toFixed(2)
                                      .toString()
                                      .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}{" "}
                                    (Annually)
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className=" m-t-10 gridClass">
                          <div className="report-tab-chart-sub">
                            <h3 className="report-tab-chart-title">
                              Operating Expenses
                            </h3>
                            <div className="operating-chart">
                              <ReactEcharts
                                className="echarts-margin"
                                option={{
                                  responsive: true,
                                  center: ["20%", "50%"],
                                  series: [
                                    {
                                      type: "pie",
                                      radius: ["50%", "70%"],
                                      center: ["50%", "50%"],
                                      selectedMode: "single",
                                      data: [
                                        {
                                          value: chartManagefee,
                                          itemStyle: { color: "#1d7ff0" },
                                          label: {
                                            formatter: [
                                              "{title1|{c}%}",
                                              "{title2|Property management fee}",
                                              "{br|}",
                                            ].join("\n"),
                                            rich: {
                                              title1: {
                                                align: "left",
                                                fontSize: "14",
                                              },
                                              title2: {
                                                color: "#999999",
                                                align: "left",
                                                fontSize: "14",
                                              },
                                            },
                                          },
                                        },
                                        {
                                          value: chartWaterbill,
                                          itemStyle: { color: "#cd78f1" },
                                          label: {
                                            formatter: [
                                              "{title1|{c}%}",
                                              "{title2|Water bill}",
                                              "{br|}",
                                            ].join("\n"),
                                            rich: {
                                              title1: {
                                                align: "right",
                                                fontSize: "14",
                                              },
                                              title2: {
                                                color: "#999999",
                                                align: "right",
                                                fontSize: "14",
                                              },
                                            },
                                          },
                                        },
                                        {
                                          value: chartUtilities,
                                          itemStyle: { color: "#bde955" },
                                          label: {
                                            formatter: [
                                              "{title1|{c}%}",
                                              "{title2|Utilities}",
                                              "{br|}",
                                            ].join("\n"),
                                            rich: {
                                              title1: {
                                                align: "right",
                                                fontSize: "14",
                                              },
                                              title2: {
                                                color: "#999999",
                                                align: "right",
                                                fontSize: "14",
                                              },
                                            },
                                          },
                                        },
                                        {
                                          value: chartTaxes,
                                          itemStyle: { color: "#7be1b1" },
                                          label: {
                                            formatter: [
                                              "{title1|{c}%}",
                                              "{title2|Taxes}",
                                              "{br|}",
                                            ].join("\n"),
                                            rich: {
                                              title1: {
                                                align: "right",
                                                fontSize: "14",
                                              },
                                              title2: {
                                                color: "#999999",
                                                align: "right",
                                                fontSize: "14",
                                              },
                                            },
                                          },
                                        },
                                        {
                                          value: chartPestcontrol,
                                          itemStyle: { color: "#f0a808" },
                                          label: {
                                            formatter: [
                                              "{title1|{c}%}",
                                              "{title2|Trash Removal/Pest Control/}",
                                              "{title3|Landscaping}",
                                              "{br|}",
                                            ].join("\n"),
                                            rich: {
                                              title1: {
                                                align: "right",
                                                fontSize: "14",
                                              },
                                              title2: {
                                                color: "#999999",
                                                align: "right",
                                                fontSize: "14",
                                              },
                                              title3: {
                                                color: "#999999",
                                                align: "right",
                                                fontSize: "14",
                                              },
                                            },
                                          },
                                        },
                                        {
                                          value: chartInsurance,
                                          itemStyle: { color: "#fc5532" },
                                          label: {
                                            formatter: [
                                              "{title1|{c}%}",
                                              "{title2|Insurance}",
                                              "{br|}",
                                            ].join("\n"),
                                            rich: {
                                              title1: {
                                                align: "right",
                                                fontSize: "14",
                                              },
                                              title2: {
                                                color: "#999999",
                                                align: "right",
                                                fontSize: "14",
                                              },
                                            },
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="page-break"></div>
                      <div className=" gridRow m-t-20">
                        <div className=" m-t-10 gridClass">
                          <div className="report-tab-chart-sub">
                            <h3 className="report-tab-chart-title">
                              Annual Profit
                            </h3>
                            <div className="table-responsive">
                              <table className="table report-tab-table">
                                <tr>
                                  <td className="">Rental Income</td>
                                  <td className="font-color-gray">
                                    ${userReportData.profit_gross_rental_income}
                                  </td>
                                </tr>
                                {userReportData.financing_type == "loan" ? (
                                  <>
                                    <tr>
                                      <td className="">Mortgage</td>
                                      <td className="font-color-gray">
                                        ${userReportData.profit_mortgage}
                                      </td>
                                    </tr>
                                  </>
                                ) : null}
                                <tr>
                                  <td className="">Operating Expenses</td>
                                  <td className="font-color-gray">
                                    ${userReportData.profit_operating_expenses}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="">
                                    Property Management Reserve
                                  </td>
                                  <td className="font-color-gray">
                                    ${userReportData.profit_pm_reserve}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="">NOI</td>
                                  <td className="font-color-gray">
                                    ${userReportData.profit_noi}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="">Cap Rate</td>
                                  <td className="font-color-gray">
                                    {userReportData.profit_cap_rate}
                                  </td>
                                </tr>
                                <tr className="bg-white-tr">
                                  {userReportData.financing_type == "loan" ? (
                                    <>
                                      <td className="">Profit</td>
                                    </>
                                  ) : (
                                    <>
                                      <td className="">Profit</td>
                                    </>
                                  )}

                                  <td className="">
                                    {userReportData.investors_investment_percentage &&
                                      userReportData
                                        .investors_investment_percentage
                                        .length > 0 &&
                                      userReportData.investors_investment_percentage.map(
                                        (investorsPercentage, keyIndx) => {
                                          let persentageAmount =
                                            (chartNoi / 100) *
                                            investorsPercentage;
                                          let rtyu = parseFloat(
                                            persentageAmount
                                          )
                                            .toFixed(2)
                                            .toString()
                                            .replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                            );
                                          return (
                                            <>
                                              Investor {keyIndx + 1} (
                                              {investorsPercentage}%) {rtyu}
                                              <br />
                                            </>
                                          );
                                        }
                                      )}
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className=" m-t-10 gridClass">
                          <div className="report-tab-chart-sub">
                            <h3 className="report-tab-chart-title">
                              Annual Profit
                            </h3>
                            <div className="operating-chart">
                              <ReactEcharts
                                className="echarts-margin"
                                option={{
                                  responsive: true,
                                  color: "#F0A808",
                                  tooltip: {
                                    trigger: "axis",
                                    // axisPointer: {
                                    // 	type: 'line'
                                    // }
                                  },
                                  xAxis: {
                                    type: "category",
                                    axisLine: {
                                      show: false,
                                    },
                                    axisTick: {
                                      show: false,
                                    },
                                    data: [
                                      "Rental\nIncome",
                                      "Operating\nExpenses",
                                      "Property\nManagement\nReserve",
                                      "NOI",
                                    ],
                                  },
                                  yAxis: {
                                    type: "value",
                                    axisLine: {
                                      show: false,
                                    },
                                    axisTick: {
                                      show: false,
                                    },
                                    axisLabel: {
                                      formatter: "${value}",
                                    },
                                  },
                                  series: [
                                    {
                                      type: "bar",
                                      smooth: true,
                                      barWidth: "20%",
                                      data: [
                                        {
                                          value: chartRentalIncome,
                                          itemStyle:
                                            chartRentalIncome > 0
                                              ? { color: "#F0A808" }
                                              : { color: "rgb(242, 40, 13)" },
                                        },
                                        {
                                          value: chartOperatingExpenses,
                                          itemStyle:
                                            chartOperatingExpenses > 0
                                              ? { color: "#F0A808" }
                                              : { color: "rgb(242, 40, 13)" },
                                        },
                                        {
                                          value: chartPropertyManagementReserve,
                                          itemStyle:
                                            chartPropertyManagementReserve > 0
                                              ? { color: "#F0A808" }
                                              : { color: "rgb(242, 40, 13)" },
                                        },
                                        {
                                          value: chartNoi,
                                          itemStyle:
                                            chartNoi > 0
                                              ? { color: "#F0A808" }
                                              : { color: "rgb(242, 40, 13)" },
                                        },
                                      ],
                                      // data: [chartRentalIncome, chartOperatingExpenses, chartPropertyManagementReserve, chartNoi]
                                    },
                                  ],
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="gridRow m-t-20">
                        <div className=" m-t-10 gridClass">
                          <div className="report-tab-chart-sub">
                            <h3 className="report-tab-chart-title">
                              Closing Costs
                            </h3>
                            <div className="table-responsive">
                              <table className="table report-tab-table">
                                {userReportData.financing_type == "loan" ? (
                                  <>
                                    <tr>
                                      <td className="">
                                        Earnest money deposit (2%)
                                      </td>
                                      <td className="font-color-gray">
                                        $
                                        {parseFloat(
                                          userReportData.earnest_money_deposit
                                        )
                                          .toFixed(2)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="">Property inspection</td>
                                      <td className="font-color-gray">
                                        $
                                        {parseFloat(
                                          userReportData.property_inspection
                                        )
                                          .toFixed(2)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="">
                                        Real estate transfer tax
                                      </td>
                                      <td className="font-color-gray">
                                        $
                                        {parseFloat(
                                          userReportData.real_estate_transfer_tax
                                        )
                                          .toFixed(2)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="">Title insurance fee</td>
                                      <td className="font-color-gray">
                                        $
                                        {parseFloat(
                                          userReportData.title_insurance_fee
                                        )
                                          .toFixed(2)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="">Origination fee</td>
                                      <td className="font-color-gray">
                                        $
                                        {parseFloat(
                                          userReportData.origination_fee
                                        )
                                          .toFixed(2)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="">Appraisal fees</td>
                                      <td className="font-color-gray">
                                        $
                                        {parseFloat(
                                          userReportData.appraisal_fees
                                        )
                                          .toFixed(2)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                    </tr>
                                    <tr className="bg-white-tr">
                                      <td className="">
                                        Total (Max of 5% purchase price or
                                        inputs entered)
                                      </td>
                                      <td className="">
                                        $
                                        {parseFloat(
                                          userReportData.closing_cost_total
                                        )
                                          .toFixed(2)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                    </tr>
                                  </>
                                ) : (
                                  <>
                                    <tr>
                                      <td className="">
                                        Earnest money deposit (2%)
                                      </td>
                                      <td className="font-color-gray">
                                        $
                                        {parseFloat(
                                          userReportData.earnest_money_deposit
                                        )
                                          .toFixed(2)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="">Property inspection</td>
                                      <td className="font-color-gray">
                                        $
                                        {parseFloat(
                                          userReportData.property_inspection
                                        )
                                          .toFixed(2)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="">
                                        Real estate transfer tax
                                      </td>
                                      <td className="font-color-gray">
                                        $
                                        {parseFloat(
                                          userReportData.real_estate_transfer_tax
                                        )
                                          .toFixed(2)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="">Title insurance fee</td>
                                      <td className="font-color-gray">
                                        $
                                        {parseFloat(
                                          userReportData.title_insurance_fee
                                        )
                                          .toFixed(2)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                    </tr>
                                    <tr className="bg-white-tr">
                                      <td className="">
                                        Total (Max of 5% purchase price or
                                        inputs entered)
                                      </td>
                                      <td className="">
                                        $
                                        {parseFloat(
                                          userReportData.closing_cost_total
                                        )
                                          .toFixed(2)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className=" m-t-10 gridClass">
                          <div className="report-tab-chart-sub">
                            <h3 className="report-tab-chart-title">
                              Closing Costs
                            </h3>
                            <div className="operating-chart">
                              <ReactEcharts
                                className="echarts-margin"
                                option={{
                                  series: [
                                    {
                                      type: "pie",
                                      radius: ["50%", "70%"],
                                      center: ["50%", "50%"],
                                      selectedMode: "single",
                                      data: [
                                        {
                                          value:
                                            userReportData.real_estate_transfer_tax,
                                          itemStyle: { color: "#1d7ff0" },
                                          label: {
                                            formatter: [
                                              "{title1|$" +
                                                parseFloat(
                                                  userReportData.real_estate_transfer_tax
                                                )
                                                  .toFixed(2)
                                                  .toString()
                                                  .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  ) +
                                                "}",
                                              "{title2|Real estate transfer tax}",
                                              "{br|}",
                                            ].join("\n"),
                                            rich: {
                                              title1: {
                                                align: "left",
                                                fontSize: "14",
                                              },
                                              title2: {
                                                color: "#999999",
                                                align: "left",
                                                fontSize: "14",
                                              },
                                            },
                                          },
                                        },
                                        {
                                          value:
                                            userReportData.title_insurance_fee,
                                          itemStyle: { color: "#f0087c" },
                                          label: {
                                            formatter: [
                                              "{title1|$" +
                                                parseFloat(
                                                  userReportData.title_insurance_fee
                                                )
                                                  .toFixed(2)
                                                  .toString()
                                                  .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  ) +
                                                "}",
                                              "{title2|Title insurance fee}",
                                              "{br|}",
                                            ].join("\n"),
                                            rich: {
                                              title1: {
                                                align: "right",
                                                fontSize: "14",
                                              },
                                              title2: {
                                                color: "#999999",
                                                align: "right",
                                                fontSize: "14",
                                              },
                                            },
                                          },
                                        },
                                        {
                                          value:
                                            userReportData.earnest_money_deposit,
                                          itemStyle: { color: "#f0a808" },
                                          label: {
                                            formatter: [
                                              "{title1|$" +
                                                parseFloat(
                                                  userReportData.earnest_money_deposit
                                                )
                                                  .toFixed(2)
                                                  .toString()
                                                  .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  ) +
                                                "}",
                                              "{title2|Earnest money deposit}",
                                              "{br|}",
                                            ].join("\n"),
                                            rich: {
                                              title1: {
                                                align: "right",
                                                fontSize: "14",
                                              },
                                              title2: {
                                                color: "#999999",
                                                align: "right",
                                                fontSize: "14",
                                              },
                                            },
                                          },
                                        },
                                        {
                                          value:
                                            userReportData.property_inspection,
                                          itemStyle: { color: "#8932fc" },
                                          label: {
                                            formatter: [
                                              "{title1|$" +
                                                parseFloat(
                                                  userReportData.property_inspection
                                                )
                                                  .toFixed(2)
                                                  .toString()
                                                  .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  ) +
                                                "}",
                                              "{title2|Property inspection}",
                                              "{br|}",
                                            ].join("\n"),
                                            rich: {
                                              title1: {
                                                align: "right",
                                                fontSize: "14",
                                              },
                                              title2: {
                                                color: "#999999",
                                                align: "right",
                                                fontSize: "14",
                                              },
                                            },
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="gridRow m-t-20">
                        <div className=" m-t-10 gridClass">
                          <div className="report-tab-chart-sub">
                            <h3 className="report-tab-chart-title">
                              Financing
                            </h3>
                            <div className="table-responsive">
                              <table className="table report-tab-table">
                                <tr>
                                  <td className="">Purchase price</td>
                                  <td className="font-color-gray">
                                    {("purchange_price" in userReportData &&
                                      userReportData.purchange_price &&
                                      `$${parseFloat(
                                        userReportData.purchange_price
                                      )
                                        .toFixed(2)
                                        .toString()
                                        .replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ","
                                        )}`) ||
                                      "-"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="">Financing via</td>
                                  <td className="font-color-gray text-transform">
                                    {("financing_type" in userReportData &&
                                      userReportData.financing_type) ||
                                      ""}
                                  </td>
                                </tr>
                                {userReportData.financing_type == "loan" ? (
                                  <>
                                    <tr>
                                      <td className="">Loan Type</td>
                                      <td className="font-color-gray text-transform">
                                        {("financing_loan_type" in
                                          userReportData &&
                                          userReportData.financing_loan_type) ||
                                          ""}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="">Down Payment</td>
                                      <td className="font-color-gray">
                                        ${downPayment}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="">Amount Financed</td>
                                      <td className="font-color-gray">
                                        $
                                        {
                                          userReportData.financing_amount_financed
                                        }
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="">Interest rate</td>
                                      <td className="font-color-gray">
                                        {userReportData.financing_interest_rate}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="">
                                        Amortization Term (months)
                                      </td>
                                      <td className="font-color-gray">
                                        {
                                          userReportData.financing_amortization_term
                                        }
                                      </td>
                                    </tr>
                                  </>
                                ) : null}
                                <tr>
                                  <td className="">Renovation Cost</td>
                                  <td className="font-color-gray">
                                    $
                                    {parseFloat(userReportData.renovation_cost)
                                      .toFixed(2)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="">Bank Reserve</td>
                                  <td className="font-color-gray">
                                    $
                                    {parseFloat(
                                      userReportData.financing_reserve
                                    )
                                      .toFixed(2)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  </td>
                                </tr>
                                {userReportData.financing_type == "loan" ? (
                                  <>
                                    <tr className="bg-white-tr">
                                      <td className="">
                                        Total for down payment (less earnest
                                        money deposit) and renovation cost
                                      </td>
                                      <td className="">${totalDownPayment}</td>
                                    </tr>
                                  </>
                                ) : null}
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className=" m-t-10 gridClass">
                          <div className="report-tab-chart-sub">
                            <h3 className="report-tab-chart-title">
                              Financing
                            </h3>
                            <div className="operating-chart">
                              <ReactEcharts
                                className="echarts-margin"
                                option={{
                                  // tooltip: {
                                  //   trigger: "item",
                                  // },
                                  legend: {
                                    top: "5%",
                                    left: "center",
                                  },
                                  series: [
                                    {
                                      type: "pie",
                                      radius: ["50%", "70%"],
                                      center: ["50%", "50%"],
                                      selectedMode: "single",
                                      data: [
                                        {
                                          value: Math.abs(
                                            downPayment
                                              .toString()
                                              .replace(/,/g, "")
                                          ),
                                          itemStyle: { color: "#009431" },
                                          label: {
                                            formatter: [
                                              "{title1|$" + downPayment + "}",
                                              "{title2|Down Payment}",
                                              "{br|}",
                                            ].join("\n"),
                                            rich: {
                                              title1: {
                                                align: "left",
                                                fontSize: "14",
                                              },
                                              title2: {
                                                color: "#999999",
                                                align: "left",
                                                fontSize: "14",
                                              },
                                            },
                                          },
                                        },
                                        {
                                          value: chartRenovationCost,
                                          itemStyle: { color: "#f0a808" },
                                          label: {
                                            formatter: [
                                              "{title1|$" +
                                                parseFloat(chartRenovationCost)
                                                  .toFixed(2)
                                                  .toString()
                                                  .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  ) +
                                                "}",
                                              "{title2|Renovation Cost}",
                                              "{br|}",
                                            ].join("\n"),
                                            rich: {
                                              title1: {
                                                align: "right",
                                                fontSize: "14",
                                              },
                                              title2: {
                                                color: "#999999",
                                                align: "right",
                                                fontSize: "14",
                                              },
                                            },
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row m-t-20">
                        <div className="col-md-12 text-center no-print-button">
                          <button
                            className="btn btn-primary btn-with-icon"
                            onClick={() =>
                              this.props.history.push(
                                `/editreportresult/${userReportData.id}`
                              )
                            }
                          >
                            EDIT <img src={penIcon} />
                          </button>
                          <button
                            className="btn btn-primary btn-with-icon"
                            onClick={() => window.print()}
                          >
                            PRINT <img src={printIcon} />
                          </button>
                          {/* <button className="btn btn-primary btn-with-icon">PRINT <img src={printIcon} /></button> */}
                        </div>
                      </div>
                    </div>
                  </Tab>

                  <Tab
                    eventKey={!userApiData ? "" : "propertyinfo"}
                    title={!userApiData ? "" : "Property Info"}
                  >
                    {!userApiData ? null : userApiData.built_year != 0 ? (
                      <>
                        <div className="page-break"></div>
                        <div className="row">
                          <div className="col-md-8">
                            <div className="bg-gray-with-border">
                              <p className="property-title">
                                <img src={homeIcon} />{" "}
                                {userApiData.property_class}
                              </p>
                              <div className="home-info">
                                <span>{userApiData.no_of_bedrooms}Br</span>
                                <span>{userApiData.no_of_bathrooms}Ba</span>
                                <span>
                                  {userApiData.gross_size} living square feet
                                </span>
                              </div>
                              <div className="prop-block">
                                <span>Built in</span>
                                <p>{userApiData.built_year}</p>
                              </div>
                              {!userApiData.sold_date ? null : (
                                <div className="prop-block">
                                  <span>
                                    Last Sold {userApiData.sold_date} for
                                  </span>
                                  <p>
                                    $
                                    {parseFloat(userApiData.sold_amount)
                                      .toFixed(0)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  </p>
                                </div>
                              )}
                              <div className="prop-block">
                                <span>Rooms</span>
                                <p>{userApiData.total_room}</p>
                              </div>
                              <div className="prop-block">
                                <span>living square feet</span>
                                <p>
                                  {parseFloat(userApiData.lot_size)
                                    .toFixed(0)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </p>
                              </div>
                              <div className="prop-block">
                                <span>Tax Assesment</span>
                                <p>${userApiData.tax_amount}</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="bg-gray-with-border">
                              <p className="property-title">Market Analysis</p>
                              <div className="home-info">
                                <span>Estimated Market Value</span>
                              </div>
                              <div className="market-price">
                                <p>
                                  $
                                  {parseFloat(userApiData.zest_year_price)
                                    .toFixed(0)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </p>
                                {/* <button className="btn btn-success">+${parseFloat(userApiData.zest_month_price).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</button>
																<span>Last 30 days</span> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="">
                            <div className="report-tab-chart-sub">
                              <h3 className="report-tab-chart-title border-0">
                                5 Year Estimated Value
                              </h3>
                              <div
                                ref={(dom) => (this.dom = dom)}
                                className="line-chart"
                              />
                            </div>
                          </div>
                        </div>
                        <p className="prop-footer">
                          <strong>Median market value in this area</strong> :
                          Estimated Value - $
                          {parseFloat(userApiData.median_zest)
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </p>
                      </>
                    ) : (
                      <h3>No data for given Address</h3>
                    )}
                  </Tab>

                  <Tab
                    eventKey={!userApiData ? "" : "comps"}
                    title={!userApiData ? "" : "Comps"}
                  >
                    {!userApiData ? null : userApiData.built_year != 0 ? (
                      <>
                        <div className="page-break"></div>
                        <div className="comp-outer">
                          <div className="comps-map">
                            <div className="googleMap">
                              <GoogleMap lat={latitude} lng={longitude} />
                            </div>
                            <img
                              width="1200"
                              height="330"
                              class="static_map"
                              src={`https://maps.googleapis.com/maps/api/staticmap?center=${userApiData.latitude},${userApiData.longitude}&zoom=4&scale=1&size=600x300&maptype=roadmap&key=AIzaSyCilLeT6TgLs2kC1YRwzTmW8uqGE2kCz98&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0xff0000%7Clabel:C%7C${userApiData.latitude},${userApiData.longitude}`}
                              alt="Google Map of Albany, NY"
                            ></img>
                          </div>

                          {userApiData.comp_property_details
                            ? userApiData.comp_property_details.property.map(
                                (compPropertyDetails, keyIndx) => {
                                  return (
                                    <div key={keyIndx} className="comps-block">
                                      <div className="bg-gray-with-border">
                                        <p className="property-title">
                                          <img src={homeIcon} /> $
                                          {parseFloat(
                                            compPropertyDetails.avm.amount.value
                                          )
                                            .toFixed(0)
                                            .toString()
                                            .replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                            )}
                                        </p>
                                        <div className="home-info">
                                          <span>
                                            {
                                              compPropertyDetails.building.rooms
                                                .bathstotal
                                            }
                                            Br
                                          </span>
                                          <span>
                                            {
                                              compPropertyDetails.building.rooms
                                                .beds
                                            }
                                            Ba
                                          </span>
                                          <span>
                                            {parseFloat(
                                              compPropertyDetails.building.size
                                                .universalsize
                                            )
                                              .toFixed(0)
                                              .toString()
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )}{" "}
                                            living square feet
                                          </span>
                                        </div>
                                        <p className="comps-address">
                                          {compPropertyDetails.address.line1}
                                          <br />
                                          {compPropertyDetails.address.line2}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                }
                              )
                            : null}
                        </div>
                      </>
                    ) : (
                      <h3>No data for given Address</h3>
                    )}
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>

          <Footer></Footer>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  router: state.router,
  userReportById: state.reports.userReportById,
});

const mapDispatchToProps = (dispatch) => {
  const reduxActions = {
    ...ReportsReduxActionsCreators,
    ...PatmentReduxActionsCreators,
  };

  return { actions: bindActionCreators(reduxActions, dispatch) };
};

const connectedReportResultPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportResult);
export { connectedReportResultPage as ReportResult };
