import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AuthReduxActionsCreators from '../../reducers/auth.reducer';

import { Helmet } from 'Components/Helmet';
import { Link } from 'react-router-dom';
import { Routes } from 'App/routes/Routes';
import { InputText } from 'Components/InputText';
import { validateFormFields } from 'Utils/commonFunction';
import loginImg from 'Assets/images/forgot-img.svg';
import Logo from 'Assets/images/rentpotential-logo.png';
import CloseIcon from 'Assets/images/close.png';
import loading from 'Assets/images/loading.gif';

const queryString = require('query-string');

const INITIAL_STATE = {
    newPassword: null,
	confirmPassword: null,
	
	errors: {
		newPassword: '',
		confirmPassword: '',
    },
}

class ResetPassword extends Component {
	constructor(props){
		super(props);
		this.state = {
			...INITIAL_STATE,
		};
	}

	handleOnErrors = (fieldName, fieldValue) => {
		this.setState(prevState => ({
		  errors: {
			...prevState.errors,
			[fieldName]: fieldValue
		  }
		}));
	}

	handleOnChangeInput = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	}
	
	resetPasswordFormValidate = () => {
        const { newPassword, confirmPassword } = this.state;
		const newPasswordIsValid = validateFormFields('password', newPassword);
		const confirmPasswordIsValid = validateFormFields('confirmPassword', confirmPassword);
		const stateOfErrors = 'errors';
		if (newPasswordIsValid) {
			this.handleOnErrors('newPassword', newPasswordIsValid.message, stateOfErrors);
		}
		if (confirmPasswordIsValid) {
			this.handleOnErrors('confirmPassword', confirmPasswordIsValid.message, stateOfErrors);
		}

		return newPasswordIsValid.status && confirmPasswordIsValid.status
	}
	
	handleOnResetPasswordSubmit = () => {
		const isResetPasswordFormFieldsValid = this.resetPasswordFormValidate();
		if(!isResetPasswordFormFieldsValid){
			console.log('You may enter wrong value, please try again')
		} else {
			const { newPassword, confirmPassword } = this.state;
			if(newPassword != confirmPassword) {
				this.setState({ 
				  errors: {
					confirmPassword: 'New Password and Confirm Password not match',
				  } 
				});
			} else {
				const token = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true }).token;
				const { actions: { resetPassword } } = this.props;
				resetPassword({ token, password: newPassword, password_confirmation: confirmPassword });
				// console.log("token---", token)
				// console.log('Done')
			}
		}
	}

	render(){
		const { errors, newPassword, confirmPassword } = this.state;
		let { fetching } = this.props;
      	return (
			<>
				<Helmet title="Rent Potential" metaName="description" metaContent="Rent Potential" />
				<div className="main-content">
					<div className="login-register-main">
						<div className="background-gray login-register-bg-img-side">
							<div className="login-register-logo">
								<img src={Logo} />
							</div>
							<div className="login-register-img">
								<img src={loginImg} />
							</div>
						</div>
						<div className="login-register-form-section">
							<Link className="close-btn-right" to={Routes.HOME_VIEW}>
								<img src={CloseIcon} />
							</Link>
							<div className="login-register-logo show-after-responsive">
								<img src={Logo} />
							</div>
							<div className="login-register-form">
								<h2 className="login-register-title">Reset Password</h2>
								{/* <div className="login-main-error">
									{forgotPasswordErrorsMsg ? forgotPasswordErrorsMsg : ''}
								</div> */}
								<div className="form-group">
									<div className="row">
										<div className="col-md-12">
											<InputText singleLableText="Enter New Password" singleInputType="password" singleInputClass={`${errors.newPassword && 'error-text-border'}`} singleInputPlaceholder="Enter New Password" singleInputName="newPassword" singleInputValue={newPassword || ''} singleInputonChangeFunc={this.handleOnChangeInput} errorMsg={errors.newPassword} />
										</div>
									</div>
								</div>
								<div className="form-group">
									<div className="row">
										<div className="col-md-12">
											<InputText singleLableText="Enter Confirm Password" singleInputType="password" singleInputClass={`${errors.confirmPassword && 'error-text-border'}`} singleInputPlaceholder="Enter Confirm Password" singleInputName="confirmPassword" singleInputValue={confirmPassword || ''} singleInputonChangeFunc={this.handleOnChangeInput} errorMsg={errors.confirmPassword} />
										</div>
									</div>
								</div>
								<div className="form-group">
									<div className="row">
										<div className="col-md-12 text-center">
											{(fetching) ?
												(
													<button className="btn btn-primary loading-btn" disabled>Loading <img className="btn-loading" src={loading} /></button>
												)
											: (
												<button className="btn btn-primary form-btn" onClick={this.handleOnResetPasswordSubmit}>SUBMIT</button>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
    	);
  	}
}

const mapStateToProps = (state) => ({
	fetching: state.authUsers.fetching,
	fetchingErr: state.authUsers.fetchingErr,
	fetchingErrMsg: state.authUsers.fetchingErrMsg,
})
  
const mapDispatchToProps = (dispatch) => {
	const reduxActions = {
	  ...AuthReduxActionsCreators
	};
	
  
	return { actions: bindActionCreators(reduxActions, dispatch)};
}

const connectedResetPasswordPage = connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
export { connectedResetPasswordPage as ResetPassword };
