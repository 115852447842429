import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LandingHomeReduxActionsCreators from "Reducers/landingHome.reducer";
import AuthReduxActionsCreators from "Reducers/auth.reducer";
import "bs-stepper/dist/css/bs-stepper.min.css";
import Stepper from "bs-stepper";
import { getItemInLocalStorage } from "App/utils/commonFunction";
import { Modal } from "Components/Modal";
import Select from "react-select";
import { Routes } from "Routes";
import NumberFormat from "react-number-format";
import InputMask from "react-input-mask";

import { StepFormButton } from "Components/StepFormButton";
import { StepFormNextPrevButton } from "Components/StepFormNextPrevButton";
import { InputText } from "Components/InputText";
import { InputTextWithIcon } from "Components/InputTextWithIcon";

import { validateFormFields } from "Utils/calculatorValidation";
import arrowRight from "Assets/images/arrow-right.png";
import loading from "Assets/images/loading.gif";
import { Link } from "react-router-dom";

const INITIAL_STATE_OBJECT = {
  pincode: "",
  address: "",
  report_name: null,
  number_of_investors: "1",
  purchange_price: null,
  other_rental_income_monthly: null,
  other_rental_income_annually: "0",

  financing_type: null,
  financing_loan_type: "residential",
  financing_amortization_term: null,
  financing_interest_rate: null,
  financing_down_payment: null,
  financing_amount_financed: null,
  financing_reserve: "0",
  renovation_cost: null,

  earnest_money_deposit: null,
  property_inspection: 600,
  real_estate_transfer_tax: 2000,
  title_insurance_fee: 1000,
  origination_fee: null,
  appraisal_fees: 600,

  prop_manage_fee_annually: null,
  prop_manage_fee_monthly: null,
  water_bill_annually: null,
  water_bill_monthly: null,
  taxes_annually: null,
  taxes_monthly: null,
  utilities_annually: null,
  utilities_monthly: null,
  trash_pest_landscaping_annually: null,
  trash_pest_landscaping_monthly: null,
  insurance_annually: null,
  insurance_monthly: null,

  profit_gross_rental_income: null,
  profit_mortgage: null,
  vacancy_plus_cap_ex: null,
  profit_operating_expenses: null,
  profit_pm_reserve: null,
  profit_noi: null,
  profit_cap_rate: null,

  metrics_one_persentage_rule: null,
  metrics_dscr: null,
  metrics_op_ex_ratio: null,

  breakeven_discount_rate: null,
  breakeven_initial_investment: null,
  breakeven_yearsto_breakeven: null,
  breakeven_return_on_investment: null,
  breakeven_np_value: null,

  // hidden inputs var
  monthly_rental_total: null,
  annually_rental_total: null,
  down_payment_number: null,
  financing_total: null,
  closing_cost_total: null,
  expences_monthly_total: null,
  expences_annually_total: null,

  pass_or_invest: null,
};

const INITIAL_STATE = {
  errors: {
    pincode: "",
    address: "",
    report_name: "",
    number_of_investors: "",
    investorsInvestmentPercentage: "",
    rentalIncomeMonthly: "",
    rentalIncomeAnnually: "",
    purchange_price: "",
    other_rental_income_monthly: "",
    other_rental_income_annually: "",

    financing_type: "",
    financing_amortization_term: "",
    financing_interest_rate: "",
    financing_down_payment: "",
    financing_amount_financed: "",
    financing_reserve: "",
    renovation_cost: "",

    earnest_money_deposit: "",
    property_inspection: "",
    real_estate_transfer_tax: "",
    title_insurance_fee: "",
    origination_fee: "",
    appraisal_fees: "",

    prop_manage_fee_annually: "",
    prop_manage_fee_monthly: "",
    water_bill_annually: "",
    water_bill_monthly: "",
    taxes_annually: "",
    taxes_monthly: "",
    utilities_annually: "",
    utilities_monthly: "",
    trash_pest_landscaping_annually: "",
    trash_pest_landscaping_monthly: "",
    insurance_annually: "",
    insurance_monthly: "",

    profit_gross_rental_income: "",
    profit_mortgage: "",
    vacancy_plus_cap_ex: "",
    profit_operating_expenses: "",
    profit_pm_reserve: "",
    profit_noi: "",
    profit_cap_rate: "",

    metrics_one_persentage_rule: "",
    metrics_dscr: "",
    metrics_op_ex_ratio: "",

    breakeven_discount_rate: "",
    breakeven_initial_investment: "",
    breakeven_yearsto_breakeven: "",
    breakeven_return_on_investment: "",
    breakeven_np_value: "",
    pass_or_invest: "",
    investorArrayError: "",
    incomeMonthlyError: "",
  },
};

class StepFromSectionWithoutApi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      ...INITIAL_STATE_OBJECT,
      rentalIncomeTextField: [{ MonthlyRent: "", AnnualIncome: "" }],
      investors_investment_percentage: [],
      rental_income_monthly: [],
      rental_income_annually: [],
      getPostalAddresses: [],
      loadingData: false,
      disabledSearchAddressBox: true,
      filtered: [],
      filteredDisplay: false,
      rentalIncomeCount: 1,
      regexp: /[0-9]/,
      isVerifyModalOpen: false,
    };
  }

  componentDidMount() {
    this.stepper = new Stepper(document.querySelector("#stepperMain"), {
      linear: true,
      animation: true,
    });
    this.RentalIncomeInnerStepper = new Stepper(
      document.querySelector("#RentalIncomeStepper"),
      {
        linear: false,
        animation: true,
      }
    );
    this.FinancingInnerStepper = new Stepper(
      document.querySelector("#FinancingStepper"),
      {
        linear: false,
        animation: true,
      }
    );
    this.ClosingCostsInnerStepper = new Stepper(
      document.querySelector("#ClosingCostsStepper"),
      {
        linear: false,
        animation: true,
      }
    );
    this.OperatingExpensesInnerStepper = new Stepper(
      document.querySelector("#OperatingExpensesStepper"),
      {
        linear: false,
        animation: true,
      }
    );
    this.AnnualProfitInnerStepper = new Stepper(
      document.querySelector("#AnnualProfitStepper"),
      {
        linear: false,
        animation: true,
      }
    );
    this.MetricsInnerStepper = new Stepper(
      document.querySelector("#MetricsStepper"),
      {
        linear: false,
        animation: true,
      }
    );
    this.BreakevenAnalysisInnerStepper = new Stepper(
      document.querySelector("#BreakevenAnalysisStepper"),
      {
        linear: false,
        animation: true,
      }
    );
    this.ResultInnerStepper = new Stepper(
      document.querySelector("#ResultStepper"),
      {
        linear: false,
        animation: true,
      }
    );
  }

  componentDidUpdate(prevProps, preState) {
    console.log("componentDidUpdate");
    if (
      prevProps.postalAddresses !== this.props.postalAddresses &&
      this.props.postalAddresses
    ) {
      const { postalAddresses } = this.props;
      this.setState({
        filtered: postalAddresses.data,
        getPostalAddresses: postalAddresses.data,
        loadingData: false,
        disabledSearchAddressBox: false,
        filtered: this.state.getPostalAddresses,
      });
    }
    if (prevProps.fetching !== this.props.fetching && this.props.fetching) {
      const { fetching } = this.props;
      // console.log("fetching-----",fetching);
      this.setState({ loadingData: fetching });
    }
    if (
      prevProps.generateReport !== this.props.generateReport &&
      this.props.generateReport
    ) {
      const { generateReport } = this.props;
      this.setState({ ...INITIAL_STATE_OBJECT });
    }
    if (
      prevProps.close_verify_modal !== this.props.close_verify_modal &&
      this.props.close_verify_modal
    ) {
      this.setState({ isVerifyModalOpen: false });
    }
  }

  addRentalIncomeTextField = (e) => {
    // console.log("e----",e);
    let incomeCountAdd = (this.state.rentalIncomeCount += 1);
    this.setState((prevState) => ({
      rentalIncomeTextField: [
        ...prevState.rentalIncomeTextField,
        { MonthlyRent: "", AnnualIncome: "" },
      ],
      rentalIncomeCount: incomeCountAdd,
    }));
  };

  removeClick(i) {
    let incomeCountAdd = (this.state.rentalIncomeCount -= 1);
    let rentalIncomeTextField = [...this.state.rentalIncomeTextField];
    rentalIncomeTextField.splice(i, 1);
    this.setState({ rentalIncomeTextField, rentalIncomeCount: incomeCountAdd });
  }

  handleOpenCloseVerifyModal = () => {
    this.setState({ isVerifyModalOpen: false });
  };

  handleOnErrors = (fieldName, fieldValue) => {
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [fieldName]: fieldValue,
      },
    }));
  };

  handleOnChangeInput = (event) => {
    // console.log("name---", event.target.name)
    // console.log("value---", event.target.value)
    var strValue = event.target.value.toString().replace(/,/g, "");
    // while (strValue.search(",") >= 0) {
    // 	strValue = (strValue + "").replace(',', '');
    // }
    // console.log("strValue---", strValue)
    this.setState({ [event.target.name]: strValue });
  };

  handleOnFocusDownPaymentInput = (event) => {
    const { purchange_price } = this.state;
    if (event.target.name == "financing_down_payment") {
      let financingAmount1 =
        purchange_price - (purchange_price * event.target.value) / 100;
      this.setState({
        financing_amount_financed: parseFloat(financingAmount1)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      });
    }
  };

  handleSearchAddressChange = (e) => {
    let currentList = [];
    let newList = [];
    if (e.target.value !== "") {
      currentList = this.state.getPostalAddresses;
      newList = currentList.filter((item) => {
        const lc = item.toLowerCase();
        const filter = e.target.value.toLowerCase();
        return lc.includes(filter);
      });
      // console.log("currentList-----",currentList);
    } else {
      newList = this.state.getPostalAddresses;
      // console.log("newList-----",newList);
    }
    // Set the filtered state based on what our rules added to newList
    this.setState({
      filtered: newList,
      [e.target.name]: e.target.value,
      filteredDisplay: true,
    });
  };

  handleOnGetFilteredText = (item) => {
    // console.log("item-----",item)
    this.setState({
      address: item,
      filteredDisplay: false,
    });
  };

  searchAddressFormValidate = () => {
    const { pincode, address } = this.state;
    const postalCodeIsValid = validateFormFields("pincode", pincode);
    const addressIsValid = validateFormFields("address", address);
    const stateOfErrors = "errors";
    if (postalCodeIsValid) {
      this.handleOnErrors("pincode", postalCodeIsValid.message, stateOfErrors);
    }

    if (addressIsValid) {
      this.handleOnErrors("address", addressIsValid.message, stateOfErrors);
    }

    return postalCodeIsValid.status && addressIsValid.status;
  };

  rentalIncomeFormValidate = () => {
    const { report_name } = this.state;
    const reportNameIsValid = validateFormFields("report_name", report_name);
    const stateOfErrors = "errors";
    if (reportNameIsValid) {
      this.handleOnErrors(
        "report_name",
        reportNameIsValid.message,
        stateOfErrors
      );
    }

    return reportNameIsValid.status;
  };

  // rentalIncomeSub2Validate = () => {
  //     const { investorsInvestmentPercentage } = this.state;
  //     const investorsInvestmentPercentageIsValid = validateFormFields('investorsInvestmentPercentage', investorsInvestmentPercentage);
  //     const stateOfErrors = 'errors';
  //     if (investorsInvestmentPercentageIsValid) {
  //         this.handleOnErrors('investorsInvestmentPercentage', investorsInvestmentPercentageIsValid.message, stateOfErrors);
  // 	}

  //     return investorsInvestmentPercentageIsValid.status
  // }

  rentalIncomeSub3Validate = () => {
    const { other_rental_income_monthly } = this.state;
    const otherRentalIncomeMonthlyIsValid = validateFormFields(
      "other_rental_income_monthly",
      other_rental_income_monthly
    );
    const stateOfErrors = "errors";
    if (otherRentalIncomeMonthlyIsValid) {
      this.handleOnErrors(
        "other_rental_income_monthly",
        otherRentalIncomeMonthlyIsValid.message,
        stateOfErrors
      );
    }

    return otherRentalIncomeMonthlyIsValid.status;
  };

  purchasePriceValidate = () => {
    const { purchange_price } = this.state;
    const purchasePriceIsValid = validateFormFields(
      "purchange_price",
      purchange_price
    );
    const stateOfErrors = "errors";
    if (purchasePriceIsValid) {
      this.handleOnErrors(
        "purchange_price",
        purchasePriceIsValid.message,
        stateOfErrors
      );
    }

    return purchasePriceIsValid.status;
  };

  financingStep3FormValidate = () => {
    const {
      financing_amortization_term,
      financing_interest_rate,
      financing_down_payment,
    } = this.state;
    const financingAmortizationTermIsValid = validateFormFields(
      "financing_amortization_term",
      financing_amortization_term
    );
    const financingInterestRateIsValid = validateFormFields(
      "financing_interest_rate",
      financing_interest_rate
    );
    const financingDownPaymentIsValid = validateFormFields(
      "financing_down_payment",
      financing_down_payment
    );
    const stateOfErrors = "errors";
    if (financingAmortizationTermIsValid) {
      this.handleOnErrors(
        "financing_amortization_term",
        financingAmortizationTermIsValid.message,
        stateOfErrors
      );
    }
    if (financingInterestRateIsValid) {
      this.handleOnErrors(
        "financing_interest_rate",
        financingInterestRateIsValid.message,
        stateOfErrors
      );
    }
    if (financingDownPaymentIsValid) {
      this.handleOnErrors(
        "financing_down_payment",
        financingDownPaymentIsValid.message,
        stateOfErrors
      );
    }

    return (
      financingAmortizationTermIsValid.status &&
      financingInterestRateIsValid.status &&
      financingDownPaymentIsValid.status
    );
  };

  financingSub4Validate = () => {
    const { renovation_cost, financing_reserve } = this.state;
    const renovationCostIsValid = validateFormFields(
      "renovation_cost",
      renovation_cost
    );
    const financingReserveIsValid = validateFormFields(
      "financing_reserve",
      financing_reserve
    );
    const stateOfErrors = "errors";
    if (renovationCostIsValid) {
      this.handleOnErrors(
        "renovation_cost",
        renovationCostIsValid.message,
        stateOfErrors
      );
    }
    if (financingReserveIsValid) {
      this.handleOnErrors(
        "financing_reserve",
        financingReserveIsValid.message,
        stateOfErrors
      );
    }

    return renovationCostIsValid.status && financingReserveIsValid.status;
  };

  closingCostsValidate = () => {
    const {
      property_inspection,
      real_estate_transfer_tax,
      title_insurance_fee,
      origination_fee,
      appraisal_fees,
    } = this.state;
    const propertyInspectionIsValid = validateFormFields(
      "property_inspection",
      property_inspection
    );
    const realEstateTransferTaxIsValid = validateFormFields(
      "real_estate_transfer_tax",
      real_estate_transfer_tax
    );
    const titleInsuranceFeeIsValid = validateFormFields(
      "title_insurance_fee",
      title_insurance_fee
    );
    const originationFeeIsValid = validateFormFields(
      "origination_fee",
      origination_fee
    );
    const appraisalFeesIsValid = validateFormFields(
      "appraisal_fees",
      appraisal_fees
    );
    const stateOfErrors = "errors";
    if (propertyInspectionIsValid) {
      this.handleOnErrors(
        "property_inspection",
        propertyInspectionIsValid.message,
        stateOfErrors
      );
    }
    if (realEstateTransferTaxIsValid) {
      this.handleOnErrors(
        "real_estate_transfer_tax",
        realEstateTransferTaxIsValid.message,
        stateOfErrors
      );
    }
    if (titleInsuranceFeeIsValid) {
      this.handleOnErrors(
        "title_insurance_fee",
        titleInsuranceFeeIsValid.message,
        stateOfErrors
      );
    }
    if (originationFeeIsValid) {
      this.handleOnErrors(
        "origination_fee",
        originationFeeIsValid.message,
        stateOfErrors
      );
    }
    if (appraisalFeesIsValid) {
      this.handleOnErrors(
        "appraisal_fees",
        appraisalFeesIsValid.message,
        stateOfErrors
      );
    }

    return (
      propertyInspectionIsValid.status &&
      realEstateTransferTaxIsValid.status &&
      titleInsuranceFeeIsValid.status &&
      originationFeeIsValid.status &&
      appraisalFeesIsValid.status
    );
  };

  operatingExpensesValidate = () => {
    const {
      water_bill_annually,
      taxes_annually,
      utilities_annually,
      trash_pest_landscaping_annually,
      insurance_annually,
    } = this.state;
    const waterBillAnnuallyIsValid = validateFormFields(
      "water_bill_annually",
      water_bill_annually
    );
    const taxesAnnuallyIsValid = validateFormFields(
      "taxes_annually",
      taxes_annually
    );
    const utilitiesAnnuallyIsValid = validateFormFields(
      "utilities_annually",
      utilities_annually
    );
    const trashPestLandscapingAnnuallyIsValid = validateFormFields(
      "trash_pest_landscaping_annually",
      trash_pest_landscaping_annually
    );
    const insuranceAnnuallyIsValid = validateFormFields(
      "insurance_annually",
      insurance_annually
    );
    const stateOfErrors = "errors";
    if (waterBillAnnuallyIsValid) {
      this.handleOnErrors(
        "water_bill_annually",
        waterBillAnnuallyIsValid.message,
        stateOfErrors
      );
    }
    if (taxesAnnuallyIsValid) {
      this.handleOnErrors(
        "taxes_annually",
        taxesAnnuallyIsValid.message,
        stateOfErrors
      );
    }
    if (utilitiesAnnuallyIsValid) {
      this.handleOnErrors(
        "utilities_annually",
        utilitiesAnnuallyIsValid.message,
        stateOfErrors
      );
    }
    if (trashPestLandscapingAnnuallyIsValid) {
      this.handleOnErrors(
        "trash_pest_landscaping_annually",
        trashPestLandscapingAnnuallyIsValid.message,
        stateOfErrors
      );
    }
    if (insuranceAnnuallyIsValid) {
      this.handleOnErrors(
        "insurance_annually",
        insuranceAnnuallyIsValid.message,
        stateOfErrors
      );
    }

    return (
      waterBillAnnuallyIsValid.status &&
      taxesAnnuallyIsValid.status &&
      utilitiesAnnuallyIsValid.status &&
      trashPestLandscapingAnnuallyIsValid.status &&
      insuranceAnnuallyIsValid.status
    );
  };

  breakevenAnalysisSub1Validate = () => {
    const { breakeven_discount_rate } = this.state;
    const breakevenDiscountRateIsValid = validateFormFields(
      "breakeven_discount_rate",
      breakeven_discount_rate
    );
    const stateOfErrors = "errors";
    if (breakevenDiscountRateIsValid) {
      this.handleOnErrors(
        "breakeven_discount_rate",
        breakevenDiscountRateIsValid.message,
        stateOfErrors
      );
    }

    return breakevenDiscountRateIsValid.status;
  };

  handleOnSearchAddressSubmit = () => {
    const emailVerified = getItemInLocalStorage("email_verified_at");
    // console.log("emailVerified---",emailVerified)
    if (emailVerified == "null") {
      // console.log("dddddd",emailVerified)
      this.setState({ isVerifyModalOpen: true });
    } else {
      // console.log("fffff",emailVerified)
      const isSearchAddressFormFieldsValid = this.searchAddressFormValidate();
      if (!isSearchAddressFormFieldsValid) {
        console.log("You may enter wrong value, please try again");
      } else {
        this.stepper.next();
      }
      // this.stepper.next();
    }
  };

  handleOnRentalIncomeSubmit = () => {
    const isRentalIncomeFormFieldsValid = this.rentalIncomeFormValidate();
    if (!isRentalIncomeFormFieldsValid) {
      console.log("You may enter wrong value, please try again");
    } else {
      this.RentalIncomeInnerStepper.next();
    }
  };

  handleOnRentalIncomeStep2Submit = () => {
    let sum = 0;
    for (var i = 0; i < this.state.number_of_investors; i++) {
      if (
        !this.state.investors_investment_percentage[i] ||
        this.state.investors_investment_percentage[i] == "0"
      ) {
        this.setState({
          errors: {
            investorArrayError:
              "Sum of all input values should be equal to 100%",
          },
          [`investors_investment_percentage${i}`]: true,
        });
        // this.setState({ [`investors_investment_percentage${i}`]: true });
        return true;
      } else {
        if (
          !this.state.regexp.test(this.state.investors_investment_percentage[i])
        ) {
          this.setState({
            errors: { investorArrayError: "Please make sure only digit" },
            [`investors_investment_percentage${i}`]: true,
          });
          // this.setState({ [`investors_investment_percentage${i}`]: true });
          return true;
        }
        this.setState({
          errors: { investorArrayError: "" },
          [`investors_investment_percentage${i}`]: false,
        });
        // this.setState({ [`investors_investment_percentage${i}`]: false });
        sum += parseFloat(this.state.investors_investment_percentage[i]);
      }
    }
    if (sum == 100) {
      this.setState({
        errors: { investorArrayError: "" },
        [`investors_investment_percentage${i}`]: false,
      });
      // this.setState({ [`investors_investment_percentage${i}`]: false });
      this.RentalIncomeInnerStepper.next();
    } else {
      this.setState({
        errors: {
          investorArrayError: "Sum of all input values should be equal to 100%",
        },
        [`investors_investment_percentage${i}`]: true,
      });
      // this.setState({ [`investors_investment_percentage${i}`]: true });
    }
  };

  handleOnRentalIncomeStep3Submit = () => {
    let incomeMonthlySum = 0;
    for (var i = 0; i < this.state.rentalIncomeCount; i++) {
      // console.log("rentalIncomeMonthlyValue---",rentalIncomeMonthlyValue)
      if (
        !this.state.rental_income_monthly[i] ||
        this.state.rental_income_monthly[i] == "0"
      ) {
        this.setState({
          errors: { incomeMonthlyError: "Enter valid monthly rental" },
          [`rental_income_monthly${i}`]: true,
        });
        // this.setState({ [`rental_income_monthly${i}`]: true });
        return true;
      } else {
        if (!this.state.regexp.test(this.state.rental_income_monthly[i])) {
          this.setState({
            errors: { incomeMonthlyError: "Please make sure only digit" },
            [`rental_income_monthly${i}`]: true,
          });
          // this.setState({ [`rental_income_monthly${i}`]: true });
          return true;
        }
        this.setState({
          errors: { incomeMonthlyError: "" },
          [`rental_income_monthly${i}`]: false,
        });
        // this.setState({ [`rental_income_monthly${i}`]: false });
        var rentalIncomeMonthlyValue = this.state.rental_income_monthly[i]
          .toString()
          .replace(/,/g, "");
        incomeMonthlySum += parseFloat(rentalIncomeMonthlyValue);
      }
    }

    let incomeAnnuallySum = 0;
    for (var i = 0; i < this.state.rentalIncomeCount; i++) {
      var rentalIncomeAnnuallyValue = this.state.rental_income_annually[i]
        .toString()
        .replace(/,/g, "");
      incomeAnnuallySum += parseFloat(rentalIncomeAnnuallyValue);
    }

    const isRentalIncomeSub3FieldsValid = this.rentalIncomeSub3Validate();
    if (!isRentalIncomeSub3FieldsValid) {
      console.log("You may enter wrong value, please try again");
    } else {
      const { other_rental_income_monthly, other_rental_income_annually } =
        this.state;
      var otherRentalIncomeAnnuallyValue = other_rental_income_annually
        .toString()
        .replace(/,/g, "");

      let monthlyRentalTotalAmount =
        incomeMonthlySum + parseFloat(other_rental_income_monthly);
      this.setState({
        monthly_rental_total: parseFloat(monthlyRentalTotalAmount).toFixed(2),
      });

      let annuallyRentalTotalAmount =
        incomeAnnuallySum + parseFloat(otherRentalIncomeAnnuallyValue);
      this.setState({
        annually_rental_total: parseFloat(annuallyRentalTotalAmount).toFixed(2),
      });

      let propManageFeeMonthlyAmount =
        (parseFloat(monthlyRentalTotalAmount) * 10) / 100;
      this.setState({ prop_manage_fee_monthly: propManageFeeMonthlyAmount });

      let propManageFeeAnnuallyAmount =
        (parseFloat(annuallyRentalTotalAmount) * 10) / 100;
      this.setState({ prop_manage_fee_annually: propManageFeeAnnuallyAmount });

      this.stepper.next(3);
    }
  };

  handleOnFinancingStep2Submit = () => {
    const { financing_type } = this.state;
    if (!financing_type) {
      this.setState({
        errors: {
          financing_type: "Select one option",
        },
      });
    } else {
      if (financing_type == "loan") {
        this.FinancingInnerStepper.to(3);
      } else {
        this.FinancingInnerStepper.to(4);
      }
      this.setState({
        errors: {
          financing_type: "",
        },
      });
    }
  };

  handleOnFinancingStep3Submit = () => {
    const isFinancingFormFieldsValid = this.financingStep3FormValidate();
    if (!isFinancingFormFieldsValid) {
      console.log("You may enter wrong value, please try again");
    } else {
      this.FinancingInnerStepper.next();
    }
  };

  handleOnFinancingStep4Submit = () => {
    const isFinancingSub4FieldsValid = this.financingSub4Validate();
    if (!isFinancingSub4FieldsValid) {
      console.log("You may enter wrong value, please try again");
    } else {
      const {
        financing_type,
        financing_amount_financed,
        purchange_price,
        financing_reserve,
        renovation_cost,
      } = this.state;

      if (!financing_amount_financed) {
        var financingAmountFinancedValue = financing_amount_financed;
        // console.log('financing_amount_financed',financingAmountFinancedValue)
      } else {
        var financingAmountFinancedValue = financing_amount_financed
          .toString()
          .replace(/,/g, "");
        // console.log('financing_amount_financed',financingAmountFinancedValue)
      }
      // let financingAmountFinancedValue = financing_amount_financed.toString().replace(/,/g, '')
      console.log("purchange_price", purchange_price);
      if (financing_type == "loan") {
        let originationFeeAmount = (parseFloat(purchange_price) * 1) / 100;
        let downPaymentNumberAmount =
          parseFloat(purchange_price) -
          parseFloat(financingAmountFinancedValue);
        let financingTotalAmount =
          downPaymentNumberAmount +
          parseFloat(renovation_cost) +
          parseFloat(financing_reserve);
        let earnestMoneyDepositAmount = (parseFloat(purchange_price) * 2) / 100;

        // console.log('originationFeeAmount',originationFeeAmount)
        // console.log('originationFeeAmount111',parseFloat(originationFeeAmount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","))

        this.setState({
          origination_fee: parseFloat(originationFeeAmount).toFixed(2),
          down_payment_number: parseFloat(downPaymentNumberAmount).toFixed(2),
          financing_total: parseFloat(financingTotalAmount).toFixed(2),
          earnest_money_deposit: parseFloat(earnestMoneyDepositAmount).toFixed(
            2
          ),
        });
      } else {
        let originationFeeAmount = 0;
        let downPaymentNumberAmount = 0;
        let financingTotalAmount =
          downPaymentNumberAmount +
          parseFloat(renovation_cost) +
          parseFloat(financing_reserve);
        let earnestMoneyDepositAmount = (parseFloat(purchange_price) * 2) / 100;

        this.setState({
          origination_fee: parseFloat(originationFeeAmount).toFixed(2),
          down_payment_number: parseFloat(downPaymentNumberAmount).toFixed(2),
          financing_total: parseFloat(financingTotalAmount).toFixed(2),
          earnest_money_deposit: parseFloat(earnestMoneyDepositAmount).toFixed(
            2
          ),
        });
      }
      this.stepper.next();
    }
  };

  handleOnClosingCostsSubmit = () => {
    const isClosingCostsFieldsValid = this.closingCostsValidate();
    if (!isClosingCostsFieldsValid) {
      console.log("You may enter wrong value, please try again");
    } else {
      const {
        purchange_price,
        earnest_money_deposit,
        property_inspection,
        real_estate_transfer_tax,
        title_insurance_fee,
        origination_fee,
        appraisal_fees,
      } = this.state;

      var originationFeeValue = origination_fee.toString().replace(/,/g, "");

      let closingCostTotalAmount =
        parseFloat(earnest_money_deposit) +
        parseFloat(property_inspection) +
        parseFloat(real_estate_transfer_tax) +
        parseFloat(title_insurance_fee) +
        parseFloat(originationFeeValue) +
        parseFloat(appraisal_fees);

      var persentageAmount = (purchange_price / 100) * 5;
      var purchange_5_persent = parseFloat(persentageAmount).toFixed(2);

      var cost_max = 0;
      if (purchange_5_persent > closingCostTotalAmount) {
        cost_max = purchange_5_persent;
      } else {
        cost_max = closingCostTotalAmount;
      }
      // console.log("closing_cost_total---", cost_max)
      this.setState({ closing_cost_total: cost_max });
      this.stepper.next();
    }
  };

  handleOnOperatingExpensesSubmit = () => {
    const isOperatingExpensesFieldsValid = this.operatingExpensesValidate();
    if (!isOperatingExpensesFieldsValid) {
      console.log("You may enter wrong value, please try again");
    } else {
      const {
        financing_type,
        prop_manage_fee_annually,
        prop_manage_fee_monthly,
        water_bill_annually,
        water_bill_monthly,
        taxes_annually,
        taxes_monthly,
        utilities_annually,
        utilities_monthly,
        trash_pest_landscaping_annually,
        trash_pest_landscaping_monthly,
        insurance_annually,
        insurance_monthly,
        financing_interest_rate,
        financing_amortization_term,
        financing_amount_financed,
        annually_rental_total,
        purchange_price,
      } = this.state;

      if (!financing_amount_financed) {
        var financingAmountFinancedValue = financing_amount_financed;
        // console.log('financing_amount_financed',financingAmountFinancedValue)
      } else {
        var financingAmountFinancedValue = financing_amount_financed
          .toString()
          .replace(/,/g, "");
        // console.log('financing_amount_financed',financingAmountFinancedValue)
      }
      // let financingAmountFinancedValue = financing_amount_financed.toString().replace(/,/g, '')

      // TOTAL expences monthly Start
      let expencesMonthlyTotalAmount1 =
        parseFloat(prop_manage_fee_monthly) +
        parseFloat(water_bill_monthly) +
        parseFloat(taxes_monthly) +
        parseFloat(utilities_monthly) +
        parseFloat(trash_pest_landscaping_monthly) +
        parseFloat(insurance_monthly);
      let capExMonthly = (parseFloat(annually_rental_total) / 12 / 100) * 3;
      let vacancyMonthly = (parseFloat(annually_rental_total) / 12 / 100) * 5;

      let i = parseFloat(financing_interest_rate) / 1200;
      let n = parseFloat(financing_amortization_term);
      let p = parseFloat(financingAmountFinancedValue);

      // let monthlyPmt = i * p * Math.pow((1 + i), n) / (1 - Math.pow((1 + i), n));
      if (financing_type == "loan") {
        var monthlyPmt =
          (i * p * Math.pow(1 + i, n)) / (1 - Math.pow(1 + i, n));
      } else {
        var monthlyPmt = 0;
      }

      let monthlyPmt1 = monthlyPmt.toFixed(2);

      let expencesMonthlyTotalAmount =
        parseFloat(expencesMonthlyTotalAmount1) +
        parseFloat(capExMonthly) +
        parseFloat(vacancyMonthly) -
        parseFloat(monthlyPmt).toFixed(2);
      this.setState({
        expences_monthly_total:
          parseFloat(expencesMonthlyTotalAmount).toFixed(2) || 0.0,
      });

      // TOTAL expences annually Start
      let expencesAnnuallyTotalAmount1 =
        parseFloat(prop_manage_fee_annually) +
        parseFloat(water_bill_annually) +
        parseFloat(taxes_annually) +
        parseFloat(utilities_annually) +
        parseFloat(trash_pest_landscaping_annually) +
        parseFloat(insurance_annually);
      let capExAnnually = capExMonthly * 12;
      let vacancyAnnually = vacancyMonthly * 12;
      let expencesAnnuallyTotalAmount =
        parseFloat(expencesAnnuallyTotalAmount1) +
        parseFloat(capExAnnually) +
        parseFloat(vacancyAnnually);
      // console.log("expencesAnnuallyTotalAmount---", parseFloat(expencesAnnuallyTotalAmount).toFixed(2))
      this.setState({
        expences_annually_total:
          parseFloat(expencesAnnuallyTotalAmount).toFixed(2) || 0.0,
      });

      // Annual Profit annually Start
      // let mortgage = (parseFloat(taxes_monthly) + parseFloat(insurance_monthly) - parseFloat(monthlyPmt).toFixed(2)) * 12;

      if (financing_type == "loan") {
        var mortgage =
          (parseFloat(taxes_monthly) +
            parseFloat(insurance_monthly) -
            parseFloat(monthlyPmt).toFixed(2)) *
          12;
      } else {
        var mortgage = 0;
      }

      let vacancyPlusCapExTotal =
        parseFloat(capExAnnually) + parseFloat(vacancyAnnually);
      var operatingTotal = 0;
      if (financing_type == "loan") {
        let result1 =
          parseFloat(expencesMonthlyTotalAmount) + parseFloat(monthlyPmt1);
        let result2 =
          parseFloat(result1).toFixed(2) - parseFloat(insurance_monthly);
        let result3 = parseFloat(result2) - parseFloat(taxes_monthly);
        operatingTotal = parseFloat(result3) * 12;
      } else {
        operatingTotal = expencesAnnuallyTotalAmount;
      }

      let profitPmReserveTotal = parseFloat(mortgage) / 12;
      // console.log("profit_pm_reserve---", parseFloat(profitPmReserveTotal).toFixed(2))

      let resultNoiTotal = parseFloat(mortgage) + parseFloat(operatingTotal);
      let profitNoiCal =
        parseFloat(annually_rental_total) - parseFloat(resultNoiTotal);
      // console.log("profit_noi---", parseFloat(profitNoiCal).toFixed(2))

      let v1 = profitNoiCal * 100;
      let profitCapRateResult = parseFloat(v1) / purchange_price;
      // console.log("profit_cap_rate---", parseFloat(profitCapRateResult).toFixed(2))

      this.setState({
        profit_gross_rental_income:
          parseFloat(annually_rental_total)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0.0,
        profit_mortgage:
          parseFloat(mortgage)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0.0,
        vacancy_plus_cap_ex:
          parseFloat(vacancyPlusCapExTotal).toFixed(2) || 0.0,
        profit_operating_expenses:
          parseFloat(operatingTotal)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0.0,
        profit_pm_reserve:
          parseFloat(profitPmReserveTotal)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0.0,
        profit_noi:
          parseFloat(profitNoiCal)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0.0,
        profit_cap_rate: parseFloat(profitCapRateResult).toFixed(2) || 0.0,
      });

      // Metrics Start
      let grossRentalMonthly = parseFloat(annually_rental_total) / 12;
      let ruleMetResult =
        parseFloat(grossRentalMonthly) / parseFloat(purchange_price);
      if (ruleMetResult >= 0.01) {
        this.setState({
          metrics_one_persentage_rule: "Yes",
        });
      } else {
        this.setState({
          metrics_one_persentage_rule: "No",
        });
      }

      if (financing_type == "loan") {
        let metricsDscrResult =
          parseFloat(annually_rental_total) - parseFloat(operatingTotal);
        var metricsDscrFinalResult =
          parseFloat(metricsDscrResult) / parseFloat(mortgage);
      } else {
        let metricsDscrResult =
          parseFloat(annually_rental_total) - parseFloat(operatingTotal);
        var metricsDscrFinalResult =
          parseFloat(metricsDscrResult) / parseFloat(vacancyPlusCapExTotal);
      }

      let metricsOpExRatioResult =
        parseFloat(profitPmReserveTotal) + parseFloat(operatingTotal);
      let metricsOpExRatioResult1 = metricsOpExRatioResult * 100;
      let metricsOpExRatioFinalResult =
        metricsOpExRatioResult1 / parseFloat(annually_rental_total);

      this.setState({
        metrics_dscr: parseFloat(metricsDscrFinalResult).toFixed(2) || 0.0,
        metrics_op_ex_ratio: Math.round(metricsOpExRatioFinalResult) || 0,
      });

      this.stepper.next();
    }
  };

  handleOnBreakevenAnalysisStep1Submit = () => {
    const isBreakevenAnalysisSub1FieldsValid =
      this.breakevenAnalysisSub1Validate();
    if (!isBreakevenAnalysisSub1FieldsValid) {
      console.log("You may enter wrong value, please try again");
    } else {
      const {
        financing_type,
        breakeven_discount_rate,
        down_payment_number,
        earnest_money_deposit,
        renovation_cost,
        financing_reserve,
        closing_cost_total,
        profit_pm_reserve,
        profit_noi,
        purchange_price,
      } = this.state;

      var profitPmReserveValue = profit_pm_reserve.toString().replace(/,/g, "");
      var profitNoiValue = profit_noi.toString().replace(/,/g, "");
      // console.log('profit_pm_reserve',profitNoiValue)

      // let downPaymentNumberResult = parseFloat(down_payment_number) - parseFloat(earnest_money_deposit);
      if (financing_type == "loan") {
        var downPaymentNumberResult =
          parseFloat(down_payment_number) - parseFloat(earnest_money_deposit);
      } else {
        var downPaymentNumberResult = parseFloat(purchange_price);
      }
      let closingWithoutEarnest =
        parseFloat(closing_cost_total) - parseFloat(earnest_money_deposit);
      var initialInvestment =
        downPaymentNumberResult +
        parseFloat(renovation_cost) +
        parseFloat(financing_reserve) +
        closingWithoutEarnest +
        parseFloat(profitPmReserveValue);
      let initialInvestmentFinalResult =
        parseFloat(initialInvestment).toFixed(2);
      let breakevenYearstoBreakevenResult =
        parseFloat(initialInvestmentFinalResult) / parseFloat(profitNoiValue);

      let noi = parseFloat(profitNoiValue) * 100;
      let breakevenReturnOnInvestmentResult =
        parseFloat(noi) / parseFloat(initialInvestmentFinalResult);

      if (financing_type == "loan") {
        let r = parseFloat(breakeven_discount_rate) / 100 + 1;
        let arr = [];
        let i = 1;
        for (i = 1; i <= 5; i++) {
          let rPow = Math.pow(r, i);
          let cashFlow = parseFloat(profitNoiValue) / rPow;
          if (i == 5) {
            let cashFlowFive =
              (parseFloat(purchange_price) * r + parseFloat(profitNoiValue)) /
              rPow;
            arr.push(cashFlowFive.toFixed(2));
          } else {
            arr.push(cashFlow.toFixed(2));
          }
        }

        let total = 0;
        arr.forEach(function (item) {
          total = Number(total) + Number(item);
        });

        var finalNpv = total - parseFloat(initialInvestmentFinalResult);
      } else {
        var finalNpv = 0;
      }

      this.setState({
        breakeven_initial_investment:
          parseFloat(initialInvestment)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0.0,
        breakeven_yearsto_breakeven:
          Math.round(breakevenYearstoBreakevenResult) || 0,
        breakeven_return_on_investment:
          parseFloat(breakevenReturnOnInvestmentResult).toFixed(2) || 0.0,
        breakeven_np_value:
          parseFloat(finalNpv)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0.0,
      });

      this.BreakevenAnalysisInnerStepper.next();
    }
  };

  handleOnBreakevenAnalysisStep2Submit = () => {
    const {
      financing_type,
      breakeven_return_on_investment,
      profit_noi,
      number_of_investors,
      breakeven_np_value,
      breakeven_initial_investment,
      metrics_dscr,
      breakeven_yearsto_breakeven,
      metrics_op_ex_ratio,
    } = this.state;
    var profitNoiValue = profit_noi.toString().replace(/,/g, "");
    var breakevenNpValue = breakeven_np_value.toString().replace(/,/g, "");
    var breakevenInitialInvestmentValue = breakeven_initial_investment
      .toString()
      .replace(/,/g, "");

    //G15
    var monthlyNoi = parseFloat(profitNoiValue) / 12;
    var monthlyNoiPerInvester = monthlyNoi / parseFloat(number_of_investors);

    //G22
    var g22 = parseFloat(breakeven_return_on_investment);
    var v1 = parseFloat(g22 / 100).toFixed(2);
    var v1_cash = parseFloat(g22 / 100).toFixed(4);
    //G15
    var v2 = monthlyNoiPerInvester;
    //G24
    var v3 = parseFloat(breakevenNpValue);
    //G20
    var v4 = parseFloat(breakevenInitialInvestmentValue);

    //G5
    var v5 = parseFloat(metrics_dscr).toFixed(2);
    //G21
    var v6 = parseFloat(breakeven_yearsto_breakeven);
    //G6
    var g6 = parseFloat(metrics_op_ex_ratio);
    var v7 = parseFloat(g6 / 100).toFixed(2);

    //H15
    var v8 = monthlyNoiPerInvester;

    if (financing_type == "loan") {
      if (
        v1 >= 0.08 &&
        v2 > 125 &&
        v3 > v4 &&
        v5 > 1.2 &&
        v6 < 15 &&
        v7 < 0.5
      ) {
        this.setState({ pass_or_invest: "Potential" });
      } else {
        if (v8 > 99 && v1 > 0.1) {
          this.setState({ pass_or_invest: "Potential" });
        } else {
          this.setState({ pass_or_invest: "Pass" });
        }
      }
    } else {
      if (v1_cash >= 0.0799 && v2 > 125 && v5 > 1.2 && v6 < 15 && v7 < 0.5) {
        this.setState({ pass_or_invest: "Potential" });
      } else {
        if (v8 > 99 && v1 > 0.1) {
          this.setState({ pass_or_invest: "Potential" });
        } else {
          this.setState({ pass_or_invest: "Pass" });
        }
      }
    }

    this.stepper.next();
  };

  handleOnChangeModules = (event) => {
    const { name, value } = event.target;
    // console.log('name---', name)
    // console.log('value---', value)
    this.setState({ [name]: value });
  };

  onChangeValue = (event) => {
    // console.log("event.target.value",event.target.value);
    this.setState({ financing_type: event.target.value });
  };

  handleOnGenerateReport = () => {
    // console.log("maximumReportAttempts---", this.props.maximumReportAttempts)
    console.log("this.state---", this.state);
    const {
      actions: { generateReport },
    } = this.props;
    generateReport({
      _token: "J1RHvOM1M1ieJZLBVSumlfvRJJDFL2A4VBjGL38W",
      api_status: this.props.maximumReportAttempts,
      ...this.state,
    });
  };

  handleOnChangeInvestmentText(i, event) {
    let investors_investment_percentage = [
      ...this.state.investors_investment_percentage,
    ];
    investors_investment_percentage[i] = event.target.value;
    this.setState({ investors_investment_percentage });
  }

  handleOnChangeIncomeAnnuallyText(idx, event) {
    let rental_income_annually = [...this.state.rental_income_annually];
    rental_income_annually[idx] = event.target.value.replace(",", "");
    this.setState({ rental_income_annually });
  }

  handleOnChangeIncomeMonthlyText(idx, event) {
    let rental_income_monthly = [...this.state.rental_income_monthly];
    // rental_income_monthly[idx] = event.target.value.replace(',', '');
    var strValue = event.target.value.toString().replace(/,/g, "");
    rental_income_monthly[idx] = strValue;
    // console.log("strValue---", strValue)
    this.setState({ rental_income_monthly });
  }

  handleOnClickResendEmail = () => {
    // console.log("deded")
    const userId = localStorage.getItem("logged_user_ID");
    const {
      actions: { resendUserVerifyEmail },
    } = this.props;
    resendUserVerifyEmail({ userId });
  };

  render() {
    // console.log("maximumReportAttempts---", this.props.maximumReportAttempts)
    const {
      isVerifyModalOpen,
      rentalIncomeTextField,
      errors,
      financing_type,
      pincode,
      address,
      report_name,
      number_of_investors,
      purchange_price,
      financing_amortization_term,
      financing_interest_rate,
      financing_down_payment,
      financing_amount_financed,
      financing_reserve,
      filtered,
      loadingData,
      disabledSearchAddressBox,
      filteredDisplay,
      other_rental_income_monthly,
      other_rental_income_annually,
      renovation_cost,
      earnest_money_deposit,
      property_inspection,
      real_estate_transfer_tax,
      title_insurance_fee,
      origination_fee,
      appraisal_fees,
      prop_manage_fee_annually,
      prop_manage_fee_monthly,
      water_bill_annually,
      water_bill_monthly,
      taxes_annually,
      taxes_monthly,
      utilities_annually,
      utilities_monthly,
      trash_pest_landscaping_annually,
      trash_pest_landscaping_monthly,
      insurance_annually,
      insurance_monthly,
      profit_gross_rental_income,
      profit_mortgage,
      vacancy_plus_cap_ex,
      profit_operating_expenses,
      profit_pm_reserve,
      profit_noi,
      profit_cap_rate,
      metrics_one_persentage_rule,
      metrics_dscr,
      metrics_op_ex_ratio,
      breakeven_discount_rate,
      breakeven_initial_investment,
      breakeven_yearsto_breakeven,
      breakeven_return_on_investment,
      breakeven_np_value,
      monthly_rental_total,
      annually_rental_total,
      down_payment_number,
      financing_total,
      closing_cost_total,
      expences_monthly_total,
      expences_annually_total,
      pass_or_invest,
      investors_investment_percentage,
      rental_income_monthly,
      rental_income_annually,
      financing_loan_type,
    } = this.state;

    const {
      rentalIncomeModalOpen,
      financingModalOpen,
      financingTwoModalOpen,
      closingCostsModalOpen,
      operatingExpensesModalOpen,
      annualProfitModalOpen,
      metricsModalOpen,
      breakevenAnalysisModalOpen,
      breakevenAnalysisTwoModalOpen,
      postalAddressesData,
      maximumReportAttempts,
    } = this.props;

    let { fetching } = this.props;
    // console.log("maximumReportAttempts----", maximumReportAttempts);
    const userLogged = localStorage.getItem("logged_user_auth_token");

    var investorArray = [];

    for (var i = 0; i < number_of_investors; i++) {
      investorArray.push(
        <div className="col-md-6 m-t-20">
          <div className="investor-form">
            <input
              type="text"
              className={`form-input form-control ${
                this.state[`investors_investment_percentage${i}`] &&
                "error-text-border"
              }`}
              placeholder={`Investor ${i + 1}`}
              name={`investors_investment_percentage${i}`}
              value={investors_investment_percentage[i] || ""}
              onChange={this.handleOnChangeInvestmentText.bind(this, i)}
            />
            <p className="rental-income-line"></p>
            <p className="rental-income-icon">%</p>
          </div>
        </div>
      );
    }
    return (
      <>
        <div id="stepperMain" className="bs-stepper padding-top-bot">
          <div className="container">
            <div className="stepper-main">
              <div className="row">
                <div className="col-md-3">
                  <div className="sub-step-section">
                    <div className="stepper-header">
                      <StepFormButton
                        stepTargetId="#RentalIncome"
                        stepText="Rental Income"
                      />
                      <StepFormButton
                        stepTargetId="#Financing"
                        stepText="Financing"
                      />
                      <StepFormButton
                        stepTargetId="#ClosingCosts"
                        stepText="Closing Costs"
                      />
                      <StepFormButton
                        stepTargetId="#OperatingExpenses"
                        stepText="Operating Expenses"
                      />
                      <StepFormButton
                        stepTargetId="#AnnualProfit"
                        stepText="Annual Profit"
                      />
                      <StepFormButton
                        stepTargetId="#Metrics"
                        stepText="Metrics"
                      />
                      <StepFormButton
                        stepTargetId="#BreakevenAnalysis"
                        stepText="Breakeven Analysis"
                      />
                      <StepFormButton
                        stepClassName="display-none"
                        stepTargetId="#Result"
                        stepText="Result"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="sub-step-section">
                    <div className="bs-stepper-content">
                      <div id="RentalIncome" className="content">
                        <div id="RentalIncomeStepper" className="bs-stepper">
                          <div className="bs-stepper-header display-none">
                            <StepFormButton stepTargetId="#RentalIncomeSub1" />
                            <StepFormButton stepTargetId="#RentalIncomeSub2" />
                            <StepFormButton stepTargetId="#RentalIncomeSub3" />
                          </div>
                          <div className="bs-stepper-content">
                            <div id="RentalIncomeSub1" className="content">
                              <div className="rental-income-main-section">
                                <p className="step-text">
                                  Step <span>1</span> of 3
                                </p>
                                <h2 className="step-title">RENTAL INCOME</h2>
                                <div className="step-form">
                                  <div className="form-group">
                                    <InputText
                                      singleLableText="Report name (you can find your saved reports in your homepage)"
                                      singleInputType="text"
                                      singleInputClass={`${
                                        errors.report_name &&
                                        "error-text-border"
                                      }`}
                                      singleInputPlaceholder="Report Name"
                                      singleInputName="report_name"
                                      singleInputValue={report_name || ""}
                                      singleInputonChangeFunc={
                                        this.handleOnChangeInput
                                      }
                                      errorMsg={errors.report_name}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label className="form-lable">
                                      How many investors are investing?
                                    </label>
                                    <select
                                      className="form-input form-control"
                                      id="number_of_investors"
                                      name="number_of_investors"
                                      onChange={this.handleOnChangeModules}
                                    >
                                      <option value="1">One</option>
                                      <option value="2">Two</option>
                                      <option value="3">Three</option>
                                      <option value="4">Four</option>
                                      <option value="5">Five</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <StepFormNextPrevButton
                                buttonActions={["next"]}
                                nextButtonOnClickHandler={
                                  this.handleOnRentalIncomeSubmit
                                }
                                nextButtonImage={arrowRight}
                                prevChildren={
                                  <i
                                    className="fa fa-angle-left"
                                    aria-hidden="true"
                                  ></i>
                                }
                                nextButtonText="Next"
                              />
                            </div>
                            <div id="RentalIncomeSub2" className="content">
                              <div className="rental-income-main-section">
                                <p className="step-text">
                                  Step <span>2</span> of 3
                                </p>
                                <h2 className="step-title">RENTAL INCOME</h2>
                                <div className="step-form">
                                  <div className="form-group">
                                    <label className="form-lable">
                                      Add percentage of investment per investor
                                    </label>
                                    <div className="row">{investorArray}</div>
                                    <div className="m-t-10">
                                      <span className="error-msg-no-position">
                                        {errors.investorArrayError}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <StepFormNextPrevButton
                                buttonActions={["prev", "next"]}
                                prevButtonOnClickHandler={() =>
                                  this.RentalIncomeInnerStepper.to(1)
                                }
                                nextButtonOnClickHandler={
                                  this.handleOnRentalIncomeStep2Submit
                                }
                                nextButtonImage={arrowRight}
                                prevChildren={
                                  <i
                                    className="fa fa-angle-left"
                                    aria-hidden="true"
                                  ></i>
                                }
                                nextButtonText="Next"
                              />
                            </div>
                            <div id="RentalIncomeSub3" className="content">
                              <div className="rental-income-main-section">
                                <p className="step-text">
                                  Step <span>3</span> of 3
                                </p>
                                <h2 className="step-title">RENTAL INCOME</h2>
                                <div className="step-form">
                                  <div className="">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <label className="form-lable">
                                          Unit
                                        </label>
                                      </div>
                                      <div className="col-md-6 text-right">
                                        <label
                                          className="form-lable step-use-link-color"
                                          onClick={rentalIncomeModalOpen}
                                        >
                                          Useful tip
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="m-b-10">
                                    <span className="error-msg-no-position">
                                      {errors.incomeMonthlyError}
                                    </span>
                                  </div>
                                  {rentalIncomeTextField.map((val, idx) => {
                                    return (
                                      <div
                                        className={`RentalIncomeSub-${idx}`}
                                        key={idx}
                                      >
                                        <div className="form-group position-relative">
                                          <div className="row">
                                            <div className="col-md-6">
                                              <InputTextWithIcon
                                                inputPlaceholder="Monthly Rent"
                                                inputType="text"
                                                inputTextIcon="$"
                                                inputClass={`${
                                                  this.state[
                                                    `rental_income_monthly${idx}`
                                                  ] && "error-text-border"
                                                }`}
                                                currencyInput={true}
                                                inputName={`rental_income_monthly${idx}`}
                                                inputValue={
                                                  rental_income_monthly[idx] ||
                                                  ""
                                                }
                                                inputChangeFunc={this.handleOnChangeIncomeMonthlyText.bind(
                                                  this,
                                                  idx
                                                )}
                                                onFocusText={() => {
                                                  let rentalIncomeAnnuallyTotal =
                                                    parseFloat(
                                                      rental_income_monthly[idx]
                                                    ) * 12;
                                                  let rental_income_annually = [
                                                    ...this.state
                                                      .rental_income_annually,
                                                  ];
                                                  rental_income_annually[idx] =
                                                    Math.round(
                                                      parseFloat(
                                                        rentalIncomeAnnuallyTotal
                                                      ).toFixed(2)
                                                    )
                                                      .toString()
                                                      .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                      );
                                                  this.setState({
                                                    rental_income_annually,
                                                  });
                                                }}
                                              />
                                            </div>
                                            <div className="col-md-6">
                                              <InputTextWithIcon
                                                inputPlaceholder="Annual Income"
                                                inputType="text"
                                                inputTextIcon="$"
                                                inputName={`rental_income_annually${idx}`}
                                                inputValue={
                                                  rental_income_annually[idx] ||
                                                  ""
                                                }
                                                inputChangeFunc={this.handleOnChangeIncomeAnnuallyText.bind(
                                                  this,
                                                  idx
                                                )}
                                                disabled={true}
                                              />
                                            </div>
                                          </div>

                                          <button
                                            className="btn btn-primary remove-text-field-btn"
                                            onClick={() =>
                                              this.removeClick(idx)
                                            }
                                          >
                                            <i
                                              className="fa fa-minus"
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                        </div>
                                      </div>
                                    );
                                  })}
                                  <div className="abb-text-field-main">
                                    <button
                                      className="btn btn-primary abb-text-field-btn"
                                      onClick={this.addRentalIncomeTextField}
                                    >
                                      <i
                                        className="fa fa-plus"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                  <div className="form-group">
                                    <label className="form-lable">
                                      Rent for garage / parking
                                    </label>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <InputTextWithIcon
                                          inputPlaceholder="Monthly Rent"
                                          inputType="text"
                                          inputTextIcon="$"
                                          inputClass={`${
                                            errors.other_rental_income_monthly &&
                                            "error-text-border"
                                          }`}
                                          currencyInput={true}
                                          inputName="other_rental_income_monthly"
                                          inputValue={
                                            other_rental_income_monthly || ""
                                          }
                                          inputChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={
                                            errors.other_rental_income_monthly
                                          }
                                          onFocusText={() => {
                                            let other_rental_income_annually =
                                              parseFloat(
                                                other_rental_income_monthly
                                              ) * 12;
                                            let otherRentalIncomeAnnually =
                                              Math.round(
                                                parseFloat(
                                                  other_rental_income_annually
                                                ).toFixed(2)
                                              )
                                                .toString()
                                                .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                                );
                                            // console.log("ddd---",ddd)
                                            this.setState({
                                              other_rental_income_annually:
                                                otherRentalIncomeAnnually,
                                            });
                                          }}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <InputTextWithIcon
                                          inputPlaceholder="Annual Income"
                                          inputType="text"
                                          inputTextIcon="$"
                                          inputName="other_rental_income_annually"
                                          inputValue={
                                            other_rental_income_annually || ""
                                          }
                                          inputChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={
                                            errors.other_rental_income_annually
                                          }
                                          disabled={true}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <StepFormNextPrevButton
                                buttonActions={["prev", "next"]}
                                prevButtonOnClickHandler={() =>
                                  this.RentalIncomeInnerStepper.to(2)
                                }
                                // nextButtonOnClickHandler = {() => this.stepper.next()}
                                nextButtonOnClickHandler={
                                  this.handleOnRentalIncomeStep3Submit
                                }
                                nextButtonImage={arrowRight}
                                prevChildren={
                                  <i
                                    className="fa fa-angle-left"
                                    aria-hidden="true"
                                  ></i>
                                }
                                nextButtonText="Next"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="Financing" className="content">
                        <div id="FinancingStepper" className="bs-stepper">
                          <div className="bs-stepper-header display-none">
                            <StepFormButton stepTargetId="#FinancingSub1" />
                            <StepFormButton stepTargetId="#FinancingSub2" />
                            <StepFormButton stepTargetId="#FinancingSub3" />
                            <StepFormButton stepTargetId="#FinancingSub4" />
                          </div>
                          <div className="bs-stepper-content">
                            <div id="FinancingSub1" className="content">
                              <div className="rental-income-main-section">
                                <p className="step-text">
                                  Step <span>1</span> of 4
                                </p>
                                <h2 className="step-title">Financing</h2>
                                <div className="step-form">
                                  <div className="form-group">
                                    <label className="form-lable">
                                      Purchase Price
                                    </label>
                                    <InputTextWithIcon
                                      currencyInput={true}
                                      inputPlaceholder="Purchase Price"
                                      inputType="text"
                                      inputTextIcon="$"
                                      inputClass={`${
                                        errors.purchange_price &&
                                        "error-text-border"
                                      }`}
                                      inputName="purchange_price"
                                      inputValue={purchange_price || ""}
                                      inputChangeFunc={this.handleOnChangeInput}
                                      errorMsg={errors.purchange_price}
                                    />
                                  </div>
                                </div>
                              </div>
                              <StepFormNextPrevButton
                                buttonActions={["prev", "next"]}
                                prevButtonOnClickHandler={() =>
                                  this.stepper.to(1)
                                }
                                // nextButtonOnClickHandler = {this.handleOnFinancingSubmit}
                                nextButtonOnClickHandler={() => {
                                  const isPurchasePriceFieldsValid =
                                    this.purchasePriceValidate();
                                  if (!isPurchasePriceFieldsValid) {
                                    console.log(
                                      "You may enter wrong value, please try again"
                                    );
                                  } else {
                                    this.FinancingInnerStepper.next();
                                  }
                                }}
                                nextButtonImage={arrowRight}
                                prevChildren={
                                  <i
                                    className="fa fa-angle-left"
                                    aria-hidden="true"
                                  ></i>
                                }
                                nextButtonText="Next"
                              />
                            </div>
                            <div id="FinancingSub2" className="content">
                              <div className="rental-income-main-section">
                                <p className="step-text">
                                  Step <span>2</span> of 4
                                </p>
                                <h2 className="step-title">Financing</h2>
                                <div className="text-center m-b-10">
                                  <span className="error-msg-no-position">
                                    {errors.financing_type}
                                  </span>
                                </div>
                                <div className="step-form">
                                  <div className="row financing-form-loan-cash">
                                    <div className="col-md-12 financing-form-loan">
                                      <div
                                        className="btn-group paymentBtnGroup btn-group-justified"
                                        data-toggle="buttons"
                                      >
                                        <label
                                          className="btn financing-form loan-box"
                                          onClick={this.onChangeValue}
                                        >
                                          <div className="loan-img"></div>
                                          <input
                                            type="radio"
                                            name="options"
                                            value="loan"
                                          />
                                          <div className="financing-text">
                                            Loan
                                          </div>
                                        </label>
                                        <label
                                          className="btn financing-form cash-box"
                                          onClick={this.onChangeValue}
                                        >
                                          <div className="cash-img"></div>
                                          <input
                                            type="radio"
                                            name="options"
                                            value="cash"
                                          />
                                          <div className="financing-text">
                                            Cash
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <StepFormNextPrevButton
                                buttonActions={["prev", "next"]}
                                prevButtonOnClickHandler={() =>
                                  this.FinancingInnerStepper.to(1)
                                }
                                // nextButtonOnClickHandler = {() => this.FinancingInnerStepper.next()}
                                nextButtonOnClickHandler={
                                  this.handleOnFinancingStep2Submit
                                }
                                nextButtonImage={arrowRight}
                                prevChildren={
                                  <i
                                    className="fa fa-angle-left"
                                    aria-hidden="true"
                                  ></i>
                                }
                                nextButtonText="Next"
                              />
                            </div>
                            <div id="FinancingSub3" className="content">
                              <div className="rental-income-main-section">
                                <p className="step-text">
                                  Step <span>3</span> of 4
                                </p>
                                <h2 className="step-title">Financing</h2>
                                <div className="step-form">
                                  <div className="form-group">
                                    <label className="form-lable">
                                      Loan Type
                                    </label>
                                    {/* <select className="form-input form-control" id="loantype">
																			<option>Residential</option>
																			<option>Commercial</option>
																		</select> */}
                                    <select
                                      className="form-input form-control"
                                      id="loantype"
                                      name="financing_loan_type"
                                      onChange={this.handleOnChangeModules}
                                    >
                                      <option value="residential">
                                        Residential
                                      </option>
                                      <option value="commercial">
                                        Commercial
                                      </option>
                                    </select>
                                  </div>
                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <label className="form-lable">
                                          Percent of a down payment are you
                                          making?
                                        </label>
                                        <InputTextWithIcon
                                          inputPlaceholder="e.g 10"
                                          inputType="text"
                                          inputTextIcon="%"
                                          inputClass={`${
                                            errors.financing_down_payment &&
                                            "error-text-border"
                                          }`}
                                          inputName="financing_down_payment"
                                          inputValue={
                                            financing_down_payment || ""
                                          }
                                          inputChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={
                                            errors.financing_down_payment
                                          }
                                          onFocusText={
                                            this.handleOnFocusDownPaymentInput
                                          }
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <InputText
                                          singleLableText="Amount financed"
                                          singleInputType="text"
                                          singleInputPlaceholder="0.00"
                                          disabled={true}
                                          singleInputName="financing_amount_financed"
                                          singleInputValue={
                                            financing_amount_financed || ""
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <label className="form-lable">
                                          Interest Rate
                                        </label>
                                        <InputTextWithIcon
                                          inputPlaceholder="e.g 5"
                                          inputType="text"
                                          inputTextIcon="%"
                                          inputClass={`${
                                            errors.financing_interest_rate &&
                                            "error-text-border"
                                          }`}
                                          inputName="financing_interest_rate"
                                          inputValue={
                                            financing_interest_rate || ""
                                          }
                                          inputChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={
                                            errors.financing_interest_rate
                                          }
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <div className="lable-main">
                                          <div className="lable1">
                                            <label className="form-lable">
                                              Amortization term (Months)
                                            </label>
                                          </div>
                                          <div className="lable2">
                                            <label
                                              className="form-lable step-use-link-color"
                                              onClick={financingModalOpen}
                                            >
                                              Useful tip
                                            </label>
                                          </div>
                                        </div>
                                        <input
                                          type="text"
                                          className={`form-input form-control ${
                                            errors.financing_amortization_term &&
                                            "error-text-border"
                                          }`}
                                          placeholder="e.g 300"
                                          name="financing_amortization_term"
                                          value={
                                            financing_amortization_term || ""
                                          }
                                          onChange={this.handleOnChangeInput}
                                        />
                                        <span className="error-msg">
                                          {errors.financing_amortization_term &&
                                            errors.financing_amortization_term}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <StepFormNextPrevButton
                                buttonActions={["prev", "next"]}
                                prevButtonOnClickHandler={() =>
                                  this.FinancingInnerStepper.to(2)
                                }
                                nextButtonOnClickHandler={
                                  this.handleOnFinancingStep3Submit
                                }
                                // nextButtonOnClickHandler = {() => this.FinancingInnerStepper.next()}
                                nextButtonImage={arrowRight}
                                prevChildren={
                                  <i
                                    className="fa fa-angle-left"
                                    aria-hidden="true"
                                  ></i>
                                }
                                nextButtonText="Next"
                              />
                            </div>
                            <div id="FinancingSub4" className="content">
                              <div className="rental-income-main-section">
                                <p className="step-text">
                                  Step <span>4</span> of 4
                                </p>
                                <h2 className="step-title">Financing</h2>
                                <div className="step-form">
                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <label className="form-lable">
                                          Renovation cost
                                        </label>
                                        <InputTextWithIcon
                                          currencyInput={true}
                                          inputPlaceholder="Renovation cost"
                                          inputType="text"
                                          inputTextIcon="$"
                                          inputClass={`${
                                            errors.renovation_cost &&
                                            "error-text-border"
                                          }`}
                                          inputName="renovation_cost"
                                          inputValue={renovation_cost || ""}
                                          inputChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={errors.renovation_cost}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <div className="lable-main">
                                          <div className="lable1">
                                            <label className="form-lable">
                                              Bank Reserve
                                            </label>
                                          </div>
                                          <div className="lable2">
                                            <label
                                              className="form-lable step-use-link-color"
                                              onClick={financingTwoModalOpen}
                                            >
                                              Useful tip
                                            </label>
                                          </div>
                                        </div>
                                        <InputTextWithIcon
                                          currencyInput={true}
                                          inputPlaceholder="0"
                                          inputType="text"
                                          inputTextIcon="$"
                                          inputClass={`${
                                            errors.financing_reserve &&
                                            "error-text-border"
                                          }`}
                                          inputName="financing_reserve"
                                          inputValue={financing_reserve || ""}
                                          inputChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={errors.financing_reserve}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <StepFormNextPrevButton
                                buttonActions={["prev", "next"]}
                                prevButtonOnClickHandler={() => {
                                  const { financing_type } = this.state;
                                  if (financing_type == "loan") {
                                    this.FinancingInnerStepper.to(3);
                                  } else {
                                    this.FinancingInnerStepper.to(2);
                                  }
                                }}
                                // nextButtonOnClickHandler = {() => this.stepper.next()}
                                nextButtonOnClickHandler={
                                  this.handleOnFinancingStep4Submit
                                }
                                nextButtonImage={arrowRight}
                                prevChildren={
                                  <i
                                    className="fa fa-angle-left"
                                    aria-hidden="true"
                                  ></i>
                                }
                                nextButtonText="Next"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="ClosingCosts" className="content">
                        <div id="ClosingCostsStepper" className="bs-stepper">
                          <div className="bs-stepper-header display-none">
                            <StepFormButton stepTargetId="#ClosingCostsSub1" />
                          </div>
                          <div className="bs-stepper-content">
                            <div id="ClosingCostsSub1" className="content">
                              <div className="rental-income-main-section">
                                <p className="step-text">
                                  Step <span>1</span> of 1
                                </p>
                                <p
                                  className="step-use-link"
                                  onClick={closingCostsModalOpen}
                                >
                                  Useful tip
                                </p>
                                <h2 className="step-title">Closing Costs</h2>
                                <div className="step-form">
                                  <div className="form-group display-none">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <label className="form-lable">
                                          Earnest money deposit (2%)
                                        </label>
                                        <InputTextWithIcon
                                          inputPlaceholder="00"
                                          inputType="text"
                                          inputTextIcon="$"
                                          inputClass={`${
                                            errors.earnest_money_deposit &&
                                            "error-text-border"
                                          }`}
                                          inputName="earnest_money_deposit"
                                          inputValue={
                                            earnest_money_deposit || ""
                                          }
                                          inputChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={
                                            errors.earnest_money_deposit
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <label className="form-lable">
                                          Property Inspection
                                        </label>
                                        <InputTextWithIcon
                                          currencyInput={true}
                                          inputPlaceholder="00"
                                          inputType="text"
                                          inputTextIcon="$"
                                          inputClass={`${
                                            errors.property_inspection &&
                                            "error-text-border"
                                          }`}
                                          inputName="property_inspection"
                                          inputValue={property_inspection || ""}
                                          inputChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={errors.property_inspection}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <InputText
                                          currencyInput={true}
                                          singleLableText="Real estate transfer tax (2%)"
                                          singleInputType="text"
                                          singleInputPlaceholder="00"
                                          singleInputClass={`${
                                            errors.real_estate_transfer_tax &&
                                            "error-text-border"
                                          }`}
                                          singleInputName="real_estate_transfer_tax"
                                          singleInputValue={
                                            real_estate_transfer_tax || ""
                                          }
                                          singleInputonChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={
                                            errors.real_estate_transfer_tax
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <div className="row">
                                      <div
                                        className={`${
                                          financing_type == "loan"
                                            ? "col-md-6"
                                            : "col-md-12"
                                        }`}
                                      >
                                        <InputText
                                          currencyInput={true}
                                          singleLableText="Title Insurance Fee"
                                          singleInputType="text"
                                          singleInputPlaceholder="00"
                                          singleInputClass={`${
                                            errors.title_insurance_fee &&
                                            "error-text-border"
                                          }`}
                                          singleInputName="title_insurance_fee"
                                          singleInputValue={
                                            title_insurance_fee || ""
                                          }
                                          singleInputonChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={errors.title_insurance_fee}
                                        />
                                      </div>
                                      <div
                                        className={`${
                                          financing_type == "loan"
                                            ? "col-md-6"
                                            : "col-md-6 display-none"
                                        }`}
                                      >
                                        <label className="form-lable">
                                          Origination fee (1%)
                                        </label>
                                        <InputTextWithIcon
                                          currencyInput={true}
                                          inputPlaceholder="00"
                                          inputType="text"
                                          inputTextIcon="$"
                                          inputClass={`${
                                            errors.origination_fee &&
                                            "error-text-border"
                                          }`}
                                          inputName="origination_fee"
                                          inputValue={origination_fee || ""}
                                          inputChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={errors.origination_fee}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-12">
                                        <label className="form-lable">
                                          Appraisal fees
                                        </label>
                                        <InputTextWithIcon
                                          currencyInput={true}
                                          inputPlaceholder="00"
                                          inputType="text"
                                          inputTextIcon="$"
                                          inputClass={`${
                                            errors.appraisal_fees &&
                                            "error-text-border"
                                          }`}
                                          inputName="appraisal_fees"
                                          inputValue={appraisal_fees || ""}
                                          inputChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={errors.appraisal_fees}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <StepFormNextPrevButton
                                buttonActions={["prev", "next"]}
                                prevButtonOnClickHandler={() =>
                                  this.stepper.to(2)
                                }
                                // nextButtonOnClickHandler = {() => this.stepper.next()}
                                nextButtonOnClickHandler={
                                  this.handleOnClosingCostsSubmit
                                }
                                nextButtonImage={arrowRight}
                                prevChildren={
                                  <i
                                    className="fa fa-angle-left"
                                    aria-hidden="true"
                                  ></i>
                                }
                                nextButtonText="Next"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="OperatingExpenses" className="content">
                        <div
                          id="OperatingExpensesStepper"
                          className="bs-stepper"
                        >
                          <div className="bs-stepper-header display-none">
                            <StepFormButton stepTargetId="#OperatingExpensesSub1" />
                          </div>
                          <div className="bs-stepper-content">
                            <div id="OperatingExpensesSub1" className="content">
                              <div className="rental-income-main-section">
                                <p className="step-text">
                                  Step <span>1</span> of 1
                                </p>
                                <p
                                  className="step-use-link"
                                  onClick={operatingExpensesModalOpen}
                                >
                                  Useful tip
                                </p>
                                <h2 className="step-title">
                                  Operating Expenses
                                </h2>
                                <div className="step-form">
                                  <div className="form-group display-none">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <InputText
                                          singleLableText="Property management fee annually (10%)"
                                          singleInputType="text"
                                          singleInputPlaceholder="0"
                                          singleInputClass={`${
                                            errors.prop_manage_fee_annually &&
                                            "error-text-border"
                                          }`}
                                          singleInputName="prop_manage_fee_annually"
                                          singleInputValue={
                                            prop_manage_fee_annually || ""
                                          }
                                          singleInputonChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={
                                            errors.prop_manage_fee_annually
                                          }
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <InputText
                                          singleLableText="Water Bill Monthly"
                                          singleInputType="text"
                                          singleInputClass={`${
                                            errors.prop_manage_fee_monthly &&
                                            "error-text-border"
                                          }`}
                                          singleInputName="prop_manage_fee_monthly"
                                          singleInputValue={
                                            prop_manage_fee_monthly || ""
                                          }
                                          singleInputonChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={
                                            errors.prop_manage_fee_monthly
                                          }
                                          disabled={true}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <InputText
                                          currencyInput={true}
                                          singleLableText="Water Bill Annually"
                                          singleInputType="text"
                                          singleInputPlaceholder="Water Bill Annually"
                                          singleInputClass={`${
                                            errors.water_bill_annually &&
                                            "error-text-border"
                                          }`}
                                          singleInputName="water_bill_annually"
                                          singleInputValue={
                                            water_bill_annually || ""
                                          }
                                          singleInputonChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={errors.water_bill_annually}
                                          singleInputOnBlurFunc={() => {
                                            let waterBillMonthlyCal =
                                              water_bill_annually / 12;
                                            this.setState({
                                              water_bill_monthly:
                                                parseFloat(
                                                  waterBillMonthlyCal
                                                ).toFixed(2),
                                            });
                                          }}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <InputText
                                          currencyInput={true}
                                          singleLableText="Water Bill Monthly"
                                          singleInputType="text"
                                          singleInputClass={`${
                                            errors.water_bill_monthly &&
                                            "error-text-border"
                                          }`}
                                          singleInputName="water_bill_monthly"
                                          singleInputValue={
                                            water_bill_monthly || ""
                                          }
                                          singleInputonChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={errors.water_bill_monthly}
                                          disabled={true}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <InputText
                                          currencyInput={true}
                                          singleLableText="Taxes Annually"
                                          singleInputType="text"
                                          singleInputPlaceholder="Taxes Annually"
                                          singleInputClass={`${
                                            errors.taxes_annually &&
                                            "error-text-border"
                                          }`}
                                          singleInputName="taxes_annually"
                                          singleInputValue={
                                            taxes_annually || ""
                                          }
                                          singleInputonChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={errors.taxes_annually}
                                          singleInputOnBlurFunc={() => {
                                            let taxesMonthlyCal =
                                              taxes_annually / 12;
                                            this.setState({
                                              taxes_monthly:
                                                parseFloat(
                                                  taxesMonthlyCal
                                                ).toFixed(2),
                                            });
                                          }}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <InputText
                                          currencyInput={true}
                                          singleLableText="Taxes monthly"
                                          singleInputType="text"
                                          singleInputClass={`${
                                            errors.taxes_monthly &&
                                            "error-text-border"
                                          }`}
                                          singleInputName="taxes_monthly"
                                          singleInputValue={taxes_monthly || ""}
                                          singleInputonChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={errors.taxes_monthly}
                                          disabled={true}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <InputText
                                          currencyInput={true}
                                          singleLableText="Utilities Annually"
                                          singleInputType="text"
                                          singleInputPlaceholder="For e.g (300)"
                                          singleInputClass={`${
                                            errors.utilities_annually &&
                                            "error-text-border"
                                          }`}
                                          singleInputName="utilities_annually"
                                          singleInputValue={
                                            utilities_annually || ""
                                          }
                                          singleInputonChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={errors.utilities_annually}
                                          singleInputOnBlurFunc={() => {
                                            let utilitiesMonthlyCal =
                                              utilities_annually / 12;
                                            this.setState({
                                              utilities_monthly:
                                                parseFloat(
                                                  utilitiesMonthlyCal
                                                ).toFixed(2),
                                            });
                                          }}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <InputText
                                          currencyInput={true}
                                          singleLableText="Utilities Monthly"
                                          singleInputType="text"
                                          singleInputClass={`${
                                            errors.utilities_monthly &&
                                            "error-text-border"
                                          }`}
                                          singleInputName="utilities_monthly"
                                          singleInputValue={
                                            utilities_monthly || ""
                                          }
                                          singleInputonChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={errors.utilities_monthly}
                                          disabled={true}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <InputText
                                          currencyInput={true}
                                          singleLableText="Trash Removal/Pest Control/Landscaping Annually"
                                          singleInputType="text"
                                          singleInputPlaceholder="For e.g (300)"
                                          singleInputClass={`${
                                            errors.trash_pest_landscaping_annually &&
                                            "error-text-border"
                                          }`}
                                          singleInputName="trash_pest_landscaping_annually"
                                          singleInputValue={
                                            trash_pest_landscaping_annually ||
                                            ""
                                          }
                                          singleInputonChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={
                                            errors.trash_pest_landscaping_annually
                                          }
                                          singleInputOnBlurFunc={() => {
                                            let trashPestLandscapingMonthlyCal =
                                              trash_pest_landscaping_annually /
                                              12;
                                            this.setState({
                                              trash_pest_landscaping_monthly:
                                                parseFloat(
                                                  trashPestLandscapingMonthlyCal
                                                ).toFixed(2),
                                            });
                                          }}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <InputText
                                          currencyInput={true}
                                          singleLableText="Trash Removal/ Pest Control/ Landscaping Monthly"
                                          singleInputType="text"
                                          singleInputClass={`${
                                            errors.trash_pest_landscaping_monthly &&
                                            "error-text-border"
                                          }`}
                                          singleInputName="trash_pest_landscaping_monthly"
                                          singleInputValue={
                                            trash_pest_landscaping_monthly || ""
                                          }
                                          singleInputonChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={
                                            errors.trash_pest_landscaping_monthly
                                          }
                                          disabled={true}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <InputText
                                          currencyInput={true}
                                          singleLableText="Insurance annually"
                                          singleInputType="text"
                                          singleInputPlaceholder="For e.g (300)"
                                          singleInputClass={`${
                                            errors.insurance_annually &&
                                            "error-text-border"
                                          }`}
                                          singleInputName="insurance_annually"
                                          singleInputValue={
                                            insurance_annually || ""
                                          }
                                          singleInputonChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={errors.insurance_annually}
                                          singleInputOnBlurFunc={() => {
                                            let insuranceMonthlyCal =
                                              insurance_annually / 12;
                                            this.setState({
                                              insurance_monthly:
                                                parseFloat(
                                                  insuranceMonthlyCal
                                                ).toFixed(2),
                                            });
                                          }}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <InputText
                                          currencyInput={true}
                                          singleLableText="Insurance monthly"
                                          singleInputType="text"
                                          singleInputClass={`${
                                            errors.insurance_monthly &&
                                            "error-text-border"
                                          }`}
                                          singleInputName="insurance_monthly"
                                          singleInputValue={
                                            insurance_monthly || ""
                                          }
                                          singleInputonChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={errors.insurance_monthly}
                                          disabled={true}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <StepFormNextPrevButton
                                buttonActions={["prev", "next"]}
                                prevButtonOnClickHandler={() =>
                                  this.stepper.to(3)
                                }
                                // nextButtonOnClickHandler = {() => this.stepper.next()}
                                nextButtonOnClickHandler={
                                  this.handleOnOperatingExpensesSubmit
                                }
                                nextButtonImage={arrowRight}
                                prevChildren={
                                  <i
                                    className="fa fa-angle-left"
                                    aria-hidden="true"
                                  ></i>
                                }
                                nextButtonText="Next"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="AnnualProfit" className="content">
                        <div id="AnnualProfitStepper" className="bs-stepper">
                          <div className="bs-stepper-header display-none">
                            <StepFormButton stepTargetId="#AnnualProfitSub1" />
                          </div>
                          <div className="bs-stepper-content">
                            <div id="AnnualProfitSub1" className="content">
                              <div className="rental-income-main-section">
                                <p className="step-text">
                                  Step <span>1</span> of 1
                                </p>
                                <h2 className="step-title">Annual Profit</h2>
                                <div className="step-form">
                                  <div className="form-group">
                                    <div className="row">
                                      <div
                                        className={`${
                                          financing_type == "loan"
                                            ? "col-md-6"
                                            : "col-md-12"
                                        }`}
                                      >
                                        <InputText
                                          singleLableText="Rental Income"
                                          singleInputType="text"
                                          singleInputPlaceholder="2400.00"
                                          singleInputClass={`${
                                            errors.profit_gross_rental_income &&
                                            "error-text-border"
                                          }`}
                                          singleInputName="profit_gross_rental_income"
                                          singleInputValue={
                                            profit_gross_rental_income || ""
                                          }
                                          singleInputonChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={
                                            errors.profit_gross_rental_income
                                          }
                                          disabled={true}
                                        />
                                      </div>
                                      <div
                                        className={`${
                                          financing_type == "loan"
                                            ? "col-md-6"
                                            : "col-md-6 display-none"
                                        }`}
                                      >
                                        <InputText
                                          singleLableText="Mortgage"
                                          singleInputType="text"
                                          singleInputPlaceholder="2400.00"
                                          singleInputClass={`${
                                            errors.profit_mortgage &&
                                            "error-text-border"
                                          }`}
                                          singleInputName="profit_mortgage"
                                          singleInputValue={
                                            profit_mortgage || ""
                                          }
                                          singleInputonChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={errors.profit_mortgage}
                                          disabled={true}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group display-none">
                                    <div className="row">
                                      <div className="col-md-12">
                                        <InputText
                                          singleLableText="Vacancy/CapEx"
                                          singleInputType="text"
                                          singleInputPlaceholder="2400.00"
                                          singleInputClass={`${
                                            errors.vacancy_plus_cap_ex &&
                                            "error-text-border"
                                          }`}
                                          singleInputName="vacancy_plus_cap_ex"
                                          singleInputValue={
                                            vacancy_plus_cap_ex || ""
                                          }
                                          singleInputonChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={errors.vacancy_plus_cap_ex}
                                          disabled={true}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <InputText
                                          singleLableText="Operating Expenses"
                                          singleInputType="text"
                                          singleInputPlaceholder="509.00"
                                          singleInputClass={`${
                                            errors.profit_operating_expenses &&
                                            "error-text-border"
                                          }`}
                                          singleInputName="profit_operating_expenses"
                                          singleInputValue={
                                            profit_operating_expenses || ""
                                          }
                                          singleInputonChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={
                                            errors.profit_operating_expenses
                                          }
                                          disabled={true}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <label className="form-lable">
                                          Property Management Reserve
                                        </label>
                                        <div className="investor-form">
                                          <input
                                            type="text"
                                            className="form-input property-management-text form-control"
                                            placeholder="0.00"
                                            name="profit_pm_reserve"
                                            value={profit_pm_reserve || ""}
                                            disabled={true}
                                          />
                                          <p className="rental-income-text-line"></p>
                                          <p className="rental-income-text">
                                            1 Month
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <InputText
                                          singleLableText="NOI"
                                          singleInputType="text"
                                          singleInputPlaceholder="1891.00"
                                          singleInputClass={`${
                                            errors.profit_noi &&
                                            "error-text-border"
                                          }`}
                                          singleInputName="profit_noi"
                                          singleInputValue={profit_noi || ""}
                                          singleInputonChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={errors.profit_noi}
                                          disabled={true}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <div className="lable-main">
                                          <div className="lable1">
                                            <label className="form-lable">
                                              Cap Rate
                                            </label>
                                          </div>
                                          <div className="lable2">
                                            <label
                                              className="form-lable step-use-link-color"
                                              onClick={annualProfitModalOpen}
                                            >
                                              What's This?
                                            </label>
                                          </div>
                                        </div>
                                        <div className="investor-form">
                                          <input
                                            type="text"
                                            className="form-input form-control"
                                            placeholder="15.76"
                                            name="profit_cap_rate"
                                            value={profit_cap_rate || ""}
                                            disabled={true}
                                          />
                                          <p className="rental-income-line"></p>
                                          <p className="rental-income-icon">
                                            %
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <StepFormNextPrevButton
                                buttonActions={["prev", "next"]}
                                prevButtonOnClickHandler={() =>
                                  this.stepper.to(4)
                                }
                                nextButtonOnClickHandler={() =>
                                  this.stepper.next()
                                }
                                nextButtonImage={arrowRight}
                                prevChildren={
                                  <i
                                    className="fa fa-angle-left"
                                    aria-hidden="true"
                                  ></i>
                                }
                                nextButtonText="Next"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="Metrics" className="content">
                        <div id="MetricsStepper" className="bs-stepper">
                          <div className="bs-stepper-header display-none">
                            <StepFormButton stepTargetId="#MetricsSub1" />
                          </div>
                          <div className="bs-stepper-content">
                            <div id="MetricsSub1" className="content">
                              <div className="rental-income-main-section">
                                <p className="step-text">
                                  Step <span>1</span> of 1
                                </p>
                                <p
                                  className="step-use-link"
                                  onClick={metricsModalOpen}
                                >
                                  Useful tip
                                </p>
                                <h2 className="step-title">Metrics</h2>
                                <div className="step-form">
                                  <div className="form-group">
                                    <InputText
                                      singleLableText="1% Rule Met?"
                                      singleInputType="text"
                                      singleInputPlaceholder="Yes"
                                      singleInputClass={`${
                                        errors.metrics_one_persentage_rule &&
                                        "error-text-border"
                                      }`}
                                      singleInputName="metrics_one_persentage_rule"
                                      singleInputValue={
                                        metrics_one_persentage_rule || ""
                                      }
                                      singleInputonChangeFunc={
                                        this.handleOnChangeInput
                                      }
                                      errorMsg={
                                        errors.metrics_one_persentage_rule
                                      }
                                      disabled={true}
                                    />
                                  </div>
                                  <div
                                    className={`form-group ${
                                      financing_type == "loan"
                                        ? ""
                                        : "display-none"
                                    }`}
                                  >
                                    <InputText
                                      singleLableText="DSCR"
                                      singleInputType="text"
                                      singleInputPlaceholder="31.94"
                                      singleInputClass={`${
                                        errors.metrics_dscr &&
                                        "error-text-border"
                                      }`}
                                      singleInputName="metrics_dscr"
                                      singleInputValue={metrics_dscr || ""}
                                      singleInputonChangeFunc={
                                        this.handleOnChangeInput
                                      }
                                      errorMsg={errors.metrics_dscr}
                                      disabled={true}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label className="form-lable">
                                      Op Ex Ratio
                                    </label>
                                    <InputTextWithIcon
                                      inputPlaceholder="21"
                                      inputType="text"
                                      inputTextIcon="%"
                                      inputClass={`${
                                        errors.metrics_op_ex_ratio &&
                                        "error-text-border"
                                      }`}
                                      inputName="metrics_op_ex_ratio"
                                      inputValue={metrics_op_ex_ratio || ""}
                                      inputChangeFunc={this.handleOnChangeInput}
                                      errorMsg={errors.metrics_op_ex_ratio}
                                      disabled={true}
                                    />
                                  </div>
                                </div>
                              </div>
                              <StepFormNextPrevButton
                                buttonActions={["prev", "next"]}
                                prevButtonOnClickHandler={() =>
                                  this.stepper.to(5)
                                }
                                nextButtonOnClickHandler={() =>
                                  this.stepper.next()
                                }
                                nextButtonImage={arrowRight}
                                prevChildren={
                                  <i
                                    className="fa fa-angle-left"
                                    aria-hidden="true"
                                  ></i>
                                }
                                nextButtonText="Next"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="BreakevenAnalysis" className="content">
                        <div
                          id="BreakevenAnalysisStepper"
                          className="bs-stepper"
                        >
                          <div className="bs-stepper-header display-none">
                            <StepFormButton stepTargetId="#BreakevenAnalysisSub1" />
                            <StepFormButton stepTargetId="#BreakevenAnalysisSub2" />
                          </div>
                          <div className="bs-stepper-content">
                            <div id="BreakevenAnalysisSub1" className="content">
                              <div className="rental-income-main-section">
                                <p className="step-text">
                                  Step <span>1</span> of 2
                                </p>
                                <p
                                  className="step-use-link"
                                  onClick={breakevenAnalysisModalOpen}
                                >
                                  Useful tip
                                </p>
                                <h2 className="step-title">
                                  Breakeven Analysis
                                </h2>
                                <div className="step-form">
                                  <div className="form-group">
                                    <label className="form-lable">
                                      Discount Rate (Rate Of Return To Determine
                                      The Present Value Of Your Future Cash
                                      Flows)
                                    </label>
                                    <InputTextWithIcon
                                      inputPlaceholder="Discount Rate"
                                      inputType="text"
                                      inputTextIcon="%"
                                      inputClass={`${
                                        errors.breakeven_discount_rate &&
                                        "error-text-border"
                                      }`}
                                      inputName="breakeven_discount_rate"
                                      inputValue={breakeven_discount_rate || ""}
                                      inputChangeFunc={this.handleOnChangeInput}
                                      errorMsg={errors.breakeven_discount_rate}
                                    />
                                  </div>
                                </div>
                              </div>
                              <StepFormNextPrevButton
                                buttonActions={["prev", "next"]}
                                prevButtonOnClickHandler={() =>
                                  this.stepper.to(6)
                                }
                                // nextButtonOnClickHandler = {() => this.BreakevenAnalysisInnerStepper.next()}
                                nextButtonOnClickHandler={
                                  this.handleOnBreakevenAnalysisStep1Submit
                                }
                                nextButtonImage={arrowRight}
                                prevChildren={
                                  <i
                                    className="fa fa-angle-left"
                                    aria-hidden="true"
                                  ></i>
                                }
                                nextButtonText="Next"
                              />
                            </div>
                            <div id="BreakevenAnalysisSub2" className="content">
                              <div className="rental-income-main-section">
                                <p className="step-text">
                                  Step <span>2</span> of 2
                                </p>
                                <p
                                  className="step-use-link"
                                  onClick={breakevenAnalysisTwoModalOpen}
                                >
                                  Useful tip
                                </p>
                                <h2 className="step-title">
                                  Breakeven Analysis
                                </h2>
                                <div className="step-form">
                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <InputText
                                          singleLableText="Initial Investment"
                                          singleInputType="text"
                                          singleInputPlaceholder="4400.00"
                                          singleInputClass={`${
                                            errors.breakeven_initial_investment &&
                                            "error-text-border"
                                          }`}
                                          singleInputName="breakeven_initial_investment"
                                          singleInputValue={
                                            breakeven_initial_investment || ""
                                          }
                                          singleInputonChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={
                                            errors.breakeven_initial_investment
                                          }
                                          disabled={true}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <InputText
                                          singleLableText="# Of Years To Break-Even"
                                          singleInputType="text"
                                          singleInputPlaceholder="0"
                                          singleInputClass={`${
                                            errors.breakeven_yearsto_breakeven &&
                                            "error-text-border"
                                          }`}
                                          singleInputName="breakeven_yearsto_breakeven"
                                          singleInputValue={
                                            breakeven_yearsto_breakeven || ""
                                          }
                                          singleInputonChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={
                                            errors.breakeven_yearsto_breakeven
                                          }
                                          disabled={true}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <label className="form-lable">
                                          Renturn On Investment
                                        </label>
                                        <InputTextWithIcon
                                          inputPlaceholder="25.40"
                                          inputType="text"
                                          inputTextIcon="%"
                                          inputClass={`${
                                            errors.breakeven_return_on_investment &&
                                            "error-text-border"
                                          }`}
                                          inputName="breakeven_return_on_investment"
                                          inputValue={
                                            breakeven_return_on_investment || ""
                                          }
                                          inputChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={
                                            errors.breakeven_return_on_investment
                                          }
                                          disabled={true}
                                        />
                                      </div>
                                      <div
                                        className={`col-md-6 ${
                                          financing_type == "loan"
                                            ? ""
                                            : "display-none"
                                        }`}
                                      >
                                        <InputText
                                          singleLableText="Net present value"
                                          singleInputType="text"
                                          singleInputPlaceholder="1000.00"
                                          singleInputClass={`${
                                            errors.breakeven_np_value &&
                                            "error-text-border"
                                          }`}
                                          singleInputName="breakeven_np_value"
                                          singleInputValue={
                                            breakeven_np_value || ""
                                          }
                                          singleInputonChangeFunc={
                                            this.handleOnChangeInput
                                          }
                                          errorMsg={errors.breakeven_np_value}
                                          disabled={true}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <StepFormNextPrevButton
                                buttonActions={["prev", "next"]}
                                prevButtonOnClickHandler={() =>
                                  this.BreakevenAnalysisInnerStepper.to(1)
                                }
                                // nextButtonOnClickHandler = {() => this.stepper.next()}
                                nextButtonOnClickHandler={
                                  this.handleOnBreakevenAnalysisStep2Submit
                                }
                                nextButtonImage={arrowRight}
                                prevChildren={
                                  <i
                                    className="fa fa-angle-left"
                                    aria-hidden="true"
                                  ></i>
                                }
                                nextButtonText="Next"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="Result" className="content">
                        <div id="ResultStepper" className="bs-stepper">
                          <div className="bs-stepper-header display-none">
                            <StepFormButton stepTargetId="#ResultSub1" />
                          </div>
                          <div className="bs-stepper-content">
                            <div id="ResultSub1" className="content">
                              <div className="rental-income-main-section">
                                <h2 className="step-title">Result</h2>
                                <div className="step-form">
                                  <div className="form-group">
                                    <InputText
                                      singleLableText="Pass Or Invest?"
                                      singleInputType="text"
                                      singleInputPlaceholder="Potential"
                                      singleInputClass={`${
                                        errors.pass_or_invest &&
                                        "error-text-border"
                                      }`}
                                      singleInputName="pass_or_invest"
                                      singleInputValue={pass_or_invest || ""}
                                      singleInputonChangeFunc={
                                        this.handleOnChangeInput
                                      }
                                      errorMsg={errors.pass_or_invest}
                                      disabled={true}
                                    />
                                  </div>
                                </div>
                              </div>
                              {fetching ? (
                                <div className="text-center">
                                  <button
                                    className="btn btn-primary loading-btn"
                                    disabled
                                  >
                                    Loading{" "}
                                    <img
                                      className="btn-loading"
                                      src={loading}
                                    />
                                  </button>
                                </div>
                              ) : (
                                <StepFormNextPrevButton
                                  buttonActions={["prev", "next"]}
                                  nextClassName="get-results-btn"
                                  prevButtonOnClickHandler={() =>
                                    this.stepper.to(7)
                                  }
                                  nextButtonOnClickHandler={
                                    this.handleOnGenerateReport
                                  }
                                  nextButtonImage={arrowRight}
                                  prevChildren={
                                    <i
                                      className="fa fa-angle-left"
                                      aria-hidden="true"
                                    ></i>
                                  }
                                  nextButtonText="get results"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <input
                        type="hidden"
                        className="form-input form-control"
                        name="monthly_rental_total"
                        value={monthly_rental_total || ""}
                        disabled={true}
                      />
                      <input
                        type="hidden"
                        className="form-input form-control"
                        name="annually_rental_total"
                        value={annually_rental_total || ""}
                        disabled={true}
                      />
                      <input
                        type="hidden"
                        className="form-input form-control"
                        name="down_payment_number"
                        value={down_payment_number || ""}
                        disabled={true}
                      />
                      <input
                        type="hidden"
                        className="form-input form-control"
                        name="financing_total"
                        value={financing_total || ""}
                        disabled={true}
                      />
                      <input
                        type="hidden"
                        className="form-input form-control"
                        name="closing_cost_total"
                        value={closing_cost_total || ""}
                        disabled={true}
                      />
                      <input
                        type="hidden"
                        className="form-input form-control"
                        name="expences_monthly_total"
                        value={expences_monthly_total || ""}
                        disabled={true}
                      />
                      <input
                        type="hidden"
                        className="form-input form-control"
                        name="expences_annually_total"
                        value={expences_annually_total || ""}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          isModalOpen={isVerifyModalOpen}
          handleOnRequestClose={this.handleOpenCloseVerifyModal}
          customStyle="modal-dialog modal-dialog-centered modal-lg verify-mail-modal"
        >
          <div className="modal-content multi-step">
            <div className="modal-body multi-step">
              <div className="text-center modal-header">
                <h2 className="modal-title">VERIFY YOUR EMAIL ADDRESS</h2>
                <span
                  className="modal-close"
                  aria-hidden="true"
                  onClick={this.handleOpenCloseVerifyModal}
                >
                  &times;
                </span>
              </div>
              <div className="text-center modal-body">
                <p>
                  Before proceeding, please check your email for a verification
                  link. If no email is received, please check your spam inbox
                  before requesting a new verification email,{" "}
                  <span
                    className="resend-email-link"
                    onClick={this.handleOnClickResendEmail}
                  >
                    click here to request another.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  postalAddresses: state.landingHome.postalAddresses,
  generateReport: state.landingHome.generateReport,
  fetching: state.landingHome.fetching,
  close_verify_modal: state.authUsers.close_verify_modal,
});

const mapDispatchToProps = (dispatch) => {
  const reduxActions = {
    ...LandingHomeReduxActionsCreators,
    ...AuthReduxActionsCreators,
  };

  return { actions: bindActionCreators(reduxActions, dispatch) };
};

const connectedStepFromSectionWithoutApiPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(StepFromSectionWithoutApi);
export { connectedStepFromSectionWithoutApiPage as StepFromSectionWithoutApi };
