import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ReportsReduxActionsCreators from 'Reducers/reports.reducer';
import PatmentReduxActionsCreators from 'Reducers/payment.reducer';

import { Helmet } from 'Components/Helmet';
import { Header } from 'Components/Header';
import { Footer } from 'Components/Footer';
import { AlertAndDeleteModal } from 'Components/AlertAndDeleteModal';
import { LoadingOrError } from 'Components/LoadingOrError'
import { Table } from 'Components/Table';

import profileHeader from 'Assets/images/saved-report.svg';
import arrowRight from 'Assets/images/arrow-right.png';
import alertProfileImg from 'Assets/images/alert_profile.svg';
import deleteProfileImg from 'Assets/images/delete_profile.svg';

class SavedReports extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isAlertModalOpen: false,
			isDeleteModalOpen: false,

			reports: []
		};
	}

	async componentDidMount() {
		// const { actions: { getUsersReports, userPlanStatus } } = this.props;
		// await getUsersReports();
		// await userPlanStatus();
		const { actions: { getUsersReports } } = this.props;
		await getUsersReports();
	}

	componentDidUpdate(prevProps, preState) {
		if ((prevProps.usersReports !== this.props.usersReports) && (this.props.usersReports)) {
			const { usersReports } = this.props;
			this.setState({ reports: usersReports });
		}
	}

	handleOpenCloseAlertModal = () => {
		this.setState((prevState) => {
			return { isAlertModalOpen: !prevState.isAlertModalOpen }
		});
	}

	handleOpenCloseDeleteModal = () => {
		this.setState((prevState) => {
			return { isDeleteModalOpen: !prevState.isDeleteModalOpen }
		});
	}

	render() {
		const { isAlertModalOpen, isDeleteModalOpen, reports } = this.state;
		let { fetching, fetchingErr, fetchingErrMsg } = this.props;
		
		const tableHead = [
			[
			  {
				value: 'S.No',
				className: 'wd90'
			  },
			  {
				value: 'Report name'
			  },
			  {
				value: 'Action',
				className: 'wd30'
			  },
			]
		]
		
		const tableRows = reports.map((v, keyIndx) => {
			return [
				{
					style: {
						bold: true,
						center: true
					},
					value: keyIndx + 1 
				},
				{
					style: {
						bold: true,
					},
					value: v.report_name || ''
				},
				{
					style: {
						bold: true,
						button: true,
						compress: true
					},
					// onClick: () => v.pass_or_invest && v.pass_or_invest === "Potential" ? this.props.history.push(`/potential-report/${v.id}`) : this.props.history.push(`/pass-report/${v.id}`), 
					onClick: () => this.props.history.push(`/reportresult/${v.id}`),
					value: 'View'
				},
			]
		})
		
		const tableStruct = {
			tableHead: tableHead,
			tableRows: tableRows
		}
		
		return (
			<>
				<Helmet title="Rent Potential" metaName="description" metaContent="Rent Potential" />
				<div className="main-content">
					<Header
						pageTitle="View"
						pageSubTitle="Saved Reports"
						pageHeaderImg={profileHeader} />
					<div className="container padding-top-bot pt-0">
						<div className="row">
							<div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
								<h2 className="profile-title">saved reports</h2>
								{(fetching || fetchingErr) ?
									(
										<div className="saved-reports-loader-wrapper">
											<LoadingOrError
												loading={fetching}
												loadingMsg={'Fetching Saved Reports...'}
												err={fetchingErr}
												errMsg={fetchingErrMsg}
											/>
										</div>
									)
								: (
									<div className="table-responsive">
										<Table
											data={reports}
											loadData={() => {}}
											maxSize={reports.length}
											table={tableStruct}
										/>
									</div>
								)}
								
							</div>
						</div>
					</div>
					<Footer></Footer>
				</div>

				<AlertAndDeleteModal
					isModalOpen={isAlertModalOpen}
					handleOnRequestClose={this.handleOpenCloseAlertModal}
					customStyle="modal-dialog modal-dialog-centered modal-md"
					popupTitle="Alert"
					popupTitleClass="payment-alert-popup-title"
					popupImage={alertProfileImg}
					popupBtnClass="popup-img"
					nosOfButtonToShow={2}
					popupBtnTextLeft="Yes"
					popupBtnActionLeft={this.handleOpenCloseAlertModal}
					popupBtnTextRight="No"
					popupBtnActionRight={this.handleOpenCloseAlertModal}
				>
					<p className="popup-alert-text">Do you really want to cancel this plan?<br />Your amount will not be refunded.</p>
				</AlertAndDeleteModal>

				<AlertAndDeleteModal
					isModalOpen={isDeleteModalOpen}
					handleOnRequestClose={this.handleOpenCloseDeleteModal}
					customStyle="modal-dialog modal-dialog-centered modal-md"
					popupTitle="delete account ?"
					popupTitleClass="payment-alert-popup-title"
					popupImage={deleteProfileImg}
					popupBtnClass="popup-img"
					nosOfButtonToShow={2}
					popupBtnTextLeft="Yes"
					popupBtnActionLeft={this.handleOpenCloseDeleteModal}
					popupBtnTextRight="No"
					popupBtnActionRight={this.handleOpenCloseDeleteModal}
				>
					<p className="popup-alert-text">Are you sure you want to delete<br />your account?</p>
				</AlertAndDeleteModal>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	usersReports: state.reports.usersReports,
	fetching: state.reports.fetching,
	fetchingErr: state.reports.fetchingErr,
	fetchingErrMsg: state.reports.fetchingErrMsg,
	router: state.router,
})
  
const mapDispatchToProps = (dispatch) => {
	const reduxActions = {
	  ...ReportsReduxActionsCreators,
	  ...PatmentReduxActionsCreators
	};
	
	return { actions: bindActionCreators(reduxActions, dispatch)};
}

const connectedSavedReportsPage = connect(mapStateToProps, mapDispatchToProps)(SavedReports);
export { connectedSavedReportsPage as SavedReports };
