export * from "./StepFromSection";

export const sumData = (data) => {
  const res = data.reduce(
    (acc, curr) => (acc += Number(curr.split(",").join(""))),
    // (acc, curr) => (acc += Number(curr.replace(/,/g, ""))),
    0
  );

  return res;
};
