import React from 'react';

const StepFormButton = ({stepTargetId,
  stepClassName,
  stepText}) => {
    
  return (
    <div className={`step ${stepClassName}`} data-target={stepTargetId}>
      <button className="step-trigger">
        <span className="bs-stepper-circle"></span>
        <span className="bs-stepper-label">{stepText}</span>
      </button>
    </div>
  )
}

const connectedStepFormButton = StepFormButton;
export {connectedStepFormButton as StepFormButton};
