import { toast } from 'react-toastify';

export const showUnhandledErrors = (messageToDisplay) => {
  toast.error(messageToDisplay, {
    position: toast.POSITION.TOP_RIGHT
  });
};

export const handleOnSuccessToastify = (messageToDisplay) => {
  toast.success(messageToDisplay, {
    position: toast.POSITION.TOP_RIGHT
  });
};

export const setItemInLocalStorage = (key = 'rent_potential_system', data, isJSONStringify = true) => {
  // setter
  return localStorage.setItem(key, isJSONStringify ? JSON.stringify(data) : data);
}

export const getItemInLocalStorage = (key = 'rent_potential_system') => {
  // getter
  return localStorage.getItem(key) ? localStorage.getItem(key) : null;
}

export const cleanLocalStorage = () => {
  // clean
  return localStorage.clear();
}

export const validateName = (name) => {
  if (!name) {
    return {
      message: 'Please enter your name',
      status: false
    };
  }
  return {
    message: '',
    status: true
  };
}

export const validateFirstName = (firstName) => {
  if (!firstName) {
    return {
      message: 'Please enter your first name',
      status: false
    };
  }
  return {
    message: '',
    status: true
  };
}

export const validateLastName = (lastName) => {
  if (!lastName) {
    return {
      message: 'Please enter your last name',
      status: false
    };
  }
  return {
    message: '',
    status: true
  };
}

export const validateMobileNumber = (mobileNumber) => {
  let number = /[0-9]/;
  if (!mobileNumber) {
    return {
      message: 'Please enter your mobile number',
      status: false
    };
  }
  if(!number.test(mobileNumber)){
    return {
      message: 'Please make sure only digit',
      status: false
    };     
  }
  return {
    message: '',
    status: true
  };
}

export const validateMessage = (message) => {
  if (!message) {
    return {
      message: 'Please enter your message',
      status: false
    };
  }
  return {
    message: '',
    status: true
  };
}

export const validateEmail = (email) => {
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!email) {
    return {
      message: 'Please enter your email address',
      status: false
    };
  }
  if (!re.test(String(email).toLowerCase())) {
    return {
      message: 'Please provide a valid email address',
      status: false
    };
  }
  return {
    message: '',
    status: true
  };
}

export const validatePassword = (password) => {
  let letter = /[a-z]/;
  let upper  =/[A-Z]/;
  let special = /[\!#$%&'()*+,-./:;<=>?@[\]^_`{|}]/;
  if (!password) {
    return {
      message: 'Please enter your password',
      status: false
    };
  }

  return {
    message: '',
    status: true
  };
}

export const validateConfirmPassword = (confirmPassword) => {
  let letterConf = /[a-z]/;
  let upperConf  =/[A-Z]/;
  let specialConf = /[\!#$%&'()*+,-./:;<=>?@[\]^_`{|}]/;

  if (!confirmPassword) {
    return {
      message: 'Please enter your confirm password',
      status: false
    };
  }

  return {
    message: '',
    status: true
  };
}

export function validateFormFields(inputFieldName, inputFieldValue) {
  switch (inputFieldName) {
    case 'firstName':
      return validateFirstName(inputFieldValue);

    case 'lastName':
      return validateLastName(inputFieldValue);

    case 'mobileNumber':
      return validateMobileNumber(inputFieldValue);

    case 'message':
      return validateMessage(inputFieldValue);

    case 'name':
      return validateName(inputFieldValue);

    case 'email':
      return validateEmail(inputFieldValue);

    case 'password':
      return validatePassword(inputFieldValue);

    case 'confirmPassword':
      return validateConfirmPassword(inputFieldValue);

    default:
      return {
        message: '',
        status: false
      };
  }
}