import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Routes } from 'App/routes/Routes';

const Footer = () => {
    const localStorage12 = localStorage.getItem('logged_user_auth_token');

    return (
        <div className="footer">
            <div className="main-wrapper">
                <div className="row footer-row">
                    <div className="col-md-4 footer-copyright-text">
                        <p>Copyright © 2020 Rent Potential. All rights reserved.</p>
                    </div>
                    <div className="col-md-5 text-center">
                        <ul className="footer-link">
                            <li><Link className="" to={Routes.ABOUT_VIEW}>about us</Link></li>
                            <li><Link className="" to={Routes.COUNTACT_US_VIEW}>contact us</Link></li>
                            <li><Link className="" to={Routes.TERMS_AND_CONDITIONS_VIEW}>Terms and Conditions</Link></li>
                        </ul>
                    </div>
                    <div className="col-md-3 footer-social text-right">
                        <div className="social-sub-div">
                            <a href="https://www.facebook.com/rentpotential" target="_blank"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                        </div>
                        <div className="social-sub-div">
                            <a href="https://twitter.com/rentpotential" target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                        </div>
                        <div className="social-sub-div">
                            <a href="https://www.instagram.com/rentpotential/" target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const connectedFooterPage = Footer;
export { connectedFooterPage as Footer };
