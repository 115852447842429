import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';

import "jquery";
import "react-popper";
import "bootstrap";

import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import 'Assets/css/style.css';
import 'Assets/css/font-awesome.min.css';
import 'react-activity/dist/react-activity.css';
import "Assets/js/general";

import createStore from '../store';
import { Root } from '../routes';

const { history, store } = createStore();

class App extends React.Component {

  constructor(){
    super();
    this.state = {};
  }

  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Root />
        </ConnectedRouter>
      </Provider>
    );
  }
}

const connectedApp = App;
export { connectedApp as App };