import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import { reduxAuthActions } from 'App/actions';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    loginUser: ['payload'],

    logoutUser: null,
    logoutSuccess: null,

    forgotPassword: ['email'],

    changePassword: ['payload'],
    changePasswordSuccess: ['payload'],

    registerUser: ['payload'],
    setVerifySuccess: ['status'],

    resetPassword: ['payload'],

    getUserProfile: null,
    getProfileSuccess: ['payload'],
    getProfileFailure: ['payload'],

    userVerifyEmail: ['payload'],

    resendUserVerifyEmail: ['userId'],
    resendVerifyEmailSuccess: ['payload'],

    updateUserProfile: ['payload'],
    updateProfileSuccess: ['payload'],

    deleteUser: ['id'],
    deleteUserSuccess: ['payload'],
    deleteUserFailure: ['payload'],

    setFullPageLoading: ['status'],
    setFullPageLoadingErr: ['error'],

    setChangePasswordLoading: ['status'],
    setUpdateProfileLoading: ['status'],

    setFetching: ['status'],
    setFetchingErr: ['error'],
    setFetchingErrMsg: ['errorMsg'],

    contactUsPost: ['payload'],
    setContactUsPostStatus: ['status']
  });
  
  export const AuthTypes = Types;
  export default Creators;
  

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    get_profile_success: [],
    get_profile_failure: [],
    update_profile_success: [],
    change_password_success: [],
    delete_user_success: [],
    delete_user_failure: [],
    open_verify_modal: false,
    close_verify_modal: [],
    error: null,
    fetching: false,
    fetchingErr: false,
    fetchingErrMsg: '',
    fullPageLoading: false,
    fullPageLoadingErr: false,
    changePasswordLoading: false,
    updateProfileLoading: false,

    statusForContactUs: false,
});
  


/* ------------- Reducers Actions ------------- */

const { 
  setChangePasswordLoading,
  setUpdateProfileLoading,
  setFullPageLoading,
  setFullPageLoadingErr,
  setFetching,
  setFetchingErr,
  setFetchingErrMsg,
  changePasswordSuccess,
  setVerifySuccess,
  getProfileSuccess,
  getProfileFailure,
  updateProfileSuccess,
  deleteUserSuccess,
  deleteUserFailure,
  resendVerifyEmailSuccess,
  logoutSuccessHandler,
  setContactUsPostStatus
} = reduxAuthActions;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.CHANGE_PASSWORD_SUCCESS]: changePasswordSuccess,
    [Types.SET_VERIFY_SUCCESS]: setVerifySuccess,
    [Types.GET_PROFILE_SUCCESS]: getProfileSuccess,
    [Types.GET_PROFILE_FAILURE]: getProfileFailure,
    [Types.UPDATE_PROFILE_SUCCESS]: updateProfileSuccess,
    [Types.DELETE_USER_SUCCESS]: deleteUserSuccess,
    [Types.DELETE_USER_FAILURE]: deleteUserFailure,
    [Types.RESEND_VERIFY_EMAIL_SUCCESS]: resendVerifyEmailSuccess,
    [Types.LOGOUT_SUCCESS]: logoutSuccessHandler,

    [Types.SET_CHANGE_PASSWORD_LOADING]: setChangePasswordLoading,
    [Types.SET_UPDATE_PROFILE_LOADING]: setUpdateProfileLoading,

    [Types.SET_FULL_PAGE_LOADING]: setFullPageLoading,
    [Types.SET_FULL_PAGE_LOADING_ERR]: setFullPageLoadingErr,

    [Types.SET_FETCHING]: setFetching,
    [Types.SET_FETCHING_ERR]: setFetchingErr,
    [Types.SET_FETCHING_ERR_MSG]: setFetchingErrMsg,
    
    [Types.SET_CONTACT_US_POST_STATUS]: setContactUsPostStatus,
  });
  