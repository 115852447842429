import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AuthReduxActionsCreators from '../../reducers/auth.reducer';

import { Helmet } from 'Components/Helmet';
import { Link } from 'react-router-dom';
import { Routes } from 'App/routes/Routes';
import { InputText } from 'Components/InputText';

import { validateFormFields, showUnhandledErrors } from 'Utils/commonFunction';

import loginImg from 'Assets/images/login-img.png';
import Logo from 'Assets/images/rentpotential-logo.png';
import CloseIcon from 'Assets/images/close.png';
import loading from 'Assets/images/loading.gif';

const INITIAL_STATE = {
    email: null,
    password: null,
	
	errors: {
		email: '',
		password: '',
    },
}

class Login extends Component {
	constructor(props){
		super(props);
		this.state = {
			...INITIAL_STATE,
			loginErrorsMsg: '',
		};
	}

	componentDidUpdate(prevProps, preState) {
		if ((prevProps.get_login_failure !== this.props.get_login_failure) && (this.props.get_login_failure)) {
			const { get_login_failure } = this.props;
			this.setState({ loginErrorsMsg: get_login_failure.message});
		}
	}

	handleOnErrors = (fieldName, fieldValue) => {
		this.setState(prevState => ({
		  errors: {
			...prevState.errors,
			[fieldName]: fieldValue
		  }
		}));
	}

	handleOnChangeInput = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	}
	
	loginFormValidate = () => {
        const { email, password } = this.state;
        const emailIsValid = validateFormFields('email', email);
        const passwordIsValid = validateFormFields('password', password);
        const stateOfErrors = 'errors';
        if (emailIsValid) {
            this.handleOnErrors('email', emailIsValid.message, stateOfErrors);
		}

		if (passwordIsValid) {
            this.handleOnErrors('password', passwordIsValid.message, stateOfErrors);
		}

        return emailIsValid.status && passwordIsValid.status
	}
	
	handleOnLoginSubmit = () => {
		const isLoginFormFieldsValid = this.loginFormValidate();
		if(!isLoginFormFieldsValid){
			console.log('You may enter wrong value, please try again')
		} else {
			const { actions: { loginUser } } = this.props;
			const { email, password } = this.state;
			loginUser({ email, password });
		}
	}

	render(){
		const { errors, email, password, loginErrorsMsg } = this.state;
		let { fetching } = this.props;
		// console.log("fetching---",fetching)
      	return (
			<>
				<Helmet title="Rent Potential" metaName="description" metaContent="Rent Potential" />
				<div className="main-content">
					<div className="login-register-main">
						<div className="background-gray login-register-bg-img-side">
							<div className="login-register-logo">
								<img src={Logo} />
							</div>
							<div className="login-register-img">
								<img src={loginImg} />
							</div>
						</div>
						<div className="login-register-form-section">
							<Link className="close-btn-right" to={Routes.HOME_VIEW}>
								<img src={CloseIcon} />
							</Link>
							<div className="login-register-logo show-after-responsive">
								<img src={Logo} />
							</div>
							<div className="login-register-form">
								<h2 className="login-register-title">LOGIN</h2>
								{/* <div className="login-main-error">
									{loginErrorsMsg ? loginErrorsMsg : ''}
								</div> */}
								<div className="form-group">
									<div className="row">
										<div className="col-md-12">
											<InputText singleLableText="Email Id" singleInputType="email" singleInputClass={`${errors.email && 'error-text-border'}`} singleInputPlaceholder="Enter Email" singleInputName="email" singleInputValue={email || ''} singleInputonChangeFunc={this.handleOnChangeInput} errorMsg={errors.email} />
										</div>
									</div>
								</div>
								<div className="form-group">
									<div className="row">
										<div className="col-md-12">
											<InputText singleLableText="Password" singleInputType="password" singleInputClass={`${errors.password && 'error-text-border'}`} singleInputPlaceholder="Password" singleInputName="password" singleInputValue={password || ''} singleInputonChangeFunc={this.handleOnChangeInput} errorMsg={errors.password} />
										</div>
									</div>
								</div>
								<div className="form-group">
									<div className="row">
										<div className="col-md-6">
											<div className="custom-control custom-checkbox">
												<input type="checkbox" className="custom-control-input" id="RememberMe" />
												<label className="custom-control-label" htmlFor="RememberMe">Remember Me</label>
											</div>
										</div>
										<div className="col-md-6 text-right">
											<Link className="link-text-color" to={Routes.FORGOT_PASSWORD_VIEW}>Forgot Password ?</Link>
										</div>
									</div>
								</div>
								<div className="form-group">
									<div className="row">
										<div className="col-md-12 text-center">
											{(fetching) ?
												(
													<button className="btn btn-primary loading-btn" disabled>Loading <img className="btn-loading" src={loading} /></button>
												)
											: (
												<button className="btn btn-primary form-btn" onClick={this.handleOnLoginSubmit}>LOGIN</button>
											)}
										</div>
									</div>
								</div>
								<div className="text-center">
									<p className="font-color-gray">Don't have an account? <Link className="link-text-color" to={Routes.REGISTER_VIEW}>Register</Link></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
    	);
  	}
}

const mapStateToProps = (state) => ({
	fetching: state.authUsers.fetching,
	fetchingErr: state.authUsers.fetchingErr,
	fetchingErrMsg: state.authUsers.fetchingErrMsg,
})
  
const mapDispatchToProps = (dispatch) => {
	const reduxActions = {
	  ...AuthReduxActionsCreators
	};
	
  
	return { actions: bindActionCreators(reduxActions, dispatch)};
}

const connectedLoginPage = connect(mapStateToProps, mapDispatchToProps)(Login);
export { connectedLoginPage as Login };
