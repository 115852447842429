import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import { reduxPaymentActions } from 'App/actions';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    stripePayment: ['payload'],
    
    setStripePaymentSuccess: ['status'],
    setStripePaymentFailure: ['status'],

    planStatus: null,
    setPlanStatus: ['payload'],
    setPremiumPlanStatus: ['status'],
    setStandardPlanStatus: ['status'],
    setCountOnetimePlan: ['status'],
    
    setPaymentFetching: ['status'],
    setPlanStatusFetching: ['status'],

    stripeClosePlan: ['payload'],
    setStripeClosePlan: ['payload'],

    setStripeFullPageLoading: ['status'],

    userPlanStatus: null,
    setUserPlanStatus: ['payload'],

    doExpressCheckoutPaypalPayment: ['payload'],
    setExpressCheckoutPaypalCallback: ['payload'],

    doExpressCheckoutSuccess: ['payload'],
    setExpressCheckoutSuccess: ['payload']
  });
  
  export const PaymentTypes = Types;
  export default Creators;
  

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    error: null,
    paymentFetching: false,
    planStatusFetching: false,
    stripePaymentSuccessModal: false,
    stripePaymentFailureModal: false,
    premiumPlanStatus: false,
    standardPlanStatus: false,
    countOnetimePlan: 0,
    stripeClosePlanSuccess: {},
    planStatusSuccess: {},
    setUserPlanStatusData: {},

    paypalPaymentDetails: {},
    statusOfExpressCheckoutPayPal: false
});
  


/* ------------- Reducers Actions ------------- */

const { 
  setPaymentFetching,
  setPlanStatusFetching,
  setStripePaymentSuccess,
  setStripePaymentFailure,
  setPremiumPlanStatus,
  setStandardPlanStatus,
  setCountOnetimePlan,
  setStripeClosePlan,
  setPlanStatus,
  setStripeFullPageLoading,
  setUserPlanStatus,
  setPayPalAPIResponseCallback,
  setExpressCheckoutSuccessAPICallback,
} = reduxPaymentActions;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_PAYMENT_FETCHING]: setPaymentFetching,
    [Types.SET_PLAN_STATUS_FETCHING]: setPlanStatusFetching,
    [Types.SET_STRIPE_PAYMENT_SUCCESS]: setStripePaymentSuccess,
    [Types.SET_STRIPE_PAYMENT_FAILURE]: setStripePaymentFailure,
    [Types.SET_PREMIUM_PLAN_STATUS]: setPremiumPlanStatus,
    [Types.SET_STANDARD_PLAN_STATUS]: setStandardPlanStatus,
    [Types.SET_COUNT_ONETIME_PLAN]: setCountOnetimePlan,
    [Types.SET_STRIPE_CLOSE_PLAN]: setStripeClosePlan,
    [Types.SET_PLAN_STATUS]: setPlanStatus,
    [Types.SET_STRIPE_FULL_PAGE_LOADING]: setStripeFullPageLoading,
    [Types.SET_USER_PLAN_STATUS]: setUserPlanStatus,
    [Types.SET_EXPRESS_CHECKOUT_PAYPAL_CALLBACK]: setPayPalAPIResponseCallback,
    [Types.SET_EXPRESS_CHECKOUT_SUCCESS]: setExpressCheckoutSuccessAPICallback,
  });
  