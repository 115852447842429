import { all, fork } from 'redux-saga/effects';

import AuthSagas from 'Sagas/auth.saga';
import PaymentSagas from 'Sagas/payment.saga';
import LandingHomeSagas from 'Sagas/landingHome.sagas';
import PlansSagas from 'Sagas/plans.sagas';
import ReportsSagas from 'Sagas/reports.sagas';

const forkList = sagasList => sagasList.map(saga => fork(saga));

export default function* root() {
  yield all([
      ...forkList(AuthSagas),
      ...forkList(PaymentSagas),
      ...forkList(LandingHomeSagas),
      ...forkList(PlansSagas),
      ...forkList(ReportsSagas),
  ]);
}
