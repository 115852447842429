import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Helmet } from "Components/Helmet";
import { Header } from "Components/Header";
import { Footer } from "Components/Footer";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { GoogleMap } from "../GoogleMap/GoogleMap";
import ReactEcharts from "echarts-for-react";
import CurrencyInput from "../../../utils/CurrencyInput";

import calculator from "Assets/images/calculator.png";
import arrowRight from "Assets/images/arrow-right.png";
import penIcon from "Assets/images/pen-icon.png";
import homeIcon from "Assets/images/info-home.svg";
import arrowLeft from "Assets/images/arrow-left.png";

import ReportsReduxActionsCreators from "Reducers/reports.reducer";

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

const INITIAL_STATE_OBJECT = {
  pincode: null,
  address: null,
  report_name: null,
  number_of_investors: null,
  purchange_price: null,
  other_rental_income_monthly: null,
  other_rental_income_annually: null,
  financing_type: null,
  financing_loan_type: "residential",
  financing_amortization_term: null,
  financing_interest_rate: null,
  financing_down_payment: null,
  financing_amount_financed: null,
  financing_reserve: null,
  renovation_cost: null,
  earnest_money_deposit: null,
  property_inspection: null,
  real_estate_transfer_tax: null,
  title_insurance_fee: null,
  origination_fee: null,
  appraisal_fees: null,
  prop_manage_fee_annually: null,
  prop_manage_fee_monthly: null,
  water_bill_annually: null,
  water_bill_monthly: null,
  taxes_annually: null,
  taxes_monthly: null,
  utilities_annually: null,
  utilities_monthly: null,
  trash_pest_landscaping_annually: null,
  trash_pest_landscaping_monthly: null,
  insurance_annually: null,
  insurance_monthly: null,
  profit_gross_rental_income: null,
  profit_mortgage: null,
  vacancy_plus_cap_ex: null,
  profit_operating_expenses: null,
  profit_pm_reserve: null,
  profit_noi: null,
  profit_cap_rate: null,
  metrics_one_persentage_rule: null,
  metrics_dscr: null,
  metrics_op_ex_ratio: null,
  breakeven_discount_rate: null,
  breakeven_initial_investment: null,
  breakeven_yearsto_breakeven: null,
  breakeven_return_on_investment: null,
  breakeven_np_value: null,
  monthly_rental_total: null,
  annually_rental_total: null,
  down_payment_number: null,
  financing_total: null,
  closing_cost_total: null,
  expences_monthly_total: null,
  expences_annually_total: null,
  pass_or_invest: null,
  reportid: null,
};

class EditReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userReportData: [{}],
      userApiData: [{}],
      downPayment: 0,
      chartRenovationCost: 0,
      totalDownPayment: 0,
      tinvestment: 0,
      resPurchangePrice: 0,
      chartRentalIncome: 0,
      chartOperatingExpenses: 0,
      chartPropertyManagementReserve: 0,
      chartNoi: 0,
      chartWaterbill: 0,
      chartTaxes: 0,
      chartUtilities: 0,
      chartPestcontrol: 0,
      chartInsurance: 0,
      chartManagefee: 0,
      chartManagefee: 0,
      chartRentalIncomeMonthly: [],
      chartRentalIncomeMonthlyName: [],
      chartEstimatedName: [],
      chartEstimatedNameValue: [],
      chartEstimatedValue: [],
      investors_investment_percentage: [],
      rental_income_monthly: [],
      rental_income_annually: [],
      ...INITIAL_STATE_OBJECT,
      updateReportOnClick: 0,
    };
  }

  componentDidMount() {
    console.log("this.props", this.props);
    if (
      "match" in this.props &&
      this.props.match &&
      "params" in this.props.match &&
      this.props.match.params
    ) {
      const {
        params: { id },
      } = this.props.match;
      const {
        actions: { getUserReportById, userPlanStatus },
      } = this.props;
      getUserReportById(id);
    }
  }

  componentDidUpdate(prevProps, preState) {
    if (
      prevProps.userReportById !== this.props.userReportById &&
      this.props.userReportById
    ) {
      const { userReportById } = this.props;
      if (!userReportById.data.down_payment_number) {
        var downPaymentValue1 = userReportById.data.down_payment_number;
      } else {
        var downPaymentValue1 = userReportById.data.down_payment_number
          .toString()
          .replace(/,/g, "");
      }
      let downPaymentValue = Math.abs(
        parseFloat(downPaymentValue1) -
          userReportById.data.earnest_money_deposit.toString().replace(/,/g, "")
      );

      let totalDownPaymentValue =
        parseFloat(userReportById.data.financing_total) -
        userReportById.data.earnest_money_deposit.toString().replace(/,/g, "");

      let chartRenovationCostValue = userReportById.data.renovation_cost
        .toString()
        .replace(/,/g, "");

      let earnestMoneyDeposit = userReportById.data.earnest_money_deposit
        .toString()
        .replace(/,/g, "");
      let breakevenInitialInvestment =
        userReportById.data.breakeven_initial_investment
          .toString()
          .replace(/,/g, "");
      let tinvestmentValue =
        parseFloat(earnestMoneyDeposit) +
        parseFloat(breakevenInitialInvestment);

      let purchangePrice = userReportById.data.purchange_price
        .toString()
        .replace(/,/g, "");
      let resPurchangePriceValue = parseFloat(purchangePrice) / 27.5;

      let chartRentalIncomeValue =
        userReportById.data.profit_gross_rental_income
          .toString()
          .replace(/,/g, "");
      let chartOperatingExpensesValue =
        userReportById.data.profit_operating_expenses
          .toString()
          .replace(/,/g, "");
      let chartPropertyManagementReserveValue =
        userReportById.data.profit_pm_reserve.toString().replace(/,/g, "");
      let chartNoiValue = userReportById.data.profit_noi
        .toString()
        .replace(/,/g, "");

      let chartWaterbillValue =
        (parseFloat(userReportById.data.water_bill_monthly) /
          parseFloat(userReportById.data.expences_monthly_total)) *
        100;
      let chartTaxesValue =
        (parseFloat(userReportById.data.taxes_monthly) /
          parseFloat(userReportById.data.expences_monthly_total)) *
        100;
      let chartUtilitiesValue =
        (parseFloat(userReportById.data.utilities_monthly) /
          parseFloat(userReportById.data.expences_monthly_total)) *
        100;
      let chartPestcontrolValue =
        (parseFloat(userReportById.data.trash_pest_landscaping_monthly) /
          parseFloat(userReportById.data.expences_monthly_total)) *
        100;
      let chartInsuranceValue =
        (parseFloat(userReportById.data.insurance_monthly) /
          parseFloat(userReportById.data.expences_monthly_total)) *
        100;
      let chartManagefeeValue =
        (parseFloat(userReportById.data.prop_manage_fee_monthly) /
          parseFloat(userReportById.data.expences_monthly_total)) *
        100;

      let totalRentalIncome =
        (parseFloat(
          userReportById.data.other_rental_income_monthly
            .toString()
            .replace(/,/g, "")
        ) /
          parseFloat(
            userReportById.data.monthly_rental_total
              .toString()
              .replace(/,/g, "")
          )) *
        100;

      var breakevenNpValue1 = userReportById.data.breakeven_np_value
        .toString()
        .replace(/,/g, "");

      let unit1income = [];
      let nkey = [];
      userReportById.data.rental_income_monthly.map(
        (rentalIncomeMonthlyMap, keyIndx) => {
          let rtNameValue = rentalIncomeMonthlyMap.toString().replace(/,/g, "");
          let rtName = parseFloat(rtNameValue)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          let rtName1Value = userReportById.data.other_rental_income_monthly
            .toString()
            .replace(/,/g, "");
          let rtName1 = parseFloat(rtName1Value)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          nkey[keyIndx] = `Unit ${keyIndx + 1}\n$${rtName}`;
          nkey.push(`Garage Rent\n$${rtName1}`);
          let rt =
            (rentalIncomeMonthlyMap.toString().replace(/,/g, "") /
              parseFloat(userReportById.data.monthly_rental_total)) *
            100;
          unit1income[keyIndx] = parseFloat(rt).toFixed(2);
          unit1income.push(parseFloat(totalRentalIncome).toFixed(2));
        }
      );

      if (userReportById.api_data) {
        console.log("ddede");
        var setLatitude = userReportById.api_data.latitude;
        var setLongitude = userReportById.api_data.longitude;
        var estimatedName = [];
        var estimatedNameValue = [];
        var estimatedValue = [];
        userReportById.api_data.last_years_zest_his.map(
          (lastYearsZestHisMap, keyIndx) => {
            estimatedName[keyIndx] = lastYearsZestHisMap.label;
            estimatedNameValue[keyIndx] = lastYearsZestHisMap.y
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            estimatedValue[keyIndx] = lastYearsZestHisMap.y;
          }
        );
      } else {
        var estimatedName = [];
        var estimatedNameValue = [];
        var estimatedValue = [];
        var setLatitude = 0;
        var setLongitude = 0;
      }

      this.setState({
        userReportData: userReportById.data,
        userApiData: userReportById.api_data,
        latitude: setLatitude,
        longitude: setLongitude,
        breakevenNpValue: Math.round(parseFloat(breakevenNpValue1))
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        downPayment:
          parseFloat(downPaymentValue)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0.0,
        totalDownPayment:
          parseFloat(totalDownPaymentValue)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0.0,
        tinvestment:
          parseFloat(tinvestmentValue)
            .toFixed(0)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0.0,
        resPurchangePrice:
          parseFloat(resPurchangePriceValue)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0.0,
        chartRenovationCost: chartRenovationCostValue,
        chartRentalIncome: chartRentalIncomeValue,
        chartOperatingExpenses: chartOperatingExpensesValue,
        chartPropertyManagementReserve: chartPropertyManagementReserveValue,
        chartNoi: chartNoiValue,

        chartWaterbill: parseFloat(chartWaterbillValue).toFixed(2),
        chartTaxes: parseFloat(chartTaxesValue).toFixed(2),
        chartUtilities: parseFloat(chartUtilitiesValue).toFixed(2),
        chartPestcontrol: parseFloat(chartPestcontrolValue).toFixed(2),
        chartInsurance: parseFloat(chartInsuranceValue).toFixed(2),
        chartManagefee: parseFloat(chartManagefeeValue).toFixed(2),

        chartRentalIncomeMonthly: unit1income,
        chartRentalIncomeMonthlyName: nkey,

        chartEstimatedName: estimatedName,
        chartEstimatedNameValue: estimatedNameValue,
        chartEstimatedValue: estimatedValue,
      });

      this.setState({
        pincode: userReportById.data.pincode,
        address: userReportById.data.address,
        report_name: userReportById.data.report_name,
        number_of_investors: userReportById.data.number_of_investors,
        purchange_price: userReportById.data.purchange_price,
        other_rental_income_monthly:
          userReportById.data.other_rental_income_monthly,
        other_rental_income_annually:
          userReportById.data.other_rental_income_annually,
        financing_type: userReportById.data.financing_type,
        financing_loan_type: userReportById.data.financing_loan_type,
        financing_amortization_term:
          userReportById.data.financing_amortization_term,
        financing_interest_rate: userReportById.data.financing_interest_rate,
        financing_down_payment: userReportById.data.financing_down_payment,
        financing_amount_financed:
          userReportById.data.financing_amount_financed,
        financing_reserve: userReportById.data.financing_reserve,
        renovation_cost: userReportById.data.renovation_cost,
        earnest_money_deposit: userReportById.data.earnest_money_deposit,
        property_inspection: userReportById.data.property_inspection,
        real_estate_transfer_tax: userReportById.data.real_estate_transfer_tax,
        title_insurance_fee: userReportById.data.title_insurance_fee,
        origination_fee: userReportById.data.origination_fee,
        appraisal_fees: userReportById.data.appraisal_fees,
        prop_manage_fee_annually: userReportById.data.prop_manage_fee_annually,
        prop_manage_fee_monthly: userReportById.data.prop_manage_fee_monthly,
        water_bill_annually: userReportById.data.water_bill_annually,
        water_bill_monthly: userReportById.data.water_bill_monthly,
        taxes_annually: userReportById.data.taxes_annually,
        taxes_monthly: userReportById.data.taxes_monthly,
        utilities_annually: userReportById.data.utilities_annually,
        utilities_monthly: userReportById.data.utilities_monthly,
        trash_pest_landscaping_annually:
          userReportById.data.trash_pest_landscaping_annually,
        trash_pest_landscaping_monthly:
          userReportById.data.trash_pest_landscaping_monthly,
        insurance_annually: userReportById.data.insurance_annually,
        insurance_monthly: userReportById.data.insurance_monthly,
        profit_gross_rental_income:
          userReportById.data.profit_gross_rental_income,
        profit_mortgage: userReportById.data.profit_mortgage,
        vacancy_plus_cap_ex: userReportById.data.vacancy_plus_cap_ex,
        profit_operating_expenses:
          userReportById.data.profit_operating_expenses,
        profit_pm_reserve: userReportById.data.profit_pm_reserve,
        profit_noi: userReportById.data.profit_noi,
        profit_cap_rate: userReportById.data.profit_cap_rate,
        metrics_one_persentage_rule:
          userReportById.data.metrics_one_persentage_rule,
        metrics_dscr: userReportById.data.metrics_dscr,
        metrics_op_ex_ratio: userReportById.data.metrics_op_ex_ratio,
        breakeven_discount_rate: userReportById.data.breakeven_discount_rate,
        breakeven_initial_investment:
          userReportById.data.breakeven_initial_investment,
        breakeven_yearsto_breakeven:
          userReportById.data.breakeven_yearsto_breakeven,
        breakeven_return_on_investment:
          userReportById.data.breakeven_return_on_investment,
        breakeven_np_value: userReportById.data.breakeven_np_value,
        monthly_rental_total: userReportById.data.monthly_rental_total,
        annually_rental_total: userReportById.data.annually_rental_total,
        down_payment_number: userReportById.data.down_payment_number,
        financing_total: userReportById.data.financing_total,
        closing_cost_total: userReportById.data.closing_cost_total,
        expences_monthly_total: userReportById.data.expences_monthly_total,
        expences_annually_total: userReportById.data.expences_annually_total,
        pass_or_invest: userReportById.data.pass_or_invest,
        reportid: userReportById.data.id,
        investors_investment_percentage:
          userReportById.data.investors_investment_percentage,
        rental_income_monthly: userReportById.data.rental_income_monthly,
        rental_income_annually: userReportById.data.rental_income_annually,
      });
    }
  }

  handleOnChangeInput = (event) => {
    var strValue = event.target.value.toString().replace(/,/g, "");
    this.setState({ [event.target.name]: strValue });
  };

  handleOnChangePurchangePriceInput = (event) => {
    var strValue = event.target.value.toString().replace(/,/g, "");
    this.setState({
      [event.target.name]: strValue,
      financing_down_payment: "",
      financing_amount_financed: "",
    });
  };

  handleOnChangeIncomeMonthlyText(idx, event) {
    let rental_income_monthly = [...this.state.rental_income_monthly];
    var strValue = event.target.value.toString().replace(/,/g, "");
    rental_income_monthly[idx] = strValue;
    console.log("strValue---", strValue);
    this.setState({ rental_income_monthly });
  }

  handleOnChangeIncomeAnnuallyText(idx, event) {
    let rental_income_annually = [...this.state.rental_income_annually];
    rental_income_annually[idx] = event.target.value.replace(",", "");
    this.setState({ rental_income_annually });
  }

  handleOnFocusDownPaymentInput = (event) => {
    const { purchange_price } = this.state;
    if (event.target.name == "financing_down_payment") {
      let financingAmount1 =
        purchange_price - (purchange_price * event.target.value) / 100;
      this.setState({
        financing_amount_financed: parseFloat(financingAmount1)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      });
    }
  };

  updateReportCalculate = () => {
    const {
      number_of_investors,
      purchange_price,
      other_rental_income_monthly,
      other_rental_income_annually,
      financing_type,
      financing_amortization_term,
      financing_interest_rate,
      financing_amount_financed,
      financing_reserve,
      renovation_cost,
      earnest_money_deposit,
      property_inspection,
      real_estate_transfer_tax,
      title_insurance_fee,
      origination_fee,
      appraisal_fees,
      prop_manage_fee_annually,
      prop_manage_fee_monthly,
      water_bill_annually,
      water_bill_monthly,
      taxes_annually,
      taxes_monthly,
      utilities_annually,
      utilities_monthly,
      trash_pest_landscaping_annually,
      trash_pest_landscaping_monthly,
      insurance_annually,
      insurance_monthly,
      profit_pm_reserve,
      profit_noi,
      metrics_dscr,
      metrics_op_ex_ratio,
      breakeven_discount_rate,
      breakeven_initial_investment,
      breakeven_yearsto_breakeven,
      breakeven_return_on_investment,
      breakeven_np_value,
      annually_rental_total,
      down_payment_number,
      closing_cost_total,
      rental_income_monthly,
      rental_income_annually,
    } = this.state;

    let incomeMonthlySum = 0;
    rental_income_monthly.map((rentalIncomeMonthly, keyIndx) => {
      var rentalIncomeMonthlyValue = rentalIncomeMonthly
        .toString()
        .replace(/,/g, "");
      incomeMonthlySum += parseFloat(rentalIncomeMonthlyValue);
    });

    let incomeAnnuallySum = 0;
    rental_income_annually.map((rentalIncomeAnnually, keyIndx) => {
      var rentalIncomeAnnuallyValue = rentalIncomeAnnually
        .toString()
        .replace(/,/g, "");
      incomeAnnuallySum += parseFloat(rentalIncomeAnnuallyValue);
    });

    var otherRentalIncomeAnnuallyValue = other_rental_income_annually
      .toString()
      .replace(/,/g, "");

    let monthlyRentalTotalAmount =
      incomeMonthlySum + parseFloat(other_rental_income_monthly);
    this.setState({
      monthly_rental_total: parseFloat(monthlyRentalTotalAmount).toFixed(2),
    });

    let annuallyRentalTotalAmount =
      incomeAnnuallySum + parseFloat(otherRentalIncomeAnnuallyValue);
    this.setState({
      annually_rental_total: parseFloat(annuallyRentalTotalAmount).toFixed(2),
    });

    let propManageFeeMonthlyAmount =
      (parseFloat(monthlyRentalTotalAmount) * 10) / 100;
    this.setState({ prop_manage_fee_monthly: propManageFeeMonthlyAmount });

    let propManageFeeAnnuallyAmount =
      (parseFloat(annuallyRentalTotalAmount) * 10) / 100;
    this.setState({ prop_manage_fee_annually: propManageFeeAnnuallyAmount });

    if (!financing_amount_financed) {
      var financingAmountFinancedValue = financing_amount_financed;
    } else {
      var financingAmountFinancedValue = financing_amount_financed
        .toString()
        .replace(/,/g, "");
    }

    if (financing_type == "loan") {
      var originationFeeAmount = (parseFloat(purchange_price) * 1) / 100;
      var downPaymentNumberAmount =
        parseFloat(purchange_price) - parseFloat(financingAmountFinancedValue);
      var financingTotalAmount =
        downPaymentNumberAmount +
        parseFloat(renovation_cost) +
        parseFloat(financing_reserve);
      var earnestMoneyDepositAmount = (parseFloat(purchange_price) * 2) / 100;

      // console.log("origination_fee_if---", originationFeeAmount);
      // console.log("down_payment_number_if---", downPaymentNumberAmount);
      // console.log("financing_total_if---", financingTotalAmount);
      // console.log("earnest_money_deposit_if---", earnestMoneyDepositAmount);

      this.setState({
        origination_fee: parseFloat(originationFeeAmount).toFixed(2),
        down_payment_number: parseFloat(downPaymentNumberAmount).toFixed(2),
        financing_total: parseFloat(financingTotalAmount).toFixed(2),
        earnest_money_deposit: parseFloat(earnestMoneyDepositAmount).toFixed(2),
      });
    } else {
      var originationFeeAmount = 0;
      var downPaymentNumberAmount = 0;
      var financingTotalAmount =
        downPaymentNumberAmount +
        parseFloat(renovation_cost) +
        parseFloat(financing_reserve);
      var earnestMoneyDepositAmount = (parseFloat(purchange_price) * 2) / 100;

      this.setState({
        origination_fee: parseFloat(originationFeeAmount).toFixed(2),
        down_payment_number: parseFloat(downPaymentNumberAmount).toFixed(2),
        financing_total: parseFloat(financingTotalAmount).toFixed(2),
        earnest_money_deposit: parseFloat(earnestMoneyDepositAmount).toFixed(2),
      });
    }

    var originationFeeValue = originationFeeAmount;

    let closingCostTotalAmount =
      earnestMoneyDepositAmount +
      parseFloat(property_inspection) +
      parseFloat(real_estate_transfer_tax) +
      parseFloat(title_insurance_fee) +
      parseFloat(originationFeeValue) +
      parseFloat(appraisal_fees);

    var persentageAmount = (purchange_price / 100) * 5;
    var purchange_5_persent = parseFloat(persentageAmount).toFixed(2);

    var cost_max = 0;
    if (purchange_5_persent > closingCostTotalAmount) {
      cost_max = purchange_5_persent;
    } else {
      cost_max = closingCostTotalAmount;
    }
    this.setState({ closing_cost_total: cost_max });

    // TOTAL expences monthly Start
    let expencesMonthlyTotalAmount1 =
      propManageFeeMonthlyAmount +
      parseFloat(water_bill_monthly) +
      parseFloat(taxes_monthly) +
      parseFloat(utilities_monthly) +
      parseFloat(trash_pest_landscaping_monthly) +
      parseFloat(insurance_monthly);
    let capExMonthly = (parseFloat(annuallyRentalTotalAmount) / 12 / 100) * 3;
    let vacancyMonthly = (parseFloat(annuallyRentalTotalAmount) / 12 / 100) * 5;

    let i = parseFloat(financing_interest_rate) / 1200;
    let n = parseFloat(financing_amortization_term);
    let p = parseFloat(financingAmountFinancedValue);

    if (financing_type == "loan") {
      var monthlyPmt = (i * p * Math.pow(1 + i, n)) / (1 - Math.pow(1 + i, n));
    } else {
      var monthlyPmt = 0;
    }

    let monthlyPmt1 = monthlyPmt.toFixed(2);

    let expencesMonthlyTotalAmount =
      parseFloat(expencesMonthlyTotalAmount1) +
      parseFloat(capExMonthly) +
      parseFloat(vacancyMonthly) -
      parseFloat(monthlyPmt).toFixed(2);
    this.setState({
      expences_monthly_total:
        parseFloat(expencesMonthlyTotalAmount).toFixed(2) || 0.0,
    });

    // TOTAL expences annually Start
    let expencesAnnuallyTotalAmount1 =
      propManageFeeAnnuallyAmount +
      parseFloat(water_bill_annually) +
      parseFloat(taxes_annually) +
      parseFloat(utilities_annually) +
      parseFloat(trash_pest_landscaping_annually) +
      parseFloat(insurance_annually);
    let capExAnnually = capExMonthly * 12;
    let vacancyAnnually = vacancyMonthly * 12;
    let expencesAnnuallyTotalAmount =
      parseFloat(expencesAnnuallyTotalAmount1) +
      parseFloat(capExAnnually) +
      parseFloat(vacancyAnnually);
    this.setState({
      expences_annually_total:
        parseFloat(expencesAnnuallyTotalAmount).toFixed(2) || 0.0,
    });

    if (financing_type == "loan") {
      var mortgage =
        (parseFloat(taxes_monthly) +
          parseFloat(insurance_monthly) -
          parseFloat(monthlyPmt).toFixed(2)) *
        12;
    } else {
      var mortgage = 0;
    }

    let vacancyPlusCapExTotal =
      parseFloat(capExAnnually) + parseFloat(vacancyAnnually);
    var operatingTotal = 0;
    if (financing_type == "loan") {
      let result1 =
        parseFloat(expencesMonthlyTotalAmount) + parseFloat(monthlyPmt1);
      let result2 =
        parseFloat(result1).toFixed(2) - parseFloat(insurance_monthly);
      let result3 = parseFloat(result2) - parseFloat(taxes_monthly);
      operatingTotal = parseFloat(result3) * 12;
    } else {
      operatingTotal = expencesAnnuallyTotalAmount;
    }

    let profitPmReserveTotal = parseFloat(mortgage) / 12;

    let resultNoiTotal = parseFloat(mortgage) + parseFloat(operatingTotal);
    let profitNoiCal =
      parseFloat(annuallyRentalTotalAmount) - parseFloat(resultNoiTotal);

    let profitCapRateResultValue = profitNoiCal * 100;
    let profitCapRateResult =
      parseFloat(profitCapRateResultValue) / purchange_price;

    this.setState({
      profit_gross_rental_income:
        parseFloat(annuallyRentalTotalAmount)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0.0,
      profit_mortgage:
        parseFloat(mortgage)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0.0,
      vacancy_plus_cap_ex: parseFloat(vacancyPlusCapExTotal).toFixed(2) || 0.0,
      profit_operating_expenses:
        parseFloat(operatingTotal)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0.0,
      profit_pm_reserve:
        parseFloat(profitPmReserveTotal)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0.0,
      profit_noi:
        parseFloat(profitNoiCal)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0.0,
      profit_cap_rate: parseFloat(profitCapRateResult).toFixed(2) || 0.0,
    });

    // Metrics Start
    let grossRentalMonthly = parseFloat(annuallyRentalTotalAmount) / 12;
    let ruleMetResult =
      parseFloat(grossRentalMonthly) / parseFloat(purchange_price);
    if (ruleMetResult >= 0.01) {
      this.setState({
        metrics_one_persentage_rule: "Yes",
      });
    } else {
      this.setState({
        metrics_one_persentage_rule: "No",
      });
    }

    if (financing_type == "loan") {
      let metricsDscrResult =
        parseFloat(annuallyRentalTotalAmount) - parseFloat(operatingTotal);
      var metricsDscrFinalResult =
        parseFloat(metricsDscrResult) / parseFloat(mortgage);
    } else {
      let metricsDscrResult =
        parseFloat(annuallyRentalTotalAmount) - parseFloat(operatingTotal);
      var metricsDscrFinalResult =
        parseFloat(metricsDscrResult) / parseFloat(vacancyPlusCapExTotal);
    }

    let metricsOpExRatioResult =
      parseFloat(profitPmReserveTotal) + parseFloat(operatingTotal);
    let metricsOpExRatioResult1 = metricsOpExRatioResult * 100;
    let metricsOpExRatioFinalResult =
      metricsOpExRatioResult1 / parseFloat(annuallyRentalTotalAmount);
    var metricsDscrFinalResultValue = metricsDscrFinalResult.toFixed(2);
    var metricsOpExRatioFinalResultValue = Math.round(
      metricsOpExRatioFinalResult
    );
    this.setState({
      metrics_dscr: parseFloat(metricsDscrFinalResult).toFixed(2) || 0.0,
      metrics_op_ex_ratio: Math.round(metricsOpExRatioFinalResult) || 0,
    });

    var profitPmReserveValue = profitPmReserveTotal;
    var profitNoiValue = profitNoiCal;
    // console.log('profit_pm_reserve',profitNoiValue)

    // let downPaymentNumberResult = parseFloat(down_payment_number) - parseFloat(earnest_money_deposit);
    if (financing_type == "loan") {
      var downPaymentNumberResult =
        downPaymentNumberAmount - earnestMoneyDepositAmount;
    } else {
      var downPaymentNumberResult = parseFloat(purchange_price);
    }
    let closingWithoutEarnest = cost_max - earnestMoneyDepositAmount;
    var initialInvestment =
      downPaymentNumberResult +
      parseFloat(renovation_cost) +
      parseFloat(financing_reserve) +
      closingWithoutEarnest +
      parseFloat(profitPmReserveValue);
    let initialInvestmentFinalResult = parseFloat(initialInvestment).toFixed(2);
    let breakevenYearstoBreakevenResult =
      parseFloat(initialInvestmentFinalResult) / parseFloat(profitNoiValue);

    let noi = parseFloat(profitNoiValue) * 100;
    let breakevenReturnOnInvestmentResult =
      parseFloat(noi) / parseFloat(initialInvestmentFinalResult);

    if (financing_type == "loan") {
      let r = parseFloat(breakeven_discount_rate) / 100 + 1;
      let arr = [];
      let i = 1;
      for (i = 1; i <= 5; i++) {
        let rPow = Math.pow(r, i);
        let cashFlow = parseFloat(profitNoiValue) / rPow;
        if (i == 5) {
          let cashFlowFive =
            (parseFloat(purchange_price) * r + parseFloat(profitNoiValue)) /
            rPow;
          arr.push(cashFlowFive.toFixed(2));
        } else {
          arr.push(cashFlow.toFixed(2));
        }
      }

      let total = 0;
      arr.forEach(function (item) {
        total = Number(total) + Number(item);
      });

      var finalNpv = total - parseFloat(initialInvestmentFinalResult);
    } else {
      var finalNpv = 0;
    }

    this.setState({
      breakeven_initial_investment:
        parseFloat(initialInvestment)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0.0,
      breakeven_yearsto_breakeven:
        Math.round(breakevenYearstoBreakevenResult) || 0,
      breakeven_return_on_investment:
        parseFloat(breakevenReturnOnInvestmentResult).toFixed(2) || 0.0,
      breakeven_np_value:
        parseFloat(finalNpv)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0.0,
    });

    var profitNoiValue = profitNoiCal;
    var breakevenNpValue = breakeven_np_value.toString().replace(/,/g, "");
    var breakevenInitialInvestmentValue = initialInvestment;

    //G15
    var monthlyNoi = parseFloat(profitNoiValue) / 12;
    var monthlyNoiPerInvester = monthlyNoi / parseFloat(number_of_investors);

    //G22
    var g22 = parseFloat(breakeven_return_on_investment);
    var v1 = parseFloat(g22 / 100).toFixed(2);
    var v1_cash = parseFloat(g22 / 100).toFixed(4);
    //G15
    var v2 = monthlyNoiPerInvester;
    //G24
    var v3 = parseFloat(breakevenNpValue);
    //G20
    var v4 = parseFloat(breakevenInitialInvestmentValue);

    //G5
    var v5 = parseFloat(metricsDscrFinalResultValue);
    //G21
    var v6 = parseFloat(breakeven_yearsto_breakeven);
    //G6
    var g6 = metricsOpExRatioFinalResultValue;
    var v7 = parseFloat(g6 / 100).toFixed(2);

    //H15
    var v8 = monthlyNoiPerInvester;

    if (financing_type == "loan") {
      if (
        v1 >= 0.08 &&
        v2 > 125 &&
        v3 > v4 &&
        v5 > 1.2 &&
        v6 < 15 &&
        v7 < 0.5
      ) {
        this.setState({ pass_or_invest: "Potential" });
      } else {
        if (v8 > 99 && v1 > 0.1) {
          this.setState({ pass_or_invest: "Potential" });
        } else {
          this.setState({ pass_or_invest: "Pass" });
        }
      }
    } else {
      if (v1_cash >= 0.0799 && v2 > 125 && v5 > 1.2 && v6 < 15 && v7 < 0.5) {
        this.setState({ pass_or_invest: "Potential" });
      } else {
        if (v8 > 99 && v1 > 0.1) {
          this.setState({ pass_or_invest: "Potential" });
        } else {
          this.setState({ pass_or_invest: "Pass" });
        }
      }
    }
  };

  updateReportHandler = (props) => {
    // console.log("this.state11111---", this.state)
    if (this.state.address == "") {
      var status = false;
    } else {
      var status = true;
    }
    const {
      actions: { updateReport },
    } = this.props;
    updateReport({
      _token: "J1RHvOM1M1ieJZLBVSumlfvRJJDFL2A4VBjGL38W",
      api_status: status,
      ...this.state,
    });
  };

  handleOnUpdateReport = async () => {
    await this.updateReportCalculate();
    await this.updateReportHandler();
  };

  render() {
    const {
      userReportData,
      userApiData,
      downPayment,
      chartRenovationCost,
      totalDownPayment,
      tinvestment,
      resPurchangePrice,
      chartRentalIncome,
      chartOperatingExpenses,
      chartPropertyManagementReserve,
      chartNoi,
      chartWaterbill,
      chartTaxes,
      chartUtilities,
      chartPestcontrol,
      chartInsurance,
      chartManagefee,
      chartRentalIncomeMonthly,
      chartRentalIncomeMonthlyName,
      chartEstimatedName,
      chartEstimatedValue,
      purchange_price,
      other_rental_income_monthly,
      other_rental_income_annually,
      financing_amortization_term,
      financing_interest_rate,
      financing_down_payment,
      financing_amount_financed,
      financing_reserve,
      renovation_cost,
      water_bill_annually,
      water_bill_monthly,
      taxes_annually,
      taxes_monthly,
      utilities_annually,
      utilities_monthly,
      trash_pest_landscaping_annually,
      trash_pest_landscaping_monthly,
      insurance_annually,
      insurance_monthly,
      rental_income_monthly,
      rental_income_annually,
      breakevenNpValue,
      latitude,
      longitude,
    } = this.state;
    return (
      <>
        <Helmet
          title="Rent Potential"
          metaName="description"
          metaContent="Rent Potential"
        />
        <div className="main-content">
          <Header
            pageTitle="Real Estate Investment"
            pageSubTitle="CALCULATOR"
            buttonText="read More"
            arrowRightImg={arrowRight}
            pageHeaderImg={calculator}
          />

          <div className="container-fluid container-padding">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <Tabs>
                  <TabList className="report-tab">
                    <Tab>Investment</Tab>
                    {userApiData ? (
                      <>
                        <Tab>Property Info</Tab>
                        <Tab>Comps</Tab>
                      </>
                    ) : null}
                  </TabList>
                  <TabPanel>
                    <div className="tab-bg-gray">
                      <div className="row">
                        <div className="col-md-8 offset-md-2">
                          {userReportData.pass_or_invest == "Potential" ? (
                            <h2 className="report-tab-title">
                              This property has potential
                            </h2>
                          ) : (
                            <h2 className="report-tab-title text-danger">
                              We would pass on this property! -3
                            </h2>
                          )}
                          <p className="report-tab-text">
                            {userReportData.metrics_one_persentage_rule ==
                            "Yes" ? (
                              <>
                                The <strong>1%</strong> rule is met for this
                                property!
                              </>
                            ) : (
                              <>
                                The <strong>1%</strong> rule is not met for this
                                property!
                              </>
                            )}{" "}
                            Your total initial investment will be{" "}
                            <strong>${tinvestment}</strong>. This deal has an
                            ROI of{" "}
                            <strong>
                              {userReportData.breakeven_return_on_investment}%
                            </strong>{" "}
                            {userReportData.financing_type == "loan" ? (
                              <>
                                , DSCR of{" "}
                                <strong>{userReportData.metrics_dscr}x</strong>
                              </>
                            ) : null}{" "}
                            , Operating Expense ratio of{" "}
                            <strong>
                              {userReportData.metrics_op_ex_ratio}%
                            </strong>{" "}
                            and will take{" "}
                            <strong>
                              {userReportData.breakeven_yearsto_breakeven}
                            </strong>{" "}
                            years to break even.{" "}
                            {userReportData.financing_type == "loan" ? (
                              <>
                                The property has a net present value of{" "}
                                <strong>${breakevenNpValue}.</strong>
                              </>
                            ) : null}{" "}
                            This property would provide tax savings via annual
                            depreciation of{" "}
                            <strong>${resPurchangePrice}</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="report-tab-chart-section">
                      <div className="row m-t-20">
                        <div className="col-md-6 m-t-10">
                          <div className="report-tab-chart-sub">
                            <h3 className="report-tab-chart-title">
                              Rental Income
                            </h3>
                            <div className="table-responsive">
                              <table className="table report-tab-table">
                                <tr>
                                  <td className="td-width-44">
                                    Total Investors
                                  </td>
                                  <td className=""></td>
                                  <td className="font-color-gray">
                                    {userReportData.number_of_investors}
                                  </td>
                                </tr>
                                {userReportData.investors_investment_percentage &&
                                  userReportData.investors_investment_percentage
                                    .length > 0 &&
                                  userReportData.investors_investment_percentage.map(
                                    (investorsPercentage, keyIndx) => {
                                      return (
                                        <tr key={keyIndx}>
                                          <td className="td-width-44">
                                            % of investors money
                                          </td>
                                          <td className="font-color-gray">
                                            Investor {keyIndx + 1}
                                          </td>
                                          <td className="font-color-gray">
                                            {investorsPercentage}%
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                {userReportData.rental_income_monthly &&
                                  userReportData.rental_income_monthly.length >
                                    0 &&
                                  userReportData.rental_income_monthly.map(
                                    (rentalIncomeMonthly, keyIndx) => {
                                      return (
                                        <tr key={keyIndx}>
                                          <td className="td-width-44">
                                            Unit {keyIndx + 1}
                                          </td>
                                          <td className="font-color-gray">
                                            ${" "}
                                            <CurrencyInput
                                              type="text"
                                              name={`rental_income_monthly${keyIndx}`}
                                              value={
                                                parseFloat(
                                                  rental_income_monthly[keyIndx]
                                                ).toFixed(2) || ""
                                              }
                                              onChange={this.handleOnChangeIncomeMonthlyText.bind(
                                                this,
                                                keyIndx
                                              )}
                                              onBlur={() => {
                                                let rentalIncomeAnnuallyTotal =
                                                  parseFloat(
                                                    rental_income_monthly[
                                                      keyIndx
                                                    ]
                                                  ) * 12;
                                                let rental_income_annually = [
                                                  ...this.state
                                                    .rental_income_annually,
                                                ];
                                                rental_income_annually[
                                                  keyIndx
                                                ] = Math.round(
                                                  parseFloat(
                                                    rentalIncomeAnnuallyTotal
                                                  ).toFixed(2)
                                                )
                                                  .toString()
                                                  .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  );
                                                this.setState({
                                                  rental_income_annually,
                                                });
                                              }}
                                            />{" "}
                                            (Monthly)
                                          </td>

                                          <td className="font-color-gray">
                                            ${" "}
                                            <input
                                              type="text"
                                              name={`rental_income_annually${keyIndx}`}
                                              value={
                                                rental_income_annually[
                                                  keyIndx
                                                ] || ""
                                              }
                                              onChange={this.handleOnChangeIncomeAnnuallyText.bind(
                                                this,
                                                keyIndx
                                              )}
                                              disabled
                                            />{" "}
                                            (Annually)
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                <tr>
                                  <td className="td-width-44">
                                    Rent for garage/parking
                                  </td>
                                  <td className="font-color-gray">
                                    ${" "}
                                    <CurrencyInput
                                      type="text"
                                      name="other_rental_income_monthly"
                                      value={
                                        parseFloat(
                                          other_rental_income_monthly
                                        ).toFixed(2) || ""
                                      }
                                      onChange={this.handleOnChangeInput}
                                      onBlur={() => {
                                        let other_rental_income_annually123 =
                                          parseFloat(
                                            other_rental_income_monthly
                                          ) * 12;
                                        let otherRentalIncomeAnnually =
                                          Math.round(
                                            parseFloat(
                                              other_rental_income_annually123
                                            ).toFixed(2)
                                          )
                                            .toString()
                                            .replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                            );
                                        // console.log("ddd---",ddd)
                                        this.setState({
                                          other_rental_income_annually:
                                            otherRentalIncomeAnnually,
                                        });
                                      }}
                                    />{" "}
                                    (Monthly)
                                  </td>
                                  <td className="font-color-gray">
                                    ${" "}
                                    <input
                                      type="text"
                                      name="other_rental_income_annually"
                                      value={other_rental_income_annually || ""}
                                      onChange={this.handleOnChangeInput}
                                      disabled
                                    />{" "}
                                    (Annually)
                                  </td>
                                </tr>
                                <tr className="bg-white-tr">
                                  <td className="td-width-44">Total Income</td>
                                  <td className="">
                                    $
                                    {parseFloat(
                                      userReportData.monthly_rental_total
                                    )
                                      .toFixed(2)
                                      .toString()
                                      .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}{" "}
                                    (Monthly)
                                  </td>
                                  <td className="">
                                    $
                                    {parseFloat(
                                      userReportData.annually_rental_total
                                    )
                                      .toFixed(2)
                                      .toString()
                                      .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}{" "}
                                    (Annually)
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 m-t-10">
                          <div className="report-tab-chart-sub">
                            <h3 className="report-tab-chart-title">
                              Rental Income
                            </h3>
                            <ReactEcharts
                              option={{
                                color: "#3AA6B4",
                                xAxis: {
                                  type: "category",
                                  axisLine: {
                                    show: false,
                                  },
                                  axisTick: {
                                    show: false,
                                  },
                                  axisLabel: {
                                    formatter: "{value}",
                                  },
                                  // data: ['Unit 1\n$5,000.00', 'Unit 2\n$3,000.00', 'Garage Rent\n$3,000.00']
                                  data: chartRentalIncomeMonthlyName,
                                },
                                yAxis: {
                                  type: "value",
                                  axisLine: {
                                    show: false,
                                  },
                                  axisTick: {
                                    show: false,
                                  },
                                  axisLabel: {
                                    formatter: "{value}%",
                                  },
                                },
                                series: [
                                  {
                                    type: "bar",
                                    smooth: true,
                                    barWidth: "20%",
                                    // data: ['55', '20', '30']
                                    data: chartRentalIncomeMonthly,
                                  },
                                ],
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row m-t-20">
                        <div className="col-md-6 m-t-10">
                          <div className="report-tab-chart-sub">
                            <h3 className="report-tab-chart-title">
                              Operating Expenses
                            </h3>
                            <div className="table-responsive">
                              <table className="table report-tab-table">
                                <tr>
                                  <td className="td-width-44">Water bill</td>
                                  <td className="font-color-gray">
                                    ${" "}
                                    <CurrencyInput
                                      type="text"
                                      name="water_bill_monthly"
                                      value={
                                        parseFloat(water_bill_monthly).toFixed(
                                          2
                                        ) || ""
                                      }
                                      onChange={this.handleOnChangeInput}
                                      disabled
                                    />{" "}
                                    (Monthly)
                                  </td>
                                  <td className="font-color-gray">
                                    ${" "}
                                    <CurrencyInput
                                      type="text"
                                      name="water_bill_annually"
                                      value={
                                        parseFloat(water_bill_annually).toFixed(
                                          2
                                        ) || ""
                                      }
                                      onChange={this.handleOnChangeInput}
                                      onBlur={() => {
                                        let waterBillMonthlyCal =
                                          water_bill_annually / 12;
                                        this.setState({
                                          water_bill_monthly:
                                            parseFloat(
                                              waterBillMonthlyCal
                                            ).toFixed(2),
                                        });
                                      }}
                                    />{" "}
                                    (Annually)
                                  </td>
                                </tr>
                                <tr>
                                  <td className="td-width-44">Taxes</td>
                                  <td className="font-color-gray">
                                    ${" "}
                                    <CurrencyInput
                                      type="text"
                                      name="taxes_monthly"
                                      value={
                                        parseFloat(taxes_monthly).toFixed(2) ||
                                        ""
                                      }
                                      onChange={this.handleOnChangeInput}
                                      disabled
                                    />{" "}
                                    (Monthly)
                                  </td>
                                  <td className="font-color-gray">
                                    ${" "}
                                    <CurrencyInput
                                      type="text"
                                      name="taxes_annually"
                                      value={
                                        parseFloat(taxes_annually).toFixed(2) ||
                                        ""
                                      }
                                      onChange={this.handleOnChangeInput}
                                      onBlur={() => {
                                        let taxesMonthlyCal =
                                          taxes_annually / 12;
                                        this.setState({
                                          taxes_monthly:
                                            parseFloat(taxesMonthlyCal).toFixed(
                                              2
                                            ),
                                        });
                                      }}
                                    />{" "}
                                    (Annually)
                                  </td>
                                </tr>
                                <tr>
                                  <td className="td-width-44">Utilities</td>
                                  <td className="font-color-gray">
                                    ${" "}
                                    <CurrencyInput
                                      type="text"
                                      name="utilities_monthly"
                                      value={
                                        parseFloat(utilities_monthly).toFixed(
                                          2
                                        ) || ""
                                      }
                                      onChange={this.handleOnChangeInput}
                                      disabled
                                    />{" "}
                                    (Monthly)
                                  </td>
                                  <td className="font-color-gray">
                                    ${" "}
                                    <CurrencyInput
                                      type="text"
                                      name="utilities_annually"
                                      value={
                                        parseFloat(utilities_annually).toFixed(
                                          2
                                        ) || ""
                                      }
                                      onChange={this.handleOnChangeInput}
                                      onBlur={() => {
                                        let utilitiesMonthlyCal =
                                          utilities_annually / 12;
                                        this.setState({
                                          utilities_monthly:
                                            parseFloat(
                                              utilitiesMonthlyCal
                                            ).toFixed(2),
                                        });
                                      }}
                                    />{" "}
                                    (Annually)
                                  </td>
                                </tr>
                                <tr>
                                  <td className="td-width-44">
                                    Trash Removal/Pest Control/Landscaping
                                  </td>
                                  <td className="font-color-gray">
                                    ${" "}
                                    <CurrencyInput
                                      type="text"
                                      name="trash_pest_landscaping_monthly"
                                      value={
                                        parseFloat(
                                          trash_pest_landscaping_monthly
                                        ).toFixed(2) || ""
                                      }
                                      onChange={this.handleOnChangeInput}
                                      disabled
                                    />{" "}
                                    (Monthly)
                                  </td>
                                  <td className="font-color-gray">
                                    ${" "}
                                    <CurrencyInput
                                      type="text"
                                      name="trash_pest_landscaping_annually"
                                      value={
                                        parseFloat(
                                          trash_pest_landscaping_annually
                                        ).toFixed(2) || ""
                                      }
                                      onChange={this.handleOnChangeInput}
                                      onBlur={() => {
                                        let trashPestLandscapingMonthlyCal =
                                          trash_pest_landscaping_annually / 12;
                                        this.setState({
                                          trash_pest_landscaping_monthly:
                                            parseFloat(
                                              trashPestLandscapingMonthlyCal
                                            ).toFixed(2),
                                        });
                                      }}
                                    />{" "}
                                    (Annually)
                                  </td>
                                </tr>
                                <tr>
                                  <td className="td-width-44">Insurance</td>
                                  <td className="font-color-gray">
                                    ${" "}
                                    <CurrencyInput
                                      type="text"
                                      name="insurance_monthly"
                                      value={
                                        parseFloat(insurance_monthly).toFixed(
                                          2
                                        ) || ""
                                      }
                                      onChange={this.handleOnChangeInput}
                                      disabled
                                    />{" "}
                                    (Monthly)
                                  </td>
                                  <td className="font-color-gray">
                                    ${" "}
                                    <CurrencyInput
                                      type="text"
                                      name="insurance_annually"
                                      value={
                                        parseFloat(insurance_annually).toFixed(
                                          2
                                        ) || ""
                                      }
                                      onChange={this.handleOnChangeInput}
                                      onBlur={() => {
                                        let insuranceMonthlyCal =
                                          insurance_annually / 12;
                                        this.setState({
                                          insurance_monthly:
                                            parseFloat(
                                              insuranceMonthlyCal
                                            ).toFixed(2),
                                        });
                                      }}
                                    />{" "}
                                    (Annually)
                                  </td>
                                </tr>
                                <tr>
                                  <td className="td-width-44">
                                    Property management fee
                                  </td>
                                  <td className="font-color-gray">
                                    ${userReportData.prop_manage_fee_monthly}{" "}
                                    (Monthly)
                                  </td>
                                  <td className="font-color-gray">
                                    ${userReportData.prop_manage_fee_annually}{" "}
                                    (Annually)
                                  </td>
                                </tr>
                                <tr className="bg-white-tr">
                                  <td className="td-width-44">
                                    Total Expenses including Vacancy (5%) and
                                    CapEx (3%)
                                  </td>
                                  <td className="">
                                    $
                                    {parseFloat(
                                      userReportData.expences_monthly_total
                                    )
                                      .toFixed(2)
                                      .toString()
                                      .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}{" "}
                                    (Monthly)
                                  </td>
                                  <td className="">
                                    $
                                    {parseFloat(
                                      userReportData.expences_annually_total
                                    )
                                      .toFixed(2)
                                      .toString()
                                      .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}{" "}
                                    (Annually)
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 m-t-10">
                          <div className="report-tab-chart-sub">
                            <h3 className="report-tab-chart-title">
                              Operating Expenses
                            </h3>
                            <ReactEcharts
                              className="echarts-margin"
                              option={{
                                series: [
                                  {
                                    type: "pie",
                                    radius: ["50%", "70%"],
                                    center: ["50%", "50%"],
                                    selectedMode: "single",
                                    data: [
                                      {
                                        value: chartManagefee,
                                        itemStyle: { color: "#1d7ff0" },
                                        label: {
                                          formatter: [
                                            "{title1|{c}%}",
                                            "{title2|Property management fee}",
                                            "{br|}",
                                          ].join("\n"),
                                          rich: {
                                            title1: {
                                              align: "left",
                                              fontSize: "14",
                                            },
                                            title2: {
                                              color: "#999999",
                                              align: "left",
                                              fontSize: "14",
                                            },
                                          },
                                        },
                                      },
                                      {
                                        value: chartWaterbill,
                                        itemStyle: { color: "#cd78f1" },
                                        label: {
                                          formatter: [
                                            "{title1|{c}%}",
                                            "{title2|Water bill}",
                                            "{br|}",
                                          ].join("\n"),
                                          rich: {
                                            title1: {
                                              align: "right",
                                              fontSize: "14",
                                            },
                                            title2: {
                                              color: "#999999",
                                              align: "right",
                                              fontSize: "14",
                                            },
                                          },
                                        },
                                      },
                                      {
                                        value: chartUtilities,
                                        itemStyle: { color: "#bde955" },
                                        label: {
                                          formatter: [
                                            "{title1|{c}%}",
                                            "{title2|Utilities}",
                                            "{br|}",
                                          ].join("\n"),
                                          rich: {
                                            title1: {
                                              align: "right",
                                              fontSize: "14",
                                            },
                                            title2: {
                                              color: "#999999",
                                              align: "right",
                                              fontSize: "14",
                                            },
                                          },
                                        },
                                      },
                                      {
                                        value: chartTaxes,
                                        itemStyle: { color: "#7be1b1" },
                                        label: {
                                          formatter: [
                                            "{title1|{c}%}",
                                            "{title2|Taxes}",
                                            "{br|}",
                                          ].join("\n"),
                                          rich: {
                                            title1: {
                                              align: "right",
                                              fontSize: "14",
                                            },
                                            title2: {
                                              color: "#999999",
                                              align: "right",
                                              fontSize: "14",
                                            },
                                          },
                                        },
                                      },
                                      {
                                        value: chartPestcontrol,
                                        itemStyle: { color: "#f0a808" },
                                        label: {
                                          formatter: [
                                            "{title1|{c}%}",
                                            "{title2|Trash Removal/Pest Control/}",
                                            "{title3|Landscaping}",
                                            "{br|}",
                                          ].join("\n"),
                                          rich: {
                                            title1: {
                                              align: "right",
                                              fontSize: "14",
                                            },
                                            title2: {
                                              color: "#999999",
                                              align: "right",
                                              fontSize: "14",
                                            },
                                            title3: {
                                              color: "#999999",
                                              align: "right",
                                              fontSize: "14",
                                            },
                                          },
                                        },
                                      },
                                      {
                                        value: chartInsurance,
                                        itemStyle: { color: "#fc5532" },
                                        label: {
                                          formatter: [
                                            "{title1|{c}%}",
                                            "{title2|Insurance}",
                                            "{br|}",
                                          ].join("\n"),
                                          rich: {
                                            title1: {
                                              align: "right",
                                              fontSize: "14",
                                            },
                                            title2: {
                                              color: "#999999",
                                              align: "right",
                                              fontSize: "14",
                                            },
                                          },
                                        },
                                      },
                                    ],
                                  },
                                ],
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row m-t-20">
                        <div className="col-md-6 m-t-10">
                          <div className="report-tab-chart-sub">
                            <h3 className="report-tab-chart-title">
                              Annual Profit
                            </h3>
                            <div className="table-responsive">
                              <table className="table report-tab-table">
                                <tr>
                                  <td className="">Rental Income</td>
                                  <td className="font-color-gray">
                                    ${userReportData.profit_gross_rental_income}
                                  </td>
                                </tr>
                                {userReportData.financing_type == "loan" ? (
                                  <>
                                    <tr>
                                      <td className="">Mortgage</td>
                                      <td className="font-color-gray">
                                        ${userReportData.profit_mortgage}
                                      </td>
                                    </tr>
                                  </>
                                ) : null}
                                <tr>
                                  <td className="">Operating Expenses</td>
                                  <td className="font-color-gray">
                                    ${userReportData.profit_operating_expenses}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="">
                                    Property Management Reserve
                                  </td>
                                  <td className="font-color-gray">
                                    ${userReportData.profit_pm_reserve}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="">NOI</td>
                                  <td className="font-color-gray">
                                    ${userReportData.profit_noi}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="">Cap Rate</td>
                                  <td className="font-color-gray">
                                    {userReportData.profit_cap_rate}
                                  </td>
                                </tr>
                                <tr className="bg-white-tr">
                                  {userReportData.financing_type == "loan" ? (
                                    <>
                                      <td className="">Profit</td>
                                    </>
                                  ) : (
                                    <>
                                      <td className="">Profit</td>
                                    </>
                                  )}

                                  <td className="">
                                    {userReportData.investors_investment_percentage &&
                                      userReportData
                                        .investors_investment_percentage
                                        .length > 0 &&
                                      userReportData.investors_investment_percentage.map(
                                        (investorsPercentage, keyIndx) => {
                                          let persentageAmount =
                                            (chartNoi / 100) *
                                            investorsPercentage;
                                          let rtyu = parseFloat(
                                            persentageAmount
                                          )
                                            .toFixed(2)
                                            .toString()
                                            .replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                            );
                                          return (
                                            <>
                                              Investor {keyIndx + 1} (
                                              {investorsPercentage}%) {rtyu}
                                              <br />
                                            </>
                                          );
                                        }
                                      )}
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 m-t-10">
                          <div className="report-tab-chart-sub">
                            <h3 className="report-tab-chart-title">
                              Annual Profit
                            </h3>
                            <ReactEcharts
                              option={{
                                color: "#F0A808",
                                tooltip: {
                                  trigger: "axis",
                                  // axisPointer: {
                                  // 	type: 'line'        // 默认为直线，可选为：'line' | 'shadow'
                                  // }
                                },
                                xAxis: {
                                  type: "category",
                                  axisLine: {
                                    show: false,
                                  },
                                  axisTick: {
                                    show: false,
                                  },
                                  data: [
                                    "Rental\nIncome",
                                    "Operating\nExpenses",
                                    "Property\nManagement\nReserve",
                                    "NOI",
                                  ],
                                },
                                yAxis: {
                                  type: "value",
                                  axisLine: {
                                    show: false,
                                  },
                                  axisTick: {
                                    show: false,
                                  },
                                  axisLabel: {
                                    formatter: "${value}",
                                  },
                                },
                                series: [
                                  {
                                    type: "bar",
                                    smooth: true,
                                    barWidth: "20%",
                                    data: [
                                      {
                                        value: chartRentalIncome,
                                        itemStyle:
                                          chartRentalIncome > 0
                                            ? { color: "#F0A808" }
                                            : { color: "rgb(242, 40, 13)" },
                                      },
                                      {
                                        value: chartOperatingExpenses,
                                        itemStyle:
                                          chartOperatingExpenses > 0
                                            ? { color: "#F0A808" }
                                            : { color: "rgb(242, 40, 13)" },
                                      },
                                      {
                                        value: chartPropertyManagementReserve,
                                        itemStyle:
                                          chartPropertyManagementReserve > 0
                                            ? { color: "#F0A808" }
                                            : { color: "rgb(242, 40, 13)" },
                                      },
                                      {
                                        value: chartNoi,
                                        itemStyle:
                                          chartNoi > 0
                                            ? { color: "#F0A808" }
                                            : { color: "rgb(242, 40, 13)" },
                                      },
                                    ],
                                    // data: [chartRentalIncome, chartOperatingExpenses, chartPropertyManagementReserve, chartNoi]
                                  },
                                ],
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row m-t-20">
                        <div className="col-md-6 m-t-10">
                          <div className="report-tab-chart-sub">
                            <h3 className="report-tab-chart-title">
                              Closing Costs
                            </h3>
                            <div className="table-responsive">
                              <table className="table report-tab-table">
                                {userReportData.financing_type == "loan" ? (
                                  <>
                                    <tr>
                                      <td className="">
                                        Earnest money deposit (2%)
                                      </td>
                                      <td className="font-color-gray">
                                        $
                                        {parseFloat(
                                          userReportData.earnest_money_deposit
                                        )
                                          .toFixed(2)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="">Property inspection</td>
                                      <td className="font-color-gray">
                                        $
                                        {parseFloat(
                                          userReportData.property_inspection
                                        )
                                          .toFixed(2)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="">
                                        Real estate transfer tax
                                      </td>
                                      <td className="font-color-gray">
                                        $
                                        {parseFloat(
                                          userReportData.real_estate_transfer_tax
                                        )
                                          .toFixed(2)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="">Title insurance fee</td>
                                      <td className="font-color-gray">
                                        $
                                        {parseFloat(
                                          userReportData.title_insurance_fee
                                        )
                                          .toFixed(2)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="">Origination fee</td>
                                      <td className="font-color-gray">
                                        $
                                        {parseFloat(
                                          userReportData.origination_fee
                                        )
                                          .toFixed(2)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="">Appraisal fees</td>
                                      <td className="font-color-gray">
                                        $
                                        {parseFloat(
                                          userReportData.appraisal_fees
                                        )
                                          .toFixed(2)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                    </tr>
                                    <tr className="bg-white-tr">
                                      <td className="">
                                        Total (Max of 5% purchase price or
                                        inputs entered)
                                      </td>
                                      <td className="">
                                        $
                                        {parseFloat(
                                          userReportData.closing_cost_total
                                        )
                                          .toFixed(2)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                    </tr>
                                  </>
                                ) : (
                                  <>
                                    <tr>
                                      <td className="">
                                        Earnest money deposit (2%)
                                      </td>
                                      <td className="font-color-gray">
                                        ${userReportData.earnest_money_deposit}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="">Property inspection</td>
                                      <td className="font-color-gray">
                                        ${userReportData.property_inspection}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="">
                                        Real estate transfer tax
                                      </td>
                                      <td className="font-color-gray">
                                        $
                                        {
                                          userReportData.real_estate_transfer_tax
                                        }
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="">Title insurance fee</td>
                                      <td className="font-color-gray">
                                        ${userReportData.title_insurance_fee}
                                      </td>
                                    </tr>
                                    <tr className="bg-white-tr">
                                      <td className="">
                                        Total (Max of 5% purchase price or
                                        inputs entered)
                                      </td>
                                      <td className="">
                                        ${userReportData.closing_cost_total}
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 m-t-10">
                          <div className="report-tab-chart-sub">
                            <h3 className="report-tab-chart-title">
                              Closing Costs
                            </h3>
                            <ReactEcharts
                              className="echarts-margin"
                              option={{
                                series: [
                                  {
                                    type: "pie",
                                    radius: ["50%", "70%"],
                                    center: ["50%", "50%"],
                                    selectedMode: "single",
                                    data: [
                                      {
                                        value:
                                          userReportData.real_estate_transfer_tax,
                                        itemStyle: { color: "#1d7ff0" },
                                        label: {
                                          formatter: [
                                            "{title1|$" +
                                              parseFloat(
                                                userReportData.real_estate_transfer_tax
                                              )
                                                .toFixed(2)
                                                .toString()
                                                .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                                ) +
                                              "}",
                                            "{title2|Real estate transfer tax}",
                                            "{br|}",
                                          ].join("\n"),
                                          rich: {
                                            title1: {
                                              align: "left",
                                              fontSize: "14",
                                            },
                                            title2: {
                                              color: "#999999",
                                              align: "left",
                                              fontSize: "14",
                                            },
                                          },
                                        },
                                      },
                                      {
                                        value:
                                          userReportData.title_insurance_fee,
                                        itemStyle: { color: "#f0087c" },
                                        label: {
                                          formatter: [
                                            "{title1|$" +
                                              parseFloat(
                                                userReportData.title_insurance_fee
                                              )
                                                .toFixed(2)
                                                .toString()
                                                .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                                ) +
                                              "}",
                                            "{title2|Title insurance fee}",
                                            "{br|}",
                                          ].join("\n"),
                                          rich: {
                                            title1: {
                                              align: "right",
                                              fontSize: "14",
                                            },
                                            title2: {
                                              color: "#999999",
                                              align: "right",
                                              fontSize: "14",
                                            },
                                          },
                                        },
                                      },
                                      {
                                        value:
                                          userReportData.earnest_money_deposit,
                                        itemStyle: { color: "#f0a808" },
                                        label: {
                                          formatter: [
                                            "{title1|$" +
                                              parseFloat(
                                                userReportData.earnest_money_deposit
                                              )
                                                .toFixed(2)
                                                .toString()
                                                .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                                ) +
                                              "}",
                                            "{title2|Earnest money deposit}",
                                            "{br|}",
                                          ].join("\n"),
                                          rich: {
                                            title1: {
                                              align: "right",
                                              fontSize: "14",
                                            },
                                            title2: {
                                              color: "#999999",
                                              align: "right",
                                              fontSize: "14",
                                            },
                                          },
                                        },
                                      },
                                      {
                                        value:
                                          userReportData.property_inspection,
                                        itemStyle: { color: "#8932fc" },
                                        label: {
                                          formatter: [
                                            "{title1|$" +
                                              parseFloat(
                                                userReportData.property_inspection
                                              )
                                                .toFixed(2)
                                                .toString()
                                                .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                                ) +
                                              "}",
                                            "{title2|Property inspection}",
                                            "{br|}",
                                          ].join("\n"),
                                          rich: {
                                            title1: {
                                              align: "right",
                                              fontSize: "14",
                                            },
                                            title2: {
                                              color: "#999999",
                                              align: "right",
                                              fontSize: "14",
                                            },
                                          },
                                        },
                                      },
                                    ],
                                  },
                                ],
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row m-t-20">
                        <div className="col-md-6 m-t-10">
                          <div className="report-tab-chart-sub">
                            <h3 className="report-tab-chart-title">
                              Financing
                            </h3>
                            <div className="table-responsive">
                              <table className="table report-tab-table">
                                <tr>
                                  <td className="td-width-60">
                                    Purchase price
                                  </td>
                                  <td className="font-color-gray">
                                    ${" "}
                                    <CurrencyInput
                                      type="text"
                                      name="purchange_price"
                                      value={
                                        parseFloat(purchange_price).toFixed(
                                          2
                                        ) || ""
                                      }
                                      onChange={
                                        this.handleOnChangePurchangePriceInput
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="td-width-60">Financing via</td>
                                  <td className="font-color-gray text-transform">
                                    {userReportData.financing_type}
                                  </td>
                                </tr>
                                {userReportData.financing_type == "loan" ? (
                                  <>
                                    <tr>
                                      <td className="td-width-60">Loan Type</td>
                                      <td className="font-color-gray text-transform">
                                        {userReportData.financing_loan_type}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="td-width-60">
                                        Percent of a down payment
                                      </td>
                                      <td
                                        colSpan="2"
                                        className="font-color-gray"
                                      >
                                        <input
                                          type="text"
                                          name="financing_down_payment"
                                          value={financing_down_payment || ""}
                                          onChange={this.handleOnChangeInput}
                                          onBlur={
                                            this.handleOnFocusDownPaymentInput
                                          }
                                        />{" "}
                                        %
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="td-width-60">
                                        Amount financed
                                      </td>
                                      <td
                                        colSpan="2"
                                        className="font-color-gray"
                                      >
                                        ${" "}
                                        <input
                                          type="text"
                                          name="financing_amount_financed"
                                          value={
                                            financing_amount_financed || ""
                                          }
                                          disabled
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="td-width-60">
                                        Interest rate
                                      </td>
                                      <td className="font-color-gray">
                                        <input
                                          type="text"
                                          name="financing_interest_rate"
                                          value={financing_interest_rate || ""}
                                          onChange={this.handleOnChangeInput}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="td-width-60">
                                        Amortization Term (months)
                                      </td>
                                      <td className="font-color-gray">
                                        <input
                                          type="text"
                                          name="financing_amortization_term"
                                          value={
                                            financing_amortization_term || ""
                                          }
                                          onChange={this.handleOnChangeInput}
                                        />
                                      </td>
                                    </tr>
                                  </>
                                ) : null}
                                <tr>
                                  <td className="td-width-60">
                                    Renovation Cost
                                  </td>
                                  <td className="font-color-gray">
                                    ${" "}
                                    <CurrencyInput
                                      type="text"
                                      name="renovation_cost"
                                      value={
                                        parseFloat(renovation_cost).toFixed(
                                          2
                                        ) || ""
                                      }
                                      onChange={this.handleOnChangeInput}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="td-width-60">Bank Reserve</td>
                                  <td className="font-color-gray">
                                    ${" "}
                                    <CurrencyInput
                                      type="text"
                                      name="financing_reserve"
                                      value={
                                        parseFloat(financing_reserve).toFixed(
                                          2
                                        ) || ""
                                      }
                                      onChange={this.handleOnChangeInput}
                                    />
                                  </td>
                                </tr>
                                {userReportData.financing_type == "loan" ? (
                                  <>
                                    <tr className="bg-white-tr">
                                      <td className="td-width-60">
                                        Total for down payment (less earnest
                                        money deposit) and renovation cost
                                      </td>
                                      <td className="">${totalDownPayment}</td>
                                    </tr>
                                  </>
                                ) : null}
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 m-t-10">
                          <div className="report-tab-chart-sub">
                            <h3 className="report-tab-chart-title">
                              Financing
                            </h3>
                            <ReactEcharts
                              className="echarts-margin"
                              option={{
                                series: [
                                  {
                                    type: "pie",
                                    radius: ["50%", "70%"],
                                    center: ["50%", "50%"],
                                    selectedMode: "single",
                                    data: [
                                      {
                                        value: Math.abs(
                                          downPayment
                                            .toString()
                                            .replace(/,/g, "")
                                        ),
                                        itemStyle: { color: "#009431" },
                                        label: {
                                          formatter: [
                                            "{title1|$" + downPayment + "}",
                                            "{title2|Down Payment}",
                                            "{br|}",
                                          ].join("\n"),
                                          rich: {
                                            title1: {
                                              align: "left",
                                              fontSize: "14",
                                            },
                                            title2: {
                                              color: "#999999",
                                              align: "left",
                                              fontSize: "14",
                                            },
                                          },
                                        },
                                      },
                                      {
                                        value: chartRenovationCost,
                                        itemStyle: { color: "#f0a808" },
                                        label: {
                                          formatter: [
                                            "{title1|$" +
                                              parseFloat(chartRenovationCost)
                                                .toFixed(2)
                                                .toString()
                                                .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                                ) +
                                              "}",
                                            "{title2|Renovation Cost}",
                                            "{br|}",
                                          ].join("\n"),
                                          rich: {
                                            title1: {
                                              align: "right",
                                              fontSize: "14",
                                            },
                                            title2: {
                                              color: "#999999",
                                              align: "right",
                                              fontSize: "14",
                                            },
                                          },
                                        },
                                      },
                                    ],
                                  },
                                ],
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row m-t-20">
                        <div className="col-md-12 text-center">
                          <button
                            className="btn btn-primary btn-with-icon back-button"
                            onClick={() =>
                              this.props.history.push(
                                `/report/${userReportData.id}`
                              )
                            }
                          >
                            <img src={arrowLeft} /> Back
                          </button>
                          <button
                            className="btn btn-primary btn-with-icon"
                            onClick={this.handleOnUpdateReport}
                          >
                            Update <img src={penIcon} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  {!userApiData ? null : (
                    <>
                      <TabPanel>
                        {userApiData.built_year != 0 ? (
                          <>
                            <div className="row">
                              <div className="col-md-8">
                                <div className="bg-gray-with-border">
                                  <p className="property-title">
                                    <img src={homeIcon} />{" "}
                                    {userApiData.property_class}
                                  </p>
                                  <div className="home-info">
                                    <span>{userApiData.no_of_bedrooms}Br</span>
                                    <span>{userApiData.no_of_bathrooms}Ba</span>
                                    <span>
                                      {userApiData.gross_size} living square
                                      feet
                                    </span>
                                  </div>
                                  <div className="prop-block">
                                    <span>Built in</span>
                                    <p>{userApiData.built_year}</p>
                                  </div>
                                  {!userApiData.sold_date ? null : (
                                    <div className="prop-block">
                                      <span>
                                        Last Sold {userApiData.sold_date} for
                                      </span>
                                      <p>
                                        $
                                        {parseFloat(userApiData.sold_amount)
                                          .toFixed(0)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </p>
                                    </div>
                                  )}
                                  <div className="prop-block">
                                    <span>Rooms</span>
                                    <p>{userApiData.total_room}</p>
                                  </div>
                                  <div className="prop-block">
                                    <span>living square feet</span>
                                    <p>
                                      {parseFloat(userApiData.lot_size)
                                        .toFixed(0)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </p>
                                  </div>
                                  <div className="prop-block">
                                    <span>Tax Assesment</span>
                                    <p>${userApiData.tax_amount}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="bg-gray-with-border">
                                  <p className="property-title">
                                    Market Analysis
                                  </p>
                                  <div className="home-info">
                                    <span>Estimated Market Value</span>
                                  </div>
                                  <div className="market-price">
                                    <p>
                                      $
                                      {parseFloat(userApiData.zest_year_price)
                                        .toFixed(0)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </p>
                                    <button className="btn btn-success">
                                      +$
                                      {parseFloat(userApiData.zest_month_price)
                                        .toFixed(0)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </button>
                                    <span>Last 30 days</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-md-6">
                                <div className="report-tab-chart-sub">
                                  <h3 className="report-tab-chart-title border-0">
                                    5 Year Estimated Value
                                  </h3>
                                  <ReactEcharts
                                    option={{
                                      tooltip: {
                                        trigger: "axis",
                                        // formatter: '<div class="line-chart-main"><span class="chart-label">{b}</span><br/><span class="chart-value">${c}</span><div class="bottom-arrow"></div></div>',
                                        formatter: function (params) {
                                          params = params[0];
                                          return (
                                            '<div class="line-chart-main"><span class="chart-label">' +
                                            params.name +
                                            '</span><br/><span class="chart-value">$' +
                                            params.value
                                              .toString()
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              ) +
                                            '</span><div class="bottom-arrow"></div></div>'
                                          );
                                        },
                                        axisPointer: {
                                          type: "none",
                                        },
                                        backgroundColor: "transparent",
                                      },
                                      xAxis: {
                                        type: "category",
                                        axisLine: {
                                          show: false,
                                        },
                                        axisTick: {
                                          show: false,
                                        },
                                        // data: ['Jan 2016', 'Jan 2017', 'Jan 2018', 'Jan 2019', 'Jan 2020']
                                        data: chartEstimatedName,
                                      },
                                      yAxis: {
                                        axisLine: {
                                          show: false,
                                        },
                                        axisTick: {
                                          show: false,
                                        },
                                        type: "value",
                                      },
                                      series: [
                                        {
                                          // data: [300000, 220000, 310000, 150000, 550000],
                                          data: chartEstimatedValue,
                                          type: "line",
                                          smooth: true,
                                          symbolSize: 15,
                                          color: "#3498DB",
                                          lineStyle: {
                                            width: 2,
                                            color: "#3498DB",
                                            shadowColor: "#000",
                                            shadowBlur: 10,
                                            shadowOffsetY: 15,
                                          },
                                        },
                                      ],
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <p className="prop-footer">
                              <strong>Median market value in this area</strong>{" "}
                              : Estimated Value - $
                              {parseFloat(userApiData.median_zest)
                                .toFixed(0)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </p>
                          </>
                        ) : (
                          <h3>No data for given Address</h3>
                        )}
                      </TabPanel>
                      <TabPanel>
                        {userApiData.built_year != 0 ? (
                          <>
                            <div className="comp-outer">
                              <div className="comps-map">
                                <div className="googleMap">
                                  <GoogleMap lat={latitude} lng={longitude} />
                                </div>
                              </div>

                              {userApiData.comp_property_details
                                ? userApiData.comp_property_details.property.map(
                                    (compPropertyDetails, keyIndx) => {
                                      return (
                                        <div
                                          key={keyIndx}
                                          className="comps-block"
                                        >
                                          <div className="bg-gray-with-border">
                                            <p className="property-title">
                                              <img src={homeIcon} /> $
                                              {parseFloat(
                                                compPropertyDetails.avm.amount
                                                  .value
                                              )
                                                .toFixed(0)
                                                .toString()
                                                .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                                )}
                                            </p>
                                            <div className="home-info">
                                              <span>
                                                {
                                                  compPropertyDetails.building
                                                    .rooms.bathstotal
                                                }
                                                Br
                                              </span>
                                              <span>
                                                {
                                                  compPropertyDetails.building
                                                    .rooms.beds
                                                }
                                                Ba
                                              </span>
                                              <span>
                                                {parseFloat(
                                                  compPropertyDetails.building
                                                    .size.universalsize
                                                )
                                                  .toFixed(0)
                                                  .toString()
                                                  .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}{" "}
                                                living square feet
                                              </span>
                                            </div>
                                            <p className="comps-address">
                                              {
                                                compPropertyDetails.address
                                                  .line1
                                              }
                                              <br />
                                              {
                                                compPropertyDetails.address
                                                  .line2
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )
                                : null}
                            </div>
                          </>
                        ) : (
                          <h3>No data for given Address</h3>
                        )}
                      </TabPanel>
                    </>
                  )}
                </Tabs>
              </div>
            </div>
          </div>

          <Footer></Footer>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  router: state.router,
  userReportById: state.reports.userReportById,
});

const mapDispatchToProps = (dispatch) => {
  const reduxActions = {
    ...ReportsReduxActionsCreators,
  };

  return { actions: bindActionCreators(reduxActions, dispatch) };
};

const connectedEditReportPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditReport);
export { connectedEditReportPage as EditReport };
