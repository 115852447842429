import React, {Component} from 'react';
import ReactModal from 'react-modal';

class PaymentSuccessUnsuccessModal extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

   
  componentWillMount() {
    ReactModal.setAppElement('body');
  }

  render() {
    const { 
      isModalOpen,
      children,
      handleOnRequestClose,
      customStyle,
      contentInfo,
      handleAfterOnModalOpened, popupTitle, popupImage, popupImageClass, popupBtnText, popupBtnAction, popupBtnClass, popupTitleClass, buttonIcon, buttonIconClass } = this.props;
      // console.log("popupBtnAction123----",popupBtnAction123)
    return (
      <ReactModal 
        shouldCloseOnOverlayClick={false} 
        isOpen={isModalOpen}
        onRequestClose={handleOnRequestClose}
        className={customStyle}
        onAfterOpen={handleAfterOnModalOpened}
        contentLabel={contentInfo}
      >
        <div className="modal-content multi-step">
            <div className="modal-body multi-step">
                <div className="text-center">
                  <h2 className={`popup-title ${popupTitleClass}`}>{popupTitle}</h2>
                  <img className={`m-b-15 ${popupImageClass}`} src={popupImage} />
                  {children}
                  <button className={`btn payment-btn ${popupBtnClass}`} onClick={popupBtnAction}>{popupBtnText} <img className={buttonIconClass} src={buttonIcon} /></button>
                </div>
            </div>
        </div>
      </ReactModal>
    )
  }
}

const connectedPaymentSuccessUnsuccessModal = PaymentSuccessUnsuccessModal;
export {connectedPaymentSuccessUnsuccessModal as PaymentSuccessUnsuccessModal};
